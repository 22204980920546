import { memo } from 'react'
import { Navigate, Outlet } from "react-router";
import { useAuthToken } from "../../config/auth";

const AuthGate = () => {
    const [authToken] = useAuthToken();
    return authToken ? <Outlet /> : <Navigate to="/" />;
};

export default memo(AuthGate);
