import React, { memo } from 'react';
import List from '@mui/material/List';
import ItemMotel from './ItemMotel'
import { useListMotel } from "./useListMotel";


export default memo(function ListMotel({ data, clicked, motelId }) {
  const classes = useListMotel()

  const handleClick = (item) => {
    clicked(item.id)
  }

  return (
    <List className={classes.list}>
      {
        data.map((value, index) => {
          return (
            <ItemMotel
              key={index}
              id={value.id}
              name={value.name}
              address={value.address}
              district={value.district}
              city={value.city}
              rentFee={value.rentFee}
              totalRoom={value.totalRoom}
              isSelected={value.id === motelId}
              clicked={() => handleClick(value)}
            />
          )
        })
      }

    </List>
  );
}) 