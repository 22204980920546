import { gql } from "@apollo/client";

const MOTEL_REPRESENTATIVES = gql`
    query motelRepresentatives {
        motelRepresentatives {
            id
            fullName
            address
            phoneNumber
            birthday
            idNumber
            idDate
            idAt
            isDefault
        }
    }
`;

const GET_MOTEL_REPRESENTATIVE = gql`
    query getMotelRepresentative($id: Int!) {
        getMotelRepresentative(id: $id) {
            fullName
            address
            phoneNumber
            birthday
            idNumber
            idDate
            idAt
            isDefault
        }
    }
`;

export { MOTEL_REPRESENTATIVES, GET_MOTEL_REPRESENTATIVE };
