import { makeStyles } from "@mui/styles";

export const useContainerServices = makeStyles({
    container: {
        padding: 0,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        margin: "0",
        gap: "10px",
        height: "calc(100% - 172px)",
        "@media screen and (max-width: 1280px)": {
            height: "calc(100% - 132px)",
        },
        "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
            height: "calc(100% - 162px)",
        },
    },

    containerService: {
        backgroundColor: "#fff",
        padding: "16px",
        boxShadow: "0px 6px 18px 0px #0000000F",
        borderRadius: "5px",
        height: "100%",
        overflow: "auto",
        width: "65.5%",
        "@media screen and (max-width: 1280px)": {
            width: "49.5%",
        },
        "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
            width: "59.5%",
        },
    },
    containerCardServices: {
        display: "flex",
        gap: "20px",
        marginBottom: "30px",
        flexWrap: "wrap",
        width: "100%",
    },
    title: {
        fontSize: "15px",
        color: "#192A3E",
        fontWeight: "700",
        marginBottom: "12px",
    },
    chips: {
        display: "flex",
        gap: "10px",
        marginBottom: "30px",
        flexWrap: "wrap",
        width: "100%",
    },
    subNote: {
        color: "#979ea8",
        marginBottom: "30px",
        wordWrap: "break-word",
        overflow: "hidden",
        maxWidth: "780px",
        width: "100%",
    },

    containerRoom: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        height: "100%",
        overflow: "auto",
        width: "34.3%",
        "@media screen and (max-width: 1280px)": {
            width: "49.5%",
        },
        "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
            width: "39.5%",
        },
    },
});
