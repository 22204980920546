import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Notification from "../../../components/Notification/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Paging from "../../../components/Paging/Paging";
import Loading from "../../../components/Loading/Loading";
import { headCellRepresentatives } from "../../../constants/Constants";
import Table from "./Table/Table";
import { MOTEL_REPRESENTATIVES } from "../../../graphql-client/Representatives/query";
import { useRepresentative } from "./useRepresentative";
import { saveState } from "../../../util/LocalStateSave";
import { SearchByKeyword } from "../../../util/SearchByKeyword";
import { HomeContext } from "../../../layout/HomeLayout/HomeLayout";

const Representatives = () => {
    const classes = useRepresentative();
    const [page, setPage] = useState(1);
    const [sliceData, setSliceData] = useState([]);
    const [totalRep, setTotalRep] = useState(0);
    const searchText = useContext(HomeContext).dbValue;
    const { loading: loadingRepresentatives, data: dataRepresentatives } =
        useQuery(MOTEL_REPRESENTATIVES);

    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    useEffect(() => {
        saveState([], "selected");
    }, [loadingRepresentatives]);

    useEffect(() => {
        if (!loadingRepresentatives) {
            const getData = dataRepresentatives.motelRepresentatives;
            const searchData = SearchByKeyword(getData, "fullName", searchText);
            setTotalRep(searchData.length);
            setSliceData(
                searchData.reverse().slice((page - 1) * 12, page * 12)
            );
        }
    }, [loadingRepresentatives, dataRepresentatives, page, searchText]);
    return (
        <>
            <Box sx={{ flexGrow: 1 }} className={classes.box}>
                {loadingRepresentatives ? (
                    <Loading />
                ) : (
                    <Grid
                        container
                        spacing={2}
                        className={classes.gridContainer}
                    >
                        <Grid item xs={12} className={classes.gridTable}>
                            <Grid
                                item
                                xs={12}
                                className={classes.gridItemTable}
                            >
                                <Table
                                    headCells={headCellRepresentatives}
                                    rowsData={sliceData}
                                    setNotify={setNotify}
                                    setConfirmDialog={setConfirmDialog}
                                    page={page}
                                    setPage={setPage}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.gridItemPaging}
                            >
                                <Paging
                                    totalPages={Math.ceil(totalRep / 12)}
                                    currentPage={page}
                                    onChangePage={(event, value) => {
                                        setPage(value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
};

export default Representatives;
