import { gql, useMutation } from "@apollo/client";
import { MOTEL_REPRESENTATIVES } from "./query";

const ADD_REPRESENTATIVE = gql`
    mutation addMotelRepresentative($req: AddMotelRepresentativeReq!) {
        addMotelRepresentative(req: $req) {
            ok
        }
    }
`;

function useAddRepresentative() {
    const [mutateAddRepresentative, { data, loading, error }] =
        useMutation(ADD_REPRESENTATIVE);

    const handleAddRepresentative = async (variables) => {
        try {
            await mutateAddRepresentative({
                variables,
                refetchQueries: [{ query: MOTEL_REPRESENTATIVES }],
            });
        } catch (err) {}
    };

    return {
        mutateAddRepresentative: handleAddRepresentative,
        dataAddRepresentative: data,
        loadingAddRepresentative: loading,
        errorAddRepresentative: error,
    };
}

const UPDATE_REPRESENTATIVE = gql`
    mutation updateMotelRepresentative(
        $id: Int!
        $req: UpdateMotelRepresentativeReq!
    ) {
        updateMotelRepresentative(id: $id, req: $req) {
            ok
        }
    }
`;

function useUpdateRepresentative() {
    const [mutateUpdateRepresentative, { data, loading, error }] = useMutation(
        UPDATE_REPRESENTATIVE
    );

    const handleUpdateRepresentative = async (variables) => {
        try {
            await mutateUpdateRepresentative({
                variables,
                refetchQueries: [{ query: MOTEL_REPRESENTATIVES }],
            });
        } catch (err) {}
    };

    return {
        mutateUpdateRepresentative: handleUpdateRepresentative,
        dataUpdateRepresentative: data,
        loadingUpdateRepresentative: loading,
        errorUpdateRepresentative: error,
    };
}

const DELETE_REPRESENTATIVE = gql`
    mutation deleteMotelRepresentative($id: Int!) {
        deleteMotelRepresentative(id: $id) {
            ok
        }
    }
`;

function useDeleteRepresentative() {
    const [mutateDeleteRepresentative, { data, loading, error }] = useMutation(
        DELETE_REPRESENTATIVE
    );

    const handleDeleteRepresentative = async (variables) => {
        try {
            await mutateDeleteRepresentative({
                variables,
                refetchQueries: [
                    {
                        query: MOTEL_REPRESENTATIVES,
                    },
                ],
            });
        } catch (error) {}
    };
    return {
        mutateDeleteRepresentative: handleDeleteRepresentative,
        dataDeleteRepresentative: data,
        loadingDeleteRepresentative: loading,
        errorDeleteRepresentative: error,
    };
}

const SET_DEFAULT_REPRESENTATIVE = gql`
    mutation setDefaultMotelRepresentative($id: Int!) {
        setDefaultMotelRepresentative(id: $id) {
            ok
        }
    }
`;

function useDefaultRepresentative() {
    const [mutateDefaultRepresentative, { data, loading, error }] = useMutation(
        SET_DEFAULT_REPRESENTATIVE
    );

    const handleDefaultRepresentative = async (variables) => {
        try {
            await mutateDefaultRepresentative({
                variables,
                refetchQueries: [
                    {
                        query: MOTEL_REPRESENTATIVES,
                    },
                ],
            });
        } catch (error) {}
    };

    return {
        mutateDefaultRepresentative: handleDefaultRepresentative,
        dataDefaultRepresentative: data,
        loadingDefaultRepresentative: loading,
        errorDefaultRepresentative: error,
    };
}

export {
    useAddRepresentative,
    useUpdateRepresentative,
    useDeleteRepresentative,
    useDefaultRepresentative,
};
