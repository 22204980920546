import React, { memo } from "react";
import ListItem from "@mui/material/ListItem";
import BoxNumber from "../../../../components/BoxNumber/BoxNumber";
import { Box, Typography } from "@mui/material";
import { useItemListContent } from "./useItemListContent";
const ItemListContent = ({
    title,
    subTitle,
    counterNumber,
    clicked,
    isSelected,
}) => {
    const classes = useItemListContent();

    return (
        <>
            <ListItem
                className={`${classes.item} ${
                    isSelected ? classes.active : ""
                }`}
                onClick={clicked}
            >
                <Box className={classes.content}>
                    <Typography className={classes.title}>{title}</Typography>
                    <Typography className={classes.subTitle}>
                        {subTitle}
                    </Typography>
                </Box>
                <Box className={classes.counter}>
                    <BoxNumber number={counterNumber} />
                </Box>
            </ListItem>
        </>
    );
};

export default memo(ItemListContent);
