import React, { useState, useEffect, memo, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ModalAdd from "../ModalAdd/ModalAdd";
import ModalUpdate from "../ModalUpdate/ModalUpdate";
import {
    validateNullBirthday,
    validateNullIdDate,
} from "../../../../useCase/Representative/useCaseRepresentative";
import ButtonHome from "../../../../components/ButtonHome/ButtonHome";
import BoxNumber from "../../../../components/BoxNumber/BoxNumber";
import EmptyIcon from "../../../../assets/Icons/EmptyIcon";

import { useTable } from "./useTable";
import { saveState, loadState } from "../../../../util/LocalStateSave";
import {
    checkIncludeArray,
    removeItems,
} from "../../../../util/ArrayHandle.js";
import {
    useDeleteRepresentative,
    useDefaultRepresentative,
} from "../../../../graphql-client/Representatives/mutation";
import {
    representativesText,
    notifyLostConnect,
} from "../../../../constants/Constants";

const EnhancedTableHead = memo(({ onSelectAllClick, rowCount, headCells }) => {
    const classes = useTable();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "header",
                        }}
                        className={classes.checkBox}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        className={classes.headCell}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
});

const EnhancedTableToolbar = memo(({ numSelected, removed }) => {
    const classes = useTable();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        return true;
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Toolbar className={classes.toolbar}>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                }}
            >
                <BoxNumber number={numSelected} />
                <Typography
                    className={classes.typography}
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} đã chọn
                </Typography>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton
                            className={classes.iconButton}
                            onClick={removed}
                        >
                            <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Box>
            <Box style={{ width: "15%" }}>
                <ButtonHome text="Thêm đại diện" onClick={handleOpen} />
                <ModalAdd closeAdd={handleClose} openAdd={open}></ModalAdd>
            </Box>
        </Toolbar>
    );
});

const EnhancedTable = ({
    headCells,
    rowsData,
    setNotify,
    setConfirmDialog,
    page,
    setPage,
}) => {
    const classes = useTable();
    const [selected, setSelected] = useState([]);
    const location = useLocation();
    const pathname = location.pathname;
    const {
        mutateDeleteRepresentative,
        errorDeleteRepresentative,
        dataDeleteRepresentative,
    } = useDeleteRepresentative();
    const { mutateDefaultRepresentative } = useDefaultRepresentative();
    const [selectedId, setSelectedId] = useState(null);
    const [openUpdate, setOpenUpdate] = useState(false);

    const handleOpenUpdate = (id) => {
        setOpenUpdate(true);
        setSelectedId(id);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setSelectedId(null);
    };

    useLayoutEffect(() => {
        setSelected(loadState("selected"));
    }, []);

    useEffect(() => {
        setSelected([]);
    }, [pathname]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rowsData.map((n) => n.id);
            const uniqueSelected = [...new Set(selected.concat(newSelecteds))];
            saveState(uniqueSelected, "selected");
            setSelected(uniqueSelected);
            return;
        }

        const spliceSelected = removeItems(
            rowsData.map((n) => n.id),
            loadState("selected")
        );
        saveState(spliceSelected, "selected");
        setSelected(spliceSelected);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        saveState(newSelected, "selected");
        setSelected(loadState("selected"));
    };
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleRemoved = () => {
        setConfirmDialog({
            // isOpen: true,
            // title: "Bạn có chắc chắn xóa bản ghi này không?",
            // subTitle: "Bạn không thể hoàn tác thao tác này",
            ...representativesText.dialog.delete,
            onConfirm: () => {
                let listRemove = selected.map((element) => parseInt(element));
                listRemove.forEach((element) => {
                    mutateDeleteRepresentative({ id: element });
                });
                saveState([], "selected");
                setSelected([]);
                setConfirmDialog({
                    isOpen: false,
                    title: "",
                    subTitle: "",
                });
            },
        });
    };

    const setDefaultRepresentative = (idRep) => {
        setConfirmDialog({
            ...representativesText.dialog.changeDefaultRepresentative,
            onConfirm: () => {
                mutateDefaultRepresentative({ id: idRep });
                setConfirmDialog({
                    isOpen: false,
                    title: "",
                    subTitle: "",
                });
            },
        });
    };

    useEffect(() => {
        if (errorDeleteRepresentative)
            setNotify({
                ...notifyLostConnect,
            });
    }, [errorDeleteRepresentative, setNotify]);

    useEffect(() => {
        if (dataDeleteRepresentative)
            setNotify({
                ...representativesText.notify.delete,
            });
    }, [dataDeleteRepresentative, setNotify]);

    useEffect(() => {
        if (rowsData.length === 0 && page !== 1) {
            let lastPage = page - 1;
            setPage(lastPage);

            setSelected(loadState("selected"));
        }
    }, [rowsData, setPage, page, selected]);

    return (
        <>
            <Paper sx={{ width: "100%", mb: 2 }} className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    selectedList={selected}
                    removed={handleRemoved}
                />
                <Divider />
                {rowsData.length > 0 ? (
                    <TableContainer className={classes.tableContainer}>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-label="sticky table"
                            stickyHeader={true}
                        >
                            <EnhancedTableHead
                                headCells={headCells}
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={checkIncludeArray(
                                    rowsData.map((n) => n.id),
                                    loadState("selected")
                                )}
                            />

                            <TableBody>
                                {rowsData.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            aria-checked={isItemSelected}
                                            className={classes.tableRow}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                width="5%"
                                            >
                                                <Checkbox
                                                    className={classes.checkBox}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby":
                                                            labelId,
                                                    }}
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell
                                                width="18%"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                onClick={() =>
                                                    handleOpenUpdate(row.id)
                                                }
                                                className={classes.tableCell}
                                            >
                                                {row.fullName}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                                width="12%"
                                                onClick={() =>
                                                    handleOpenUpdate(row.id)
                                                }
                                            >
                                                {row.phoneNumber}
                                            </TableCell>
                                            <TableCell
                                                width="12%"
                                                onClick={() =>
                                                    handleOpenUpdate(row.id)
                                                }
                                            >
                                                {validateNullBirthday(
                                                    row.birthday
                                                )}
                                            </TableCell>
                                            <TableCell
                                                width="12%"
                                                onClick={() =>
                                                    handleOpenUpdate(row.id)
                                                }
                                            >
                                                {row.idNumber}
                                            </TableCell>
                                            <TableCell
                                                width="17%"
                                                onClick={() =>
                                                    handleOpenUpdate(row.id)
                                                }
                                                className={classes.tableCell}
                                            >
                                                {validateNullIdDate(
                                                    row.idDate,
                                                    row.idAt
                                                )}
                                            </TableCell>
                                            <TableCell
                                                width="17%"
                                                onClick={() =>
                                                    handleOpenUpdate(row.id)
                                                }
                                                className={classes.tableCell}
                                            >
                                                {row.address}
                                            </TableCell>
                                            <TableCell width="12%">
                                                {row.isDefault ? (
                                                    <Button
                                                        className={`
                                                            ${classes.btnDefault} active`}
                                                        size="small"
                                                        variant="outlined"
                                                        disableRipple={true}
                                                        disableFocusRipple={
                                                            true
                                                        }
                                                        disableElevation={true}
                                                    >
                                                        Mặc định
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        className={`${classes.btnDefault}`}
                                                        size="small"
                                                        variant="outlined"
                                                        disableRipple={true}
                                                        disableFocusRipple={
                                                            true
                                                        }
                                                        disableElevation={true}
                                                        onClick={() =>
                                                            setDefaultRepresentative(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        Đặt là mặc định
                                                    </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box className={classes.emptyData}>
                        <EmptyIcon />
                    </Box>
                )}
            </Paper>
            <ModalUpdate
                closeAdd={handleCloseUpdate}
                openAdd={openUpdate}
                representativeId={selectedId}
            />
        </>
    );
};

export default EnhancedTable;
