import { validateEmailUtil } from "../../util/ValidateEmail";
import {
    alertEmail,
    alertCode,
    alertNewPassword,
} from "../../constants/Constants";

export const validateSendEmail = (email) => {
    let msg = {};

    if (!email) {
        msg.email = alertEmail.blank;
    } else {
        if (!validateEmailUtil(email)) {
            msg.email = alertEmail.validate;
        }
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
};

export const validateNewPassword = (code, newPassword) => {
    let msg = {};

    if (!code) {
        msg.code = alertCode.blank;
    }
    if (!newPassword) {
        msg.newPassword = alertNewPassword.blank;
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
};
