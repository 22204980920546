import {DeleteOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {Button, Form, InputNumber, Modal, notification, Popconfirm, Space, Table, Tabs} from 'antd';
import {useForm} from 'antd/es/form/Form';
import dayjs from 'dayjs';
import * as PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {CustomDatePicker, CustomMonthPicker} from '../../components/CustomDatePicker';
import {RoomName} from '../../components/Room';
import {SearchableSelect} from '../../components/SearchableSelect';
import {TenantName} from '../../components/Tenant';
import UploadImages from '../../components/UploadImages';
import {MY_MOTELS} from '../../graphql-client/Motel/query';
import {useCreateStatement, useDeleteStatement, useUpdateStatement} from '../../graphql-client/Statement/mutation';
import {
    GET_NEW_STATEMENT,
    QUERY_GET_STATEMENT,
    QUERY_ROOM_BY_STATEMENT,
    QUERY_ROOM_STATEMENTS,
} from '../../graphql-client/Statement/query';
import {formatDate, parseDate} from '../../util/Date';
import {parseImageUrl} from '../../util/Image';
import {zeroPad} from '../../util/Number';
import {normalizeRequestObject} from '../../util/Request';
import {isUnitService} from '../../util/Service';

function TabNoStatement({startDate, endDate, motelIds, hasStatement}) {
    let {t} = useTranslation();
    const request = {
        startDate: startDate, endDate: endDate, hasStatement, hasContract: true, motelIds: motelIds,
    };

    const shouldSkipQuery = !startDate || !endDate;
    const {data, loading, refetch} = useQuery(QUERY_ROOM_BY_STATEMENT, {
        variables: normalizeRequestObject(request), skip: shouldSkipQuery,
    });

    useDeepCompareEffect(() => {
        if (!shouldSkipQuery) {
            refetch(normalizeRequestObject(request));
        }
    }, [request]);
    const dataRooms = data?.roomsByStatementStatus;

    const [showModal, setShowModal] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState();

    function openCreateStatement(roomId, roomContractId) {
        setSelectedRoom({
            roomId, roomContractId,
        });
        setShowModal(true);
    }

    function closeModal() {
        setSelectedRoom(null);
        setShowModal(false);
    }

    return <>
        <Table
            loading={loading}
            dataSource={dataRooms}
            rowKey={(record, index) => {
                return record.id;
            }}

            columns={[
                {
                    title: t('room'), render: (value, record, index) => {
                        return <RoomName room={record}/>;
                    },
                }, {
                    title: t('tenant'), dataIndex: ['tenants'], render: (value, record, index) => {
                        return <TenantName tenant={value[0]}/>;
                    },
                }, {
                    title: t('action'), render: (value, record, index) => {
                        return <>
                            <Button type="primary"
                                    onClick={() => openCreateStatement(record.id, record.activeContractId)}>{t(
                                'createStatement')}</Button>
                        </>;
                    },
                },

            ]}

        />
        <ModalStatementDetail open={showModal} onCancel={() => {
            closeModal();
        }} selectedRoomId={selectedRoom?.roomId} statementAt={startDate} roomContractId={selectedRoom?.roomContractId}/>

    </>;
}

function ModalStatementDetail({onCancel, open, selectedRoomId, statementAt, roomContractId, statementId}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState({
        statementAt: dayjs(new Date()),
    });

    const variables = {
        roomId: selectedRoomId, statementAt: statementAt,
    };
    const {data: newStatementData} = useQuery(GET_NEW_STATEMENT, {
        variables: normalizeRequestObject(variables), skip: !selectedRoomId,
    });

    const {data: statementData, loading: getStatementLoading} = useQuery(QUERY_GET_STATEMENT, {
        variables: {
            id: statementId,
        }, skip: !statementId,
    });
    const [services, setServices] = useState([]);

    const {updateStatement, loading: updateLoading} = useUpdateStatement();

    useEffect(() => {
        if (statementData) {
            const roomStatement = statementData.getRoomStatement;
            const newServices = roomStatement.serviceStatements.map(item => item.service);
            setServices(newServices);

            setInitialValues({
                id: roomStatement.id,
                statementAt: parseDate(roomStatement.statementAt),
                roomContractId: roomStatement.roomContractId,
                serviceStatements: roomStatement?.serviceStatements.map(item => {
                    return {
                        serviceId: item.serviceId,
                        totalUnit: item.totalUnit,
                        lastIndex: item.lastIndex,
                        index: item.index,
                        imageUrl: item.imageUrl ? [parseImageUrl(item.imageUrl)]: null,
                    };
                }),
            });
        }
    }, [statementData]);

    useEffect(() => {
        if (newStatementData) {

            const newRoomStatement = newStatementData?.getNewRoomStatement;
            const newServices = newRoomStatement?.serviceStatements?.map(item => item.service);

            setServices(newServices);
            const initValue = {
                roomContractId: roomContractId, serviceStatements: newRoomStatement?.serviceStatements.map(item => {
                    let totalUnit = null;
                    if (item.feeType === 'room') {
                        totalUnit = 1;
                    } else if (item.feeType === 'tenant') {
                        totalUnit = newStatementData.getNewRoomStatement.room.totalTenant;
                    }

                    return {
                        serviceId: item.service.id, totalUnit, lastIndex: item.lastIndex,
                    };
                }),
            };
            setInitialValues({...initialValues, ...initValue});
        }
    }, [newStatementData]);

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues]);

    const idToService = services?.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
    }, {});

    function getServiceFromForm(name) {
        let serviceId = form.getFieldValue(['serviceStatements', name, 'serviceId']);
        return idToService ? idToService[serviceId] : null;
    }

    function handleValuesChange(changedValues, allValues) {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName === 'serviceStatements') {
            form.setFieldValue('serviceStatements', allValues.serviceStatements?.map(item => {
                const serviceId = item.serviceId;
                const service = idToService[serviceId];
                if (isUnitService(service) && item.index && item.lastIndex) {
                    return {...item, totalUnit: item.index - item.lastIndex};
                }
                return item;
            }));
        }

    }

    const {createStatement, createLoading} = useCreateStatement();

    async function handleCreateStatement(values) {
        try {
            await createStatement(normalizeRequestObject(values));
            notification.success({message: t('statement.createSuccess')});
            onCancel();
        } catch (e) {
            notification.error({
                message: t('statement.createFailed'), description: e?.message,
            });
        }
    }

    async function handleUpdateStatement(values) {
        try {
            await updateStatement(statementId, normalizeRequestObject(values));
            notification.success({message: t('statement.updateSuccess')});
            onCancel();
        } catch (e) {
            notification.error({
                message: t('statement.updateFaield'), description: e?.message,
            });
        }
    }

    async function handleSubmit() {
        const values = await form.validateFields();

        // get only first image
        values.serviceStatements = values.serviceStatements?.map(item => {
            if (item.imageUrl) {
                return {...item, imageUrl: item.imageUrl[0]};
            }
            return item;
        });
        if (!statementId) {
            await handleCreateStatement(values);
        } else {
            await handleUpdateStatement(values);
        }

    }

    const title = statementId? t('statement.update'): t('statement.create');

    return <Modal title={title} open={open} onCancel={onCancel} width={900}
                                                     onOk={handleSubmit} confirmLoading={createLoading || updateLoading}>
        <Form form={form} layout="vertical" onValuesChange={handleValuesChange}>
            <Form.Item name={'roomContractId'} style={{display: 'none'}}>
                <></>
            </Form.Item>
            <Form.Item label={t('statement.createdAt')} name={'statementAt'}>
                <CustomDatePicker/>
            </Form.Item>
            <Form.List name="serviceStatements">
                {fields => {
                    return (<Table
                        rowKey={(record, index) => {
                            return index;
                        }}
                        dataSource={fields}
                        columns={[
                            {
                                title: t('service.service'), render: (value, {key, name, ...restField}, index) => {
                                    let service = getServiceFromForm(name);
                                    return <span>{service?.name}</span>;
                                },
                            }, {
                                title: t('service.lastIndex'), render: (value, {key, name, ...restField}, index) => {
                                    let service = getServiceFromForm(name);
                                    if (isUnitService(service)) {
                                        return <Form.Item {...restField} name={[name, 'lastIndex']}
                                                          rules={[{required: true}]}>
                                            <InputNumber placeholder={service.unit}/>
                                        </Form.Item>;
                                    }
                                    return <></>;

                                },
                            }, {
                                title: t('service.index'), render: (value, {key, name, ...restField}, index) => {
                                    let service = getServiceFromForm(name);
                                    return isUnitService(service) ?
                                        <Form.Item {...restField} name={[name, 'index']} rules={[{required: true}]}>
                                            <InputNumber placeholder={service.unit}/>
                                        </Form.Item> :
                                        <></>;
                                },
                            }, {
                                title: t('service.totalUnit'), render: (value, {key, name, ...restField}, index) => {
                                    const service = getServiceFromForm(name);
                                    const unitService = isUnitService(service);
                                    const placeholder = unitService ? service.unit : t('service.totalUnit');
                                    return <Form.Item {...restField} name={[name, 'totalUnit']}
                                                      rules={[{required: true}]}>
                                        <InputNumber placeholder={placeholder} disabled={unitService}/>
                                    </Form.Item>;
                                },
                            }, {
                                title: t('service.image'), render: (value, {key, name, ...restField}, index) => {
                                    let imageUrls = form.getFieldValue(['serviceStatements', name, 'imageUrl']);
                                    return <UploadImages name={[name, 'imageUrl']} maxTotalImage={1} initialFileList={imageUrls}/>;
                                },
                            },

                        ]}
                    />);
                }}
            </Form.List>

        </Form>
    </Modal>;
}

ModalStatementDetail.propTypes = {
    open: PropTypes.bool, onCancel: PropTypes.func, selectedRoomId: PropTypes.string,
};

function TabStatements({startDate, endDate, motelIds}) {
    const {t} = useTranslation();
    const variables = {
        startDate, endDate, motelIds,
    };
    const {data, loading} = useQuery(QUERY_ROOM_STATEMENTS, {
        variables: normalizeRequestObject(variables),
    });
    const roomStatements = data?.roomStatements;
    const {deleteStatement} = useDeleteStatement();

    async function handleDeleteStatement(statementId) {
        try {
            await deleteStatement(statementId);
            notification.success({
                message: t('statement.deleteSuccess'),
            });
        } catch (e) {
            notification.error({
                message: t('statement.deleteFailed'), description: e?.message,
            });
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [selectedStatementId, setSelectedStatementId] = useState();

    function openStatementDetail(statementId) {
        setSelectedStatementId(statementId);
        setShowModal(true);
    }

    function closeModal() {
        setSelectedStatementId(null);
        setShowModal(false);
    }

    return <><Table
        loading={loading}
        dataSource={roomStatements}
        rowKey={(record) => {
            return record.id;
        }}
        columns={[
            {
                title: '#', dataIndex: ['id'], render: (value, record, index) => {
                    return <a href="#" onClick={() => {
                        openStatementDetail(record.id);
                    }}>{`#${zeroPad(record.id)}`}</a>;
                },
            }, {
                title: t('room'), dataIndex: ['room'], render: (value, record, index) => {
                    return <RoomName room={value}/>;
                },
            }, {
                title: t('tenant'), dataIndex: ['roomContract', 'representativeTenant', 'fullName'],
            }, {
                title: t('statement.createdAt'), dataIndex: ['statementAt'], render: (value, record, index) => {
                    return formatDate(value);
                },
            }, {
                title: t('statement.createdBy'), dataIndex: ['creator', 'fullName'],
            }, {
                title: t('action'), render: (value, record, index) => {
                    return <Popconfirm title={t('statement.deleteConfirm')} onConfirm={() => {
                        handleDeleteStatement(record.id);
                    }}>
                        <DeleteOutlined onClick={() => {
                        }}/>
                    </Popconfirm>;
                },
            }]}
    />
        <ModalStatementDetail open={showModal} onCancel={() => {
            closeModal();
        }} statementId={selectedStatementId}/>
    </>;
}

TabStatements.propTypes = {
    endDate: PropTypes.any, motelIds: PropTypes.array, startDate: PropTypes.any,
};

const RoomStatement = () => {
    let {t} = useTranslation();
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue({
            month: dayjs(new Date()),
        });
    }, []);

    const month = Form.useWatch('month', form);
    const startDate = month?.startOf('month');
    const endDate = month?.endOf('month');

    const motelIds = Form.useWatch('motelIds', form);

    const {data: motelData} = useQuery(MY_MOTELS);
    const motels = motelData?.myMotels?.map(item => {
        return {
            label: item.name, value: item.id,
        };
    });

    const items = [
        {
            key: 'no_statement',
            label: t('roomNoStatement'),
            children: <TabNoStatement startDate={startDate} endDate={endDate} motelIds={motelIds}
                                      hasStatement={false}/>,
        }, {
            key: 'has_statement',
            label: t('roomHasStatement'),
            children: <TabStatements startDate={startDate} endDate={endDate} motelIds={motelIds}/>,
        }];

    return <>
        <div>
            <Form form={form} layout={'vertical'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Space>
                    <Form.Item label={t('motel')} name={'motelIds'} style={{minWidth: 200}}>
                        <SearchableSelect mode={'multiple'} allowClear={true} placeholder={t('pleaseSelectMotel')}
                                          options={motels}/>
                    </Form.Item>
                    <Form.Item label={t('time.time')} name={'month'} style={{width: 200}}>
                        <CustomMonthPicker/>
                    </Form.Item>
                </Space>
            </Form>
        </div>
        <Tabs defaultActiveKey="no_statement" items={items}/>
    </>;
};

export default RoomStatement;
