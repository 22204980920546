import { makeStyles } from "@mui/styles";

export const useTable = makeStyles((theme) => ({
    emptyData: {
        height: "90%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    checkBox: {
        color: "#D5D5D5",
        "&.Mui-checked": {
            color: "#04C35C",
        },
    },
    headCell: {
        color: "rgba(4, 195, 92, 1)",
        fontSize: "16px",
        lineHeight: "19px",
        fontStyle: "normal",
    },
    deleteIcon: {
        color: "#C2CFE0",
    },
    iconButton: {
        padding: 0,
        margin: 0,
    },
    buttonAdd: {
        display: "flex",
        alignItems: "center",
    },
    toolbar: {
        gap: "24px",
        paddingLeft: "16px",
        justifyContent: "space-between",
    },
    typography: {
        color: "rgba(51, 77, 110, 1)",
    },
    tableRow: {
        "&.Mui-selected": {
            backgroundColor: "#FFFFFF",
        },
        "&.Mui-selected:hover": {
            backgroundColor: "#FFFFFF",
        },
    },
    paper: {
        boxShadow: "none",
        overflow: "hidden",
        margin: 0,
        height: "100%",
    },

    tableCell: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        textWrap: "break-word",
        maxWidth: 0,
    },
    // tableCellAddress: {
    //     textAlign: "right",
    //     overflow: "hidden",
    //     textOverflow: "ellipsis",
    //     textWrap: "break-word",
    //     maxWidth: 0,
    // },

    tableContainer: {
        height: "calc(100% - 80px)",
    },
    btnDefault: {
        border: "2px solid #FFB946",
        textTransform: "none",
        width: "120px",
        height: "35px",
        color: "#FFB946",
        "&:hover": {
            border: "2px solid #FFB946",
            backgroundColor: "#FFFFFF",
        },
        "&.active": {
            border: "2px solid  #FF3C5F",
            color: " #FF3C5F",
        },
    },
}));
