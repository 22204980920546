/* eslint-disable */
import { makeStyles } from "@mui/styles";
export const useModalContainer = makeStyles({
    modalStyle: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        boxShadow: "0px 6px 18px rgba(0, 0, 0, 0.06)",
        maxHeight: "85%",
        ["@media screen and (max-width: 1280px)"]: {
            height: "90%",
        },
        overflow: "auto",
        color: "#4A5568",
    },
    header: {
        display: "flex",
        padding: "10px 0px 10px 20px",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        fontWeight: "1000",
    },
    title: {
        fontWeight: "bolder",
    },
    // formContainer:{
    //     pa
    // }
});
