import {PlusOutlined} from '@ant-design/icons';
import {FloatButton} from 'antd';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ModalAdd from './ModalAdd/ModalAdd';
import ContractTable from './Table/Table';

const ContractPage = ({status}) => {
    let {t} = useTranslation();
    let [isModalOpen, setIsModalOpen] = useState(false);

    function closeModal() {
        setIsModalOpen(false);
    }

    function openModal() {
        setIsModalOpen(true);
    }

    return (<>
        <ContractTable filters={{status: status}}/>
        <FloatButton icon={<PlusOutlined />} type="primary" onClick={() => openModal()}>{t('createContract')}</FloatButton>
        {isModalOpen && <ModalAdd isModalOpen={isModalOpen} closeModal={closeModal}/>}
    </>);
};

export default ContractPage;
