import React, { useState, useEffect, memo } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useTabItem } from './useTabItem'

const TabItem = ({ children, data, tabPanelError }) => {
    const classes = useTabItem()
    const [value, setValue] = useState('1');

    useEffect(() => {
        if (tabPanelError) {
            setValue(tabPanelError)
        }
    }, [tabPanelError])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value} className={classes.tabContext} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" className={classes.tabList} >
                        {data.map((value, index) =>
                            <Tab key={index} label={value.label} value={value.value} />
                        )}
                    </TabList>
                </Box>
                {children}
            </TabContext>
        </Box>
    )
}

export default memo(TabItem)
