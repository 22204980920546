import { memo } from 'react'
import { Box, Divider, Typography } from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import RoomIcon from '../../../assets/Icons/RoomIcon'
import BasicChip from "../../../components/Chip/BasicChip";
import { useItemRoom } from "./useItemRoom";
import { convertStatus } from "../../../useCase/Room/useCaseRoom"

const ItemRoom = ({ data, isSelected, clicked }) => {
    const classes = useItemRoom();

    return (
        <>
            <ListItem style={{ height: "auto", padding: "8px" }} className={`${classes.item} ${isSelected ? classes.active : ""}`} onClick={clicked}>
                <ListItemAvatar style={{ minWidth: "0", marginRight: "8px" }}>
                    <RoomIcon fill="#c2cfe0" />
                </ListItemAvatar>
                <Box className={classes.detailRoom}>
                    <Box className={classes.detailRoomTop}>
                        <Box className={classes.nameRoom}>
                            <Typography>{data.name}</Typography>
                        </Box>

                        <Typography className={classes.price}>{`${new Intl.NumberFormat().format(data.price)} đ`}</Typography>
                        <div className={classes.containerChip}>
                            <BasicChip title={convertStatus(data.status)} isActive={data.status === "available" ? true : false} />
                        </div>
                    </Box>
                    <Box className={classes.detailRoomBottom}>
                        <Typography>Người thuê: {data.totalTenant}</Typography>
                        <Typography>Hóa đơn: {data.totalOverdueInvoice}</Typography>
                        <Typography>Vấn đề: {data.totalLowIssue + data.totalMediumIssue + data.totalHighIssue}</Typography>
                    </Box>
                </Box>
            </ListItem>
            <Divider />
        </>
    )
}

export default memo(ItemRoom)
