/* eslint-disable */
import { gql, useMutation } from "@apollo/client";
import { useAuthToken } from "../../config/auth";

const SIGN_IN = gql`
    mutation SignIn($signInRequest: SignInRequest) {
        signIn(signInRequest: $signInRequest) {
            token
        }
    }
`;

function useSignIn() {
    const [_, setAuthToken, removeAuthToken] = useAuthToken();
    const [mutateSignIn, { data, loading, error }] = useMutation(SIGN_IN, {
        onCompleted: ({ signIn }) => {
            setAuthToken(signIn.token);
        },
    });

    const handleSignIn = async (variables) => {
        try {
            await mutateSignIn({ variables: { signInRequest: variables } });
        } catch (error) {}
    };

    return {
        mutateSignIn: handleSignIn,
        dataSignIn: data,
        loadingSignIn: loading,
        errorSignIn: error,
    };
}

const SIGN_UP = gql`
    mutation SignUp($signUpRequest: SignUpRequest) {
        signUp(signUpRequest: $signUpRequest) {
            ok
        }
    }
`;

function useSignUp() {
    const [mutateSignUp, { data, loading, error }] = useMutation(SIGN_UP, {
        onCompleted: ({ signUp }) => {
            console.log("Success");
        },
    });

    const handleSignUp = async (variables) => {
        try {
            await mutateSignUp({ variables: { signUpRequest: variables } });
        } catch (error) {}
    };
    return {
        mutateSignUp: handleSignUp,
        dataSignUp: data,
        loadingSignUp: loading,
        errorSignUp: error,
    };
}

const REQUEST_CHANGE_PASSWORD = gql`
    mutation requestChangePassword($email: String!) {
        requestChangePassword(email: $email) {
            ok
        }
    }
`;

function useRequestChangePassword() {
    const [mutateRequestChangePassword, { data, loading, error }] = useMutation(
        REQUEST_CHANGE_PASSWORD
    );

    const handleRequestChangePassword = async (variables) => {
        try {
            await mutateRequestChangePassword({
                variables,
            });
            // return;
        } catch (error) {}
    };
    return {
        mutateRequestChangePassword: handleRequestChangePassword,
        dataRequestChangePassword: data,
        errorRequestChangePassword: error,
    };
}

const CHANGE_PASSWORD = gql`
    mutation changePassword($changePasswordReq: ChangePasswordReq) {
        changePassword(changePasswordReq: $changePasswordReq) {
            ok
        }
    }
`;

function useChangePassword() {
    const [mutateChangePassword, { data, loading, error }] =
        useMutation(CHANGE_PASSWORD);

    const handleChangePassword = async (variables) => {
        try {
            await mutateChangePassword({
                variables: { changePasswordReq: variables },
            });
        } catch (error) {}
    };
    return {
        mutateChangePassword: handleChangePassword,
        dataChangePassword: data,
        errorChangePassword: error,
    };
}

export { useSignIn, useSignUp, useChangePassword, useRequestChangePassword };
