const IconError = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 39.6666C31.2217 39.6666 34.1383 38.3608 36.2496 36.2495C38.3609 34.1383 39.6667 31.2216 39.6667 28C39.6667 24.7783 38.3609 21.8617 36.2496 19.7504C34.1383 17.6392 31.2217 16.3333 28 16.3333C24.7784 16.3333 21.8617 17.6392 19.7505 19.7504C17.6392 21.8617 16.3334 24.7783 16.3334 28C16.3334 31.2216 17.6392 34.1383 19.7505 36.2495C21.8617 38.3608 24.7784 39.6666 28 39.6666Z" fill="#FCDDEC" stroke="#FF3927" strokeWidth="2" strokeLinejoin="round" />
            <path d="M23.3334 28L26.8334 31.5L33.8334 24.5" stroke="#FF3927" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default IconError
