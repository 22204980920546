import { Form } from "react-bootstrap";
import styles from "./InputButton.module.css";
import React, { memo } from "react"

const InputButton = ({
    text,
    name,
    type,
    placeholder,
    changed,
    error,
    star,
    buttonText,
}) => {
    return (
        <Form.Group className={styles.formGroup} controlId={name}>
            <Form.Label className={styles.label}>
                {text}{" "}
                <span className={`${styles.star} ${star ? styles.active : ""}`}>
                    *
                </span>
            </Form.Label>
            <div className={styles.inputAndButton}>
                <input
                    className={`${styles.input} ${error ? styles.active : ""}`}
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    onChange={changed}
                />
                <button className={styles.button}>{buttonText}</button>
            </div>
            <div className={`${styles.alert} ${error ? styles.active : ""}`}>
                <i className={`fa fa-exclamation-circle ${styles.icon}`}></i>
                <label className={styles.labelError}>{error}</label>
            </div>
        </Form.Group>
    );
};

export default memo(InputButton);
