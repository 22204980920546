import { makeStyles } from "@mui/styles";
export const useDatePicker = makeStyles({
    container: {
        height: "120px",
        width: "100%",
    },
    label: {
        marginBottom: "11px",
        fontSize: "14px",
        lineHeight: "16px",
    },
    datePickerInput: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E8E8E8 !important",
            },
            "&:hover fieldset": {
                borderColor: "#E8E8E8 !important",
            },
            "&.Mui-focused fieldset": {
                border: "1px solid #E8E8E8 !important",
                borderRadius: "5px",
            },
        },
        "& .MuiOutlinedInput-notchedOutline > legend": {
            width: "0",
        },
        "& .MuiOutlinedInput-input": {
            padding: "14px 0px 14px 16px",
            fontStyle: "normal",
            "&::placeholder": {
                color: "rgba(194, 207, 224, 1)",
                opacity: 1,
            },
        },
    },
    datePickerInputError: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#d32f2f !important",
            },
            "&:hover fieldset": {
                borderColor: "#d32f2f !important",
            },
            "&.Mui-focused fieldset": {
                border: "1px solid #d32f2f !important",
                borderRadius: "5px",
            },
        },
    },
    hiddenIt: {
        display: "none",
    },
    errorMessage: {
        color: "#f7685b",
        fontSize: "12px",
        display: "flex",
        marginTop: "5px",
    },
    iconError: {
        fontSize: "14px",
        position: "relative",
        top: "2px",
        marginRight: "8px",
    },
    star: {
        color: "#f7685b",
    },
});
