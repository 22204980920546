import { makeStyles } from "@mui/styles";

export const useListRoom = makeStyles({
    itemRoom: {
        width: "100%",
    },
    floor: {
        backgroundColor: "#E5FDF0",
        height: "35px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "16px",
        color: "#04C35C",
        fontWeight: "700",
        fontSize: "20px",
    },
    detailRoom: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "93%",
    },
    detailRoomTop: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    detailRoomBottom: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "10px",
        gap: "2px",
        "& > p": {
            fontSize: "14px",
            color: "rgba(144, 160, 183, 1)",
        },
    },
    nameRoom: {
        width: "45%",
        "& .MuiTypography-root": {
            fontSize: "14px",
            color: "rgba(51, 77, 110, 1)",
            fontWeight: "600",
        },
        "@media screen and (max-width: 1280px)": {
            width: "40%",
        },
        "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
            width: "40%",
        },
        wordWrap: "break-word",
    },
    price: {
        width: "35%",
        fontSize: "12px",
        color: "#04C35C",
    },
    containerChip: {
        height: "0px",
        display: "flex",
        justifyContent: "end",
        width: "20%",
        "& > .MuiChip-root": {
            fontSize: "10px",
            fontWeight: "400",
        },
        "& > .MuiChip-root > .MuiChip-label": {
            padding: "0 8px",
        },
    },
});
