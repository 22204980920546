
const InvoiceIcon = ({ fill }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_5533_1065)">
                <path d="M1 1V19.5714H12.4286V18.1429H2.42857V2.42857H9.57143V6.71429H13.8571V8.14286H15.2857V5.71429L15.0714 5.5L10.7857 1.21429L10.5714 1H1ZM11 3.42857L12.8571 5.28571H11V3.42857ZM3.85714 8.14286V9.57143H12.4286V8.14286H3.85714ZM16 9.57143V11C14.7857 11.2143 13.8571 12.2143 13.8571 13.5C13.8571 14.9286 14.9286 16 16.3571 16H17.0714C17.6429 16 18.1429 16.5 18.1429 17.0714C18.1429 17.6429 17.6429 18.1429 17.0714 18.1429H14.5714V19.5714H16V21H17.4286V19.5714C18.6429 19.3571 19.5714 18.3571 19.5714 17.0714C19.5714 15.6429 18.5 14.5714 17.0714 14.5714H16.3571C15.7857 14.5714 15.2857 14.0714 15.2857 13.5C15.2857 12.9286 15.7857 12.4286 16.3571 12.4286H18.8571V11H17.4286V9.57143H16ZM3.85714 11.7143V13.1429H8.85714V11.7143H3.85714ZM10.2857 11.7143V13.1429H12.4286V11.7143H10.2857ZM3.85714 14.5714V16H8.85714V14.5714H3.85714ZM10.2857 14.5714V16H12.4286V14.5714H10.2857Z" fill={fill} />
            </g>
            <defs>
                <clipPath id="clip0_5533_1065">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default InvoiceIcon
