import { makeStyles } from "@mui/styles";
export const useModalAdd = makeStyles({
    container: {
        padding: "30px 16px 30px 16px",

        "&> .MuiGrid-item": {
            padding: 0,
        },
        "&> .MuiGrid-item:last-child": {
            marginTop: "30px",
        },
    },
    button: {
        display: "flex",
        alignItems: "center",
    },
    iconButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        padding: "0px",
        color: "#000000 ",
        textTransform: "none",
    },
    avatar: {
        height: "50px",
        width: "50px",
        border: "1px solid #C2CFE0",
        padding: "10px",
    },

    label: {
        marginBottom: "11px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        padding: 0,
    },

    alertLabel: {
        fontSize: "14px",
        height: "24px",
        textAlign: "center",
        margin: 0,
        left: "50%",
        color: "#f7685b",
    },
    tabPanel: {
        display: "flex",
        justifyContent: "start",
        alignContent: "flex-start",
        flexWrap: "wrap",
        padding: 0,
        paddingTop: "20px",
        gap: "16px",
        overflowY: "auto",
        height: "150px",
    },
});
