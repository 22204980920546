/* eslint-disable */
import { makeStyles } from "@mui/styles";

export const useTable = makeStyles({
    box: {
        width: "100%",
        height: "100%",
    },
    checkBox: {
        color: "#D5D5D5",
        "&.Mui-checked": {
            color: "#04C35C",
        },
    },
    headCell: {
        color: "rgba(4, 195, 92, 1)",
        fontSize: "16px",
        lineHeight: "19px",
        fontStyle: "normal",
    },
    deleteIcon: {
        color: "#C2CFE0",
    },
    iconButton: {
        padding: 0,
        margin: 0,
    },
    toolbar: {
        gap: "24px",
        paddingLeft: "16px",
        justifyContent: "space-between",
    },
    typography: {
        color: "rgba(51, 77, 110, 1)",
    },
    tableRow: {
        "&.Mui-selected": {
            backgroundColor: "#FFFFFF",
        },
        "&.Mui-selected:hover": {
            backgroundColor: "#FFFFFF",
        },
    },
    paper: {
        boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.06)",
    },
});
