import axios from "axios";

export const handleUploadImage = async (fileArray) => {
    let stringArray = [];
    await Promise.all(
        fileArray.map(async (element) => {
            const formData = new FormData();
            formData.append("file", element);
            try {
                const config = {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                };
                const { data } = await axios.post(
                    process.env.REACT_APP_API_UPLOAD,
                    formData,
                    config
                );
                stringArray.push(data);
            } catch (error) {
                stringArray.push("");
            }
        })
    );
    return stringArray.map((element) => element.result.fileId);
};
