import { makeStyles } from "@mui/styles";

export const useButtonHome = makeStyles({
    button: {
        width: "100%",
        backgroundColor: "rgba(4, 195, 92, 1)",
        boxShadow: "0px 4px 10px 0px rgba(16, 156, 241, 0.24)",
        borderRadius: "4px",
        color: "#FFFFFF",
        height: "42px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "rgba(4, 195, 92, 1)",
            boxShadow: "0px 4px 10px 0px rgba(16, 156, 241, 0.24)",
            borderRadius: "4px",
            color: "#FFFFFF",
        },
    },
});
