import { gql } from "@apollo/client";

const GET_TENANT = gql`
    query getTenant($id: Int!) {
        getTenant(id: $id) {
            fullName
            phoneNumber
            imageUrls
            email
            birthday
            idNumber
            idIssueDate
            idIssueBy
            address
            roomContractId
        }
    }
`;

const SEARCH_TENANT = gql`
    query searchTenants(
        $page: Int!
        $size: Int!
        $statues: [TenantStatus]
        $searchText: String
    ) {
        searchTenants(
            page: $page
            size: $size
            statues: $statues
            searchText: $searchText
        ) {
            results {
                id
                fullName
                room {
                    id
                    name
                }
                phoneNumber
                idNumber
                idIssueDate
                address
            }
            totalPages
            total
        }
    }
`;

const GET_ROOMS = gql`
    query myRooms($hasContract: Boolean) {
        myRooms(hasContract: $hasContract) {
            id
            name
            activeContractId
            motel {
                name
            }
        }
    }
`;

const GET_MOTEL_REPRESENTATIVES = gql`
    query {
        motelRepresentatives{
            id
            fullName
        }
    }
`;


export { GET_TENANT, SEARCH_TENANT, GET_ROOMS, GET_MOTEL_REPRESENTATIVES };
