import { Button } from "@mui/material"
import { useButtonSmall } from './useButtonSmall'
import React, { memo } from 'react'

const ButtonSmall = ({ text, clicked }) => {
    const classes = useButtonSmall()
    return (
        <Button
            size="small"
            className={`${classes.button} ${text === "Cập nhật" ? classes.update : classes.delete}`}
            onClick={clicked}
        >
            {text}
        </Button>
    )
}

export default memo(ButtonSmall)
