import React, { memo } from "react";
import { Container } from "react-bootstrap";
import styles from "./FormContainer.module.css";

const FormContainer = ({ children, text }) => {
    return (
        <Container className={styles.container}>
            <p className={styles.text}>{text}</p>
            {children}
        </Container>
    );
};
export default memo(FormContainer);
