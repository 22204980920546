import { Box, Typography } from "@mui/material";
import { roomText } from "../../../constants/Constants";
import { useContainerServices } from "./useContainerServices";
import { categoryServicesRoom } from "../../../useCase/Room/useCaseRoom";
import CardService from "../../../components/CardService/CardService";
import BasicChip from "../../../components/Chip/BasicChip";
import ListTenant from "../ListTenant/ListTenant";
import ContainerContract from "../ContainerContract/ContainerContract";
import ListImageRoom from "../ListImageRoom/ListImageRoom";
import { memo } from "react";
import EmptyIcon from "../../../assets/Icons/EmptyIcon";

const ContainerServices = ({ data }) => {
    const classes = useContainerServices();

    return (
        <Box className={classes.container}>
            <Box className={classes.containerService}>
                <Typography className={classes.title}>
                    {roomText.paidService}
                </Typography>
                <Box className={classes.containerCardServices}>
                    {categoryServicesRoom(data.allServices).paidServices
                        .length > 0 ? (
                        <>
                            {categoryServicesRoom(
                                data.allServices
                            ).paidServices.map((value, index) => (
                                <CardService key={index} data={value} />
                            ))}
                        </>
                    ) : (
                        <EmptyIcon />
                    )}
                </Box>

                <Typography className={classes.title}>
                    {roomText.freeService}
                </Typography>
                <Box className={classes.containerCardServices}>
                    {categoryServicesRoom(data.allServices).freeServices
                        .length > 0 ? (
                        <>
                            {categoryServicesRoom(
                                data.allServices
                            ).freeServices.map((value, index) => (
                                <CardService key={index} data={value} />
                            ))}
                        </>
                    ) : (
                        <EmptyIcon />
                    )}
                </Box>
                <Typography className={classes.title}>
                    {roomText.systemServices}
                </Typography>
                <Box className={classes.chips}>
                    {data.allSystemServices.map((item, index) => (
                        <div key={index} style={{ height: "32px" }}>
                            <BasicChip
                                title={item.service.name}
                                isActive={item.isActive}
                            />
                        </div>
                    ))}
                </Box>

                <Typography className={classes.title}>
                    {roomText.furnitureStatus}
                </Typography>
                <Typography className={classes.subNote}>
                    {data.furnitureStatus === null ? "Không" : "Có"}
                </Typography>
                <Typography className={classes.title}>
                    {roomText.description}
                </Typography>
                <Box className={classes.subNote}>
                    {data.description ? (
                        <Typography>{data.description}</Typography>
                    ) : (
                        <EmptyIcon />
                    )}
                </Box>
                <Typography className={classes.title}>
                    {roomText.note}
                </Typography>
                <Box>
                    {data.note ? (
                        <Typography className={classes.subNote}>
                            {data.note}
                        </Typography>
                    ) : (
                        <EmptyIcon />
                    )}
                </Box>
            </Box>
            <Box className={classes.containerRoom}>
                <ContainerContract activeContract={data.activeContract} />
                <ListTenant listTenants={data.tenants} />
                <ListImageRoom listImage={data.imageUrls} />
            </Box>
        </Box>
    );
};

export default memo(ContainerServices);
