import React, { memo } from "react";
import List from "@mui/material/List";
import ItemMotel from "./ItemListContent";
import { useListContent } from "./useListContent";
import { saveState } from "../../../../util/LocalStateSave";

export default memo(function ListContent({
    data,
    clicked,
    selectedId,
    selectedType,
}) {
    const classes = useListContent();

    const handleClick = (item) => {
        clicked(item.id);
        selectedType(item.type);
        saveState([], "selected");
    };

    return (
        <List className={classes.list}>
            {data.map((value, index) => {
                return (
                    <ItemMotel
                        key={index}
                        id={value.id}
                        title={value.title}
                        subTitle={value.subTitle}
                        counterNumber={value.total}
                        isSelected={value.id === selectedId}
                        clicked={() => handleClick(value)}
                    />
                );
            })}
        </List>
    );
});
