import { makeStyles } from "@mui/styles";

export const useMenuItem = makeStyles({
    navigationList: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        // gap: "10%",
        justifyContent: "space-between",
    },
    menuItem: {
        justifyContent: "space-around",
        gap: "16.5px",
        margin: "10px 0",
        color: "#334d6e",
        "&:hover": {
            backgroundColor: "#FFFFFF",
            color: "#04c45c",
        },
    },
    subMenuItemText: {
        "& .MuiTypography-root": { fontSize: "14px" },
    },
    subMenuActive: {
        "& .MuiTypography-root": { fontSize: "14px", color: "#04c45c" },
    },
    icon: {
        "&:hover": {
            color: "#04c45c",
        },
    },
});
