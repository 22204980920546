/* eslint-disable */
import React, { useState, useEffect, memo } from "react";
import Grid from "@mui/material/Grid";
import TabPanel from "@mui/lab/TabPanel";

import TabItem from "../../../components/TabItem/TabItem";
import TextArea from "../../../components/TextArea/TextArea";
import Input from "../../../components/Input/Input";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import ButtonHome from "../../../components/ButtonHome/ButtonHome";
import AutoCompleteInput from "../../../components/AutoCompleteInput/AutoCompleteInput";
import Notification from "../../../components/Notification/Notification";
import CheckboxService from "../../../components/CheckboxService/CheckboxService";
import SelectInput from "../../../components/SelectInput/SelectInput";

import styles from "./ModalDetail.module.css";
import { motelText } from "../../../constants/Constants";
import { useModalDetail } from "./useModalDetail";
import { useUpdateMotel } from "../../../graphql-client/Motel/mutation";
import { categoryServicesMotel } from "../../../useCase/Motel/categoryServicesMotel";
import { convertServicesData } from "../../../util/ConvertServices";
import { ConvertArrayStringToNumber } from "../../../util/ConvertArrayStringToNumber";
import { validateAddMotel } from "../../../useCase/Motel/AddMotelUseCase";
import { getCity, getDistrict } from "../../../util/GetLocation";
import EmptyIcon from "../../../assets/Icons/EmptyIcon";

export default memo(function ModalDetail({
    openAdd,
    closeAdd,
    currentEnterpriseUsers,
    services,
    systemServices,
    data,
}) {
    const classes = useModalDetail();

    const { mutateUpdateMotel, dataUpdateMotel, errorUpdateMotel } =
        useUpdateMotel();
    const [freeServices, setFreeServices] = useState([]);
    const [paidServices, setPaidServices] = useState([]);
    const [dataCity, setDataCity] = useState(getCity());
    const [dataDistrict, setDataDistrict] = useState("");

    const [nameMotel, setNameMotel] = useState("");
    const [totalFloor, setTotalFloor] = useState("");
    const [managerId, setManagerId] = useState("");

    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [district, setDistrict] = useState("");
    const [disableDistrictInput, setDisableDistrictInput] = useState(true);

    const [openTime, setOpenTime] = useState("");
    const [closeTime, setCloseTime] = useState("");
    const [endDayOfMonth, setEndDayOfMonth] = useState("");
    const [dayLeaveNumber, setDayLeaveNumber] = useState("");
    const [startPaidDay, setStartPaidDay] = useState("");
    const [endPaidDay, setEndPaidDay] = useState("");

    const [serviceIds, setServiceIds] = useState([]);
    const [systemServiceIds, setSystemServiceIds] = useState([]);
    const [note, setNote] = useState("");
    const [invoiceNote, setInvoiceNote] = useState("");
    const [description, setDescription] = useState("");
    const [tabPanel, setTabPanel] = useState("");

    const [validationMessage, setValidationMessage] = useState({});
    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    useEffect(() => {
        let data = services;
        setFreeServices(
            convertServicesData(categoryServicesMotel(data).freeServices)
        );
        setPaidServices(
            convertServicesData(categoryServicesMotel(data).paidServices)
        );
    }, [setFreeServices, setPaidServices, services]);

    const findItem = (data, field, searchTerm) => {
        return data.find((item) => item[field] === searchTerm);
    };

    useEffect(() => {
        if (openAdd === true) {
            const {
                name,
                totalFloor,
                managers,
                address,
                city,
                district,
                openTime,
                closeTime,
                endDayOfMonth,
                dayLeaveNumber,
                startPaidDay,
                endPaidDay,
                services,
                allSystemServices,
                note,
                invoiceNote,
                description,
            } = data.getMotel;

            setNameMotel(name);
            setTotalFloor(totalFloor);
            setManagerId(managers[0]);

            setAddress(address);

            setCity(findItem(dataCity, "cityName", city));

            setDataDistrict(getDistrict(city.cityName));

            setDistrict(findItem(getDistrict(city), "districtName", district));

            setOpenTime(openTime);
            setCloseTime(closeTime);
            // setEndDayOfMonth(
            //     findItem(
            //         motelText.inputEndDayOfMonth.data,
            //         "id",
            //         endDayOfMonth.toString()
            //     )
            // );
            setEndDayOfMonth(endDayOfMonth.toString());
            setDayLeaveNumber(dayLeaveNumber);
            // setStartPaidDay(
            //     findItem(
            //         motelText.inputStartPaidDay.data,
            //         "id",
            //         startPaidDay.toString()
            //     )
            // );
            setStartPaidDay(startPaidDay.toString());
            // setEndPaidDay(
            //     findItem(
            //         motelText.inputEndPaidDay.data,
            //         "id",
            //         endPaidDay.toString()
            //     )
            // );
            setEndPaidDay(endPaidDay.toString());

            setServiceIds(services.map((a) => a.id));
            setSystemServiceIds(
                allSystemServices
                    .filter((item) => item.isActive === true)
                    .map((item) => item.service.id)
            );
            setNote(note);
            setInvoiceNote(invoiceNote);
            setDescription(description);

            setValidationMessage({});
            setTabPanel("");
        }
    }, [openAdd]);

    useEffect(() => {
        if (city) {
            setDisableDistrictInput(false);
            setDataDistrict(getDistrict(city.cityName));
        } else {
            setDistrict(null);
            setDisableDistrictInput(true);
            setDataDistrict([]);
        }
    }, [city]);

    const handleServiceIdsChanged = (id) => {
        const serviceIndex = serviceIds.indexOf(id);

        let newServices = [];

        if (serviceIndex === -1) {
            newServices = newServices.concat(serviceIds, id);
        } else if (serviceIndex === 0) {
            newServices = newServices.concat(serviceIds.slice(1));
        } else if (serviceIndex === serviceIds.length - 1) {
            newServices = newServices.concat(serviceIds.slice(0, -1));
        } else if (serviceIndex > 0) {
            newServices = newServices.concat(
                serviceIds.slice(0, serviceIndex),
                serviceIds.slice(serviceIndex + 1)
            );
        }

        setServiceIds(newServices);
    };

    const isServiceIdsSelected = (id) => serviceIds.indexOf(id) !== -1;

    const handleSystemServiceIdsChanged = (id) => {
        const systemServiceIndex = systemServiceIds.indexOf(id);

        let newSystemServices = [];

        if (systemServiceIndex === -1) {
            newSystemServices = newSystemServices.concat(systemServiceIds, id);
        } else if (systemServiceIndex === 0) {
            newSystemServices = newSystemServices.concat(
                systemServiceIds.slice(1)
            );
        } else if (systemServiceIndex === systemServiceIds.length - 1) {
            newSystemServices = newSystemServices.concat(
                systemServiceIds.slice(0, -1)
            );
        } else if (systemServiceIndex > 0) {
            newSystemServices = newSystemServices.concat(
                systemServiceIds.slice(0, systemServiceIndex),
                systemServiceIds.slice(systemServiceIndex + 1)
            );
        }

        setSystemServiceIds(newSystemServices);
    };

    const isSystemServiceIdsSelected = (id) =>
        systemServiceIds.indexOf(id) !== -1;

    const handleUpdateMotel = async (e) => {
        e.preventDefault();

        const isValid = validateAddMotel(
            nameMotel,
            totalFloor,
            managerId,

            address,
            city,
            district,

            openTime,
            closeTime,
            endDayOfMonth,
            dayLeaveNumber,
            startPaidDay,
            endPaidDay,

            serviceIds,
            systemServiceIds,
            description,
            note,
            invoiceNote
        );

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
            if (isValid.msg.description) {
                setTabPanel("4");
            }
        } else {
            setValidationMessage(isValid.msg);
            await mutateUpdateMotel(
                {
                    motel: {
                        name: nameMotel,
                        totalFloor: +totalFloor,
                        managerIds: managerId.id,
                        description: description,

                        address: address,
                        city: city.cityName,
                        district: district.districtName,

                        openTime: openTime,
                        closeTime: closeTime,
                        endDayOfMonth: endDayOfMonth,
                        dayLeaveNumber: dayLeaveNumber,
                        startPaidDay: startPaidDay,
                        endPaidDay: endPaidDay,

                        serviceIds: ConvertArrayStringToNumber(serviceIds),
                        systemServiceIds:
                            ConvertArrayStringToNumber(systemServiceIds),
                        note: note,
                        invoiceNote: invoiceNote,
                    },
                    id: +data.getMotel.id,
                },
                data.getMotel.id
            );
            setNotify({
                ...motelText.notify.update,
            });
            closeAdd();
        }
    };

    return (
        <>
            <ModalContainer
                closeModal={closeAdd}
                openModal={openAdd}
                title={motelText.modalMotel.titleUpdate}
            >
                <form onSubmit={handleUpdateMotel} className={styles.form}>
                    <Grid container className={classes.container}>
                        <Grid item xl={5.75} lg={6}>
                            <Input
                                text={motelText.inputNameMotel.label}
                                type={motelText.inputNameMotel.type}
                                placeholder={
                                    motelText.inputNameMotel.placeholder
                                }
                                star={true}
                                changed={(e) => setNameMotel(e.target.value)}
                                error={validationMessage.nameMotel}
                                value={nameMotel}
                            />
                        </Grid>
                        <Grid item xl={0.5} lg={0.25}></Grid>

                        <Grid item xl={2.625} lg={2.75}>
                            <Input
                                text={motelText.inputTotalFloor.label}
                                type={motelText.inputTotalFloor.type}
                                placeholder={
                                    motelText.inputTotalFloor.placeholder
                                }
                                star={true}
                                changed={(e) => setTotalFloor(e.target.value)}
                                error={validationMessage.totalFloor}
                                value={totalFloor}
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>
                        <Grid item xl={2.625} lg={2.75}>
                            <AutoCompleteInput
                                label={motelText.inputManager.label}
                                star={true}
                                data={
                                    currentEnterpriseUsers.currentEnterpriseUsers
                                }
                                placeholder={motelText.inputManager.placeholder}
                                changed={(_event, selected) => {
                                    setManagerId(selected);
                                }}
                                // error={validationMessage.feeType}
                                defaultValue={managerId}
                                field="fullName"
                            />
                        </Grid>

                        <Grid item xl={5.75} lg={6}>
                            <Input
                                text={motelText.inputAddress.label}
                                type={motelText.inputAddress.type}
                                placeholder={motelText.inputAddress.placeholder}
                                star={true}
                                changed={(e) => setAddress(e.target.value)}
                                error={validationMessage.address}
                                value={address}
                            />
                        </Grid>
                        <Grid item xl={0.5} lg={0.25}></Grid>

                        <Grid item xl={2.625} lg={2.75}>
                            <AutoCompleteInput
                                label={motelText.inputCity.label}
                                star={true}
                                data={dataCity}
                                placeholder={motelText.inputCity.placeholder}
                                changed={(_event, selected) => {
                                    setCity(selected);
                                    setDistrict(null);
                                }}
                                defaultValue={city}
                                field="cityName"
                                error={validationMessage.city}
                            />
                        </Grid>
                        <Grid item xl={0.5} lg={0.25}></Grid>

                        <Grid item xl={2.625} lg={2.75}>
                            <AutoCompleteInput
                                label={motelText.inputDistrict.label}
                                star={true}
                                isDisabled={disableDistrictInput}
                                data={dataDistrict}
                                placeholder={
                                    motelText.inputDistrict.placeholder
                                }
                                changed={(_event, selected) => {
                                    setDistrict(selected);
                                }}
                                defaultValue={district}
                                error={validationMessage.district}
                                field="districtName"
                            />
                        </Grid>

                        <Grid item xl={9.5 / 6} lg={10.75 / 6}>
                            <Input
                                text={motelText.inputOpenTime.label}
                                type={motelText.inputOpenTime.type}
                                star={true}
                                changed={(e) => setOpenTime(e.target.value)}
                                value={openTime}
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>

                        <Grid item xl={9.5 / 6} lg={10.75 / 6}>
                            <Input
                                text={motelText.inputCloseTime.label}
                                type={motelText.inputCloseTime.type}
                                star={true}
                                changed={(e) => setCloseTime(e.target.value)}
                                value={closeTime}
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>
                        <Grid item xl={9.5 / 6} lg={10.75 / 6}>
                            <SelectInput
                                label={motelText.inputEndDayOfMonth.label}
                                star={true}
                                data={motelText.inputEndDayOfMonth.data}
                                placeholder={
                                    motelText.inputEndDayOfMonth.placeholder
                                }
                                onSelectChange={(id) => {
                                    setEndDayOfMonth(id);
                                }}
                                error={validationMessage.endDayOfMonth}
                                defaultValue={endDayOfMonth}
                                field="text"
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>
                        <Grid item xl={9.5 / 6} lg={10.75 / 6}>
                            <Input
                                text={motelText.inputDayLeaveNumber.label}
                                type={motelText.inputDayLeaveNumber.type}
                                placeholder={
                                    motelText.inputDayLeaveNumber.placeholder
                                }
                                star={true}
                                changed={(e) =>
                                    setDayLeaveNumber(e.target.value)
                                }
                                error={validationMessage.dayLeaveNumber}
                                value={dayLeaveNumber}
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>
                        <Grid item xl={9.5 / 6} lg={10.75 / 6}>
                            <SelectInput
                                label={motelText.inputStartPaidDay.label}
                                star={true}
                                data={motelText.inputStartPaidDay.data}
                                placeholder={
                                    motelText.inputStartPaidDay.placeholder
                                }
                                onSelectChange={(id) => {
                                    setStartPaidDay(id);
                                }}
                                error={validationMessage.startPaidDay}
                                defaultValue={startPaidDay}
                                field="text"
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>
                        <Grid item xl={9.5 / 6} lg={10.75 / 6}>
                            <SelectInput
                                label={motelText.inputEndPaidDay.label}
                                star={true}
                                data={motelText.inputEndPaidDay.data}
                                placeholder={
                                    motelText.inputEndPaidDay.placeholder
                                }
                                onSelectChange={(id) => {
                                    setEndPaidDay(id);
                                }}
                                error={validationMessage.endPaidDay}
                                defaultValue={endPaidDay}
                                field="text"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TabItem
                                data={motelText.modalMotel.tabItems}
                                tabPanelError={tabPanel}
                            >
                                <TabPanel
                                    value="1"
                                    className={classes.tabPanel}
                                >
                                    {paidServices.length > 0 ? (
                                        <>
                                            {paidServices.map((item, index) => {
                                                const isItemSelected =
                                                    isServiceIdsSelected(
                                                        item.id
                                                    );
                                                return (
                                                    <CheckboxService
                                                        key={index}
                                                        data={item}
                                                        changed={(id) =>
                                                            handleServiceIdsChanged(
                                                                id
                                                            )
                                                        }
                                                        isItemSelected={
                                                            isItemSelected
                                                        }
                                                    />
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <EmptyIcon />
                                    )}
                                </TabPanel>
                                <TabPanel
                                    value="2"
                                    className={classes.tabPanel}
                                >
                                    {freeServices.length > 0 ? (
                                        <>
                                            {freeServices.map((item, index) => {
                                                const isItemSelected =
                                                    isServiceIdsSelected(
                                                        item.id
                                                    );
                                                return (
                                                    <CheckboxService
                                                        key={index}
                                                        data={item}
                                                        changed={(id) =>
                                                            handleServiceIdsChanged(
                                                                id
                                                            )
                                                        }
                                                        isItemSelected={
                                                            isItemSelected
                                                        }
                                                    />
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <EmptyIcon />
                                    )}
                                </TabPanel>
                                <TabPanel
                                    value="3"
                                    className={classes.tabPanel}
                                >
                                    {systemServices.length > 0 ? (
                                        <>
                                            {systemServices.map(
                                                (item, index) => {
                                                    const isItemSelected =
                                                        isSystemServiceIdsSelected(
                                                            item.id
                                                        );
                                                    return (
                                                        <CheckboxService
                                                            key={index}
                                                            data={item}
                                                            changed={(id) =>
                                                                handleSystemServiceIdsChanged(
                                                                    id
                                                                )
                                                            }
                                                            isItemSelected={
                                                                isItemSelected
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </>
                                    ) : (
                                        <EmptyIcon />
                                    )}
                                </TabPanel>
                                <TabPanel
                                    value="4"
                                    className={classes.tabPanel}
                                >
                                    <TextArea
                                        placeholder={
                                            motelText.textAreaDescription
                                                .placeholder
                                        }
                                        value={description}
                                        changed={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        error={validationMessage.description}
                                    />
                                </TabPanel>
                                <TabPanel
                                    value="5"
                                    className={classes.tabPanel}
                                >
                                    <TextArea
                                        placeholder={
                                            motelText.textAreaNote.placeholder
                                        }
                                        value={note}
                                        changed={(e) => setNote(e.target.value)}
                                    />
                                </TabPanel>
                                <TabPanel
                                    value="6"
                                    className={classes.tabPanel}
                                >
                                    <TextArea
                                        placeholder={
                                            motelText.textAreaInvoiceNote
                                                .placeholder
                                        }
                                        value={invoiceNote}
                                        changed={(e) =>
                                            setInvoiceNote(e.target.value)
                                        }
                                    />
                                </TabPanel>
                            </TabItem>
                        </Grid>

                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}>
                            <ButtonHome
                                type="submit"
                                text={motelText.modalMotel.buttonUpdate}
                            />
                        </Grid>
                    </Grid>
                </form>
            </ModalContainer>
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
});
