import {
    alertPassword,
    alertEmail,
    alertFullName,
    alertPhoneNumber,
    alertTotalRoom,
    alertAgreeTerms,
} from "../../constants/Constants";
import { validateEmailUtil } from "../../util/ValidateEmail";

export const validateSignUp = (
    email,
    password,
    phoneNumber,
    fullName,
    totalRoom,
    isChecked
) => {
    const msg = {};

    if (!email) {
        msg.email = alertEmail.blank;
    } else {
        if (!validateEmailUtil(email)) {
            msg.email = alertEmail.validate;
        }
    }

    if (!password) {
        msg.password = alertPassword.blank;
    }

    if (!fullName) {
        msg.fullName = alertFullName.blank;
    }

    if (!phoneNumber) {
        msg.phoneNumber = alertPhoneNumber.blank;
    }

    if (!totalRoom) {
        msg.totalRoom = alertTotalRoom.blank;
    }

    if (!isChecked) {
        msg.isChecked = alertAgreeTerms.noAgreeTerms;
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
};
