import React from "react";
import Typography from "@mui/material/Typography";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import { useDatePicker } from "./useDatePicker";

const DatePicker = ({ label, maxDate, value, changed, error, star }) => {
    const classes = useDatePicker();
    return (
        <Box className={classes.container}>
            <Typography className={classes.label}>
                {label}
                {star ? <span className={classes.star}>&nbsp;*</span> : <></>}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    inputFormat="dd/MM/yyyy"
                    maxDate={maxDate}
                    value={value}
                    onChange={changed}
                    renderInput={(params) => (
                        <TextField
                            className={`${classes.datePickerInput} ${
                                error ? classes.datePickerInputError : ""
                            }`}
                            {...params}
                        />
                    )}
                />
            </LocalizationProvider>

            <Typography
                className={`${classes.hiddenIt} ${
                    error ? classes.errorMessage : ""
                }`}
            >
                <i
                    className={`fa fa-exclamation-circle ${classes.iconError}`}
                ></i>
                {error}
            </Typography>
        </Box>
    );
};

export default DatePicker;
