import { roomText } from "../../constants/Constants";
import { convertServicesData } from "../../util/ConvertServices";

export function validateAddRoom(
    name,
    floorNumber,
    price,

    totalBedRoom,
    totalGuestRoom,
    area,
    maxTenant,

    deposit,

    description,
    note
) {
    let msg = {};
    if (!name) {
        msg.name = roomText.inputNameRoom.alertBlank;
    }

    if (name.length > 100) {
        msg.name = roomText.inputNameRoom.alertExceed;
    }

    if (!floorNumber) {
        msg.floorNumber = roomText.inputFloorNumber.alertBlank;
    }

    if (isNaN(floorNumber)) {
        msg.floorNumber = roomText.inputFloorNumber.alertNotNumber;
    }

    if (floorNumber.length > 3) {
        msg.floorNumber = roomText.inputFloorNumber.alertExceed;
    }

    if (!price) {
        msg.price = roomText.inputPrice.alertBlank;
    }

    if (isNaN(price)) {
        msg.price = roomText.inputPrice.alertNotNumber;
    }

    if (price.length > 9) {
        msg.price = roomText.inputPrice.alertExceed;
    }

    if (!totalBedRoom && totalBedRoom.length === 0) {
        msg.totalBedRoom = roomText.inputTotalBedRoom.alertBlank;
    }

    if (isNaN(totalBedRoom)) {
        msg.totalBedRoom = roomText.inputTotalBedRoom.alertNotNumber;
    }

    if (totalBedRoom && totalBedRoom.length > 3) {
        msg.totalBedRoom = roomText.inputTotalBedRoom.alertExceed;
    }

    if (!totalGuestRoom && totalGuestRoom.length === 0) {
        msg.totalGuestRoom = roomText.inputTotalGuestRoom.alertBlank;
    }

    if (isNaN(totalGuestRoom)) {
        msg.totalGuestRoom = roomText.inputTotalGuestRoom.alertNotNumber;
    }

    if (totalGuestRoom && totalGuestRoom.length > 3) {
        msg.totalGuestRoom = roomText.inputTotalGuestRoom.alertExceed;
    }

    if (!area) {
        msg.area = roomText.inputArea.alertBlank;
    }

    if (isNaN(area)) {
        msg.area = roomText.inputArea.alertNotNumber;
    }

    if (area.length > 10) {
        msg.area = roomText.inputArea.alertExceed;
    }

    if (!maxTenant) {
        msg.maxTenant = roomText.inputMaxTenant.alertBlank;
    }

    if (isNaN(maxTenant)) {
        msg.maxTenant = roomText.inputMaxTenant.alertNotNumber;
    }

    if (maxTenant.length > 3) {
        msg.maxTenant = roomText.inputMaxTenant.alertExceed;
    }

    if (deposit.length > 9) {
        msg.deposit = roomText.inputDeposit.alertExceed;
    }

    if (!deposit) {
        msg.deposit = roomText.inputDeposit.alertBlank;
    }

    if (isNaN(deposit)) {
        msg.deposit = roomText.inputDeposit.alertNotNumber;
    }

    if (description && description.length > 2000) {
        msg.description = roomText.textAreaDescription.alertExceed;
    }

    if (note && note.length > 2000) {
        msg.note = roomText.textAreaNote.alertExceed;
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
}

export function floorMotel(arr) {
    const result = arr.map(({ floorNumber }) => floorNumber);
    return [...new Set(result)].sort((a, b) => a - b);
}

export const filterRoomByFloorNumber = (floorNumber, data) => {
    return data.filter((item) => item.floorNumber === floorNumber);
};

export const findFirstRoomByFloorNumber = (arr) => {
    return arr.find(
        (element) => element.floorNumber === Math.min(...floorMotel(arr))
    );
};

export function categoryServicesRoom(data) {
    let freeServices = [];
    let paidServices = [];

    for (const element of data
        .filter((e) => e.isActive === true)
        .map((a) => a.service)) {
        if (element.fee === 0) {
            freeServices.push(element);
        } else {
            paidServices.push(element);
        }
    }
    return {
        freeServices: convertServicesData(freeServices),
        paidServices: convertServicesData(paidServices),
    };
}

export function serviceActive(data) {
    return data
        .filter((item) => item.isActive === true)
        .map((item) => item.service.id);
}

export function convertStatus(status) {
    switch (status) {
        case "available":
            return "Phòng trống";
        case "empty":
            return "Phòng trống";
        case "pre_available":
            return "Phòng trống";
        case "not_available":
            return "Đã thuê";
        default:
            break;
    }
}

export function sortRoomByFloorNumber(data) {
    const cloneData = [...data];
    return cloneData.sort((a, b) => {
        return a.floorNumber - b.floorNumber;
    });
}
