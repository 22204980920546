import moment from "moment";
import { validateEmailUtil } from "../../util/ValidateEmail";
import { validateNumber } from "../../util/ValidateNumber";
import { validatePhoneNumber } from "../../util/ValidatePhoneNumber";
import { alertEmail, tenantValidate } from "../../constants/Constants";

const INVALID_DATE = "Invalid date";
const FULL_NAME_LIMIT = 200;
const PHONE_NUMBER_LIMIT = 15;
const ID_NUMBER_LIMIT = 20;
const ID_ISSUE_BY_LIMIT = 100;
const ADDRESS_LIMIT = 200;
const TODAY = moment();

export function validateAddTenant(
    fullName,
    phoneNumber,
    idNumber,
    email,
    address,
    idIssueBy,
    birthday,
    idIssueDate
) {
    let msg = {};
    if (!fullName) {
        msg.fullName = tenantValidate.fullName.blank;
    } else if (fullName.length > FULL_NAME_LIMIT) {
        msg.fullName = tenantValidate.fullName.overLimit;
    }
    if (!phoneNumber) {
        msg.phoneNumber = tenantValidate.phoneNumber.blank;
    } else if (!validatePhoneNumber(phoneNumber)) {
        msg.phoneNumber = tenantValidate.phoneNumber.notNumber;
    } else if (phoneNumber.length > PHONE_NUMBER_LIMIT) {
        msg.phoneNumber = tenantValidate.phoneNumber.overLimit;
    }
    if (idNumber) {
        if (idNumber.length > ID_NUMBER_LIMIT) {
            msg.idNumber = tenantValidate.idNumber.overLimit;
        } else if (!validateNumber(idNumber)) {
            msg.idNumber = tenantValidate.idNumber.notNumber;
        }
    }
    if (email) {
        if (!validateEmailUtil(email)) {
            msg.email = alertEmail.validate;
        }
    }
    if (address) {
        if (address.length > ADDRESS_LIMIT) {
            msg.address = tenantValidate.address.overLimit;
        }
    }
    if (idIssueBy) {
        if (idIssueBy.length > ID_ISSUE_BY_LIMIT) {
            msg.idIssueBy = tenantValidate.idIssueBy.overLimit;
        }
    }
    if (birthday) {
        if (birthday === INVALID_DATE) {
            msg.birthday = tenantValidate.birthday.invalidDate;
        } else if (moment(birthday).isAfter(TODAY)) {
            msg.birthday = tenantValidate.birthday.afterDate;
        }
    }
    if (idIssueDate) {
        if (idIssueDate === INVALID_DATE) {
            msg.idIssueDate = tenantValidate.idIssueDate.invalidDate;
        } else if (moment(idIssueDate).isAfter(TODAY)) {
            msg.idIssueDate = tenantValidate.idIssueDate.afterDate;
        }
    }
    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
}
