import { makeStyles } from "@mui/styles";
export const useModalUpdate = makeStyles({
    container: {
        padding: "30px 16px 30px 16px",

        "&> .MuiGrid-item": {
            padding: 0,
        },
    },
    tabPanel: {
        display: "flex",
        justifyContent: "start",
        alignContent: "flex-start",
        flexWrap: "wrap",
        padding: 0,
        paddingTop: "20px",
        gap: "16px",
        overflowY: "auto",
        height: "150px",
    },
});
