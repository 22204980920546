import { makeStyles } from "@mui/styles";

export const usePaging = makeStyles((theme) => ({
    pagination: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .Mui-selected": {
            color: "#04C45C",
            backgroundColor: "#FFFFFF",
            fontWeight: "bold",
            border: "2px solid #04C45C",
        },
        "& .MuiPaginationItem-firstLast": {
            backgroundColor: "#919EAB",
            color: "#FFFFFF",
        },
        "& .MuiPaginationItem-previousNext": {
            backgroundColor: "#919EAB",
            color: "#FFFFFF",
        },
    },
}));
