import { motelText } from "../../constants/Constants";

export function validateAddMotel(
    nameMotel,
    totalFloor,
    managerId,

    address,
    city,
    district,

    openTime,
    closeTime,
    endDayOfMonth,
    dayLeaveNumber,
    startPaidDay,
    endPaidDay,

    serviceIds,
    systemServiceIds,
    description,
    note,
    invoiceNote
) {
    let msg = {};
    if (!nameMotel) {
        msg.nameMotel = motelText.inputNameMotel.alertBlank;
    }

    if (nameMotel.length > 100) {
        msg.nameMotel = motelText.inputNameMotel.alertExceed;
    }

    if (!totalFloor) {
        msg.totalFloor = motelText.inputTotalFloor.alertBlank;
    }

    if (isNaN(totalFloor)) {
        msg.totalFloor = motelText.inputTotalFloor.alertNotNumber;
    }

    if (totalFloor.length > 3) {
        msg.totalFloor = motelText.inputTotalFloor.alertExceed;
    }

    if (!address) {
        msg.address = motelText.inputAddress.alertBlank;
    }

    if (address.length > 200) {
        msg.address = motelText.inputAddress.alertExceed;
    }

    if (!city) {
        msg.city = motelText.inputCity.alertBlank;
    }

    if (!district) {
        msg.district = motelText.inputDistrict.alertBlank;
    }

    if (!endDayOfMonth) {
        msg.endDayOfMonth = motelText.inputEndDayOfMonth.alertBlank;
    }

    if (!dayLeaveNumber) {
        msg.dayLeaveNumber = motelText.inputDayLeaveNumber.alertBlank;
    }

    if (isNaN(dayLeaveNumber)) {
        msg.dayLeaveNumber = motelText.inputDayLeaveNumber.alertNotNumber;
    }

    if (dayLeaveNumber.length > 3) {
        msg.dayLeaveNumber = motelText.inputDayLeaveNumber.alertExceed;
    }

    if (!startPaidDay) {
        msg.startPaidDay = motelText.inputStartPaidDay.alertBlank;
    }

    if (!endPaidDay) {
        msg.endPaidDay = motelText.inputEndPaidDay.alertBlank;
    }

    if (!description) {
        msg.description = motelText.textAreaDescription.alertBlank;
    }

    if (description.length > 2000) {
        msg.description = motelText.textAreaDescription.alertExceed;
    }

    if (note !== null && note.length > 100) {
        msg.description = motelText.textAreaDescription.alertExceed;
    }

    if (invoiceNote.length > 100) {
        msg.description = motelText.textAreaDescription.alertExceed;
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
}
