import React, { useState, useEffect, memo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useQuery } from "@apollo/client";
import {
    GET_MOTELS,
    MY_MOTELS,
    CURRENT_ENTERPRISE_USERS,
    SYSTEM_SERVICES,
} from "../../graphql-client/Motel/query";
import { useDeleteMotel } from "../../graphql-client/Motel/mutation";
import { GET_SERVICES } from "../../graphql-client/Services/query";

import Loading from "../../components/Loading/Loading";
import ButtonHome from "../../components/ButtonHome/ButtonHome";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Notification from "../../components/Notification/Notification";

import InfoMotel from "./InfoMotel/InfoMotel";
import ListMotel from "./ListMotel/ListMotel";
import ContainerServices from "./ContainerServices/ContainerServices";
import { CardsMotelDetail } from "./CardsMotelDetail/CardsMotelDetail";
import ModalAdd from "./ModalAdd/ModalAdd";

import { motelText } from "../../constants/Constants";
import { convertServicesData } from "../../util/ConvertServices";

import { useMotel } from "./useMotel";
import EmptyIcon from "../../assets/Icons/EmptyIcon";

const Motel = () => {
    const classes = useMotel();
    const [open, setOpen] = useState(false);
    const [myMotels, setMyMotels] = useState([]);
    const [motelId, setMotelId] = useState(null);
    const [motel, setMotel] = useState();
    const [currentEnterpriseUsers, setCurrentEnterpriseUsers] = useState([]);
    const [services, setServices] = useState([]);
    const [systemServices, setSystemServices] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    const {
        loading: loadingGetMyMotels,
        data: dataGetMyMotels,
        error: errorGetMyMotels,
    } = useQuery(MY_MOTELS);

    const {
        loading: loadingGetMotel,
        data: dataGetMotel,
        error: errorGetMotel,
    } = useQuery(GET_MOTELS, {
        variables: {
            motelId: motelId,
        },
        skip: motelId === null,
    });

    const {
        loading: loadingCurrentEnterpriseUsers,
        data: dataCurrentEnterpriseUsers,
        error: errorCurrentEnterpriseUsers,
    } = useQuery(CURRENT_ENTERPRISE_USERS);

    const {
        loading: getServicesLoading,
        data: getServicesData,
        error: getServicesError,
    } = useQuery(GET_SERVICES);

    const {
        loading: loadingSystemServices,
        data: dataSystemServices,
        error: errorSystemServices,
    } = useQuery(SYSTEM_SERVICES);

    const { mutateDeleteMotel, dataDeleteMotel } = useDeleteMotel();

    const handleOpen = () => {
        setOpen(true);
        return true;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteMotel = (id) => {
        let index = myMotels.findIndex((x) => x.id === id);
        setConfirmDialog({
            ...motelText.dialog.delete,
            onConfirm: () => {
                mutateDeleteMotel({ id: +id });
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                if (myMotels.length === 1) {
                    setMotelId(null);
                    setMotel();
                } else {
                    setMotelId(
                        index === myMotels.length - 1
                            ? myMotels[index - 1].id
                            : myMotels[index + 1].id
                    );
                }
                setNotify({
                    ...motelText.notify.delete,
                });
            },
        });
    };

    useEffect(() => {
        if (getServicesLoading === false) {
            let data = getServicesData.services;
            setServices(convertServicesData(data));
        }
    }, [getServicesLoading, getServicesData]);

    useEffect(() => {
        if (loadingGetMyMotels === false) {
            setMyMotels(dataGetMyMotels.myMotels);
            // setMotelId(null)
            if (!motelId && dataGetMyMotels.myMotels.length > 0) {
                setMotelId(dataGetMyMotels.myMotels[0].id);
            }
        }
    }, [loadingGetMyMotels, dataGetMyMotels]);

    useEffect(() => {
        if (loadingGetMotel === false) {
            setMotel(dataGetMotel);
            if (motelId === null) {
                setMotel();
            }
        }
    }, [loadingGetMotel, dataGetMotel, motelId]);

    useEffect(() => {
        if (loadingCurrentEnterpriseUsers === false) {
            setCurrentEnterpriseUsers(dataCurrentEnterpriseUsers);
        }
    }, [loadingCurrentEnterpriseUsers, dataCurrentEnterpriseUsers]);

    useEffect(() => {
        if (loadingSystemServices === false) {
            setSystemServices(dataSystemServices.systemServices);
        }
    }, [loadingSystemServices, dataSystemServices]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }} className={classes.container}>
                <Grid container spacing={2} style={{ height: "100%" }}>
                    <Grid item xs={3} style={{ height: "100%" }}>
                        <Box className={classes.containerMotel}>
                            <Box className={classes.containerList}>
                                {loadingGetMyMotels ? (
                                    <Loading />
                                ) : (
                                    <>
                                        {myMotels.length > 0 ? (
                                            <ListMotel
                                                data={myMotels}
                                                clicked={(item) =>
                                                    setMotelId(item)
                                                }
                                                motelId={motelId}
                                            />
                                        ) : (
                                            <EmptyIcon />
                                        )}
                                    </>
                                )}
                            </Box>
                            <Box className={classes.buttonContainer}>
                                <ButtonHome
                                    text={motelText.modalMotel.buttonAdd}
                                    onClick={handleOpen}
                                />
                                <ModalAdd
                                    closeAdd={handleClose}
                                    openAdd={open}
                                    currentEnterpriseUsers={
                                        currentEnterpriseUsers
                                    }
                                    services={services}
                                    systemServices={systemServices}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={9}
                        style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {loadingGetMotel ? (
                            <Loading />
                        ) : motel === undefined ? (
                            <EmptyIcon />
                        ) : (
                            <Box style={{ height: "100%", width: "100%" }}>
                                <InfoMotel
                                    data={motel}
                                    clickedDelete={(id) =>
                                        handleDeleteMotel(id)
                                    }
                                    currentEnterpriseUsers={
                                        currentEnterpriseUsers
                                    }
                                    services={services}
                                    systemServices={systemServices}
                                />
                                <CardsMotelDetail data={motel} />
                                <ContainerServices data={motel} />
                            </Box>
                        )}
                        <Notification notify={notify} setNotify={setNotify} />
                        <ConfirmDialog
                            confirmDialog={confirmDialog}
                            setConfirmDialog={setConfirmDialog}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default memo(Motel);
