import { gql, useMutation } from "@apollo/client";
import { CONTRACT_TERMS } from "./query";

const ADD_CONTRACT_TERM = gql`
    mutation addContractTerm($req: AddContractTermReq!) {
        addContractTerm(req: $req) {
            ok
        }
    }
`;

function useAddContractTerm() {
    const [mutateAddContractTerm, { data, loading, error }] =
        useMutation(ADD_CONTRACT_TERM);

    const handleAddContractTerm = async (variables) => {
        try {
            await mutateAddContractTerm({
                variables,
                refetchQueries: [{ query: CONTRACT_TERMS }],
            });
        } catch (err) {}
    };

    return {
        mutateAddContractTerm: handleAddContractTerm,
        dataAddContractTerm: data,
        loadingAddContractTerm: loading,
        errorAddContractTerm: error,
    };
}

const UPDATE_CONTRACT_TERM = gql`
    mutation updateContractTerm($id: Int!, $req: AddContractTermReq!) {
        updateContractTerm(id: $id, req: $req) {
            ok
        }
    }
`;

function useUpdateContractTerm() {
    const [mutateUpdateContractTerm, { data, loading, error }] =
        useMutation(UPDATE_CONTRACT_TERM);

    const handleUpdateContractTerm = async (variables, id) => {
        try {
            await mutateUpdateContractTerm({
                variables,
                refetchQueries: [{ query: CONTRACT_TERMS }],
            });
        } catch (err) {}
    };

    return {
        mutateUpdateContractTerm: handleUpdateContractTerm,
        dataUpdateContractTerm: data,
        loadingUpdateContractTerm: loading,
        errorUpdateContractTerm: error,
    };
}

const DELETE_CONTRACT_TERM = gql`
    mutation deleteContractTerm($id: Int!) {
        deleteContractTerm(id: $id) {
            ok
        }
    }
`;

function useDeleteContractTerm() {
    const [mutateDeleteContractTerm, { data, loading, error }] =
        useMutation(DELETE_CONTRACT_TERM);

    const handleDeleteContractTerm = async (variables) => {
        try {
            await mutateDeleteContractTerm({
                variables,
                refetchQueries: [{ query: CONTRACT_TERMS }],
            });
        } catch (error) {}
    };
    return {
        mutateDeleteContractTerm: handleDeleteContractTerm,
        dataDeleteContractTerm: data,
        loadingDeleteContractTerm: loading,
        errorDeleteContractTerm: error,
    };
}

export { useAddContractTerm, useUpdateContractTerm, useDeleteContractTerm };
