import React, { memo } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchInput from "../SearchInput/SearchInput";
import { useHeaderHome } from "./useHeaderHome";

export default memo(function ElevateAppBar({ changed, value }) {
    const classes = useHeaderHome();

    return (
        <>
            <AppBar
                elevation={0}
                color="transparent"
                position="fixed"
                className={classes.appBar}
            >
                <Toolbar className={classes.toolbar}>
                    <SearchInput className={classes.icon} changed={changed} value={value} />
                </Toolbar>
            </AppBar>
        </>
    );
})
