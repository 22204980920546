import { gql } from "@apollo/client";

const GET_ME = gql`
    query {
        me {
            id
            email
            fullName
            avatar
        }
    }
`;

export { GET_ME };
