/* eslint-disable jsx-a11y/iframe-has-title */
import LoadingSVG from "../../assets/loading.svg";
import React, { memo } from "react";
import styles from "./Loading.module.css";

const Loading = () => {
    return <img className={styles.loading} src={LoadingSVG} alt="" />;
};

export default memo(Loading);
