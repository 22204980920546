import React, { memo } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useConfirmDialog } from "./useConfirmDialog";
import Button from '@mui/material/Button';



export default memo(function ConfirmDialog(props) {

    const { confirmDialog, setConfirmDialog } = props;
    const classes = useConfirmDialog()

    return (
        <Dialog open={confirmDialog.isOpen} className={classes.dialog}>
            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.title}>
                    {confirmDialog.title}
                </Typography>
                <Typography className={classes.subTitle}>
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button className={classes.buttonNo} onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>Hủy</Button>
                <Button className={classes.buttonYes} onClick={confirmDialog.onConfirm}>Đồng ý</Button>
            </DialogActions>
        </Dialog>
    )
})