import React, { memo } from "react";
import Snackbar from "@mui/material/Snackbar";
import { useNotifications } from "./useNotification";
import { Box } from "@mui/system";
import IconSuccess from "../../assets/Icons/IconSuccess";
import IconError from "../../assets/Icons/IconError";
import IconClose from "../../assets/Icons/IconClose";

import { IconButton, Typography } from "@mui/material";

export default memo(function Notification({ notify, setNotify }) {
    const classes = useNotifications();

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setNotify({
            ...notify,
            isOpen: false,
        });
    };

    return (
        <Snackbar
            className={classes.snackbar}
            open={notify.isOpen}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleClose}
        >
            <Box className={classes.boxContainer}>
                <IconButton
                    disableRipple={true}
                    className={classes.iconClose}
                    onClick={handleClose}
                >
                    <IconClose />
                </IconButton>
                {notify.type === "error" ? (
                    <>
                        <Box className={classes.iconError}>
                            <IconError />
                        </Box>
                        <Box className={classes.content}>
                            <Typography className={classes.title}>
                                {notify.title}
                            </Typography>
                            <Typography className={classes.subTitle}>
                                {notify.message}
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box className={classes.iconSuccess}>
                            <IconSuccess />
                        </Box>
                        <Box className={classes.content}>
                            <Typography className={classes.title}>
                                {notify.title}
                            </Typography>
                            <Typography className={classes.subTitle}>
                                {notify.message}
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </Snackbar>
    );
});
