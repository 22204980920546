import React from "react";
import { useQuery } from "@apollo/client";
import Loading from "../../components/Loading/Loading";
import { GET_DASHBOARD } from "../../graphql-client/Dashboard/query";
import { Container } from "react-bootstrap";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import HouseIcon from "@mui/icons-material/House";
import BedIcon from "@mui/icons-material/Bed";
import { Person } from "@mui/icons-material";

const houseIcon = <HouseIcon />;
const bedIcon = <BedIcon />;
const personIcon = <Person />;

function getDashboardCard(number, icon, title) {
    return (
        <Card sx={{ height: "100%" }}>
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{ justifyContent: "space-between" }}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            {title}
                        </Typography>
                        <Typography color="textPrimary" variant="h4">
                            {number}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: "error.main",
                                height: 56,
                                width: 56,
                            }}
                        >
                            {icon}
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

const Dashboard = () => {
    const { loading: loadingTenants, data: getDashboard } =
        useQuery(GET_DASHBOARD);
    if (!getDashboard) {
        return <Loading />;
    }

    const { me } = getDashboard;
    const { totalMotel, totalRoom, totalEmptyRoom, totalTenant } = me;
    return (
        <>
            {loadingTenants ? (
                <Loading />
            ) : (
                <Container>
                    <Grid container spacing={3}>
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            {getDashboardCard(totalMotel, houseIcon, "Tòa nhà")}
                        </Grid>
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            {getDashboardCard(totalRoom, bedIcon, "Phòng")}
                        </Grid>
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            {getDashboardCard(
                                totalEmptyRoom,
                                bedIcon,
                                "Phòng trống"
                            )}
                        </Grid>
                        <Grid item lg={3} sm={6} xl={3} xs={12}>
                            {getDashboardCard(
                                totalTenant,
                                personIcon,
                                "Người thuê"
                            )}
                        </Grid>
                    </Grid>
                </Container>
            )}
        </>
    );
};

export default Dashboard;
