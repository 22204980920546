import styles from "./Radio.module.css";
import { memo } from 'react'

const Radio = ({ name, value, isSelected, changed, text }) => {
    return (
        <div className={styles.container}>
            <input
                className={styles.radio}
                type="radio"
                name={name}
                value={value}
                checked={isSelected}
                onChange={changed}
            />
            <label>{text}</label>
        </div>
    );
};

export default memo(Radio);
