import { makeStyles } from "@mui/styles";

export const useMotel = makeStyles({
    container: {
        overflow: "hidden",
        height: "calc(100% + 16px)",
        // paddingBottom: "1%",

        // "@media screen and (max-width: 1280px)": {
        //     height: "100%",
        //     paddingBottom: "3%",
        // },
    },
    containerDetail: {
        height: "100%",
    },
    containerMotel: {
        height: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        boxShadow: "0px 6px 18px 0px #0000000f",
    },
    containerList: {
        height: "calc(100% - 64px)",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonContainer: {
        height: "64px",
        display: "flex",
        alignItems: "center",
        padding: "0 5%",
    },
});
