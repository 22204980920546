import { makeStyles } from "@mui/styles";

export const useBasicChip = makeStyles({
    chip: {
        height: "100%",
        borderRadius: "15px",
        color: "#FFFFFF",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "600",
        padding: "10px 0px",
    },
    chipActive: {
        backgroundColor: "rgba(4, 195, 92, 1)",
    },
    chipNotActive: {
        backgroundColor: "rgba(196, 196, 196, 1)",
    },
});
