import {gql} from '@apollo/client';

export const QUERY_GET_STATEMENT = gql`
    query GetStatement($id: Int!) {
        getRoomStatement(id: $id){
            id
            statementAt
            roomContractId
            serviceStatements{
                serviceId
                service{
                    id
                    name
                    unit
                    feeType
                }
                totalUnit
                lastIndex
                index
                imageUrl
            }
        }
    }
`;
export const QUERY_ROOM_STATEMENTS = gql`
    query RoomStatements($startDate: Date, $endDate: Date!, $motelIds: [Int!]){
        roomStatements(startDate: $startDate, endDate: $endDate, motelIds: $motelIds){
            id
            statementAt
            roomContractId
            roomContract {
                representativeTenant {
                    id
                    fullName
                }
            }
            creator{
                id
                fullName
            }
            room {
                id
                name
                motel{
                    id
                    name
                }
            }
            createdAt
        }
    }
`;
export const QUERY_ROOM_BY_STATEMENT = gql`
    query RoomsByStatementStatus($startDate: Date!, $endDate: Date!, $hasStatement: Boolean, $hasContract: Boolean, $motelIds: [Int!]) {
        roomsByStatementStatus(startDate: $startDate, endDate: $endDate, hasStatement: $hasStatement, hasContract: $hasContract, motelIds: $motelIds){
            id
            name
            activeContractId
            motel{
                id
                name
            }
            admin{
                id
                fullName
            }
            tenants {
                id
                fullName
            }
        }

    }

`;
export const GET_NEW_STATEMENT = gql`
    query GetNewStatement($roomId: Int!, $statementAt: Date!){
        getNewRoomStatement(roomId: $roomId, statementAt: $statementAt){
            room{
                id
                name
                totalTenant
                activeContractId
            }
            serviceStatements{
                service{
                    id
                    icon
                    name
                    fee
                    feeType
                    unit
                }
                lastIndex
            }
        }
    }
`;