import { makeStyles } from "@mui/styles";
export const useButtonUpload = makeStyles({
    iconButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        padding: "0px",
        color: "#000000 ",
        textTransform: "none",
        marginLeft: "5px",
    },
    avatar: {
        height: "50px",
        width: "50px",
        border: "1px solid #C2CFE0",
        padding: "10px",
    },
    input: {
        width: "100%",
        "& .MuiInputBase-input": {
            padding: "15px 0px 15px 10px",
        },
        "& .MuiSvgIcon-root": {
            marginLeft: "20px",
        },
    },
    popoverPaper: {
        width: "18.3%",
        height: "30%",
    },
    itemIcon: {
        padding: "0",
    },
    gridItem: {
        padding: "10px",
    },
    hiddenIt: {
        display: "none",
    },
    labelError: {
        color: "#f7685b",
        fontSize: "12px",
        display: "flex",
        justifyContent: "center",
        marginTop: "5px",
    },
    iconError: {
        fontSize: "14px",
        position: "relative",
        top: "15%",
        marginRight: "8px",
    },
    formGroup: {
        height: "110px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        top: "18%",
        position: "relative",
    },
});
