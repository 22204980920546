import React, { memo } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useProfile } from "./useProfile";
import { GET_ME } from "../../graphql-client/Auth/query"
import avatar from "../../assets/avatar.png";
import { useQuery } from "@apollo/client";

const Profile = () => {
    const classes = useProfile();
    const { loading: getMeLoading, data: getMeData, error: getMeError, } = useQuery(GET_ME);
    if (getMeError) return `Error! ${getMeError.message}`;

    return (<>
        {getMeLoading ? (<></>) : (<Card className={classes.card}>
            <CardMedia
                component="img"
                className={classes.avatar}
                image={`${getMeData.me.avatar ? getMeData.me.avatar : avatar}`}
                alt="Live from space album cover"
            />
            <Box className={classes.box}>
                <CardContent className={classes.cardContent}>
                    <Typography
                        color="text.secondary"
                        component="div"
                        className={classes.username}
                    >
                        {getMeData.me.fullName}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                        className={classes.email}
                    >
                        {getMeData.me.email}
                    </Typography>
                </CardContent>
            </Box>
        </Card>)}
    </>


    );
};

export default memo(Profile);
