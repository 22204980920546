import React, { memo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MotelIcon from '../../../assets/Icons/MotelIcon'
import BoxNumber from "../../../components/BoxNumber/BoxNumber"
import { Box, Typography } from '@mui/material';
import { useItemMotel } from './useItemMotel'
const ItemMotel = ({ name, address, district, city, rentFee, totalRoom, clicked, isSelected }) => {
    const classes = useItemMotel()

    return (
        <>
            <ListItem style={{ height: "auto", textOverflow: "hidden", padding: "8px" }} className={`${classes.item} ${isSelected ? classes.active : ""}`} onClick={clicked}>
                <ListItemAvatar className={classes.avatar} style={{ marginRight: "4px", width: "20px" }}>
                    <MotelIcon fill="#c2cfe0" />
                </ListItemAvatar>
                <Box className={classes.content}>
                    <Typography className={classes.nameMotel}>{name}</Typography>
                    <Typography className={classes.address}>{`${address}, ${district}, ${city}`}</Typography>
                </Box>
                <Box className={classes.detail}>
                    <ListItemText primary={`${new Intl.NumberFormat().format(rentFee)} VND`} />
                    <BoxNumber number={totalRoom} />
                </Box>
            </ListItem>

        </>

    )
}

export default memo(ItemMotel)
