import React, { memo, useState, } from 'react';
import { Avatar, Card, CardHeader, CardActions, Typography } from "@mui/material"
import ButtonSmall from "../../../components/ButtonSmall/ButtonSmall"
import { useInfoMotel } from "./useInfoMotel"
import ModalDetail from '../ModalDetail/ModalDetail'
import { motelText } from '../../../constants/Constants'

const InfoMotel = ({ data, clickedDelete, currentEnterpriseUsers, services, systemServices }) => {
    const classes = useInfoMotel()
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={<Avatar src={data.getMotel.owner.avatar} />}
                    title={
                        <Typography className={classes.nameMotel}>
                            {data.getMotel.name}&nbsp;
                            {
                                data.getMotel.openTime === null || data.getMotel.openTime === null ?
                                    <Typography
                                        component="span"
                                        className={classes.time}
                                    >
                                    </Typography> :
                                    <Typography
                                        component="span"
                                        className={classes.time}
                                    >
                                        {`${data.getMotel.openTime} - ${data.getMotel.closeTime}`}
                                    </Typography>
                            }
                        </Typography>
                    }
                    subheader={`${data.getMotel.address}, ${data.getMotel.district}, ${data.getMotel.city}`}
                />
                <CardActions>
                    <ButtonSmall text={motelText.buttonDelete} clicked={() => clickedDelete(data.getMotel.id)} />
                    <ButtonSmall text={motelText.buttonUpdate} clicked={handleOpen} />
                </CardActions>
            </Card>
            <ModalDetail closeAdd={handleClose} openAdd={open} currentEnterpriseUsers={currentEnterpriseUsers} services={services} systemServices={systemServices} data={data} />
        </>
    )
}

export default memo(InfoMotel)
