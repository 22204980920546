import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Box, } from "@mui/material";

import { useItemTenant } from "./useItemTenant"
const ItemTenant = ({ value }) => {
    const classes = useItemTenant()
    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemAvatar sx={{ m: 0 }}>
                    <Avatar alt={value.fullName} src={value.imageUrls ? value.imageUrls[0] : ""} />
                </ListItemAvatar>
                <Box>
                    <Typography className={classes.name}>{value.fullName}</Typography>
                    <Typography className={classes.phoneNumber}>{value.phoneNumber}</Typography>
                </Box>

            </ListItem>
            <Divider />
        </>

    )
}

export default ItemTenant
