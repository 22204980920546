/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from "react";
import debounce from "lodash/debounce";

export function useDebounce(callback, delay) {
    const debouncedFn = useCallback(
        debounce((...args) => callback(...args), delay),
        [delay]
    );
    return debouncedFn;
}

export function useDebounceAlt(callback, delay) {
    const memoizedCallback = useCallback(callback, []);
    const debouncedFn = useRef(debounce(memoizedCallback, delay));

    useEffect(() => {
        debouncedFn.current = debounce(memoizedCallback, delay);
    }, [memoizedCallback, debouncedFn, delay]);

    return debouncedFn.current;
}
