import { makeStyles } from "@mui/styles";

export const useProfile = makeStyles({
    card: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        boxShadow: "none",
        borderRadius: "inherit",
        padding: "0px 24px",
        gap: "18px",
        height: "95px ",
    },
    box: {
        display: "flex",
        flexDirection: "column",
        padding: "0",
    },
    cardContent: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    avatar: {
        width: "47px",
        height: "47px",
        borderRadius: "50%",
    },
    username: {
        fontStyle: "Medium",
        fontSize: "16px",
        lineHeight: "21px",
        color: "rgba(25, 42, 62, 1)",
        fontWeight: "1000",
    },
    email: {
        color: "rgba(144, 160, 183, 1)",
        fontSize: "14px",
    },
});
