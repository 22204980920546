import { Box, Typography } from '@mui/material';

import { useContainerContract } from './useContainerContract'
import ButtonSmall from "../../../components/ButtonSmall/ButtonSmall"
import EmptyIcon from "../../../assets/Icons/EmptyIcon"
import moment from "moment";

const ContainerContract = ({ activeContract }) => {
    const classes = useContainerContract();

    return (

        <Box className={classes.container}>
            <Typography style={{ color: "#192A3E", fontSize: "15px", fontWeight: 700 }}>Hợp đồng <Typography component="span" style={{ fontWeight: "700" }}>{activeContract !== null ? activeContract.number : ""}</Typography></Typography>

            {
                activeContract ?
                    <>
                        <Typography>{`Từ ngày ${moment(activeContract.startDate).format("DD-MM-YYYY")} đến ${activeContract.endDate ? moment(activeContract.endDate).format("DD-MM-YYYY") : "[Không xác định thời hạn]"}`}</Typography>
                        <Box className={classes.containerButton}>
                            <ButtonSmall text="Cập nhật" />
                            <ButtonSmall text="Xóa" />
                            <ButtonSmall text="Thanh lý" />
                        </Box>
                    </> :
                    <EmptyIcon />
            }

        </Box>
    )
}

export default ContainerContract
