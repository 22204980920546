import { makeStyles } from "@mui/styles";

export const useButtonSmall = makeStyles({
    button: {
        color: "#fff",
        fontSize: "13px",
        width: "80px",
        textTransform: "none",
    },

    delete: {
        backgroundColor: "#F7685B",
        "&:hover": {
            backgroundColor: "#F7685B",
        },
    },
    update: {
        backgroundColor: "#2ED47A",
        "&:hover": {
            backgroundColor: "#2ED47A",
        },
    },
});
