import React, { memo } from "react";
import Grid from "@mui/material/Grid";
import CardInfo from "../../../components/CardInfo/CardInfo";
import RoomAvatar from "../../../assets/MotelIcons/room.svg";
import { useCardsRoomDetail } from "./useCardsRoomDetail";
import { roomText } from "../../../constants/Constants"

const CardsRoomDetail = ({ data }) => {

    const classes = useCardsRoomDetail();
    return (
        <Grid container className={classes.cards}>
            <CardInfo path={RoomAvatar} title={roomText.area} quantity={`${data.area}m2`} />
            <CardInfo path={RoomAvatar} title={roomText.bedRoom} quantity={data.totalBedRoom ? data.totalBedRoom : 0} />
            <CardInfo path={RoomAvatar} title={roomText.guestRoom} quantity={data.totalGuestRoom ? data.totalGuestRoom : 0} />
            <CardInfo path={RoomAvatar} title={roomText.maxTenant} quantity={data.maxTenant} />
            <CardInfo path={RoomAvatar} title={roomText.deposit} quantity={`${new Intl.NumberFormat().format(data.deposit)}đ`} />
        </Grid>

    );
}

export default memo(CardsRoomDetail)
