import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import Grid from "@mui/material/Grid";
import styles from "./ModalUpdate.module.css";
import ModalContainer from "../../../../components/ModalContainer/ModalContainer";
import ButtonHome from "../../../../components/ButtonHome/ButtonHome";
import Notification from "../../../../components/Notification/Notification";
import Input from "../../../../components/Input/Input";
import TextArea from "../../../../components/TextArea/TextArea";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import { useModalUpdate } from "./useModalUpdate";
import { useUpdateRepresentative } from "../../../../graphql-client/Representatives/mutation";
import { representativesText, today } from "../../../../constants/Constants";
import { validateAddRepresentative } from "../../../../useCase/Representative/useCaseRepresentative";
import { GET_MOTEL_REPRESENTATIVE } from "../../../../graphql-client/Representatives/query";

const ModalUpdate = ({ openAdd, closeAdd, representativeId }) => {
    const classes = useModalUpdate();
    const { loading: loadingGetRepresentative, data: dataGetRepresentative } =
        useQuery(GET_MOTEL_REPRESENTATIVE, {
            variables: {
                id: Number(representativeId),
            },
            skip: representativeId === null,
            fetchPolicy: "no-cache",
        });
    const { mutateUpdateRepresentative } = useUpdateRepresentative();
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [address, setAddress] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [idDate, setIdDate] = useState(null);
    const [idAt, setIdAt] = useState("");
    const [defaultRep, setDefaultRep] = useState(false);
    const [validationMessage, setValidationMessage] = useState({});

    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    useEffect(() => {
        if (openAdd === true) {
            clearState();
        }
    }, [openAdd]);

    useEffect(() => {
        setValidationMessage({});
        if (dataGetRepresentative) {
            const {
                fullName,
                phoneNumber,
                address,
                birthday,
                idNumber,
                idAt,
                idDate,
                isDefault,
            } = dataGetRepresentative.getMotelRepresentative;
            setFullName(fullName);
            setPhoneNumber(phoneNumber);
            setBirthday(birthday);
            setAddress(address);
            setIdNumber(idNumber);
            setIdAt(idAt);
            setIdDate(idDate);
            setDefaultRep(isDefault);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGetRepresentative]);

    const clearState = () => {
        setValidationMessage({});
        setFullName("");
        setPhoneNumber("");
        setBirthday(null);
        setAddress("");
        setIdNumber("");
        setIdDate(null);
        setIdAt("");
    };

    const handleUpdateContract = async (e) => {
        e.preventDefault();

        const isValid = validateAddRepresentative(
            fullName,
            phoneNumber,
            birthday,
            idNumber,
            idDate,
            idAt,
            address
        );

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            await mutateUpdateRepresentative({
                id: representativeId,
                req: {
                    fullName: fullName,
                    phoneNumber: phoneNumber,
                    birthday: birthday,
                    idNumber: idNumber,
                    idDate: idDate,
                    idAt: idAt,
                    address: address,
                    isDefault: defaultRep,
                },
            });
            setNotify({
                ...representativesText.notify.update,
            });
            closeAdd();
        }
    };

    return (
        <>
            {loadingGetRepresentative ? (
                <></>
            ) : (
                <ModalContainer
                    closeModal={closeAdd}
                    openModal={openAdd}
                    title={representativesText.modalRepresentatives.titleUpdate}
                >
                    <form
                        onSubmit={handleUpdateContract}
                        className={styles.form}
                    >
                        <Grid container className={classes.container}>
                            <Grid item xs={11 / 3}>
                                <Input
                                    text={representativesText.inputName.label}
                                    type={representativesText.inputName.type}
                                    placeholder={
                                        representativesText.inputName
                                            .placeholder
                                    }
                                    star={true}
                                    changed={(e) => setFullName(e.target.value)}
                                    error={validationMessage.fullName}
                                    value={fullName}
                                />
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={11 / 3}>
                                <Input
                                    text={
                                        representativesText.inputPhoneNumber
                                            .label
                                    }
                                    type={
                                        representativesText.inputPhoneNumber
                                            .type
                                    }
                                    placeholder={
                                        representativesText.inputPhoneNumber
                                            .placeholder
                                    }
                                    star={true}
                                    changed={(e) =>
                                        setPhoneNumber(e.target.value)
                                    }
                                    error={validationMessage.phoneNumber}
                                    value={phoneNumber}
                                />
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={11 / 3}>
                                <DatePicker
                                    label={
                                        representativesText.inputBirthday.label
                                    }
                                    value={birthday}
                                    star={true}
                                    maxDate={new Date(today)}
                                    changed={(valueDate) => {
                                        if (valueDate) {
                                            setBirthday(
                                                moment(valueDate).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );
                                        } else {
                                            setBirthday(valueDate);
                                        }
                                    }}
                                    error={validationMessage.birthday}
                                />
                            </Grid>

                            <Grid item xs={11 / 3}>
                                <Input
                                    text={
                                        representativesText.inputIdNumber.label
                                    }
                                    type={
                                        representativesText.inputIdNumber.type
                                    }
                                    placeholder={
                                        representativesText.inputIdNumber
                                            .placeholder
                                    }
                                    star={true}
                                    changed={(e) => setIdNumber(e.target.value)}
                                    error={validationMessage.idNumber}
                                    value={idNumber}
                                />
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={11 / 3}>
                                <DatePicker
                                    label={
                                        representativesText.inputIdDate.label
                                    }
                                    value={idDate}
                                    star={true}
                                    maxDate={new Date(today)}
                                    changed={(valueDate) => {
                                        if (valueDate) {
                                            setIdDate(
                                                moment(valueDate).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );
                                        } else {
                                            setIdDate(valueDate);
                                        }
                                    }}
                                    error={validationMessage.idDate}
                                />
                            </Grid>

                            <Grid item xs={25 / 6}></Grid>

                            <Grid item xs={5.75}>
                                <TextArea
                                    label={
                                        representativesText.textAreaIdAt.label
                                    }
                                    placeholder={
                                        representativesText.textAreaIdAt
                                            .placeholder
                                    }
                                    changed={(e) => setIdAt(e.target.value)}
                                    error={validationMessage.idAt}
                                    value={idAt}
                                    star={true}
                                />
                            </Grid>

                            <Grid item xs={0.5}></Grid>

                            <Grid item xs={5.75}>
                                <TextArea
                                    label={
                                        representativesText.textAreaAddress
                                            .label
                                    }
                                    placeholder={
                                        representativesText.textAreaAddress
                                            .placeholder
                                    }
                                    changed={(e) => setAddress(e.target.value)}
                                    error={validationMessage.address}
                                    value={address}
                                    star={true}
                                />
                            </Grid>

                            <Grid item xs={10}></Grid>
                            <Grid
                                item
                                xs={2}
                                style={{
                                    display: "flex",
                                    gap: "20px",
                                }}
                            >
                                <ButtonHome
                                    type="submit"
                                    text={
                                        representativesText.modalRepresentatives
                                            .buttonUpdate
                                    }
                                    onClick={handleUpdateContract}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalContainer>
            )}
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
};

export default ModalUpdate;
