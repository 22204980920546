import {DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined} from '@ant-design/icons';
import {gql, useMutation, useQuery} from '@apollo/client';
import {
    Avatar,
    Card,
    Dropdown,
    FloatButton,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Space,
    Table,
    Tabs,
    Typography,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomDatePicker} from '../../components/CustomDatePicker';
import {RoomName} from '../../components/Room';
import {NumberInput} from '../../components/SearchableSelect';
import {SelectPaymentMethod} from '../../components/SelectPaymentMethod/SelectPaymentMethod';
import UploadImages from '../../components/UploadImages';
import {formatDate, parseDate} from '../../util/Date';
import {parseImageUrl} from '../../util/Image';
import {formatNumber, zeroPad} from '../../util/Number';
import {normalizeRequestObject, omit} from '../../util/Request';
import {SelectMotel, SelectRoom} from '../Accounting/Transaction/Transaction';

const QUERY_ROOM_DEPOSITS = gql`
    query RoomDeposits($statuses: [RoomDepositStatus], $startDate: Date, $endDate: Date, $motelIds: [Int]) {
        roomDeposits(statues: $statuses, startDate: $startDate, endDate: $endDate, motelIds: $motelIds) {
            id
            room {
                id
                name
                motel {
                    id
                    name
                }
            }
            amount
            status
            paymentMethod
            startDate
            createdAt
            customers {
                id
                fullName
            }
        }
    }

`;



function TabDeposit({endDate, motelIds, startDate, status, onSelectedDeposit}) {
    const {t} = useTranslation();
    const getDepositRequests = normalizeRequestObject({
        statuses: [status], startDate: startDate, endDate: endDate, motelIds: motelIds,
    });
    const {data: depositData, loading, refetch} = useQuery(QUERY_ROOM_DEPOSITS, {
        variables: getDepositRequests,
    });

    const {cancelDeposit, loading: loadingCancel} = useCancelDeposit();

    async function handleCancelDeposit(id) {
        try {
            await cancelDeposit(id);
            notification.success({
                message: t('deposit.cancelSuccess')
            })
        } catch (e) {
            notification.error({
                message: t('deposit.cancelFailed'),
            })
        }
    }

    const {deleteDeposit, loading: loadingDelete} = useDeleteDeposit();

    async function handleDeleteDeposit(id) {
        try {
            await deleteDeposit(id);
            notification.success({
                message: t('deposit.deleteSuccess')
            })
        } catch (e) {
            notification.error({
                message: t('deposit.deleteFailed'),
            })
        }
    }

    useEffect(() => {
        refetch(getDepositRequests);
    }, [
        JSON.stringify(getDepositRequests)]);

    function getRowActionItems(deposit) {
        const items = [
            {
                key: '1',
                label: (
                    <Popconfirm title={t('deposit.confirmCancel')} onConfirm={() => handleCancelDeposit(deposit.id)}>
                        {t('deposit.actionCancel')}
                    </Popconfirm>
                ),
            },
            // {
            //     key: '2',
            //     label: (
            //         <a href="#">
            //             {t('deposit.actionCreateContract')}
            //         </a>
            //     ),
            // },
            {
                key: '3',
                danger: true,
                label: (
                    <Popconfirm title={t('deposit.confirmDelete')} onConfirm={() => handleDeleteDeposit(deposit.id)}>
                        {t('deposit.actionDelete')}
                    </Popconfirm>
                ),
            },
        ];
        return items;
    }

    return <>
        <Table
            loading={loading}
            dataSource={depositData?.roomDeposits}
            columns={[
                {
                    title: '#', render: (value, record, index) => {
                        return <a href={'#'} onClick={() => {
                            onSelectedDeposit(record.id);
                        }}>{zeroPad(record.id, 6)}</a>;
                    },
                }, {
                    title: t('room'), render: (value, record) => {
                        return <RoomName room={record.room}/>;
                    },
                }, {
                    title: t('deposit.amount'), render: (value, record) => {
                        return <span>{formatNumber(record.amount)}</span>;
                    },
                }, {
                    title: t('deposit.tenant'), render: (value, record) => {
                        return <span>{record.customers[0].fullName}</span>;
                    },
                }, {
                    title: t('deposit.startDate'), render: (value, record) => {
                        return <span>{formatDate(record.startDate)}</span>;
                    },
                }, {
                    title: t('deposit.createdAt'), render: (value, record) => {
                        return <span>{formatDate(record.createdAt)}</span>;
                    },
                },
                {
                    title: t('deposit.action'), render: (value, record) => {
                        return <Dropdown
                            menu={{
                                items: getRowActionItems(record),
                            }}
                        >
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    {t('deposit.action')}
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>;
                    },
                }

                ]

            }
        />
    </>;
}

const QUERY_GET_DEPOSIT = gql`
    query GetDeposit($id: Int!) {
        getRoomDeposit(id: $id) {
            id
            roomId
            room {
                id
                name
                motel {
                    id
                    name
                }
            }
            amount
            paymentMethod
            startDate
            createdAt
            imageUrls
            customers {
                id
                fullName
                phoneNumber
                email
                address
                idNumber
                imageUrls
            }
        }
    }

`;

const {Meta} = Card;

function CartTenant({onDeleteClick, tenant, onEdit}) {
    return (<>
        <Card
            style={{
                width: 230, marginTop: 12, marginRight: 12,
            }}
            actions={[
                <EditOutlined key="edit" onClick={onEdit}/>,
                <DeleteOutlined type={'danger'} key="delete" onClick={onDeleteClick}/>]}
        >
            <Meta
                avatar={<Avatar src="https://joesch.moe/api/v1/random?key=2"/>}
                title={tenant?.fullName}
                description={tenant?.phoneNumber}
            />
        </Card>
    </>);
}

const MUTATION_ADD_DEPOSIT = gql`
    mutation AddDeposit($request: AddDepositReq!){
        addRoomDeposit(req: $request){
            ok
        }
    }
`;

const MUTATION_UPDATE_DEPOSIT = gql`
    mutation UpdateDeposit($id: Int!, $request: UpdateDepositReq!){
        updateRoomDeposit(id: $id, req: $request){
            ok
        }
    }
`;

function useAddDeposit() {
    const [mutateAddDeposit, {loading}] = useMutation(MUTATION_ADD_DEPOSIT, {
        refetchQueries: ['RoomDeposits'],
    });

    async function addDeposit(req) {
        await mutateAddDeposit({
            variables: {
                request: req,
            },
        });
    }

    return {
        addDeposit, loading,
    };
}

const MUTATION_CANCEL_DEPOSIT = gql`
    mutation CancelDeposit($id: Int!){
        cancelRoomDeposit(id: $id){
            ok
        }
    }
`;

function useCancelDeposit() {
    const [mutateCancelDeposit, {loading}] = useMutation(MUTATION_CANCEL_DEPOSIT, {
        refetchQueries: ['RoomDeposits'],
    });

    async function cancelDeposit(id) {
        await mutateCancelDeposit({
            variables: {
                id
            },
        });
    }

    return {
        cancelDeposit, loading,
    };
}

const MUTATION_DELETE_DEPOSIT = gql`
    mutation DeleteDeposit($id: Int!){
        deleteRoomDeposit(id: $id){
            ok
        }
    }
`;

function useDeleteDeposit() {
    const [mutateDeleteDeposit, {loading}] = useMutation(MUTATION_DELETE_DEPOSIT, {
        refetchQueries: ['RoomDeposits'],
    });

    async function deleteDeposit(id) {
        await mutateDeleteDeposit({
            variables: {
                id
            },
        });
    }

    return {
        deleteDeposit, loading,
    };
}

function useUpdateDeposit() {
    const [mutateUpdateDeposit, {loading}] = useMutation(MUTATION_UPDATE_DEPOSIT, {
        refetchQueries: ['RoomDeposits'],
    });

    async function updateDeposit(id, req) {
        await mutateUpdateDeposit({
            variables: {
                id: id, request: req,
            },
        });
    }

    return {
        updateDeposit, loading,
    };
}

function InputTenant() {
    const {t} = useTranslation();
    const [selectedTenant, setSelectedTenant] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const form = Form.useFormInstance();

    function handleCancel() {
        setModalOpen(false);
    }

    function openTenantModal(tenant) {
        setSelectedTenant(tenant);
        setModalOpen(true);
    }

    return <div style={{display: 'flex'}}>
        <Form.List name="customerReqs" style={{display: 'flex'}}>
            {(fields, {add, remove}, {errors}) => {
                function handleSubmit(values, name) {
                    setModalOpen(false);
                    setSelectedTenant(null);
                    if (values.id) {
                        let customerReqs = form.getFieldValue('customerReqs');
                        customerReqs = customerReqs?.map(item => {
                            console.log(item, values.id);
                            if (parseInt(item.id) === parseInt(values.id)) {
                                item = values;
                            }
                            return item
                        })
                        form.setFieldValue('customerReqs', customerReqs)
                    } else {
                        add(values);
                    }
                }

                return (<>
                    {fields.map(({key, name, ...restField}) => {
                        let tenant = form.getFieldValue(['customerReqs', name]);
                        return <CartTenant onDeleteClick={() => remove(name)} tenant={tenant}
                                           onEdit={() => openTenantModal(tenant, name)}/>;
                    })}
                    <Card
                        onClick={() => openTenantModal()}
                        style={{
                            minHeight: 140,
                            width: 230,
                            marginTop: 12,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}

                    >
                        <Meta
                            description={<div>
                                <Typography.Text>{t('deposit.addTenant')} </Typography.Text><PlusOutlined/></div>}
                        />
                    </Card>
                    <ModalTenantDetail open={modalOpen} tenant={selectedTenant} onCancel={handleCancel}
                                       onOk={(values) => {
                                           handleSubmit(values);
                                       }}/>

                </>);
            }}
        </Form.List>
    </div>;
}

function ModalTenantDetail({open, onCancel, onOk, tenant}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();

    async function handleSubmit() {
        const values = await form.validateFields();
        onOk(values);
        form.resetFields();
    }

    useEffect(() => {
        if (tenant) {
            setInitialValues({
                ...tenant,
            });
        }
    }, [
        JSON.stringify(tenant)]);

    const [initialValues, setInitialValues] = useState();

    useEffect(() => {
        form.setFieldsValue({
            ...initialValues,
        });
    }, [
        JSON.stringify(initialValues)]);

    return <Modal open={open} onCancel={onCancel} title={t('deposit.addTenant')} onOk={() => handleSubmit()}
                  destroyOnClose>
        <Form form={form} layout={'vertical'} name={'tenantForm'}>
            <Form.Item
                style={{
                    display: 'none',
                }}
                name={['id']}
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label={t('fullName')}
                name={['fullName']}
                rules={[
                    {
                        required: true,
                    }]}
            >
                <Input placeholder={t('fullName')}/>
            </Form.Item>
            <Form.Item
                label={t('phoneNumber')}
                name={['phoneNumber']}
                rules={[
                    {
                        required: true,
                    }]}
            >
                <Input placeholder={t('phoneNumber')}/>
            </Form.Item>
            <Form.Item
                label={t('email')}
                name={['email']}
            >
                <Input placeholder={t('email')}/>
            </Form.Item>
            <Form.Item
                label={t('idNumber')}
                name={['idNumber']}
            >
                <Input placeholder={t('idNumber')}/>
            </Form.Item>

            <Form.Item
                label={t('address')}
                name={['address']}
            >
                <Input placeholder={t('address')}/>
            </Form.Item>
            <UploadImages name={'imageUrls'} label={t('deposit.tenantImage')}/>
        </Form>

    </Modal>;
}

function ModalDepositDetail({onCancel, open, depositId}) {
    const {t} = useTranslation();
    const getDepositRequest = normalizeRequestObject({
        id: depositId,
    });
    const {data: depositData, loading} = useQuery(QUERY_GET_DEPOSIT, {
        variables: getDepositRequest, skip: !depositId,
    });
    const roomDeposit = depositData?.getRoomDeposit;
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState();

    useEffect(() => {
        if (roomDeposit) {
            const customerReqs = roomDeposit?.customers?.map(item => {
                return {
                    ...item, imageUrls: item.imageUrls?.filter(url => !!url).map(url => parseImageUrl(url)),
                };
            });
            setInitialValues({
                ...roomDeposit,
                startDate: parseDate(roomDeposit.startDate),
                imageUrls: roomDeposit.imageUrls?.map(url => parseImageUrl(url)),
                customerReqs: customerReqs,
            });
        }
    }, [
        JSON.stringify(roomDeposit)]);

    useEffect(() => {
        form.setFieldsValue({
            ...initialValues,
        });
    }, [
        JSON.stringify(initialValues)]);

    function handleCancel() {
        onCancel();
    }

    const {addDeposit, loading: loadingAdd} = useAddDeposit();
    const {updateDeposit, loading: loadingUpdate} = useUpdateDeposit();

    async function handleSubmit() {
        const values = await form.validateFields();
        // remove id field
        values.customerReqs = values.customerReqs?.map(item => omit(item, ['id', '__typename']));
        try {
            if (!depositId) {
                await addDeposit(normalizeRequestObject(values));
                notification.success({
                    message: t('deposit.createSuccess'),
                });
            } else {
                await updateDeposit(depositId, normalizeRequestObject(values));
                notification.success({
                    message: t('deposit.updateSuccess'),
                });
            }

            handleCancel();
            form.resetFields();

        } catch (e) {
            notification.error({
                message: t('deposit.createFailed'), description: e.message,
            });
        }
    }

    return <Modal open={open} onCancel={handleCancel} width={800} onOk={handleSubmit}
                  confirmLoading={loadingAdd || loadingUpdate}>
        <Form layout={'vertical'} form={form}>
            <Form.Item label={t('deposit.amount')} name={'amount'} rules={[{required: true}]}>
                <NumberInput/>
            </Form.Item>
            <Form.Item label={t('room')} name={'roomId'} rules={[{required: true}]}>
                <SelectRoom/>
            </Form.Item>
            <Form.Item label={t('deposit.startDate')} name={'startDate'} rules={[{required: true}]}>
                <CustomDatePicker/>
            </Form.Item>
            <Form.Item label={t('deposit.paymentMethod')} name={'paymentMethod'}>
                <SelectPaymentMethod/>
            </Form.Item>
            <InputTenant/>
            <UploadImages name={'imageUrls'} label={t('deposit.image')}/>
        </Form>
    </Modal>;
}

const Deposit = () => {
    let {t} = useTranslation();
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue({
            month: dayjs(new Date()),
        });
    }, []);
    const month = Form.useWatch('month', form);
    const startDate = month?.startOf('startDate');
    const endDate = month?.endOf('endDate');

    const motelIds = Form.useWatch('motelIds', form);
    const [showModal, setShowModal] = useState(false);
    const [selectedDepositId, setSelectedDepositId] = useState();

    function openDepositDetail(id) {
        setSelectedDepositId(id);
        setShowModal(true);
    }

    function closeModal() {
        setSelectedDepositId(null);
        setShowModal(false);
    }

    const motels = motelIds?.length === 0 ? null : motelIds;
    const items = [
        {
            key: 'pending',
            label: t('deposit.pending'),
            children: <TabDeposit startDate={startDate} endDate={endDate} motelIds={motels} status={'pending'}
                                  onSelectedDeposit={openDepositDetail}/>,
        }, {
            key: 'overdue',
            label: t('deposit.overdue'),
            children: <TabDeposit startDate={startDate} endDate={endDate} motelIds={motels} status={'overdue'}
                                  onSelectedDeposit={openDepositDetail}/>,
        }, {
            key: 'canceled',
            label: t('deposit.canceled'),
            children: <TabDeposit startDate={startDate} endDate={endDate} motelIds={motels} status={'canceled'}
                                  onSelectedDeposit={openDepositDetail}/>,
        }, {
            key: 'created_contract',
            label: t('deposit.createdContract'),
            children: <TabDeposit startDate={startDate} endDate={endDate} motelId={motels} status={'created_contract'}
                                  onSelectedDeposit={openDepositDetail}/>,
        }];

    return <>
        <div>
            <Form form={form} layout={'vertical'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Space>
                    <Form.Item label={t('motel')} name={'motelIds'} style={{minWidth: 200}}>
                        <SelectMotel mode={'multiple'}/>
                    </Form.Item>
                    <Form.Item label={t('dateTime.startDate')} name={'startDate'} style={{width: 200}}>
                        <CustomDatePicker allowClear={false}/>
                    </Form.Item>
                    <Form.Item label={t('dateTime.endDate')} name={'endDate'} style={{width: 200}}>
                        <CustomDatePicker allowClear={false}/>
                    </Form.Item>
                </Space>
            </Form>
        </div>
        <ModalDepositDetail open={showModal} onCancel={closeModal} depositId={selectedDepositId}/>
        <Tabs defaultActiveKey="initial" items={items}/>
        <FloatButton type={'primary'} icon={<PlusOutlined/>} onClick={() => setShowModal(true)}/>
    </>;
};

export default Deposit;
