import { gql } from "@apollo/client";

const CONTRACT_TERMS = gql`
    query contractTerms {
        contractTerms {
            id
            content
            type
        }
    }
`;

const GET_CONTRACT_TERM = gql`
    query getContractTerm($id: Int!) {
        getContractTerm(id: $id) {
            type
            content
        }
    }
`;
export { CONTRACT_TERMS, GET_CONTRACT_TERM };
