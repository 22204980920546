import { makeStyles } from "@mui/styles";
export const useSelectTypeTerm = makeStyles({
    container: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginBottom: "48px",
    },
    card: {
        padding: "24px",
        width: "200px",
        height: "120px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        border: "1px solid #C4C4C4",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        cursor: "pointer",
    },
    title: {
        fontSize: "20px",
    },
    subTitle: {
        color: "#727E8C",
        fontSize: "12px",
    },
    activeTitle: {
        color: "#04C45C",
    },
    activeCard: {
        border: "2px solid #04C45C",
    },
});
