import { makeStyles } from "@mui/styles";
export const useModalDetail = makeStyles({
    container: {
        padding: "30px 40px 30px 20px",
        "&> .MuiGrid-item": {
            padding: 0,
        },
        "&> .MuiGrid-item:last-child": {
            marginTop: "30px",
        },
    },
    button: {
        display: "flex",
        alignItems: "center",
    },
    iconButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
        padding: "0px",
        color: "#000000 ",
        textTransform: "none",
    },
    avatar: {
        height: "50px",
        width: "50px",
        border: "1px solid #C2CFE0",
        padding: "10px",
    },
    checkBox: {
        color: "#D5D5D5",
        "&.Mui-checked": {
            color: "#04C35C",
        },
    },
    label: {
        marginBottom: "11px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        padding: 0,
    },
    input: {
        border: "1px solid #E8E8E8",
        borderRadius: "5px",
        padding: "10px 20px 10px 20px",
        minHeight: "107px",
    },
    formControl: {
        width: "100%",
    },
});
