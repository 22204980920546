import React, { memo } from "react";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { useSelectInput } from "./useSelectInput";
import styles from "./SelectInput.module.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const Placeholder = memo(({ children }) => {
    const classes = useSelectInput();
    return <div className={classes.placeholder}>{children}</div>;
});

export default memo(function SelectInput({
    label,
    star,
    data,
    placeholder,
    onSelectChange,
    defaultValue,
    error,
    field,
    isDisable,
}) {
    const classes = useSelectInput();
    const handleChange = (event) => {
        onSelectChange(event.target.value);
    };

    return (
        <FormControl fullWidth className={classes.formControl}>
            <Typography
                variant="p"
                component="div"
                className={`${classes.label} ${
                    label ? "" : styles.labelActive
                }`}
            >
                {label}{" "}
                <span className={`${styles.star} ${star ? styles.active : ""}`}>
                    *
                </span>
            </Typography>

            {data.length === 0 ? (
                <Select
                    disabled={isDisable}
                    className={classes.select}
                    displayEmpty
                    value={defaultValue || ""}
                    onChange={handleChange}
                    input={
                        <InputBase
                            className={
                                error ? classes.inputError : classes.input
                            }
                        />
                    }
                    // MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                        if (!selected) {
                            return (
                                <Placeholder className={classes.placeholder}>
                                    Không có dữ liệu
                                </Placeholder>
                            );
                        }

                        return data[data.findIndex((x) => x.id === selected)][
                            field
                        ];
                    }}
                >
                    <MenuItem disabled value="" className={classes.menuItem}>
                        Không có dữ liệu
                    </MenuItem>
                </Select>
            ) : (
                <Select
                    disabled={isDisable}
                    className={classes.select}
                    displayEmpty
                    value={defaultValue || ""}
                    onChange={handleChange}
                    input={
                        <InputBase
                            className={
                                error ? classes.inputError : classes.input
                            }
                        />
                    }
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    renderValue={(selected) => {
                        if (!selected) {
                            return (
                                <Placeholder className={classes.placeholder}>
                                    {placeholder}
                                </Placeholder>
                            );
                        }

                        return data[data.findIndex((x) => x.id === selected)][
                            field
                        ];
                    }}
                >
                    {data.map((item, index) => (
                        <MenuItem
                            key={index}
                            value={item.id}
                            className={classes.menuItem}
                        >
                            {item[field]}
                        </MenuItem>
                    ))}
                </Select>
            )}

            <Typography
                className={`${classes.hiddenIt} ${
                    error ? classes.labelError : ""
                }`}
            >
                <i
                    className={`fa fa-exclamation-circle ${classes.iconError}`}
                ></i>
                {error}
            </Typography>
        </FormControl>
    );
});
