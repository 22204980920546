import React, { memo } from "react";
import Grid from "@mui/material/Grid";
import CardInfo from "../../../components/CardInfo/CardInfo";
import RoomAvatar from "../../../assets/MotelIcons/room.svg";
import TenantAvatar from "../../../assets/MotelIcons/tenant.svg";
import FeeAvatar from "../../../assets/MotelIcons/paidFee.svg";
import FloorAvatar from "../../../assets/MotelIcons/floor.svg";
import { useCardsMotelDetail } from "./useCardsMotelDetail";
import { motelText } from "../../../constants/Constants"

export const CardsMotelDetail = memo(({ data }) => {

    const classes = useCardsMotelDetail();
    return (
        <Grid container className={classes.cards}>
            <CardInfo path={RoomAvatar} title={motelText.totalRoom} quantity={data.getMotel.totalRoom} />
            <CardInfo
                path={TenantAvatar}
                title={motelText.totalTenant}
                quantity={data.getMotel.totalTenant}
            />
            <CardInfo path={FloorAvatar} title={motelText.totalFloor} quantity={data.getMotel.totalFloor} />
            <CardInfo
                path={FeeAvatar}
                title={motelText.fee}
                quantity={`${new Intl.NumberFormat().format(data.getMotel.rentFee)} VND`}
            />
            <CardInfo
                path={data.getMotel.managers[0].avatar}
                title={motelText.manager}
                quantity={data.getMotel.managers[0].fullName}
                imgAva={true}
            />
        </Grid>

    );
}) 