import {gql} from '@apollo/client';

const GET_SERVICES = gql`
    query getServices {
        services {
            id
            name
            feeType
            icon
            fee
            updatedAt
            description
            unit
            monthlyBase
        }
    }
`;

const GET_SERVICE = gql`
    query getService($serviceId: Int!) {
        getService(serviceId: $serviceId) {
            name
            feeType
            icon
            fee
            updatedAt
            description
            unit
            monthlyBase
        }
    }
`;

const SEARCH_ICONS = gql`
    query searchIcons($searchText: String) {
        searchIcons(searchText: $searchText) {
            id
            imageUrl
        }
    }
`;
export { GET_SERVICES, GET_SERVICE, SEARCH_ICONS };
