/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSignIn } from "../../graphql-client/Auth/mutations";
import Button from "../../components/Button/Button";
import FormContainer from "../../components/FormContainer/FormContainer";
import Input from "../../components/Input/Input";
import styles from "./SignIn.module.css";
import {
    textEmail,
    textPassword,
    alertPassword,
    alertEmail,
    textSignIn,
} from "../../constants/Constants";
import { useAuthToken } from "../../config/auth";
import { appRouter } from "../../constants/AppRouter";
import { catchGraphQlErrors } from "../../util/CatchGraphQLErrors";
import { validateSignIn } from "../../useCase/Auth/SignInUseCase";

const Login = () => {
    const [_, setAuthToken, removeAuthToken] = useAuthToken();
    const { mutateSignIn, dataSignIn, errorSignIn } = useSignIn();
    const [alert, setAlert] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validationMessage, setValidationMessage] = useState({});
    const [authToken] = useAuthToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (errorSignIn) {
            setAlert(catchGraphQlErrors(errorSignIn));
        }

        if (authToken || dataSignIn && authToken) {
            navigate(appRouter.dashboard);
        }

    }, [dataSignIn, navigate, authToken, errorSignIn]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = validateSignIn(email, password);
        setAlert("");
        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            setValidationMessage(isValid.msg);
            removeAuthToken();
            mutateSignIn({
                email: email,
                password: password,
            });
        }
    };

    return (
        <>
            <FormContainer text={textSignIn.titleSignIn}>
                <Form onSubmit={handleSubmit} className={styles.form}>
                    <Input
                        text={textEmail.text}
                        name={textEmail.name}
                        type={textEmail.type}
                        placeholder={textEmail.placeholder}
                        error={validationMessage.email}
                        changed={(e) => setEmail(e.target.value)}
                    />
                    <Input
                        text={textPassword.text}
                        name={textPassword.name}
                        type={textPassword.type}
                        placeholder={textPassword.placeholder}
                        error={validationMessage.password}
                        changed={(e) => setPassword(e.target.value)}
                    />

                    <Form.Group className={styles.formGroup}>
                        <Form.Check
                            className={styles.checkbox}
                            type="checkbox"
                            label={textSignIn.rememberSignIn}
                        />
                        <Link
                            to="forgotpassword"
                            className={styles.linkForgotPassword}
                        >
                            {textSignIn.forgotPassword}
                        </Link>
                    </Form.Group>
                    <p className={styles.alertLabel}>{alert}</p>
                    <Button text={textSignIn.textButton} />

                    <div className={styles.footerForm}>
                        {textSignIn.haveAccount}
                        <Link to="signup" className={styles.linkSignUp}>
                            &ensp;{textSignIn.signUpNow}
                        </Link>
                    </div>
                </Form>
            </FormContainer>
        </>
    );
};

export default Login;
