/* eslint-disable */
import { makeStyles } from "@mui/styles";
export const useSelectInput = makeStyles({
    input: {
        width: "100%",
        border: "1px solid #E8E8E8",
        height: "50px",
        borderRadius: "5px",
        "& .MuiInputBase-input": {
            padding: "15px 0px 15px 16px",
        },
    },
    placeholder: {
        color: "#C2CFE0",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    select: {
        fontSize: "14px",
        fontWeight: 400,
        width: "100%",

        "& .MuiSelect-select": {
            height: "50px",
            display: "flex",
            alignItems: "center",
            padding: 0,
            paddingLeft: "16px",
        },
    },
    formControl: {
        width: "100%",
    },
    label: {
        marginBottom: "11px",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        padding: 0,
        textOverflow: "ellipsis",
        overflow: "hidden",
        ["@media screen and (max-width: 1280px)"]: {
            fontSize: "11px",
        },
    },
    inputError: {
        width: "100%",
        border: "1px solid #f7685b",
        height: "50px",
        borderRadius: "5px",
        "& .MuiInputBase-input": {
            padding: "15px 0px 15px 20px",
        },
    },
    hiddenIt: {
        display: "none",
    },
    labelError: {
        color: "#f7685b",
        fontSize: "12px",
        display: "flex",
        marginTop: "5px",
    },
    iconError: {
        fontSize: "14px",
        position: "relative",
        top: "2px",
        marginRight: "8px",
    },
});
