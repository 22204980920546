import { gql } from "@apollo/client";

const SEARCH_CONTRACTS = gql`
    query searchContracts(
        $page: Int!
        $size: Int!
        $status: RoomContractStatus
        $searchText: String
    ) {
        roomContracts(
            page: $page
            size: $size
            status: $status
            searchText: $searchText
        ) {
            results {
                id
                number
                room {
                    id
                    name
                    motel {
                        name
                    }

                }
                startDate
                endDate
                representativeTenant{
                    fullName
                }
                createdBy{
                    fullName
                }
                createdAt
            }
            totalPages
            total
        }
    }
`;


const GET_CONTRACT = gql`
    query GetContract($id: Int!){
        getRoomContract(id: $id){
            id
            number
            room{
                id
                name
                totalTenant
                motel{
                    id
                    name
                }
                allServices {
                    isActive
                    service {
                        id
                        name
                        icon
                        feeType
                        fee
                        unit
                    }
                }
            }
            previewUrl
            startDate
            endDate
            startPaidDate
            roomPrice
            deposit
            monthsToPay
            contractServices{
                serviceId
                quantity
                isActive
                lastIndex
            }
            tenants{
                id
                fullName
                idNumber
                idIssueBy
                idIssueDate
                email
                phoneNumber
            }
            motelRepresentativeId
            allServices{
                service{
                    id
                    name
                    unit
                    fee
                }
                
                isActive
            }
            contractTerms{
                id
                type
                content
            }
            imageUrls
        }
        
        contractTerms {
            id
            content
            type
        }
    }
`;
export { SEARCH_CONTRACTS, GET_CONTRACT };
