import { makeStyles } from "@mui/styles";

export const useItemMotel = makeStyles({
    item: {
        height: "74px",
        borderBottom: "1px solid rgba(235, 239, 242, 1)",
        justifyContent: "space-between",
        "&:hover": {
            cursor: "pointer",
        },
        width: "100%",
    },
    active: {
        borderRight: "2px solid #04C45C",
    },

    nameMotel: {
        color: "#04C35C",
        textOverflow: "ellipsis",
    },
    address: {
        color: "#192A3E",
        fontSize: "12px",
        lineHeight: "18px",
        letterSpacing: "0.01e",
    },
    avatar: {
        width: "10%",
        minWidth: "0",
    },
    content: {
        minWidth: 0,
        width: "70%",
        "& .MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
        },
    },
    detail: {
        width: "20%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        "& .MuiListItemText-primary": {
            color: "#90A0B7",
            fontSize: "12px",
            lineHeight: "15px",
            textAlign: "right",
            letterSpacing: "0.01em",
        },
    },
});
