import { makeStyles } from "@mui/styles";

export const useListImageRoom = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "16px",
        // height: "fitContent",
        width: "100%",
        backgroundColor: "#fff",
        padding: "8px",
        borderRadius: "5px",
        boxShadow: "0px 6px 18px 0px #0000000F",
    },
    title: {
        fontSize: "15px",
        color: "#192A3E",
        fontWeight: "700",
    },
    listImage: {
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
        gap: "20px",
        flexWrap: "wrap",
        // height: "100%",
        "& div": {
            width: "47.5%",
            borderRadius: "5px",
            height: "100px",
        },
        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
        "@media screen and (max-width: 1280px)": {
            gap: "8px",
        },
        "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
            gap: "14px",
        },
    },
    modalImage: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        "& .carousel-item": {
            height: "800px",
            width: "100%",
            "@media screen and (max-width: 1280px)": {
                height: "500px",
            },
            "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
                height: "650px",
            },
        },

        "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
        },
    },
});
