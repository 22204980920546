import Button from "@mui/material/Button";
import { useButtonHome } from "./useButtonHome";
import { memo } from "react"

const ButtonHome = ({ text, ...rest }) => {
    const classes = useButtonHome();
    return (
        <Button className={classes.button} {...rest}>
            {text}
        </Button>
    );
};

export default memo(ButtonHome);
