import { makeStyles } from "@mui/styles";

export const CardInfo = makeStyles((theme) => ({
    reviewCard: {
        width: "19%",
        boxShadow: "0px 6px 18px 0px #0000000F",
    },
    cardHeader: {
        padding: "15px 0px 15px 10px",
        height: "100%",
        "& .MuiCardHeader-title": {
            fontSize: "16px",
            fontWeight: 700,
        },
        "& .MuiCardHeader-subheader": {
            fontSize: "16px",
            fontWeight: 700,
        },
        "& .MuiCardHeader-avatar": {
            marginRight: "5px",
        },
        "@media screen and (max-width: 1280px)": {
            padding: "5px 0px 5px 3px",
            "& .MuiCardHeader-title": {
                fontSize: "14px",
            },
            "& .MuiCardHeader-subheader": {
                fontSize: "14px",
            },
        },

        "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
            padding: "10px 0px 10px 3px",
            "& .MuiCardHeader-title": {
                fontSize: "15px",
            },
            "& .MuiCardHeader-subheader": {
                fontSize: "15px",
            },
        },
    },
    avatar: {
        backgroundColor: "#04C35C33",
        padding: "7px",
        "@media screen and (max-width: 1280px)": {
            width: "35px",
            height: "35px",
        },

        "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
            width: "45px",
            height: "45px",
        },
        // [theme.breakpoints.down("lg")]: {

        // },
        // [theme.breakpoints.up("xl")]: {

        // },
    },
    imgAva: {
        width: "35px",
        height: "35px",
        [theme.breakpoints.down("lg")]: {
            width: "25px",
            height: "25px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "45px",
            height: "45px",
        },
    },
}));
