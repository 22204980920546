/* eslint-disable */
import {gql, useMutation} from '@apollo/client';
import {notification} from 'antd';
import {useTranslation} from 'react-i18next';

const ADD_CONTRACT = gql`
    mutation addRoomContract($req: AddRoomContractReq!) {
        addRoomContract(req: $req) {
            ok
            id
        }
    }
`;

const UPDATE_CONTRACT = gql`
    mutation updateRoomContract($id: Int!, $req: UpdateRoomContractReq!) {
        updateRoomContract(id: $id, req: $req) {
            ok
        }
    }
`;


const DELETE_CONTRACT = gql`
    mutation deleteContract($id: Int!){
        deleteRoomContract(id: $id){
            ok
        }
    }
`;

function useDeleteContract() {
    let {t} = useTranslation();
    let [mutateDeleteContract] = useMutation(DELETE_CONTRACT);
    async function deleteContract(id) {
        try {
            await mutateDeleteContract({
                variables: {
                    id
                },
                refetchQueries: [
                    'searchContracts',
                ]
            })
        } catch (e){
            notification.error({
                message: t('createContractFailed'),
                description: e
            })
        }
    }
    return {
        deleteContract
    }
}


function useAddRoomContract() {
    const [mutateAddContract, {data, loading, error}] = useMutation(ADD_CONTRACT);
    let {t} = useTranslation();
    const handleAddRoom = async (variables) => {
        try {
            await mutateAddContract({
                variables,
                refetchQueries: [
                    'myRooms',
                    'searchContracts',
                ],
            });
        } catch (e) {
            notification.error({
                message: t('createContractFailed')
            })
            throw e;
        }
    };

    return {
        mutateAddRoom: handleAddRoom,
        dataAddRoom: data,
        loadingAddContract: loading,
        errorAddContract: error,
    };
}

function useUpdateRoomContract() {
    const [mutateUpdateContract, {loading}] = useMutation(UPDATE_CONTRACT);
    let {t} = useTranslation();
    const updateContract = async (variables) => {
        try {
            await mutateUpdateContract({
                variables,
                refetchQueries: [
                    'myRooms',
                    'searchContracts',
                    'getRoomContract',
                ],
            });
        } catch (e) {
            notification.error({
                message: t('updateContractFailed')
            })
            throw e;
        }
    };

    return {
        updateContract,
        loading,
    };
}

export {useAddRoomContract, useDeleteContract, useUpdateRoomContract};
