import { Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ForgotPassword.module.css";
import FormContainer from "../../components/FormContainer/FormContainer";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import InputButton from "../../components/InputButton/InputButton";
import {
    textEmail,
    textForgotPassword,
    textConfirmCode,
    textNewPassword,
    successCode,
    successNewPassword,
} from "../../constants/Constants";
import {
    validateSendEmail,
    validateNewPassword,
} from "../../useCase/Auth/ForgotPasswordUseCase";
import {
    useRequestChangePassword,
    useChangePassword,
} from "../../graphql-client/Auth/mutations";
import { catchGraphQlErrors } from "../../util/CatchGraphQLErrors";
import { appRouter } from "../../constants/AppRouter";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [validationMessage, setValidationMessage] = useState({});
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const {
        mutateRequestChangePassword,
        dataRequestChangePassword,
        errorRequestChangePassword,
    } = useRequestChangePassword();
    const { mutateChangePassword, dataChangePassword, errorChangePassword } =
        useChangePassword();
    const [alertEmail, setAlertEmail] = useState("");
    const [alertNewPassword, setAlertNewPassword] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (errorRequestChangePassword) {
            setAlertEmail(catchGraphQlErrors(errorRequestChangePassword));
        }
        if (dataRequestChangePassword) {
            alert(successCode.text);
        }
    }, [errorRequestChangePassword, dataRequestChangePassword]);

    useEffect(() => {
        if (errorChangePassword) {
            setAlertNewPassword(catchGraphQlErrors(errorChangePassword));
        }
        if (dataChangePassword) {
            alert(successNewPassword.text);
            navigate(appRouter.signIn, { replace: true });
        }
    }, [errorChangePassword, dataChangePassword, navigate]);

    const handleRequestChangePassword = (e) => {
        e.preventDefault();

        const isValid = validateSendEmail(email);
        setAlertEmail("");

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            setValidationMessage(isValid.msg);
            mutateRequestChangePassword({
                email,
            });
        }
    };

    const handleChangePassword = (e) => {
        e.preventDefault();

        const isValid = validateNewPassword(code, newPassword);

        setAlertNewPassword("");
        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            setValidationMessage(isValid.msg);
            mutateChangePassword({
                email: email,
                code: code,
                password: newPassword,
            });
        }
    };
    return (
        <FormContainer text={textForgotPassword.titleForgotPassword}>
            <p className={styles.warning}>{textForgotPassword.textWarning}</p>
            <Form
                className={styles.requestChangePassword}
                onSubmit={handleRequestChangePassword}
            >
                <InputButton
                    text={textEmail.text}
                    star={true}
                    placeholder={textEmail.placeholder}
                    error={validationMessage.email}
                    changed={(e) => setEmail(e.target.value)}
                    type={textEmail.type}
                    buttonText={textForgotPassword.textButtonConfirmCode}
                />
                <p className={styles.alertLabel}>{alertEmail}</p>
            </Form>

            <Form onSubmit={handleChangePassword}>
                <Input
                    text={textConfirmCode.text}
                    star={true}
                    placeholder={textConfirmCode.placeholder}
                    error={validationMessage.code}
                    changed={(e) => setCode(e.target.value)}
                    type={textConfirmCode.type}
                />
                <Input
                    text={textNewPassword.text}
                    star={true}
                    placeholder={textNewPassword.placeholder}
                    error={validationMessage.newPassword}
                    changed={(e) => setNewPassword(e.target.value)}
                    type={textNewPassword.type}
                />
                <p className={styles.alertLabel}>{alertNewPassword}</p>
                <div className={styles.buttonConfirmChange}>
                    <Button
                        className={styles.buttonConfirmChange}
                        text={textForgotPassword.textButtonConfirmChange}
                    />
                </div>
            </Form>
        </FormContainer>
    );
};

export default ForgotPassword;
