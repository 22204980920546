/* eslint-disable */
import { makeStyles } from "@mui/styles";

export const useCardService = makeStyles({
    card: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #04C35C",
        borderRadius: "5px",
        gap: "10px",
        padding: "10px",
        boxShadow: "none",
        ["@media screen and (max-width: 1280px)"]: {
            padding: "8px",
        },
    },
    cardMedia: {
        width: "35px",
        height: "35px",
        ["@media screen and (max-width: 1280px)"]: {
            width: "24px",
            height: "24px",
        },
    },
    cardContent: {
        padding: "0px !important",
        margin: 0,
    },
    nameService: {
        color: "#334D6E",
        fontWeight: "700",
    },
    priceService: {
        color: "#FF3927",
        fontSize: "12px",
    },
});
