import { useEffect, memo } from "react";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import styles from "./ModalAdd.module.css";
import Grid from "@mui/material/Grid";
import Input from "../../../components/Input/Input";
import { roomText } from "../../../constants/Constants";
import { useModalAdd } from "./useModalAdd";
import RadioGroupBasic from "../RadioGroupBasic/RadioGroupBasic";
import { useState } from "react";
import TabItem from "../../../components/TabItem/TabItem";
import TabPanel from "@mui/lab/TabPanel";
import ButtonHome from "../../../components/ButtonHome/ButtonHome";
import CheckboxService from "../../../components/CheckboxService/CheckboxService";
import TextArea from "../../../components/TextArea/TextArea";
import { useAddRoom } from "../../../graphql-client/Room/mutation";
import { validateAddRoom } from "../../../useCase/Room/useCaseRoom";
import { resizeImage } from "../../../util/ResizeImage";
import { handleUploadImage } from "../../../services/common";
import UploadImage from "../../../components/UploadImage/UploadImage";
import Notification from "../../../components/Notification/Notification";
import EmptyIcon from "../../../assets/Icons/EmptyIcon";

const ModalAdd = ({ openAdd, closeAdd, services, systemServices, motelId }) => {
    const classes = useModalAdd();
    const { mutateAddRoom, dataAddRoom, errorAddRoom } = useAddRoom();

    const [name, setName] = useState("");
    const [floorNumber, setFloorNumber] = useState("");
    const [price, setPrice] = useState("");

    const [totalBedRoom, setTotalBedRoom] = useState("");
    const [totalGuestRoom, setTotalGuestRoom] = useState("");
    const [area, setArea] = useState("");
    const [maxTenant, setMaxTenant] = useState("");

    const [deposit, setDeposit] = useState("");
    const [gender, setGender] = useState("male");

    const [serviceIds, setServiceIds] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [raws, setRaws] = useState([]);
    const [systemServiceIds, setSystemServiceIds] = useState([]);
    const [description, setDescription] = useState("");
    const [note, setNote] = useState("");

    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });
    const [validationMessage, setValidationMessage] = useState({});
    const [tabPanel, setTabPanel] = useState("");

    const validFile = ["image/png", "image/jpeg", "image/jpg"];

    async function handleChange(e) {
        const rawFile = e.target.files[0];
        if (!validFile.includes(rawFile.type)) {
            setNotify({
                ...roomText.notify.error,
            });
        } else {
            let resizedFile = rawFile;
            try {
                resizedFile = await resizeImage(
                    rawFile,
                    1920,
                    1080,
                    "JPEG",
                    50,
                    0,
                    "file"
                );
            } catch (err) {}
            setPreviews([...previews, URL.createObjectURL(resizedFile)]);
            setRaws([...raws, resizedFile]);
        }
    }

    function deleteFile(i) {
        const deletedPreview = previews.filter((item, index) => index !== i);
        setPreviews(deletedPreview);
        const deletedRaw = raws.filter((item, index) => index !== i);
        setRaws(deletedRaw);
    }

    useEffect(() => {
        if (openAdd === true) {
            clearState();
        }
    }, [openAdd]);

    const clearState = () => {
        setName("");
        setFloorNumber("");
        setPrice("");

        setTotalBedRoom("");
        setTotalGuestRoom("");
        setArea("");
        setMaxTenant("");

        setDeposit("");
        setGender("male");

        setServiceIds([]);
        setPreviews([]);
        setRaws([]);
        setSystemServiceIds([]);
        setDescription("");
        setNote("");

        setValidationMessage({});
        setTabPanel("1");
    };

    const handleAddRoom = async (e) => {
        e.preventDefault();

        const isValid = validateAddRoom(
            name,
            floorNumber,
            price,

            totalBedRoom,
            totalGuestRoom,
            area,
            maxTenant,

            deposit,

            description,
            note
        );

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
            if (isValid.msg.description && isValid.msg.note) {
                setTabPanel("4");
            }

            if (isValid.msg.description) {
                setTabPanel("4");
            }

            if (isValid.msg.note) {
                setTabPanel("5");
            }
        } else {
            setValidationMessage(isValid.msg);
            switch (gender === "") {
                case true:
                    await mutateAddRoom(
                        {
                            motelId: motelId,
                            addRoomReq: {
                                name: name,
                                floorNumber: floorNumber,
                                price: price,

                                totalBedRoom: totalBedRoom,
                                totalGuestRoom: totalGuestRoom,
                                area: area,
                                maxTenant: maxTenant,

                                deposit: deposit,

                                serviceIds: serviceIds,
                                imageUrls: await handleUploadImage(raws),
                                systemServiceIds: systemServiceIds,
                                description: description,
                                note: note,
                            },
                        },
                        motelId
                    );
                    setNotify({
                        ...roomText.notify.add,
                    });
                    closeAdd();
                    break;

                case false:
                    await mutateAddRoom(
                        {
                            motelId: motelId,
                            addRoomReq: {
                                name: name,
                                floorNumber: floorNumber,
                                price: price,

                                totalBedRoom: totalBedRoom,
                                totalGuestRoom: totalGuestRoom,
                                area: area,
                                maxTenant: maxTenant,

                                deposit: deposit,
                                gender: gender,

                                serviceIds: serviceIds,
                                imageUrls: await handleUploadImage(raws),
                                systemServiceIds: systemServiceIds,
                                description: description,
                                note: note,
                            },
                        },
                        motelId
                    );
                    setNotify({
                        ...roomText.notify.add,
                    });
                    closeAdd();
                    break;

                default:
                    break;
            }
        }
    };

    const handleContinueAdd = async (e) => {
        e.preventDefault();

        const isValid = validateAddRoom(
            name,
            floorNumber,
            price,

            totalBedRoom,
            totalGuestRoom,
            area,
            maxTenant,

            deposit,

            description,
            note
        );

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
            if (isValid.msg.description && isValid.msg.note) {
                setTabPanel("4");
            }

            if (isValid.msg.description) {
                setTabPanel("4");
            }

            if (isValid.msg.note) {
                setTabPanel("5");
            }
        } else {
            setValidationMessage(isValid.msg);
            switch (gender === "") {
                case true:
                    await mutateAddRoom(
                        {
                            motelId: motelId,
                            addRoomReq: {
                                name: name,
                                floorNumber: floorNumber,
                                price: price,

                                totalBedRoom: totalBedRoom,
                                totalGuestRoom: totalGuestRoom,
                                area: area,
                                maxTenant: maxTenant,

                                deposit: deposit,

                                serviceIds: serviceIds,
                                imageUrls: await handleUploadImage(raws),
                                systemServiceIds: systemServiceIds,
                                description: description,
                                note: note,
                            },
                        },
                        motelId
                    );
                    setNotify({
                        ...roomText.notify.add,
                    });
                    clearState();
                    break;

                case false:
                    await mutateAddRoom(
                        {
                            motelId: motelId,
                            addRoomReq: {
                                name: name,
                                floorNumber: floorNumber,
                                price: price,

                                totalBedRoom: totalBedRoom,
                                totalGuestRoom: totalGuestRoom,
                                area: area,
                                maxTenant: maxTenant,

                                deposit: deposit,
                                gender: gender,

                                serviceIds: serviceIds,
                                imageUrls: await handleUploadImage(raws),
                                systemServiceIds: systemServiceIds,
                                description: description,
                                note: note,
                            },
                        },
                        motelId
                    );
                    setNotify({
                        ...roomText.notify.add,
                    });
                    clearState();
                    break;

                default:
                    break;
            }
        }
    };

    const handleServiceIdsChanged = (id) => {
        const serviceIndex = serviceIds.indexOf(id);

        let newServices = [];

        if (serviceIndex === -1) {
            newServices = newServices.concat(serviceIds, id);
        } else if (serviceIndex === 0) {
            newServices = newServices.concat(serviceIds.slice(1));
        } else if (serviceIndex === serviceIds.length - 1) {
            newServices = newServices.concat(serviceIds.slice(0, -1));
        } else if (serviceIndex > 0) {
            newServices = newServices.concat(
                serviceIds.slice(0, serviceIndex),
                serviceIds.slice(serviceIndex + 1)
            );
        }

        setServiceIds(newServices);
    };

    const isServiceIdsSelected = (id) => serviceIds.indexOf(id) !== -1;

    const handleSystemServiceIdsChanged = (id) => {
        const systemServiceIndex = systemServiceIds.indexOf(id);

        let newSystemServices = [];

        if (systemServiceIndex === -1) {
            newSystemServices = newSystemServices.concat(systemServiceIds, id);
        } else if (systemServiceIndex === 0) {
            newSystemServices = newSystemServices.concat(
                systemServiceIds.slice(1)
            );
        } else if (systemServiceIndex === systemServiceIds.length - 1) {
            newSystemServices = newSystemServices.concat(
                systemServiceIds.slice(0, -1)
            );
        } else if (systemServiceIndex > 0) {
            newSystemServices = newSystemServices.concat(
                systemServiceIds.slice(0, systemServiceIndex),
                systemServiceIds.slice(systemServiceIndex + 1)
            );
        }

        setSystemServiceIds(newSystemServices);
    };

    const isSystemServiceIdsSelected = (id) =>
        systemServiceIds.indexOf(id) !== -1;

    return (
        <>
            <ModalContainer
                closeModal={closeAdd}
                openModal={openAdd}
                title={roomText.modalRoom.titleAdd}
            >
                <form onSubmit={handleAddRoom} className={styles.form}>
                    <Grid container className={classes.container}>
                        <Grid item xl={5.75} lg={6}>
                            <Input
                                text={roomText.inputNameRoom.label}
                                type={roomText.inputNameRoom.type}
                                placeholder={roomText.inputNameRoom.placeholder}
                                star={true}
                                changed={(e) => setName(e.target.value)}
                                error={validationMessage.name}
                                value={name}
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>
                        <Grid item xl={2.625} lg={2.75}>
                            <Input
                                text={roomText.inputFloorNumber.label}
                                type={roomText.inputFloorNumber.type}
                                placeholder={
                                    roomText.inputFloorNumber.placeholder
                                }
                                star={true}
                                changed={(e) => setFloorNumber(e.target.value)}
                                error={validationMessage.floorNumber}
                                value={floorNumber}
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>
                        <Grid item xl={2.625} lg={2.75}>
                            <Input
                                text={roomText.inputPrice.label}
                                type={roomText.inputPrice.type}
                                placeholder={roomText.inputPrice.placeholder}
                                star={true}
                                changed={(e) => setPrice(e.target.value)}
                                error={validationMessage.price}
                                value={price}
                            />
                        </Grid>

                        <Grid item xl={2.625} lg={2.875}>
                            <Input
                                text={roomText.inputTotalBedRoom.label}
                                type={roomText.inputTotalBedRoom.type}
                                placeholder={
                                    roomText.inputTotalBedRoom.placeholder
                                }
                                star={true}
                                changed={(e) => setTotalBedRoom(e.target.value)}
                                error={validationMessage.totalBedRoom}
                                value={totalBedRoom}
                            />
                        </Grid>
                        <Grid item xl={0.5} lg={0.25}></Grid>

                        <Grid item xl={2.625} lg={2.875}>
                            <Input
                                text={roomText.inputTotalGuestRoom.label}
                                type={roomText.inputTotalGuestRoom.type}
                                placeholder={
                                    roomText.inputTotalGuestRoom.placeholder
                                }
                                star={true}
                                changed={(e) =>
                                    setTotalGuestRoom(e.target.value)
                                }
                                error={validationMessage.totalGuestRoom}
                                value={totalGuestRoom}
                            />
                        </Grid>
                        <Grid item xl={0.5} lg={0.25}></Grid>

                        <Grid item xl={2.625} lg={2.75}>
                            <Input
                                text={roomText.inputArea.label}
                                type={roomText.inputArea.type}
                                placeholder={roomText.inputArea.placeholder}
                                star={true}
                                changed={(e) => setArea(e.target.value)}
                                error={validationMessage.area}
                                value={area}
                            />
                        </Grid>
                        <Grid item xl={0.5} lg={0.25}></Grid>

                        <Grid item xl={2.625} lg={2.75}>
                            <Input
                                text={roomText.inputMaxTenant.label}
                                type={roomText.inputMaxTenant.type}
                                placeholder={
                                    roomText.inputMaxTenant.placeholder
                                }
                                star={true}
                                changed={(e) => setMaxTenant(e.target.value)}
                                error={validationMessage.maxTenant}
                                value={maxTenant}
                            />
                        </Grid>

                        <Grid item xl={2.625} lg={2.875}>
                            <Input
                                text={roomText.inputDeposit.label}
                                type={roomText.inputDeposit.type}
                                placeholder={roomText.inputDeposit.placeholder}
                                star={true}
                                changed={(e) => setDeposit(e.target.value)}
                                error={validationMessage.deposit}
                                value={deposit}
                            />
                        </Grid>

                        <Grid item xl={0.5} lg={0.25}></Grid>

                        <Grid item xl={8.875} lg={8.875}>
                            <RadioGroupBasic
                                label={roomText.radioGender.label}
                                data={roomText.radioGender.valueRadio}
                                value={gender}
                                changed={(e) => setGender(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TabItem
                                data={roomText.modalRoom.tabItems}
                                tabPanelError={tabPanel}
                            >
                                <TabPanel
                                    value="1"
                                    className={classes.tabPanel}
                                >
                                    {services.length > 0 ? (
                                        <>
                                            {services.map((item, index) => {
                                                const isItemSelected =
                                                    isServiceIdsSelected(
                                                        item.id
                                                    );
                                                return (
                                                    <CheckboxService
                                                        key={index}
                                                        data={item}
                                                        changed={(id) =>
                                                            handleServiceIdsChanged(
                                                                id
                                                            )
                                                        }
                                                        isItemSelected={
                                                            isItemSelected
                                                        }
                                                    />
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <EmptyIcon />
                                    )}
                                </TabPanel>
                                <TabPanel
                                    value="2"
                                    className={classes.tabPanel}
                                    style={{ gap: "0" }}
                                >
                                    <UploadImage
                                        previews={previews}
                                        raws={raws}
                                        changed={handleChange}
                                        deleted={deleteFile}
                                        maxImage={
                                            process.env
                                                .REACT_APP_MAX_UPLOAD_ID_IMAGE
                                        }
                                    />
                                </TabPanel>
                                <TabPanel
                                    value="3"
                                    className={classes.tabPanel}
                                >
                                    {systemServices.length > 0 ? (
                                        <>
                                            {systemServices.map(
                                                (item, index) => {
                                                    const isItemSelected =
                                                        isSystemServiceIdsSelected(
                                                            item.id
                                                        );
                                                    return (
                                                        <CheckboxService
                                                            key={index}
                                                            data={item}
                                                            changed={(id) =>
                                                                handleSystemServiceIdsChanged(
                                                                    id
                                                                )
                                                            }
                                                            isItemSelected={
                                                                isItemSelected
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                        </>
                                    ) : (
                                        <EmptyIcon />
                                    )}
                                </TabPanel>
                                <TabPanel
                                    value="4"
                                    className={classes.tabPanel}
                                >
                                    <TextArea
                                        placeholder={
                                            roomText.textAreaDescription
                                                .placeholder
                                        }
                                        value={description}
                                        changed={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        error={validationMessage.description}
                                    />
                                </TabPanel>
                                <TabPanel
                                    value="5"
                                    className={classes.tabPanel}
                                >
                                    <TextArea
                                        placeholder={
                                            roomText.textAreaNote.placeholder
                                        }
                                        value={note}
                                        changed={(e) => setNote(e.target.value)}
                                        error={validationMessage.note}
                                    />
                                </TabPanel>
                            </TabItem>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <ButtonHome
                                type="submit"
                                text={roomText.modalRoom.buttonContinueAdd}
                                onClick={handleContinueAdd}
                            />
                            <ButtonHome
                                type="submit"
                                text={roomText.modalRoom.buttonAdd}
                                onClick={handleAddRoom}
                            />
                        </Grid>
                    </Grid>
                </form>
            </ModalContainer>
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
};

export default memo(ModalAdd);
