import moment from "moment";
import { tenantValidate } from "../../constants/Constants";

function validateNullRoomName(roomName) {
    if (roomName === null) return tenantValidate.list.roomNameNull;
    else return roomName.name;
}
function validateNullAddress(address) {
    if (address === null || address.length === 0)
        return tenantValidate.list.nullMessage;
    else return address;
}
function validateNullIdNumber(idNumber) {
    if (idNumber === null || idNumber.length === 0)
        return tenantValidate.list.nullMessage;
    else return idNumber;
}
function validateNullIdDate(idDate) {
    if (idDate === null || idDate.length === 0)
        return tenantValidate.list.nullMessage;
    else return moment(idDate).format("DD-MM-YYYY");
}
export {
    validateNullRoomName,
    validateNullAddress,
    validateNullIdNumber,
    validateNullIdDate,
};
