import { gql } from "@apollo/client";

const GET_DASHBOARD = gql`
    query getDashboard {
      me{
        totalMotel
        totalRoom
        totalEmptyRoom
        totalTenant
      }
    }
`;

export { GET_DASHBOARD };
