import React, { memo } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useModalContainer } from "./useModalContainer";

export default memo(function ModalContainer({
    openModal,
    closeModal,
    title,
    children,
}) {
    const classes = useModalContainer();
    return (
        <Modal
            open={openModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.modalStyle}>
                <Box className={classes.header}>
                    <Typography className={classes.title} variant="h6" component="div">
                        {title}
                    </Typography>
                    <IconButton aria-label="closed" onClick={closeModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Divider />
                <Box className={classes.formContainer}>{children}</Box>
            </Box>
        </Modal>
    );
})
