import {gql, useMutation, useQuery} from '@apollo/client';
import {normalize} from '@testing-library/jest-dom/dist/utils';
import {Button, Col, Form, Image, Input, Modal, notification, Radio, Row, Select, Space, Table} from 'antd';
import * as PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SearchableSelect} from '../../../components/SearchableSelect';
import {formatDateTime} from '../../../util/Date';
import {normalizeRequestObject} from '../../../util/Request';

const QUERY_TRANSACTION_GROUPS = gql`
    query TransactionGroups($groupTypes: [TransactionGroupType], $onlyRoot: Boolean) {
        transactionGroups(groupTypes: $groupTypes, onlyRoot: $onlyRoot){
            id
            createdAt
            name
            iconUrl
            type
            parentId
            children {
                id
                name
                createdAt
                iconUrl
                type
                parentId
                children{
                    id
                    name
                    createdAt
                    iconUrl
                    type
                    parentId
                }
            }


        }
    }
`;
const QUERY_TRANSACTION_GROUPS_LITE = gql`
    query TransactionGroupsLite($groupTypes: [TransactionGroupType], $onlyRoot: Boolean) {
        transactionGroups(groupTypes: $groupTypes, onlyRoot: $onlyRoot){
            id
            createdAt
            name
            iconUrl
            type
        }
    }
`;

const MUTATION_UPDATE_TRANSACTION_GROUP = gql`
    mutation UpdateTransactionGroup($id: Int!, $req: UpdateTransactionGroupReq) {
        updateTransactionGroup(id: $id, request: $req){
            ok
        }
    }
`;

const MUTATION_ADD_TRANSACTION_GROUP = gql`
    mutation AddTransactionGroup($req: AddTransactionGroupReq) {
        addTransactionGroup(request: $req) {
            ok
        }
    }
`;

function useCreateGroup() {
    const [mutateAddGroup, {loading}] = useMutation(MUTATION_ADD_TRANSACTION_GROUP, {
        refetchQueries: [
            'TransactionGroupsLite',
            'TransactionGroups',
        ]
    });

    async function createGroup(req) {
        await mutateAddGroup({
            variables: {
                req,
            },
        });
    }

    return {
        createGroup, loading,
    };
}

export function SelectTransactionGroup({onlyRoot, groupTypes, ignoreIds=[], ...rest}) {
    const form = Form.useFormInstance();

    const request = {
        onlyRoot,
        groupTypes,
    };
    const {data: groupsData, refetch} = useQuery(QUERY_TRANSACTION_GROUPS_LITE, {
        variables: request,
    });

    useEffect(() => {
        refetch(request);
    }, [
        JSON.stringify(request)]);

    const transactionGroups = groupsData?.transactionGroups;
    const parentOptions = transactionGroups?.filter(item => !ignoreIds.includes(item.id)).map(item => {
        return {
            label: item.name, value: parseInt(item.id),
        };
    });
    return <SearchableSelect options={parentOptions} {...rest}/>
}

function useUpdateTransactionGroup() {
    const [mutateUpdateGroup, {loading}] = useMutation(MUTATION_UPDATE_TRANSACTION_GROUP, {refetchQueries: [
            'TransactionGroupsLite',
            'TransactionGroups',
        ]});

    async function updateGroup(id, req) {
        await mutateUpdateGroup({variables: {id, req}});
    }

    return {
        updateGroup, loading,
    };
}

const QUERY_SEARCH_ICONS = gql`
    query Icons($searchText: String) {
        searchIcons(searchText: $searchText){
            id
            imageUrl
        }
    }
`;

function ModalTransactionGroupDetail({transactionGroup, onCancel, open}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const initialState = {
        name: transactionGroup?.name,
        iconUrl: transactionGroup?.iconUrl,
        parentId: transactionGroup?.parentId,
        type: transactionGroup?.type || 'revenue',
    };
    const [initialValues, setInitialValues] = useState(initialState);

    useEffect(() => {
        setInitialValues({...initialState});
    }, [
        JSON.stringify(initialState)]);

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues]);

    console.log(initialValues);
    const {data: iconsData} = useQuery(QUERY_SEARCH_ICONS);
    const icons = iconsData?.searchIcons;
    const iconOptions = icons?.map(item => {
        return <Select.Option value={item.imageUrl}><Image width={20} height={20} src={item.imageUrl}/></Select.Option>;
    });

    const title = transactionGroup ? t('accountingGroup.update') : t('accountingGroup.create');
    const type = Form.useWatch('type', form);

    const {createGroup, loading: createLoading} = useCreateGroup();
    const {updateGroup, loading: updateLoading} = useUpdateTransactionGroup();

    async function handleSubmit() {
        const values = await form.validateFields();
        if (transactionGroup) {
            try {
                await updateGroup(transactionGroup.id, normalizeRequestObject(values));
                notification.success({
                    message: t('accountingGroup.updateSuccess'),
                });
                onCancel();
            } catch (e) {
                notification.error({
                    message: t('accountingGroup.updateFailed'), description: e.message,
                });
            }
        } else {
            try {
                await createGroup(normalizeRequestObject(values));
                notification.success({
                    message: t('accountingGroup.createSuccess'),
                });
                onCancel();
            } catch (e) {
                notification.error({
                    message: t('accountingGroup.createFailed'), description: e.message,
                });
            }
        }
    }

    const groupTypes = type ? [type] : null;

    return <Modal title={title} open={open} onCancel={onCancel}
                  onOk={handleSubmit} width={700} confirmLoading={updateLoading || createLoading}>
        <Form form={form} layout="vertical">
            <Form.Item label={t('accountingGroup.type')} name={'type'}>
                <Radio.Group>
                    <Radio value={'revenue'}>{t('accountingGroup.revenue')}</Radio>
                    <Radio value={'expense'}>{t('accountingGroup.expense')}</Radio>
                    <Radio value={'debt_collection'}>{t('accountingGroup.debtCollection')}</Radio>
                    <Radio value={'loan_payment'}>{t('accountingGroup.loanPayment')}</Radio>
                    <Radio value={'payable'}>{t('accountingGroup.payable')}</Radio>
                    <Radio value={'receivable'}>{t('accountingGroup.receivable')}</Radio>
                </Radio.Group>
            </Form.Item>
            <Row gutter={12}>
                <Col span={18}>
                    <Form.Item label={t('accountingGroup.name')} name={'name'} rules={[
                        {
                            required: true,
                        }]}>
                        <Input/>
                    </Form.Item>

                </Col>
                <Col span={6}>
                    <Form.Item label={t('accountingGroup.icon')} name={'iconUrl'} rules={[
                        {
                            required: true,
                        }]}>
                        <Select>
                            {iconOptions}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            {<Form.Item label={t('accountingGroup.parent')} name={'parentId'}>
                <SelectTransactionGroup onlyRoot={true} groupTypes={groupTypes} ignoreIds={[transactionGroup?.id]}/>
            </Form.Item>}
            <Form.Item label={t('accountingGroup.note')} name={'note'}>
                <Input.TextArea/>
            </Form.Item>
        </Form>
    </Modal>;

}

function TransactionGroup() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const groupTypes = Form.useWatch('groupTypes', form);
    const request = {
        onlyRoot: true, groupTypes,
    };
    const {data: groupsData, loading, refetch} = useQuery(QUERY_TRANSACTION_GROUPS, {
        variables: request,
    });

    useEffect(() => {
        refetch(request);
    }, [JSON.stringify(request)]);

    const transactionGroups = groupsData?.transactionGroups;

    const [showModal, setShowModal] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState();

    function openGroupDetailModal(group) {
        setSelectedGroup(group);
        setShowModal(true);
    }

    function closeModal() {
        setSelectedGroup(null);
        setShowModal(false);
    }

    const columns = [
        {
            title: t('accountingGroup.name'), dataIndex: 'name', render: (value, record) => {
                return <a href={'#'} onClick={() => {
                    openGroupDetailModal(record);
                }}>{value}</a>;
            },
        }, {
            title: t('accountingGroup.type'), dataIndex: 'type', render: (value) => {
                switch (value) {
                    case 'revenue':
                        return t('accountingGroup.revenue');
                    case 'receivable':
                        return t('accountingGroup.receivable');
                    case 'payable':
                        return t('accountingGroup.payable');
                    case 'debt_collection':
                        return t('accountingGroup.debtCollection');
                    case 'loan_payment':
                        return t('accountingGroup.loanPayment');
                    case 'expense':
                        return t('accountingGroup.expense');
                    default:
                        return '';
                }

            },
        }, {
            title: t('accountingGroup.icon'), dataIndex: 'iconUrl', render: (value) => {
                return <Image src={value} width={30} height={30}/>;
            },
        }, {
            title: t('accountingGroup.createdAt'), dataIndex: 'createdAt', render: (value) => {
                return formatDateTime(value);
            },
        }];

    const groupTypeOptions = [
        {
            label: t('accountingGroup.revenue'), value: 'revenue',
        }, {
            label: t('accountingGroup.expense'), value: 'expense',
        }, {
            label: t('accountingGroup.debtCollection'), value: 'debt_collection',
        }, {
            label: t('accountingGroup.loanPayment'), value: 'loan_payment',
        }, {
            label: t('accountingGroup.loan'), value: 'loan',
        }];

    return <div style={{overflow: 'auto', height: '100%'}}>
        <Form form={form} style={{
            display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
        }}>
            <Space>
                <Form.Item label={t('accountingGroup.type')} name={'groupTypes'} style={{width: 200}}>
                    <SearchableSelect options={groupTypeOptions} mode={'multiple'}/>
                </Form.Item>
                <Button type='primary' onClick={() => openGroupDetailModal()}>{t('accountingGroup.create')}</Button>
            </Space>
        </Form>
        <Table
            loading={loading}
            columns={columns}
            rowKey={(record) => {
                return record.id;
            }}
            dataSource={transactionGroups}
        />
        <ModalTransactionGroupDetail open={showModal} onCancel={() => {
            closeModal();
        }} transactionGroup={selectedGroup}/>
    </div>;
}

export default TransactionGroup;