import { makeStyles } from "@mui/styles";

export const useService = makeStyles({
    box: {
        overflow: "hidden",
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
    },
});
