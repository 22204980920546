import { convertServicesData } from "../../util/ConvertServices";

export function categoryServicesMotel(data) {
    let freeServices = [];
    let paidServices = [];

    for (const element of data) {
        if (element.fee === 0) {
            freeServices.push(element);
        } else {
            paidServices.push(element);
        }
    }
    return {
        freeServices: convertServicesData(freeServices),
        paidServices: convertServicesData(paidServices),
    };
}
