import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

export const useHeaderHome = makeStyles({
    toolbar: {
        border: "1px solid #EBEFF2",
        borderLeftStyle: "none",
        minHeight: "50px",
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        backgroundColor: "#FFFFFF",
        height: "50px",
        zIndex: 2,
    },
    icon: {
        color: "rgba(194, 207, 224, 1)",
    },
});
