export const appRouter = {
    dashboard: "dashboard",
    roomStatement: "roomstatement",
    invoice: "invoice",
    deposit: "deposit",
    debt: "debt",
    tenant: {
        assignedRoom: "/tenant/assigned_room",
        notAssignedRoom: "/tenant/not_assigned_room",
        canceled: "/tenant/canceled",
    },
    contract: {
        active: "/contract/active",
        overdue: "/contract/overdue",
        canceled: "/contract/canceled",
        representatives: "/contract/representatives",
        contractTerms: "/contract/contractTerms",
    },
    accounting: {
        transaction: "/accounting/transaction",
        transactionGroup: "/accounting/transactionGroup",
    },
    motel: "motel",
    room: "room",
    service: "service",
    signIn: "/",
};
