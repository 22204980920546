import {DeleteOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {notification, Popconfirm, Table} from 'antd';
import React, {memo, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RoomName} from '../../../../components/Room';
import {useDeleteContract} from '../../../../graphql-client/Contract/mutation';
import {SEARCH_CONTRACTS} from '../../../../graphql-client/Contract/query';
import {HomeContext} from '../../../../layout/HomeLayout/HomeLayout';
import {formatDate} from '../../../../util/Date';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {v4 as uuidv4} from 'uuid';
import ModalAdd from '../ModalAdd/ModalAdd';


function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        }, [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

function ContractTable({filters = {}}) {
    const context = useContext(HomeContext);
    let {t} = useTranslation();
    let pageSize = 5;
    const debouncedSearchText = useDebounce(context.dbValue, 500);
    const defaultPageFilter = {page: 1, size: pageSize, searchText: context.db};
    let [filterData, setFilterData] = useState({...defaultPageFilter});

    let [isModalOpen, setIsModalOpen] = useState(false);

    function closeModal() {
        setIsModalOpen(false);
    }

    function openModal() {
        setIsModalOpen(true);
    }



    const {loading: loadingContracts, data: dataContracts, refetch} = useQuery(SEARCH_CONTRACTS, {
        variables: filterData,
    });

    const {deleteContract} = useDeleteContract();
    useDeepCompareEffect(() => {
        setFilterData({...filterData, searchText: context.dbValue, page: 1, ...filters});
    }, [debouncedSearchText, filters]);

    function refetchData() {
        refetch(filterData);
    }

    useDeepCompareEffect(() => {
        refetchData();
    }, [filterData]);

    let roomContracts = dataContracts?.roomContracts;
    let total = roomContracts?.total;
    let [selectedContractId, setSelectedContractId] = useState();

    async function handleDeleteContract(id) {
        await deleteContract(id);
        notification.success({
            message: t('deleteContractSuccess'),
        });
    }

    function showContractModalDetail(contractId) {
        setSelectedContractId(contractId)
        openModal()
    }

    return <>
        <Table
        rowKey={() => uuidv4()}
        loading={loadingContracts}
        columns={[
            {
                title: t('roomContract'), dataIndex: 'number', render: (value, record, index) => {
                    return <a href='#' onClick={() => showContractModalDetail(record.id)}>{value}</a>;
                },
            }, {
                title: t('room'), dataIndex: 'room', render: (value, record, index) => {
                    return <RoomName room={record.room}/>;
                },
            }, {
                title: t('tenant'), dataIndex: ['representativeTenant', 'fullName'],
            }, {
                title: t('startDate'), dataIndex: 'startDate', render: (value, record, index) => {
                    return formatDate(record);
                },
            }, {
                title: t('endDate'), dataIndex: 'endDate', render: (value, record, index) => {
                    return record ? formatDate(record) : t('contractUndefined');
                },
            }, {
                title: t('createdBy'), dataIndex: ['createdBy', 'fullName'],
            }, {
                title: t('createdAt'), dataIndex: 'createdAt', render: (value, record, index) => {
                    return formatDate(record);
                },
            }, {
                title: 'Action', key: 'operation', fixed: 'right', width: 100, render: (value, record, index) => {
                    return <Popconfirm title={t('deleteContractConfirmation')} onConfirm={() => {
                        handleDeleteContract(value.id);
                    }}>
                        <DeleteOutlined onClick={() => {
                        }}/>
                    </Popconfirm>;
                },
            },

        ]}
        dataSource={roomContracts?.results}
        pagination={{
            position: filterData?.page, pageSize: pageSize, total: total, onChange: function(page, pageSize) {
                setFilterData({...filterData, page: page, size: pageSize});
            },
        }}
    ></Table>
        {isModalOpen && selectedContractId && <ModalAdd isModalOpen={isModalOpen} closeModal={closeModal} contractId={selectedContractId}/>}
    </>;

}

export default ContractTable;
