import { makeStyles } from "@mui/styles";

export const useCardsMotelDetail = makeStyles({
    cards: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        margin: "10px 0px",
        flexWrap: "wrap",
    },
});
