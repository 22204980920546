import { contractTermsText } from "../../constants/Constants";

function validateAddContractTerm(content) {
    let msg = {};
    if (!content) {
        msg.content = contractTermsText.textAreaContext.alertBlank;
    }

    if (content.length > 2000) {
        msg.content = contractTermsText.textAreaContext.alertExceed;
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
}
export { validateAddContractTerm };
