import {ConfigProvider} from 'antd';
import vnVN from 'antd/locale/vi_VN';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { useAppApolloClient } from "./config/ApolloClient";
import { useTranslation, initReactI18next } from "react-i18next";

import AuthLayout from "./layout/AuthLayout/AuthLayout";
import HomeLayout from "./layout/HomeLayout/HomeLayout";
import AuthGate from "./layout/AuthGate/AuthGate";
import Transaction from './page/Accounting/Transaction/Transaction';
import TransactionGroup from './page/Accounting/TransactionGroup/AccountingGroup';
import Debt from './page/Debt/Debt';
import Deposit from './page/Deposit/Deposit';
import SignIn from "./page/SignIn/SignIn";
import SignUp from "./page/SignUp/SignUp";
import ForgotPassword from "./page/ForgotPassword/ForgotPassword";
import Dashboard from "./page/Dashboard/Dashboard";
import RoomStatement from "./page/RoomStatement/RoomStatement";
import Invoice from "./page/Invoice/Invoice";
import Tenant from "./page/Tenant/Tenant";
import ContractPage from "./page/Contract/Contract/ContractPage";
import Representatives from "./page/Contract/Representatives/Representatives";
import ContractTerms from "./page/Contract/ContractTerms/ContractTerms";
import Motel from "./page/Motel/Motel";
import Room from "./page/Room/Room";
import Service from "./page/Service/Service";
import NotFound from "./page/NotFound/NotFound";

function App() {
    const apolloClient = useAppApolloClient();
    let {t} = useTranslation();
    const validateMessages = {
        required: t('pleaseFillInformation'),
        // ...
    };
    return (
        <ConfigProvider
            locale={vnVN}
            form={{
                validateMessages
            }}
            componentSize={'middle'}
            theme={{
                token: {
                    colorPrimary: '#04C35C',
                },
            }}
        >
            <ApolloProvider client={apolloClient}>
            <BrowserRouter>
                <Routes>
                    <Route element={<AuthLayout/>}>
                        <Route path="/" element={<SignIn/>}/>
                        <Route path="signup" element={<SignUp/>}/>
                        <Route
                            path="forgotpassword"
                            element={<ForgotPassword/>}
                        />
                    </Route>

                    <Route element={<AuthGate/>}>
                        <Route element={<HomeLayout/>}>
                            {/* dashboard */}
                            <Route path="dashboard" element={<Dashboard/>}/>
                            {/* roomstatement */}
                            <Route
                                path="roomstatement"
                                element={<RoomStatement/>}
                            />
                            {/* invoice */}
                            <Route path="invoice" element={<Invoice/>}/>
                            {/* invoice */}
                            <Route path="deposit" element={<Deposit/>}/>
                            <Route path="debt" element={<Debt/>}/>
                            {/* tenant */}
                            <Route
                                path="tenant/assigned_room"
                                element={<Tenant/>}
                            />
                            <Route
                                path="tenant/not_assigned_room"
                                element={<Tenant/>}
                            />
                            <Route
                                path="tenant/canceled"
                                element={<Tenant/>}
                            />
                            {/* contract */}
                            <Route
                                path="contract/active"
                                element={<ContractPage status={'active'}/>}
                            />

                            <Route
                                path="contract/overdue"
                                element={<ContractPage status='overdue'/>}
                            />

                            <Route
                                path="contract/canceled"
                                element={<ContractPage status='canceled'/>}
                            />

                            <Route
                                path="contract/representatives"
                                element={<Representatives/>}
                            />

                            <Route
                                path="contract/contractTerms"
                                element={<ContractTerms/>}
                            />
                            {/* motel */}
                            <Route path="motel" element={<Motel/>}/>
                            {/* room */}
                            <Route path="room" element={<Room/>}/>
                            {/* Service */}
                            <Route path="service" element={<Service/>}/>
                            <Route
                                path="accounting/transactionGroup"
                                element={<TransactionGroup/>}
                            />
                            <Route
                                path="accounting/transaction"
                                element={<Transaction/>}
                            />
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        </ApolloProvider></ConfigProvider>
    );
}

export default App;
