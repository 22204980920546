import { makeStyles } from "@mui/styles";

export const useContainerContract = makeStyles({
    container: {
        padding: "8px",
        backgroundColor: "#fff",
        boxShadow: "0px 6px 18px 0px #0000000F",
        borderRadius: "5px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "auto",
    },
    containerButton: {
        alignSelf: "center",
        display: "flex",
        justifyContent: "center",
        gap: "16px",
        width: "100%",
    },
});
