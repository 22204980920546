import styles from "./BoxNumber.module.css";
import { memo } from "react"

const BoxNumber = ({ number }) => {
    return (
        <div className={styles.boxNumber}>
            <p className={styles.text}>{number}</p>
        </div>
    );
};

export default memo(BoxNumber);
