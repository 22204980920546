import {
    ApolloClient,
    InMemoryCache,
    ApolloLink,
    HttpLink,
} from "@apollo/client";
import { useAuthToken } from "./auth";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_END_POINT });

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

const authMiddleware = (authToken) =>
    new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        if (authToken) {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${authToken}`,
                },
            });
        }

        return forward(operation);
    });

const cache = new InMemoryCache({});

export const useAppApolloClient = () => {
    const [authToken] = useAuthToken();
    return new ApolloClient({
        // defaultOptions: defaultOptions,
        link: ApolloLink.from([
            onError(({ graphQLErrors, networkError }) => {
                if (networkError) {
                    console.log(`[Network error]: ${networkError}`);
                }

                if (graphQLErrors) {
                    graphQLErrors.forEach(({ message, locations, path }) =>
                        console.log(
                            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                        )
                    );
                }
            }),
            authMiddleware(authToken).concat(httpLink),
        ]),
        cache,
    });
};
