import {MinusCircleOutlined, PlusOutlined, ShareAltOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Modal,
    notification,
    Row,
    Select,
    Space,
    Spin,
    Table,
    Tabs,
    Typography,
} from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomDatePicker} from '../../../../components/CustomDatePicker';
import {NumberInput, SearchableSelect} from '../../../../components/SearchableSelect';
import UploadImages from '../../../../components/UploadImages';
import {useAddRoomContract, useUpdateRoomContract} from '../../../../graphql-client/Contract/mutation';
import {GET_CONTRACT} from '../../../../graphql-client/Contract/query';
import {CONTRACT_TERMS} from '../../../../graphql-client/ContractTerms/query';
import {GET_ROOM_SERVICES} from '../../../../graphql-client/Room/query';
import {GET_MOTEL_REPRESENTATIVES, GET_ROOMS} from '../../../../graphql-client/Tenant/query';
import {parseDate} from '../../../../util/Date';
import {parseImageUrl} from '../../../../util/Image';
import {convertInt, normalizeRequestObject, omit} from '../../../../util/Request';
import styles from './ModalAdd.module.css';

const {Text} = Typography;


function ImageForm({name, files}) {
    return <UploadImages name={name} files={files}></UploadImages>;
}

function TenantForm() {
    let {t} = useTranslation();
    const form = Form.useFormInstance();
    return <Form.List name="tenantReqs"
                      rules={[
                          {
                              validator: (rule, value, callback) => {
                                  if (!(value && value.length > 0)) {
                                      return Promise.reject(t('needAtLeastOneTenant'));
                                  }
                                  return Promise.resolve();
                              }, message: t('needAtLeastOneTenant'),
                          }]}
    >
        {(fields, {add, remove}, {errors}) => (<>
            {errors && <Text type="danger">{errors[0]}</Text>}
            {fields.map(({key, name, ...restField}) => {
                let tenantId = form.getFieldValue(['tenantReqs', name, 'id']);
                let isDisabled = !!tenantId;
                return (<Space
                    key={key}
                    style={{
                        display: 'flex', marginBottom: 20,
                    }}
                    align="baseline"
                >
                    <Form.Item
                        {...restField}
                        name={[name, 'id']}
                        style={{
                            display: 'none',
                        }}
                    >
                        <Input placeholder={t('fullName')} disabled={isDisabled}/>
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'fullName']}
                        rules={[
                            {
                                required: true,
                            }]}
                    >
                        <Input placeholder={t('fullName')} disabled={isDisabled}/>
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'phoneNumber']}
                        rules={[
                            {
                                required: true,
                            }]}
                    >
                        <Input placeholder={t('phoneNumber')} disabled={isDisabled}/>
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'email']}
                    >
                        <Input placeholder={t('email')} disabled={isDisabled}/>
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'birthDay']}
                    >
                        <CustomDatePicker placeholder={t('birthDay')} disabled={isDisabled}/>
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'idNumber']}
                    >
                        <Input placeholder={t('idNumber')} disabled={isDisabled}/>
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'idIssuedAt']}
                    >
                        <DatePicker placeholder={t('idIssuedAt')} disabled={isDisabled}/>
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'idIssuedAddress']}
                    >
                        <Input placeholder={t('idIssuedAddress')} disabled={isDisabled}/>
                    </Form.Item>
                    <Form.Item
                        {...restField}
                        name={[name, 'address']}
                    >
                        <Input placeholder={t('address')} disabled={isDisabled}/>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)}/>
                </Space>);
            })}
            <Form.Item style={{
                display: 'flex', justifyContent: 'center', width: '100%',
            }}>
                <Button type="dashed" style={{width: 200}} onClick={() => add()} block icon={<PlusOutlined/>}>
                    {t('addNewTenant')}
                </Button>
            </Form.Item>
        </>)}
    </Form.List>;
}

function ServiceForm({services}) {
    let {t} = useTranslation();
    const form = Form.useFormInstance();
    if (!services) {
        return <></>;
    }
    let idToService = services.reduce((map, obj) => {
        map[obj.service.id] = obj.service;
        return map;
    }, {});

    return <Form.List name="services">
        {fields => {
            return (<Table
                dataSource={fields}
                columns={[
                    {
                        align: 'center', title: t('service.isUsed'), render: (value, {key, name, ...restField}, index) => {
                            return <Form.Item {...restField} name={[name, 'isActive']} rules={[{required: true}]}
                                              valuePropName="checked" style={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox/>
                            </Form.Item>;
                        },
                    }, {
                        title: t('service.service'), render: (value, {key, name, ...restField}, index) => {
                            let serviceId = form.getFieldValue(['services', name, 'serviceId']);
                            let service = idToService[serviceId];
                            return <span>{service.name}</span>;
                        },
                    }, {
                        title: t('service.quantity'), render: (value, {key, name, ...restField}, index) => {
                            return <Form.Item {...restField} name={[name, 'quantity']} rules={[{required: true}]}>
                                <InputNumber placeholder={t('quantity')}/>
                            </Form.Item>;
                        },
                    }, {
                        title: t('service.lastIndex'), render: (value, {key, name, ...restField}, index) => {
                            let serviceId = form.getFieldValue(['services', name, 'serviceId']);
                            let service = idToService[serviceId];
                            if (service.feeType === 'unit') {
                                return <Form.Item {...restField} name={[name, 'lastIndex']} rules={[{required: true}]}>
                                    <InputNumber placeholder={t('lastIndex')}/>
                                </Form.Item>;
                            }
                            return <></>;

                        },
                    }]}
            />);
        }}
    </Form.List>;
}

function TermForm() {
    let {t} = useTranslation();
    const form = Form.useFormInstance();

    function convertTermType(text) {
        if (text === 'owner') {
            return t('termA');
        } else if (text === 'tenant') {
            return t('termB');
        }
        return t('termBoth');
    }

    return <Form.List name="terms">
        {fields => {
            return (<Table
                dataSource={fields}
                columns={[
                    {
                        render: (value, {key, name, ...restField}, index) => {
                            return <Form.Item {...restField} name={[name, 'isActive']} rules={[{required: true}]}
                                              valuePropName="checked"
                                              style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox/>
                            </Form.Item>;
                        },
                    }, {
                        title: t('type'), render: (value, {key, name, ...restField}, index) => {
                            let termType = form.getFieldValue(['terms', name, 'type']);
                            return <span>{convertTermType(termType)}</span>;
                        },
                    }, {
                        title: t('content'), render: (value, {key, name, ...restField}, index) => {
                            let content = form.getFieldValue(['terms', name, 'content']);
                            return <span>{content}</span>;
                        },
                    }]}
            />);
        }}
    </Form.List>;
}




function getServiceQuantity(service, totalTenant) {
    let serviceUnit = service.unit;
    if (serviceUnit === 'person') {
        return totalTenant;
    }

    return 1;
}

const ModalAdd = ({isModalOpen, closeModal, onSuccess, contractId}) => {
    let {t} = useTranslation();

    const [form] = Form.useForm();
    const selectedRoomId = Form.useWatch('roomId', form);
    let isUpdateContract = !!contractId;

    let {mutateAddRoom, loadingAddContract} = useAddRoomContract();
    let {updateContract, loading: updateLoading} = useUpdateRoomContract();

    async function handleUpdateRoomContract() {
        let request = await getFormValues();
        await updateContract({
            id: contractId, req: request,
        });
        notification.success({
            message: t('updateContractSuccess'),
        });
        closeModal();
    }

    async function handleSubmit() {
        if (isUpdateContract) {
            await handleUpdateRoomContract();
        } else {
            await handleAddRoom();
        }
    }

    async function getFormValues() {
        let values = await form.validateFields();
        values.termIds = values.terms?.filter(item => item.isActive).map(item => item.id);
        values = omit(values, ['terms']);
        values.tenantIds = [];
        return normalizeRequestObject(values);
    }

    async function handleAddRoom() {
        let request = await getFormValues();
        await mutateAddRoom({req: request});
        notification.success({message: t('createContractSuccess')});
        closeModal();
        onSuccess && onSuccess();
    }

    const {data: dataRooms} = useQuery(GET_ROOMS,
        {variables: {hasContract: isUpdateContract ? undefined : false}, skip: !isModalOpen});

    const {data: dataRepresentatives} = useQuery(GET_MOTEL_REPRESENTATIVES, {skip: !isModalOpen});
    const {data: dataContractTerms} = useQuery(CONTRACT_TERMS, {skip: !isModalOpen || isUpdateContract});
    const {data: dataRoom, refetch: refetchRoom} = useQuery(GET_ROOM_SERVICES, {
        variables: {
            roomId: selectedRoomId,
        }, skip: !selectedRoomId || !isModalOpen || isUpdateContract,
    });

    let {data: roomContract, loading: loadingGetContract} = useQuery(GET_CONTRACT, {
        variables: {
            id: contractId,
        }, skip: !isUpdateContract,
    });

    function convertContractServices(contractData) {
        let contractServices = contractData.contractServices;
        let serviceIdToService = _.keyBy(contractServices, 'serviceId');
        let allServices = contractData.allServices;
        let results = allServices?.map(item => {
            let serviceDetail = serviceIdToService[item.service.id];
            return {
                serviceId: item.service.id,
                isActive: item.isActive,
                quantity: serviceDetail?.quantity || getServiceQuantity(item.service, contractData.room.totalTenant),
                lastIndex: serviceDetail?.lastIndex,
            };
        });
        return results;
    }

    // merge existed contract terms and all terms
    function convertContractTerms(contractData, allContractTerms) {
        let idToContractDetail = _.keyBy(contractData?.contractTerms, 'id');
        return allContractTerms.map(item => {
            let selectedTerm = idToContractDetail[item.id];
            return {
                isActive: !!selectedTerm, id: convertInt(item.id), type: item.type, content: item.content,
            };
        });
    }

    useEffect(() => {
        let contractData = roomContract?.getRoomContract;
        let contractTerms = roomContract?.contractTerms;
        if (contractData) {
            // todo bind to form
            form.setFieldsValue({
                roomId: convertInt(contractData.room?.id),
                startDate: parseDate(contractData.startDate),
                endDate: parseDate(contractData.endDate),
                startPaidDate: parseDate(contractData.startPaidDate),
                services: convertContractServices(contractData),
                deposit: contractData.deposit,
                roomPrice: contractData.roomPrice,
                monthsToPay: contractData.monthsToPay,
                motelRepresentativeId: contractData.motelRepresentativeId,
                tenantReqs: contractData.tenants.map(item => {
                    return {
                        id: item.id,
                        fullName: item.fullName,
                        phoneNumber: item.phoneNumber,
                        idNumber: item.idNumber,
                        email: item.email,
                        address: item.address,
                        imageUrls: item.imageUrls,
                        birthday: parseDate(item.birthday),
                        idIssueDate: parseDate(item.idIssueDate),
                        idIssueBy: item.idIssueBy,
                    };
                }),
                imageUrls: contractData.imageUrls?.map(url => {
                    return parseImageUrl(url);
                }),
                terms: convertContractTerms(contractData, contractTerms),

            });
        }
    }, [roomContract]);

    const allRepresentatives = dataRepresentatives?.motelRepresentatives.map(item => {
        return {
            value: convertInt(item.id), label: item.fullName,
        };
    });

    let allRooms = dataRooms?.myRooms.map((element) => {
        return {
            value: convertInt(element.id), label: element.name + ' - ' + element.motel.name,
        };
    });

    const monthsToPayList = [
        {
            value: 1, label: t('oneMonth'),
        }, {
            value: 2, label: t('twoMonth'),
        }, {
            value: 3, label: t('threeMonth'),
        }, {
            value: 4, label: t('fourMonth'),
        }, {
            value: 5, label: t('fiveMonth'),
        }, {
            value: 6, label: t('sixMonth'),
        }];

    useEffect(() => {
        if (selectedRoomId) {
            refetchRoom({roomId: selectedRoomId});
        }
    }, [selectedRoomId]);

    let room = dataRoom?.getRoom;
    useEffect(() => {
        if (!isUpdateContract) {
            form.setFieldValue('services', room?.allServices?.map(({isActive, service}) => {
                return {
                    serviceId: convertInt(service?.id),
                    isActive: isActive,
                    quantity: getServiceQuantity(service, room?.totalTenant),
                };
            }));

            form.setFieldValue('deposit', room?.deposit || 0);
            form.setFieldValue('roomPrice', room?.price || 0);
        }

    }, [dataRoom]);
    useEffect(() => {
        if (!isUpdateContract) {
            let newTerms = dataContractTerms?.contractTerms?.map(({id, type, content}) => {
                return {
                    isActive: true, id: convertInt(id), type, content,
                };
            });
            form.setFieldValue('terms', newTerms);
        }

    }, [dataContractTerms]);

    const tabItems = [
        {
            key: 'tenant', label: t('tenant'), children: <TenantForm/>, forceRender: true,
        }, {
            key: 'service',
            label: t('service.service'),
            children: <ServiceForm services={room?.allServices || roomContract?.getRoomContract?.room?.allServices}/>,
            forceRender: true,
        }, {
            key: 'contractTerm', label: t('contractTerm'), children: <TermForm/>, forceRender: true,
        }, {
            key: 'contractImage', label: t('contractImage'), children: <ImageForm name={'imageUrls'} />, forceRender: true,
        }];

    let initialValues = {
        monthsToPay: 1,
    };

    function getTitle() {
        let previewUrl = roomContract?.getRoomContract?.previewUrl;
        return isUpdateContract ?
            <div><Space><span>{t('updateContract')}</span><a href={previewUrl} target="_blank"
                                                             rel="noreferrer"><ShareAltOutlined/></a></Space></div> :
            t('addContract');
    }

    return (<>
        <Modal
            confirmLoading={loadingAddContract || updateLoading}
            open={isModalOpen}
            title={getTitle()}
            width={1500}
            style={{
                zIndex: 1,
            }}
            onOk={handleSubmit}
            destroyOnClose={true}
            onCancel={closeModal}

        >

            <Spin spinning={loadingGetContract}>
                <Form className={styles.form} form={form} layout={'vertical'} initialValues={initialValues}
                      preserve={false}>
                    <Row gutter={12}>
                        <Col span={4}>
                            <Form.Item label={t('chooseRoom')} name="roomId" required
                                       rules={[{required: true}]}>
                                <SearchableSelect disabled={isUpdateContract} options={allRooms}
                                                  placeholder={t('pleaseChooseRoom')}/>
                            </Form.Item>

                        </Col>
                        <Col span={4}>
                            <Form.Item label={t('contractRepresentative')} name="motelRepresentativeId"
                                       rules={[{required: true}]}>
                                <SearchableSelect
                                    placeholder={t('contractRepresentativePlaceholder')}
                                    options={allRepresentatives}
                                /></Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={t('contractStartDate')} name="startDate" rules={[{required: true}]}>
                                <CustomDatePicker/></Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={t('contractEndDate')} name="endDate">
                                <CustomDatePicker/></Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={t('startPaidDate')} name="startPaidDate" rules={[{required: true}]}>
                                <CustomDatePicker/></Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={4}>
                            <Form.Item label={t('monthsToPay')} name="monthsToPay" rules={[{required: true}]}>
                                <Select
                                    options={monthsToPayList}
                                    placeholder={t('monthsToPayPlaceholder')}
                                /></Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={t('roomPrice')} name="roomPrice" rules={[{required: true}]}>
                                <NumberInput/></Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label={t('roomDepositPrice')} name="deposit">
                                <NumberInput/></Form.Item>
                        </Col>

                    </Row>
                    <Row>
                        <Tabs defaultActiveKey="tenant" items={tabItems}/>
                    </Row>
                </Form></Spin>
        </Modal>
    </>);
};

export default ModalAdd;
