import { memo } from 'react'
import { Box, Typography } from "@mui/material";

import { useListRoom } from "./useListRoom";
import { floorMotel, filterRoomByFloorNumber } from "../../../useCase/Room/useCaseRoom"
import ItemRoom from "./ItemRoom";
import EmptyIcon from '../../../assets/Icons/EmptyIcon';


const ListRoom = ({ data, roomId, clicked }) => {
    const classes = useListRoom();

    const handleClick = (item) => {
        clicked(item.id)
    }


    return (

        <Box className={classes.container}>
            {
                data.length > 0 ?
                    <>
                        {floorMotel(data).map((value, index) => {
                            return (
                                <Box key={index} className={classes.itemRoom}>
                                    <Typography className={classes.floor}>Tầng {value}</Typography>
                                    {filterRoomByFloorNumber(value, data).map((value, index) => {
                                        return (
                                            <ItemRoom
                                                key={index}
                                                data={value}
                                                isSelected={value.id === roomId}
                                                clicked={() => handleClick(value)}
                                            />
                                        )
                                    })}
                                </Box>
                            )
                        })}
                    </> :
                    <EmptyIcon />
            }

        </Box>
    )
}

export default memo(ListRoom)
