import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Navigation from "../Navigation/Navigation";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Logo from "../../assets/logo.png";
import { useDrawerHome } from "./useDrawerHome";
import Profile from "../Profile/Profile";
import { memo } from 'react'

const DrawerHome = () => {
    const classes = useDrawerHome();

    return (
        <Drawer className={classes.drawer} variant="permanent" anchor="left">
            <Toolbar className={classes.toolbar}>
                <CardMedia
                    component="img"
                    image={Logo}
                    alt=""
                    className={classes.logo}
                />
                <CardContent className={classes.content}>LaLaHome</CardContent>
            </Toolbar>
            <Profile />
            <Toolbar style={{
                height: "80%",
                display: "flex",
                alignItems: "flex-start"
            }}>
                <Navigation />
            </Toolbar>
        </Drawer>
    );
};

export default memo(DrawerHome);
