import React, { useState, useEffect, memo } from "react";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import DashboardIcon from "../../assets/Icons/DashboardIcon";
import RoomStatementIcon from "../../assets/Icons/RoomStatementIcon";
import InvoiceIcon from "../../assets/Icons/InvoiceIcon";
import ContractIcon from "../../assets/Icons/ContractIcon";
import TenantIcon from "../../assets/Icons/TenantIcon";
import MotelIcon from "../../assets/Icons/MotelIcon";
import RoomIcon from "../../assets/Icons/RoomIcon";
import ServiceIcon from "../../assets/Icons/ServiceIcon";

import styles from "./ItemNavigation.module.css";
import {
    navigation,
    tenantText,
    contractText, accountingText,
} from '../../constants/Constants';
import { useMenuItem } from "./useMenuItem";
import { Box } from "@mui/system";
import { useLogout } from "../../config/auth";
import { saveState } from "../../util/LocalStateSave";

const Navigation = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const redirect = pathname.split("/")[1];
    const classes = useMenuItem();
    const [isExpandContract, setIsExpandContract] = useState(false);
    const [isExpandTenant, setIsExpandTenant] = useState(false);
    const [isExpandAccounting, setIsExpandAccounting] = useState(false);
    const logout = useLogout();

    function handleClick() {
        saveState([], "selected");
    }

    useEffect(() => {
        redirect === "contract"
            ? setIsExpandContract(true)
            : setIsExpandContract(false);
        redirect === "tenant"
            ? setIsExpandTenant(true)
            : setIsExpandTenant(false);
    }, [pathname, redirect]);

    const changeColorIcon = (name) => {
        return redirect === name ? "#04c45c" : "#c2cfe0";
    };

    const activeMenuItem = (name) => {
        return name === redirect ? styles.active : "";
    };

    const activeSubMenuItem = (name) => {
        return name === pathname ? styles.active : "";
    };

    const IconComponent = {
        dashboard: <DashboardIcon stroke={changeColorIcon("dashboard")} />,
        roomStatement: (
            <RoomStatementIcon stroke={changeColorIcon("roomstatement")} />
        ),
        invoice: <InvoiceIcon fill={changeColorIcon("invoice")} />,
        contract: <ContractIcon stroke={changeColorIcon("contract")} />,
        tenant: <TenantIcon fill={changeColorIcon("tenant")} />,
        motel: <MotelIcon fill={changeColorIcon("motel")} />,
        room: <RoomIcon fill={changeColorIcon("room")} />,
        service: <ServiceIcon stroke={changeColorIcon("service")} />,
    };

    return (
        <Box
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <List className={classes.navigationList}>
                <Box>
                    {/* dashboard */}
                    <Link
                        to={navigation.dashboard.link}
                        className={`${styles.link} ${activeMenuItem(
                            navigation.dashboard.link
                        )} `}
                    >
                        <MenuItem
                            disableRipple
                            className={classes.menuItem}
                            onClick={handleClick}
                        >
                            {IconComponent.dashboard}
                            <ListItemText primary={navigation.dashboard.text} />
                        </MenuItem>
                    </Link>
                    {/* roomStatement */}
                    <Link
                        to={navigation.roomStatement.link}
                        className={`${styles.link} ${activeMenuItem(
                            navigation.roomStatement.link
                        )} `}
                    >
                        <MenuItem
                            disableRipple
                            className={classes.menuItem}
                            onClick={handleClick}
                        >
                            {IconComponent.roomStatement}
                            <ListItemText
                                primary={navigation.roomStatement.text}
                            />
                        </MenuItem>
                    </Link>
                    {/* invoice */}
                    {/*<Link*/}
                    {/*    to={navigation.invoice.link}*/}
                    {/*    className={`${styles.link} ${activeMenuItem(*/}
                    {/*        navigation.invoice.link*/}
                    {/*    )} `}*/}
                    {/*>*/}
                    {/*    <MenuItem*/}
                    {/*        disableRipple*/}
                    {/*        className={classes.menuItem}*/}
                    {/*        onClick={handleClick}*/}
                    {/*    >*/}
                    {/*        {IconComponent.invoice}*/}
                    {/*        <ListItemText primary={navigation.invoice.text} />*/}
                    {/*    </MenuItem>*/}
                    {/*</Link>*/}
                    {/* contract */}
                    {/*<MenuItem*/}
                    {/*    disableRipple*/}
                    {/*    className={`${classes.menuItem} ${*/}
                    {/*        styles.link*/}
                    {/*    } ${activeMenuItem("contract")}`}*/}
                    {/*    onClick={() => setIsExpandContract(!isExpandContract)}*/}
                    {/*>*/}
                    {/*    {IconComponent.contract}*/}
                    {/*    <ListItemText*/}
                    {/*        primary={navigation.contract.text}*/}
                    {/*        className={classes.menuItemText}*/}
                    {/*    />*/}
                    {/*    {isExpandContract ? (*/}
                    {/*        <ExpandLessIcon />*/}
                    {/*    ) : (*/}
                    {/*        <ExpandMoreIcon />*/}
                    {/*    )}*/}
                    {/*</MenuItem>*/}
                    <Collapse
                        in={isExpandContract}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <Link
                                to={navigation.contract.link.active}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.contract.link.active
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={contractText.navigation.active}
                                    />
                                </MenuItem>
                            </Link>
                            <Link
                                to={navigation.contract.link.overdue}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.contract.link.overdue
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={
                                            contractText.navigation.overdue
                                        }
                                    />
                                </MenuItem>
                            </Link>
                            <Link
                                to={navigation.contract.link.canceled}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.contract.link.canceled
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={
                                            contractText.navigation.canceled
                                        }
                                    />
                                </MenuItem>
                            </Link>
                            <Link
                                to={navigation.contract.link.representatives}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.contract.link.representatives
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={
                                            contractText.navigation
                                                .representatives
                                        }
                                    />
                                </MenuItem>
                            </Link>
                            <Link
                                to={navigation.contract.link.contractTerms}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.contract.link.contractTerms
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={
                                            contractText.navigation
                                                .contractTerms
                                        }
                                    />
                                </MenuItem>
                            </Link>
                        </List>
                    </Collapse>
                    {/* deposit */}
                    <Link
                        to={navigation.deposit.link}
                        className={`${styles.link} ${activeMenuItem(
                            navigation.deposit.link
                        )} `}
                    >
                        <MenuItem
                            disableRipple
                            className={classes.menuItem}
                            onClick={handleClick}
                        >
                            {IconComponent.invoice}
                            <ListItemText primary={navigation.deposit.text} />
                        </MenuItem>
                    </Link>
                    {/* tenant */}
                    <MenuItem
                        disableRipple
                        className={`${classes.menuItem} ${
                            styles.link
                        } ${activeMenuItem("tenant")}`}
                        onClick={() => setIsExpandTenant(!isExpandTenant)}
                    >
                        {IconComponent.tenant}
                        <ListItemText
                            primary={navigation.tenant.text}
                            className={classes.menuItemText}
                        />
                        {isExpandTenant ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </MenuItem>
                    <Collapse in={isExpandTenant} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <Link
                                to={navigation.tenant.link.assignedRoom}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.tenant.link.assignedRoom
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={
                                            tenantText.navigation.assigned_room
                                        }
                                    />
                                </MenuItem>
                            </Link>

                            <Link
                                to={navigation.tenant.link.notAssignedRoom}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.tenant.link.notAssignedRoom
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={
                                            tenantText.navigation
                                                .not_assigned_room
                                        }
                                    />
                                </MenuItem>
                            </Link>

                            <Link
                                to={navigation.tenant.link.canceled}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.tenant.link.canceled
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={tenantText.navigation.canceled}
                                    />
                                </MenuItem>
                            </Link>
                        </List>
                    </Collapse>
                    {/* motel */}
                    <Link
                        to={navigation.motel.link}
                        className={`${styles.link} ${activeMenuItem(
                            navigation.motel.link
                        )}`}
                    >
                        <MenuItem
                            disableRipple
                            className={classes.menuItem}
                            onClick={handleClick}
                        >
                            {IconComponent.motel}
                            <ListItemText primary={navigation.motel.text} />
                        </MenuItem>
                    </Link>
                    {/* room */}
                    <Link
                        to={navigation.room.link}
                        className={`${styles.link} ${activeMenuItem(
                            navigation.room.link
                        )}`}
                    >
                        <MenuItem
                            disableRipple
                            className={classes.menuItem}
                            onClick={handleClick}
                        >
                            {IconComponent.room}
                            <ListItemText primary={navigation.room.text} />
                        </MenuItem>
                    </Link>
                    {/* service */}
                    <Link
                        to={navigation.service.link}
                        className={`${styles.link} ${activeMenuItem(
                            navigation.service.link
                        )}`}
                    >
                        <MenuItem
                            disableRipple
                            className={classes.menuItem}
                            onClick={handleClick}
                        >
                            {IconComponent.service}
                            <ListItemText primary={navigation.service.text} />
                        </MenuItem>
                    </Link>
                    {/* accounting */}
                    <MenuItem
                        disableRipple
                        className={`${classes.menuItem} ${
                            styles.link
                        } ${activeMenuItem("accounting")}`}
                        onClick={() => setIsExpandAccounting(!isExpandAccounting)}
                    >
                        {IconComponent.contract}
                        <ListItemText
                            primary={navigation.accounting.text}
                            className={classes.menuItemText}
                        />
                        {isExpandAccounting ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </MenuItem>
                    <Collapse
                        in={isExpandAccounting}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <Link
                                to={navigation.accounting.link.transaction}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.accounting.link.transaction
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={accountingText.navigation.transaction}
                                    />
                                </MenuItem>
                            </Link>
                            <Link
                                to={navigation.accounting.link.transactionGroup}
                                className={`${styles.link} ${activeSubMenuItem(
                                    navigation.accounting.link.transactionGroup
                                )}`}
                            >
                                <MenuItem
                                    disableRipple
                                    className={classes.menuItem}
                                >
                                    <ListItemText
                                        inset
                                        primary={
                                            accountingText.navigation.transactionGroup
                                        }
                                    />
                                </MenuItem>
                            </Link>
                        </List>
                    </Collapse>
                    <Link
                        to={navigation.debt.link}
                        className={`${styles.link} ${activeMenuItem(
                            navigation.debt.link
                        )} `}
                    >
                        <MenuItem
                            disableRipple
                            className={classes.menuItem}
                            onClick={handleClick}
                        >
                            {IconComponent.invoice}
                            <ListItemText primary={navigation.debt.text} />
                        </MenuItem>
                    </Link>
                </Box>


                <Link to={navigation.logout.link} className={styles.linkLogout}>
                    <MenuItem
                        disableRipple
                        className={classes.menuItem}
                        onClick={logout}
                        style={{ color: "rgba(247, 104, 91, 1)" }}
                    >
                        <ListItemIcon
                            style={{
                                minWidth: 0,
                                color: "rgba(247, 104, 91, 1)",
                            }}
                        >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={navigation.logout.text} />
                    </MenuItem>
                </Link>
            </List>
        </Box>
    );
};

export default memo(Navigation);
