import {InputNumber, Select} from 'antd';
import React from 'react';

export function SearchableSelect({...rest}) {
    return <Select
        showSearch
        filterOption={(inputValue, option) => {
            return option?.label.toLowerCase().includes(inputValue.toLowerCase());
        }}
        {...rest}
    />;
}

export function NumberInput({...rest}) {
    return <InputNumber
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
        {...rest}
    />;
}
