/* eslint-disable */
import { gql, useMutation } from "@apollo/client";
import { MY_ROOMS, GET_ROOM } from "./query";

const ADD_ROOM = gql`
    mutation addRoom($motelId: Int!, $addRoomReq: AddRoomRequest!) {
        addRoom(motelId: $motelId, addRoomReq: $addRoomReq) {
            ok
        }
    }
`;

function useAddRoom() {
    const [mutateAddRoom, { data, loading, error }] = useMutation(ADD_ROOM);

    const handleAddRoom = async (variables, motelId) => {
        try {
            await mutateAddRoom({
                variables,
                refetchQueries: [
                    { query: MY_ROOMS, variables: { motelIds: [motelId] } },
                    // { query: GET_ROOM, variables: { roomId: roomId } },
                ],
            });
        } catch (error) {}
    };

    return {
        mutateAddRoom: handleAddRoom,
        dataAddRoom: data,
        loadingAddRoom: loading,
        errorAddRoom: error,
    };
}

const DELETE_ROOM = gql`
    mutation deleteRoom($id: Int!) {
        deleteRoom(id: $id) {
            ok
        }
    }
`;

function useDeleteRoom() {
    const [mutateDeleteRoom, { data, loading, error }] =
        useMutation(DELETE_ROOM);

    const handleDeleteRoom = async (variables, id) => {
        try {
            await mutateDeleteRoom({
                variables,
                refetchQueries: [
                    { query: MY_ROOMS, variables: { motelIds: [id] } },
                ],
            });
        } catch (error) {}
    };

    return {
        mutateDeleteRoom: handleDeleteRoom,
        dataDeleteRoom: data,
        loadingDeleteRoom: loading,
        errorDeleteRoom: error,
    };
}

const UPDATE_ROOM = gql`
    mutation updateRoom($id: Int!, $updateRoomReq: UpdateRoomRequest!) {
        updateRoom(id: $id, updateRoomReq: $updateRoomReq) {
            ok
        }
    }
`;

function useUpdateRoom() {
    const [mutateUpdateRoom, { data, loading, error }] =
        useMutation(UPDATE_ROOM);

    const handleUpdateRoom = async (variables, motelId, roomId) => {
        try {
            await mutateUpdateRoom({
                variables,
                refetchQueries: [
                    { query: MY_ROOMS, variables: { motelIds: [motelId] } },
                    // { query: GET_ROOM, variables: { roomId: roomId } },
                ],
            });
        } catch (error) {}
    };

    return {
        mutateUpdateRoom: handleUpdateRoom,
        dataUpdateRoom: data,
        loadingUpdateRoom: loading,
        errorUpdateRoom: error,
    };
}

export { useAddRoom, useDeleteRoom, useUpdateRoom };
