import {PlusOutlined} from '@ant-design/icons';
import {Form, Modal, Upload} from 'antd';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

function getFile(e) {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
}

const UploadImages = ({name, label, maxTotalImage=5, initialFileList=[]}) => {
    let {t} = useTranslation();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState(initialFileList || []);
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {t('upload')}
            </div>
        </div>
    );
    return (
        <><Form.Item name={name} getValueFromEvent={getFile} valuePropName='fileList' label={label} >
            <Upload
                action={process.env.REACT_APP_API_UPLOAD}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                maxCount={maxTotalImage}
            >
                {fileList.length >= maxTotalImage ? null : uploadButton}
            </Upload>
        </Form.Item>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="preview"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
};
export default UploadImages;