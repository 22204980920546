import {DatePicker} from 'antd';
import React from 'react';

export function CustomDatePicker({...rest}) {
    return <DatePicker format={'DD-MM-YYYY'} {...rest} />;
}

export function CustomMonthPicker({...rest}) {
    return <DatePicker format={'MM-YYYY'} picker={'month'} {...rest} />;
}
