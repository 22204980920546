import { gql, useMutation } from "@apollo/client";
import { SEARCH_TENANT } from "./query";

const DELETE_TENANT = gql`
    mutation deleteTenant($id: Int!) {
        deleteTenant(id: $id) {
            ok
        }
    }
`;

function useDeleteTenant() {
    const [mutateDeleteTenant, { data, loading, error }] =
        useMutation(DELETE_TENANT);

    const handleDeleteTenant = async (
        variables,
        page,
        size,
        status,
        searchText
    ) => {
        try {
            await mutateDeleteTenant({
                variables,
                refetchQueries: [
                    {
                        query: SEARCH_TENANT,
                        variables: {
                            page: page,
                            size: size,
                            statues: status,
                            searchText: searchText,
                        },
                    },
                ],
            });
        } catch (error) {}
    };

    return {
        mutateDeleteTenant: handleDeleteTenant,
        dataDeleteTenant: data,
        loadingDeleteTenant: loading,
        errorDeleteTenant: error,
    };
}

const ADD_TENANT = gql`
    mutation addTenant($request: AddUpdateTenantRequest) {
        addTenant(request: $request) {
            ok
        }
    }
`;

function useAddTenant() {
    const [mutateAddTenant, { data, loading, error }] = useMutation(ADD_TENANT);

    const handleAddTenant = async (
        variables,
        page,
        size,
        status,
        searchText
    ) => {
        try {
            await mutateAddTenant({
                variables,
                refetchQueries: [
                    {
                        query: SEARCH_TENANT,
                        variables: {
                            page: page,
                            size: size,
                            statues: status,
                            searchText: searchText,
                        },
                    },
                ],
            });
        } catch (err) {
            console.log(err);
        }
    };
    return {
        mutateAddTenant: handleAddTenant,
        dataAddTenant: data,
        loadingAddTenant: loading,
        errorAddTenant: error,
    };
}

const UPDATE_TENANT = gql`
    mutation updateTenant($id: Int!, $request: AddUpdateTenantRequest) {
        updateTenant(id: $id, request: $request) {
            ok
        }
    }
`;

function useUpdateTenant() {
    const [mutateUpdateTenant, { data, loading, error }] =
        useMutation(UPDATE_TENANT);

    const handleUpdateTenant = async (
        variables,
        page,
        size,
        status,
        searchText
    ) => {
        try {
            await mutateUpdateTenant({
                variables,
                refetchQueries: [
                    {
                        query: SEARCH_TENANT,
                        variables: {
                            page: page,
                            size: size,
                            statues: status,
                            searchText: searchText,
                        },
                    },
                ],
            });
        } catch (err) {
            console.log(err);
        }
    };
    return {
        mutateUpdateTenant: handleUpdateTenant,
        dataUpdateTenant: data,
        loadingUpdateTenant: loading,
        errorUpdateTenant: error,
    };
}

export { useDeleteTenant, useAddTenant, useUpdateTenant };
