import { makeStyles } from "@mui/styles";

export const useRadioGroupBasic = makeStyles({
    radio: {
        "& .MuiRadio-root": {
            color: "#04C35C",
            "&:hover": {
                backgroundColor: "#fff",
            },
        },
        "& .MuiTypography-root": {
            fontSize: "14px",
        },
        "&.Mui-checked": {
            color: "#04C35C",
        },
    },
});
