import { memo, useState } from "react";
import { Card, CardHeader, CardActions, Typography } from "@mui/material";
import { useInfoRoom } from "./useInfoRoom";
import ButtonSmall from "../../../components/ButtonSmall/ButtonSmall";
import ModalUpdate from "../ModalUpdate/ModalUpdate";
import { convertStatus } from "../../../useCase/Room/useCaseRoom";

const InfoRoom = ({
    data,
    clickedDelete,
    services,
    systemServices,
    motelId,
}) => {
    const classes = useInfoRoom();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        <Typography className={classes.nameRoom}>
                            {data.name}&nbsp;&nbsp;&nbsp;&nbsp;
                            <Typography
                                component="span"
                                className={classes.statusRoom}
                            >
                                {convertStatus(data.status)}
                            </Typography>
                        </Typography>
                    }
                    subheader={`${new Intl.NumberFormat().format(data.price)}đ`}
                />
                <CardActions>
                    <ButtonSmall
                        text="Xóa"
                        clicked={() => clickedDelete(data.id)}
                    />
                    <ButtonSmall text="Cập nhật" clicked={handleOpen} />
                </CardActions>
            </Card>
            <ModalUpdate
                closeAdd={handleClose}
                openAdd={open}
                data={data}
                services={services}
                systemServices={systemServices}
                motelId={motelId}
            />
        </>
    );
};

export default memo(InfoRoom);
