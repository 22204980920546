import {CheckCircleFilled, DeleteOutlined, ShareAltOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    notification,
    Popconfirm,
    Radio,
    Row,
    Space,
    Table,
    Tabs,
    Tag,
    Typography,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import dayjs from 'dayjs';
import * as PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {CustomDatePicker, CustomMonthPicker} from '../../components/CustomDatePicker';
import {RoomName} from '../../components/Room';
import {NumberInput, SearchableSelect} from '../../components/SearchableSelect';
import {SelectPaymentMethod} from '../../components/SelectPaymentMethod/SelectPaymentMethod';
import {TenantName} from '../../components/Tenant';
import UploadImages from '../../components/UploadImages';
import {
    useCreateInvoice,
    useDeleteInvoice,
    usePayInvoice,
    useUpdateInvoice,
} from '../../graphql-client/Invoice/mutation';
import {
    QUERY_GET_LITE_ROOM_INVOICE_WITH_TENANTS,
    QUERY_GET_NEW_ROOM_INVOICE,
    QUERY_GET_ROOM_INVOICE,
    QUERY_ROOM_INVOICES,
    QUERY_ROOM_STATEMENTS_LITE,
    QUERY_ROOMS_BY_INVOICE_STATUS,
} from '../../graphql-client/Invoice/query';
import {MY_MOTELS} from '../../graphql-client/Motel/query';
import {formatDate, parseDate} from '../../util/Date';
import {parseImageUrl} from '../../util/Image';
import {zeroPad} from '../../util/Number';
import {convertInt, normalizeRequestObject, omit} from '../../util/Request';
import {isUnitService} from '../../util/Service';

function SummaryItemRow({title, name, disabled, titleType}) {
    const summaryItemStyle = {display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 12};
    return <Row>
        <Col span={8} style={summaryItemStyle}>
            <Typography.Text strong type={titleType}>{title}</Typography.Text>
            <Divider type={'vertical'}></Divider>
        </Col>
        <Col span={16}>
            <Form.Item name={name}>
                <NumberInput disabled={disabled}/>
            </Form.Item>
        </Col>
    </Row>;
}

function ModalInvoiceDetail({open, onCancel, roomContractId, roomId, startDate, endDate, invoiceId}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();

    const includeHasInvoiceRoom = Form.useWatch('includeHasInvoiceRoom', form);
    const selectedRoomStatementId = Form.useWatch('roomStatementId', form);
    const selectedRoomContractId = Form.useWatch('roomContractId', form);
    const invoiceDate = Form.useWatch('date', form);

    let [initialValues, setInitialValues] = useState({
        includeHasInvoiceRoom: false, roomContractId, date: startDate,
    });

    useEffect(() => {
        setInitialValues({
            includeHasInvoiceRoom: false, roomContractId,
        });
    }, [
        roomContractId, roomId, startDate, endDate]);

    function getRequest() {
        let hasInvoice = false;
        if (includeHasInvoiceRoom) {
            hasInvoice = undefined;
        }
        const request = {
            startDate, endDate, hasInvoice,
        };
        return normalizeRequestObject(request);
    }

    const skipFetchRooms = !startDate || !endDate;
    const {data: roomsData, refetch} = useQuery(QUERY_ROOMS_BY_INVOICE_STATUS, {
        variables: getRequest(), skip: skipFetchRooms,
    });
    const rooms = roomsData?.roomsByInvoiceStatus;
    useEffect(() => {
        if (!skipFetchRooms) {
            refetch(getRequest());
        }
    }, [includeHasInvoiceRoom]);
    const roomOptions = rooms?.map(room => {
        return {
            label: `${room.name} - ${room.motel?.name}`, value: convertInt(room.activeContractId),
        };
    });

    function getNewInvoiceRequest() {
        return normalizeRequestObject({
            invoiceDate, roomContractId: selectedRoomContractId, roomStatementId: selectedRoomStatementId,
        });
    }

    const skipFetchNewInvoice = !selectedRoomContractId || !startDate || !endDate;
    const newInvoiceReq = getNewInvoiceRequest();
    const {data: newInvoiceData, refetch: refetchNewInvoice} = useQuery(QUERY_GET_NEW_ROOM_INVOICE, {
        variables: newInvoiceReq, skip: skipFetchNewInvoice,
    });

    useEffect(() => {
        if (!skipFetchNewInvoice) {
            refetchNewInvoice(newInvoiceReq);
        }
    }, [newInvoiceReq]);

    const [services, setServices] = useState([]);

    const skipFetchStatements = !selectedRoomContractId || !endDate;
    const {data: statementsData} = useQuery(QUERY_ROOM_STATEMENTS_LITE, {
        variables: normalizeRequestObject({
            roomId, endDate: endDate, limit: 5,
        }), skip: skipFetchStatements,
    });

    useEffect(() => {
        if (statementsData) {
            if (statementsData.roomStatements && statementsData.roomStatements.length > 0) {
                form.setFieldValue('roomStatementId', statementsData.roomStatements[0].id);
            }
        }
    }, [JSON.stringify(statementsData)]);

    const statementOptions = statementsData?.roomStatements?.map(item => {
        return {
            label: formatDate(item.statementAt), value: item.id,
        };
    });

    useEffect(() => {
        if (newInvoiceData) {
            const newRoomInvoice = newInvoiceData.getNewRoomInvoice;
            const newServices = newRoomInvoice.serviceItems?.map(item => item.service);
            setServices(newServices);
            const newInitialValues = {
                ...initialValues,
                date: parseDate(newRoomInvoice.date),
                startPaidDate: parseDate(newRoomInvoice.startPaidDate),
                endPaidDate: parseDate(newRoomInvoice.endPaidDate),
                roomContractId: convertInt(newRoomInvoice.roomContract.id),
                roomPriceStartDate: parseDate(newRoomInvoice.roomPriceStartDate),
                roomPriceEndDate: parseDate(newRoomInvoice.roomPriceEndDate),
                roomPrice: newRoomInvoice.roomPrice,
                contractDeposit: newRoomInvoice.contractDeposit,
                prepaidDeposit: newRoomInvoice.prepaidDeposit,
                items: newRoomInvoice.serviceItems.filter((item) => item.isActive).map(item => {
                    return {
                        serviceId: convertInt(item.service.id),
                        totalUnit: item.quantity,
                        price: item.service.fee,
                        total: item.quantity * item.service.fee,
                        imageUrl: item.imageUrl ? [parseImageUrl(item.imageUrl)] : null,
                        index: item.index,
                        lastIndex: item.lastIndex,
                        isActive: item.isActive,
                        name: item.service.name,

                    };
                }),
                isFirstCycleMonthInvoice: newRoomInvoice.isFirstCycleMonthInvoice,
                servicePriceEndDate: newRoomInvoice.servicePriceEndDate,
                servicePriceStartDate: newRoomInvoice.servicePriceStartDate,
                originalRoomPrice: newRoomInvoice.roomPrice,
                extraPrice: 0,
                discount: 0,
            };
            setInitialValues(newInitialValues);
        }

    }, [
        JSON.stringify(newInvoiceData)]);

    const skipFetchInvoice = !invoiceId;
    const getInvoiceReq = normalizeRequestObject({
        invoiceId: invoiceId,
    });
    const {data: invoiceData, loading: getLoading, refetch: refetchInvoice} = useQuery(QUERY_GET_ROOM_INVOICE, {
        variables: getInvoiceReq, skip: skipFetchInvoice,
    });

    useEffect(() => {
        if (!skipFetchInvoice) {
            refetchInvoice(getInvoiceReq);
        }
    }, [
        JSON.stringify(getInvoiceReq)]);
    const [room, setRoom] = useState();

    useEffect(() => {
        if (invoiceData) {
            const roomInvoice = invoiceData.getRoomInvoice;
            const newServices = roomInvoice.invoiceItems?.map(item => item.service);
            setServices(newServices);
            setRoom(roomInvoice.room);
            console.log(roomInvoice.total);
            const newInitialValues = {
                ...initialValues,
                date: parseDate(roomInvoice.date),
                startPaidDate: parseDate(roomInvoice.startPaidDate),
                endPaidDate: parseDate(roomInvoice.endPaidDate),
                roomContractId: convertInt(roomInvoice.roomContract.id),
                roomPriceStartDate: parseDate(roomInvoice.roomPriceStartDate),
                roomPriceEndDate: parseDate(roomInvoice.roomPriceEndDate),
                roomPrice: roomInvoice.roomPrice,
                contractDeposit: roomInvoice.contractDeposit || 0,
                prepaidDeposit: roomInvoice.prepaidDeposit || 0,
                discount: roomInvoice.discount || 0,
                extraPrice: roomInvoice.extraPrice || 0,
                items: roomInvoice.invoiceItems.filter((item) => item.isActive).map(item => {
                    return {
                        serviceId: convertInt(item.service.id),
                        totalUnit: item.totalUnit,
                        price: item.service.fee,
                        total: item.total,
                        imageUrl: item.imageUrl ? [parseImageUrl(item.imageUrl)] : null,
                        index: item.index,
                        lastIndex: item.lastIndex,
                        isActive: item.isActive,
                        name: item.service.name,

                    };
                }),
                isFirstCycleMonthInvoice: roomInvoice.isFirstCycleMonthInvoice,
                servicePriceEndDate: roomInvoice.servicePriceEndDate,
                servicePriceStartDate: roomInvoice.servicePriceStartDate,
                originalRoomPrice: roomInvoice.roomPrice,
                note: roomInvoice.note,
                total: roomInvoice.total,
            };
            setInitialValues(newInitialValues);
        }

    }, [JSON.stringify(invoiceData)]);

    useDeepCompareEffect(() => {
        form.setFieldsValue(initialValues);
        initCalculatedFields(initialValues);
    }, [initialValues]);

    const idToService = services?.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
    }, {});

    function getServiceFromForm(name) {
        let serviceId = form.getFieldValue(['items', name, 'serviceId']);
        return idToService ? idToService[serviceId] : null;
    }

    const {createInvoice, loading: createLoading} = useCreateInvoice();
    const {updateInvoice, loading: updateLoading} = useUpdateInvoice();

    async function handleUpdateInvoice(values) {
        try {
            await updateInvoice(invoiceId, values);
            notification.success({
                message: t('invoice.updateSuccess'),
            });
            onCancel();
        } catch (e) {
            notification.error({
                message: t('invoice.updateFailed'), description: e?.message,
            });
        }
    }

    async function handleCreateInvoice(values) {
        try {
            await createInvoice(values);
            notification.success({
                message: t('invoice.createSuccess'),
            });
            onCancel();
        } catch (e) {
            notification.error({
                message: t('invoice.createFailed'), description: e?.message,
            });
        }
    }

    async function handleSubmit() {
        let values = await form.validateFields();
        values = omit(values, ['includeHasInvoiceRoom', 'totalServicePrice']);
        values.items = values.items?.map(item => {
            return {
                ...item, imageUrl: item.imageUrl ? item.imageUrl[0] : null,
            };
        });
        if (invoiceId) {
            await handleUpdateInvoice(values);
        } else {
            await handleCreateInvoice(values);
        }

    }

    function initCalculatedFields(allValues) {
        const serviceItems = allValues.items;
        const totalServiceFee = serviceItems?.reduce((aggr, item) => {
            return aggr + item.total || 0;
        }, 0);
        form.setFieldValue('totalServicePrice', totalServiceFee);
        form.setFieldValue('total', allValues.roomPrice + (allValues.contractDeposit || 0) + (totalServiceFee || 0) +
            (allValues.extraPrice || 0) - (allValues.prepaidDeposit || 0) - (allValues.discount || 0));
    }

    function handleValuesChange(changedValues, allValues) {
        const fieldName = Object.keys(changedValues)[0];
        if (fieldName === 'items') {
            const newItems = allValues.items?.map(item => {
                let newItem = {...item};
                const serviceId = item.serviceId;
                const service = idToService[serviceId];
                if (isUnitService(service) && item.index && item.lastIndex) {
                    newItem.totalUnit = item.index - item.lastIndex;
                }
                newItem.total = newItem.price * newItem.totalUnit;
                return newItem;
            });
            form.setFieldValue('items', newItems);
        }
        initCalculatedFields(allValues);
    }

    const roomInvoice = invoiceData?.getRoomInvoice;
    const title = invoiceId ? <div>
        <Space>
            <span>{t('invoice.update')}</span>
            <a href={roomInvoice?.previewUrl} target="_blank" rel="noreferrer"><ShareAltOutlined/></a>
        </Space>
    </div> : t('invoice.create');

    return <>
        <Modal title={title} open={open} onCancel={onCancel} width={1300} destroyOnClose
               confirmLoading={createLoading || updateLoading}
               onOk={handleSubmit}>
            <Form form={form} layout={'vertical'} onValuesChange={handleValuesChange}>
                <Divider orientation={'left'}/>
                <Row gutter={12}>
                    <Col>
                        <Form.Item label={t('invoice.invoiceType')}>
                            <Radio.Group defaultValue="normal" buttonStyle="solid" disabled={!!invoiceId}>
                                <Radio.Button value="normal">{t('invoice.typeNormal')}</Radio.Button>
                                <Radio.Button value="custom">{t('invoice.typeCustom')}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item label={t('room')} name={'roomContractId'}>
                            {invoiceId ?
                                <RoomName room={room}/> :
                                <SearchableSelect options={roomOptions} style={{minWidth: 200}}/>}
                            {/*<span><RoomName room={{}} /></span>*/}
                        </Form.Item>
                    </Col>
                    <Col style={{display: 'none'}}>
                        <Form.Item name={'isFirstCycleMonthInvoice'}></Form.Item>
                    </Col>
                    <Col style={{display: 'none'}}>
                        <Form.Item name={'servicePriceEndDate'}></Form.Item>
                    </Col>
                    <Col style={{display: 'none'}}>
                        <Form.Item name={'servicePriceStartDate'}></Form.Item>
                    </Col>
                    <Col style={{display: 'none'}}>
                        <Form.Item name={'originalRoomPrice'}></Form.Item>
                    </Col>
                    {!invoiceId && <Col>
                        <Form.Item label={t('invoice.includeHasInvoiceRoom')} name={'includeHasInvoiceRoom'}
                                   valuePropName={'checked'}>
                            <Checkbox/>
                            {/*<span><RoomName room={{}} /></span>*/}
                        </Form.Item>

                    </Col>}
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Divider orientation={'left'}>{t('invoice.roomPriceAndDeposit')}</Divider>
                        <Row gutter={12}>
                            <Col>
                                <Form.Item label={t('invoice.invoiceDate')} name={'date'}>
                                    <CustomMonthPicker/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item label={t('invoice.roomStartDate')} name={'roomPriceStartDate'}>
                                    <CustomDatePicker disabled/>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item label={t('invoice.roomEndDate')} name={'roomPriceEndDate'}>
                                    <CustomDatePicker disabled/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider orientation={'left'}>{t('invoice.service')}</Divider>
                        {!invoiceId && <Row>
                            <Col>
                                <Form.Item label={t('invoice.selectStatement')} name={'roomStatementId'}>
                                    <SearchableSelect options={statementOptions}/>
                                </Form.Item>
                            </Col>
                        </Row>}
                        <Row>
                            <Col>
                                <Form.List name="items">
                                    {fields => {
                                        return (<Table
                                            rowKey={(record, index) => {
                                                return index;
                                            }}
                                            dataSource={fields}
                                            columns={[
                                                {
                                                    title: t('service.service'),
                                                    render: (value, {key, name, ...restField}, index) => {
                                                        let service = getServiceFromForm(name);
                                                        return <>
                                                            <span>{service?.name}</span>
                                                            <Form.Item style={{display: 'none'}}
                                                                       name={[name, 'serviceId']}></Form.Item>
                                                        </>;
                                                    },
                                                }, {
                                                    title: t('price.unit'),
                                                    render: (value, {key, name, ...restField}, index) => {
                                                        let price = form.getFieldValue(['items', name, 'price']);
                                                        return <Form.Item {...restField} name={[name, 'price']}
                                                                          rules={[{required: true}]}>
                                                            <span>{formatNumber(price)}</span>
                                                        </Form.Item>;

                                                    },
                                                }, {
                                                    title: t('service.oldIndex'),
                                                    render: (value, {key, name, ...restField}, index) => {
                                                        let service = getServiceFromForm(name);
                                                        if (isUnitService(service)) {
                                                            return <Form.Item {...restField} name={[name, 'lastIndex']}
                                                                              rules={[{required: true}]}>
                                                                <InputNumber placeholder={service.unit}/>
                                                            </Form.Item>;
                                                        }
                                                        return <></>;

                                                    },
                                                }, {
                                                    title: t('service.index'),
                                                    render: (value, {key, name, ...restField}, index) => {
                                                        let service = getServiceFromForm(name);
                                                        return isUnitService(service) ?
                                                            <Form.Item {...restField} name={[name, 'index']}
                                                                       rules={[{required: true}]}>
                                                                <InputNumber placeholder={service.unit}/>
                                                            </Form.Item> :
                                                            <></>;
                                                    },
                                                }, {
                                                    title: t('service.totalUnit'),
                                                    render: (value, {key, name, ...restField}, index) => {
                                                        const service = getServiceFromForm(name);
                                                        const unitService = isUnitService(service);
                                                        const placeholder = unitService ?
                                                            service.unit :
                                                            t('service.totalUnit');
                                                        return <Form.Item {...restField} name={[name, 'totalUnit']}
                                                                          rules={[{required: true}]}>
                                                            <InputNumber placeholder={placeholder}
                                                                         disabled={unitService}/>
                                                        </Form.Item>;
                                                    },
                                                }, {
                                                    title: t('price.total'),
                                                    render: (value, {key, name, ...restField}, index) => {
                                                        let total = form.getFieldValue(['items', name, 'total']);
                                                        return <Form.Item {...restField} name={[name, 'total']}
                                                                          rules={[{required: true}]}>
                                                            <span>{formatNumber(total)}</span>
                                                        </Form.Item>;
                                                    },
                                                }, {
                                                    title: t('service.image'),
                                                    render: (value, {key, name, ...restField}, index) => {
                                                        let imageUrls = form.getFieldValue(['items', name, 'imageUrl']);
                                                        return <UploadImages name={[name, 'imageUrl']} maxTotalImage={1}
                                                                             initialFileList={imageUrls}/>;
                                                    },
                                                },

                                            ]}
                                        />);
                                    }}
                                </Form.List>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={12}>
                        <Divider orientation={'left'}>{t('invoice.summary')}</Divider>
                        <SummaryItemRow title={t('invoice.roomPrice')} name={'roomPrice'} disabled/>
                        <SummaryItemRow title={t('invoice.totalServicePrice')} name={'totalServicePrice'} disabled/>
                        <SummaryItemRow title={t('invoice.contractDeposit')} name={'contractDeposit'}/>
                        <SummaryItemRow title={t('invoice.extraPrice')} name={'extraPrice'}/>
                        <SummaryItemRow title={t('invoice.prepaidDeposit')} name={'prepaidDeposit'}
                                        titleType={'warning'}/>
                        <SummaryItemRow title={t('invoice.discount')} name={'discount'} titleType={'warning'}/>
                        <Divider/>
                        <SummaryItemRow title={t('invoice.total')} titleType={'success'} name={'total'} disabled/>
                        <Row gutter={12}>

                            <Col span={8} style={{
                                display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 12,
                            }}>
                                <Typography.Text>{t('invoice.paidDateRange')}</Typography.Text>
                                <Divider type={'vertical'}></Divider>
                            </Col>
                            <Col span={12}>
                                <Row gutter={12}>
                                    <Col span={10}>
                                        <Form.Item name={'startPaidDate'}>
                                            <CustomDatePicker/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} style={{display: 'flex', alignItems: 'center'}}>
                                        <div>{t('time.to')}</div>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item name={'endPaidDate'}>
                                            <CustomDatePicker/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={8} style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 12,
                            }}>
                                <Typography.Text>{t('invoice.note')}</Typography.Text>
                                <Divider type={'vertical'}></Divider>
                            </Col>
                            <Col span={12}>
                                <Form.Item name={'note'}><Input.TextArea style={{width: '100%'}} rows={4}/></Form.Item>
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </Form>
        </Modal>
    </>;
}

ModalInvoiceDetail.propTypes = {
    onCancel: PropTypes.func, roomContractId: PropTypes.any, open: PropTypes.bool,
};

function TabNoInvoice({startDate, endDate, motelId}) {
    let {t} = useTranslation();
    const request = {
        startDate: startDate, endDate: endDate, hasInvoice: false, motelId: motelId,
    };

    const skipGetRooms = !startDate || !endDate;
    const {data, loading, refetch} = useQuery(QUERY_ROOMS_BY_INVOICE_STATUS, {
        variables: normalizeRequestObject(request), skip: skipGetRooms,
    });

    useDeepCompareEffect(() => {
        if (!skipGetRooms) {
            refetch(normalizeRequestObject(request));
        }
    }, [request]);
    const dataRooms = data?.roomsByInvoiceStatus;

    const [showModal, setShowModal] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState();

    function openCreateInvoice(room) {
        setSelectedRoom(room);
        setShowModal(true);
    }

    function closeModal() {
        setSelectedRoom(null);
        setShowModal(false);
    }

    return <>
        <Table
            loading={loading}
            dataSource={dataRooms}
            rowKey={(record, index) => {
                return record.id;
            }}

            columns={[
                {
                    title: t('room'), render: (value, record, index) => {
                        return <RoomName room={record}/>;
                    },
                }, {
                    title: t('tenant'), dataIndex: ['representativeTenant'], render: (value, record, index) => {
                        return <TenantName tenant={value}/>;
                    },
                }, {
                    title: t('action'), render: (value, record, index) => {
                        return <>
                            <Button type="primary"
                                    onClick={() => openCreateInvoice(record)}>{t('invoice.create')}</Button>
                        </>;
                    },
                },

            ]}

        />
        <ModalInvoiceDetail open={showModal} onCancel={() => {
            closeModal();
        }} roomContractId={selectedRoom?.activeContractId} roomId={selectedRoom?.id} startDate={startDate}
                            endDate={endDate}/>

    </>;

}

TabNoInvoice.propTypes = {
    endDate: PropTypes.any, motelIds: PropTypes.any, startDate: PropTypes.any,
};

function formatNumber(n) {
    if (n) {
        return new Intl.NumberFormat().format(n);
    }
    return n;
}

function InvoiceType({type}) {
    const {t} = useTranslation();
    if (type === 'normal') {
        return <Tag color={'green'}>{t('invoice.typeNormal')}</Tag>;
    } else if (type === 'canceled') {
        return <Tag color={'red'}>{t('invoice.typeCancel')}</Tag>;
    }
    return <Tag color={'blue'}>{t('invoice.typeCustom')}</Tag>;
}

function ModalInvoicePayment({open, invoiceId, onCancel}) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const request = normalizeRequestObject({
        invoiceId,
    });
    const {data: invoiceData, loading, refetch} = useQuery(QUERY_GET_LITE_ROOM_INVOICE_WITH_TENANTS, {
        variables: request, skip: !invoiceId,
    });
    const roomInvoice = invoiceData?.getRoomInvoice;
    const [initialValues, setInitialValues] = useState({});

    const {payInvoice, loading: payLoading} = usePayInvoice();

    const tenantOptions = roomInvoice?.roomContract?.tenants.map(item => {
        return {
            label: item.fullName, value: item.id,
        };
    });

    useEffect(() => {
        if (roomInvoice) {
            setInitialValues({
                amount: roomInvoice.total, paidDate: dayjs(new Date()), paymentMethod: 'cash',
            });
        }
    }, [
        JSON.stringify(roomInvoice)]);

    useEffect(() => {
        if (invoiceId) {
            refetch(request);
        }
    }, [
        JSON.stringify(request)]);

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [
        JSON.stringify(initialValues)]);

    async function handleSubmit() {
        const values = await form.validateFields();
        try {
            await payInvoice(invoiceId, normalizeRequestObject(values));
            notification.success({
                message: t('invoice.paySuccess'),
            })
            onCancel();
        } catch (e) {
            notification.error({
                message: t('invoice.payFailed'),
                description: e?.message
            })
        }

    }

    return <Modal title={t('invoice.confirmPaid')} open={open} onCancel={onCancel} onOk={handleSubmit}
                  confirmLoading={payLoading} destroyOnClose>
        <Form form={form} layout={'vertical'}>
            <Form.Item label={t('invoice.paidAmount')} name={'amount'} rules={[
                {required: true}
            ]}>
                <NumberInput/>
            </Form.Item>
            <Form.Item label={t('invoice.paidDate')} name={'paidDate'} rules={[
                {required: true}
            ]}>
                <CustomDatePicker/>
            </Form.Item>
            <Form.Item label={t('invoice.paymentMethod')} name={'paymentMethod'} rules={[
                {required: true}
            ]}>
                <SelectPaymentMethod />
            </Form.Item>
            <Form.Item label={t('invoice.paidUser')} name={'tenantId'}>
                <SearchableSelect options={tenantOptions}/>
            </Form.Item>
            <UploadImages name={'imageUrls'} label={t('invoice.paymentImage')}/>
            <Form.Item label={t('invoice.note')} name={'note'}>
                <Input.TextArea rows={4}/>
            </Form.Item>
        </Form>

    </Modal>;
}

function TabInvoices({startDate, endDate, motelId, status}) {
    const {t} = useTranslation();
    const variables = {
        startDate, endDate, motelId, status,
    };
    const {data, loading} = useQuery(QUERY_ROOM_INVOICES, {
        variables: normalizeRequestObject(variables),
    });
    const roomInvoices = data?.roomInvoices;
    const {deleteInvoice} = useDeleteInvoice();

    async function handleDeleteInvoice(invoiceId) {
        try {
            await deleteInvoice(invoiceId);
            notification.success({
                message: t('invoice.deleteSuccess'),
            });
        } catch (e) {
            notification.error({
                message: t('invoice.deleteFailed'), description: e?.message,
            });
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState();

    function openStatementDetail(invoiceId) {
        setSelectedInvoiceId(invoiceId);
        setShowModal(true);
    }

    function closeModal() {
        setSelectedInvoiceId(null);
        setShowModal(false);
    }

    const [showModalPayment, setShowModalPayment] = useState(false);

    function openPaymentModal(invoiceId) {
        setSelectedInvoiceId(invoiceId);
        setShowModalPayment(true);
    }

    function closePaymentModal() {
        setSelectedInvoiceId(null);
        setShowModalPayment(false);
    }

    return <><Table
        loading={loading}
        dataSource={roomInvoices}
        rowKey={(record) => {
            return record.id;
        }}
        columns={[
            {
                title: '#', dataIndex: ['id'], render: (value, record, index) => {
                    return <a href="#" onClick={() => {
                        openStatementDetail(record.id);
                    }}>{`#${zeroPad(record.id)}`}</a>;
                },
            }, {
                title: t('room'), dataIndex: ['room'], render: (value, record, index) => {
                    return <RoomName room={value}/>;
                },
            }, {
                title: t('tenant'), dataIndex: ['roomContract', 'representativeTenant', 'fullName'],
            }, {
                title: t('invoice.type'), dataIndex: ['type'], render: (value, record, index) => {
                    return <InvoiceType type={value}/>;
                },
            }, {
                title: t('invoice.roomPrice'), dataIndex: ['roomPrice'], render: (value, record, index) => {
                    return formatNumber(value);
                },
            }, {
                title: t('invoice.servicePrice'), dataIndex: ['totalServicePrice'], render: (value, record, index) => {
                    return formatNumber(value);
                },
            }, {
                title: t('invoice.total'), dataIndex: ['total'], render: (value, record, index) => {
                    return formatNumber(value);
                },
            }, {
                title: t('invoice.createdAt'), dataIndex: ['createdAt'], render: (value, record, index) => {
                    return formatDate(record);
                },
            }, {
                title: t('invoice.createdBy'), dataIndex: ['creator', 'fullName'],
            }, {
                title: t('action'), render: (value, record, index) => {
                    return <Space>
                        <Popconfirm title={t('invoice.deleteConfirm')}
                                    onConfirm={() => {
                                        handleDeleteInvoice(record.id);
                                    }}>
                            <DeleteOutlined style={{
                                color: 'red',
                            }} onClick={() => {
                            }}/>
                        </Popconfirm>
                        {record.status !== 'done' && <CheckCircleFilled style={{
                            color: 'green',
                        }} onClick={() => {
                            openPaymentModal(record.id);
                        }}/>}
                    </Space>;
                },
            }]}
    />
        <ModalInvoiceDetail open={showModal}
                            onCancel={() => {
                                closeModal();
                            }}
                            invoiceId={selectedInvoiceId}/>
        <ModalInvoicePayment open={showModalPayment}
                             onCancel={() => {
                                 closePaymentModal();
                             }}
                             invoiceId={selectedInvoiceId}/>
    </>;
}

TabInvoices.propTypes = {
    endDate: PropTypes.any, motelId: PropTypes.any, startDate: PropTypes.any, status: PropTypes.string,
};
const Invoice = () => {
    let {t} = useTranslation();
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue({
            month: dayjs(new Date()),
        });
    }, []);
    const month = Form.useWatch('month', form);
    const startDate = month?.startOf('month');
    const endDate = month?.endOf('month');

    const motelId = Form.useWatch('motelId', form);
    const {data: motelData} = useQuery(MY_MOTELS);
    const motels = motelData?.myMotels?.map(item => {
        return {
            label: item.name, value: item.id,
        };
    });

    const items = [
        {
            key: 'no_invoice',
            label: t('invoice.tabNoInvoice'),
            children: <TabNoInvoice startDate={startDate} endDate={endDate} motelId={motelId}/>,
        }, {
            key: 'initial',
            label: t('invoice.initial'),
            children: <TabInvoices startDate={startDate} endDate={endDate} motelId={motelId} status={'initial'}/>,
        }, {
            key: 'overdue',
            label: t('invoice.overdue'),
            children: <TabInvoices startDate={startDate} endDate={endDate} motelId={motelId} status={'overdue'}/>,
        }, {
            key: 'done',
            label: t('invoice.done'),
            children: <TabInvoices startDate={startDate} endDate={endDate} motelId={motelId} status={'done'}/>,
        }];

    return <>
        <div>
            <Form form={form} layout={'vertical'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Space>
                    <Form.Item label={t('motel')} name={'motelId'} style={{minWidth: 200}}>
                        <SearchableSelect allowClear={true} placeholder={t('pleaseSelectMotel')}
                                          options={motels}/>
                    </Form.Item>
                    <Form.Item label={t('time.time')} name={'month'} style={{width: 200}}>
                        <CustomMonthPicker allowClear={false}/>
                    </Form.Item>
                </Space>
            </Form>
        </div>
        <Tabs defaultActiveKey="no_statement" items={items}/>
    </>;
};

export default Invoice;
