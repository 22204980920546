import { gql } from "@apollo/client";

const MY_ROOMS = gql`
    query myRooms($motelIds: [Int]) {
        myRooms(motelIds: $motelIds) {
            totalTenant
            totalOverdueInvoice
            totalLowIssue
            totalMediumIssue
            totalHighIssue
            id
            name
            floorNumber
            area
            price
            status
            totalGuestRoom
            totalBedRoom
            maxTenant
            deposit
            allSystemServices {
                service {
                    name
                    id
                }
                isActive
            }
            allServices {
                isActive
                service {
                    id
                    name
                    icon
                    feeType
                    fee
                    unit
                }
            }
            furnitureStatus
            description
            note
            tenants {
                id
                phoneNumber
                imageUrls
                fullName
            }
            activeContract {
                id
                number
                startDate
                endDate
            }
            gender
            imageUrls
        }
    }
`;

const GET_ROOM = gql`
    query getRoom($roomId: Int!) {
        getRoom(roomId: $roomId) {
            id
            name
            floorNumber
            area
            price
            status
            totalGuestRoom
            totalBedRoom
            maxTenant
            deposit
            allSystemServices {
                service {
                    name
                    id
                }
                isActive
            }
            allServices {
                isActive
                service {
                    id
                    name
                    icon
                    feeType
                    fee
                    unit
                }
            }
            furnitureStatus
            description
            note
            tenants {
                id
                phoneNumber
                imageUrls
                fullName
            }
            activeContract {
                id
                number
                startDate
                endDate
            }
            gender
            imageUrls
        }
    }
`;
const GET_ROOM_SERVICES = gql`
    query getRoom($roomId: Int!) {
        getRoom(roomId: $roomId) {
            id
            name
            totalTenant
            deposit
            price
            allServices {
                isActive
                service {
                    id
                    name
                    icon
                    feeType
                    fee
                    unit
                }
            }
        }
    }
`;

const SYSTEM_SERVICES = gql`
    query {
        systemServices(serviceType: ROOM) {
            id
            name
        }
    }
`;

export { MY_ROOMS, GET_ROOM, SYSTEM_SERVICES, GET_ROOM_SERVICES };
