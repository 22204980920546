import LightBulb from "../../assets/LightBulb.png";
import { serviceText } from "../../constants/Constants";

export function validateAddService(
    name,
    icon,
    feeType,
    unit,
    fee,
    description
) {
    let msg = {};

    if (icon === LightBulb) {
        msg.icon = serviceText.inputIconService.alertBlank;
    }
    if (description.length > 100) {
        msg.description = serviceText.inputDescription.alertExceed;
    }
    if (!feeType) {
        msg.feeType = serviceText.inputFeeType.alertBlank;
    }
    if (!name) {
        msg.name = serviceText.inputNameService.alertBlank;
    } else if (name.length > 25) {
        msg.name = serviceText.inputNameService.alertExceed;
    }
    if (!unit) {
        msg.unit = serviceText.inputUnit.alertBlank;
    }
    if (unit.length > 10) {
        msg.unit = serviceText.inputUnit.alertExceed;
    }
    if (isNaN(fee)) {
        msg.fee = serviceText.inputFee.alertNotNumber;
    }
    if (fee.length > 9) {
        msg.fee = serviceText.inputFee.alertExceed;
    }
    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
}
