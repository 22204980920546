import { useState } from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { Box, Typography, Modal } from '@mui/material'
import { useListImageRoom } from './useListImageRoom'
import EmptyIcon from '../../../assets/Icons/EmptyIcon'

const ListImageRoom = ({ listImage }) => {
    const classes = useListImageRoom()
    const [open, setOpen] = useState(false);

    const handleClose = () => setOpen(false);
    const clicked = (e) => {
        setOpen(true);
    }
    return (
        <Box className={classes.container}>
            <Typography className={classes.title}>Ảnh phòng</Typography>
            {
                listImage && listImage.length > 0 ?
                    <>
                        <Box className={classes.listImage}>
                            {listImage.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <img src={item} alt="" onClick={(e) => clicked(e)} />
                                    </div>
                                )
                            })}
                        </Box>
                        <Modal
                            open={open}
                            onClose={handleClose}
                        >
                            <Box className={classes.modalImage}>
                                <Carousel indicators={false}>
                                    {listImage.map((item, index) => {
                                        return (
                                            <Carousel.Item key={index}>
                                                <img src={item} alt="" />
                                            </Carousel.Item>
                                        )
                                    })}
                                </Carousel>
                            </Box>
                        </Modal>
                    </>
                    :
                    <EmptyIcon />
            }


        </Box>
    )
}

export default ListImageRoom
