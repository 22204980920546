import { serviceText } from "../../constants/Constants";

export const validateDetailService = (name, fee, unit, description) => {
    let msg = {};

    if (!name) {
        msg.name = serviceText.inputNameService.alertBlank;
    }

    if (name.length > 25) {
        msg.name = serviceText.inputNameService.alertExceed;
    }

    if (isNaN(fee)) {
        msg.fee = serviceText.inputFee.alertNotNumber;
    }

    if (fee.length > 9) {
        msg.fee = serviceText.inputFee.alertExceed;
    }

    if (!unit) {
        msg.unit = serviceText.inputUnit.alertBlank;
    }

    if (unit && unit.length > 10) {
        msg.unit = serviceText.inputUnit.alertExceed;
    }

    if (description && description.length > 100) {
        msg.description = serviceText.inputDescription.alertExceed;
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
};
