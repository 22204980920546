import React, { memo } from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material"
import { useCardService } from "./useCardService"

const CardService = ({ data }) => {
    const classes = useCardService()

    return (
        <Card className={classes.card}>
            <CardMedia
                component="img"
                className={classes.cardMedia}
                image={data.icon}
            />
            <CardContent className={classes.cardContent}>
                <Typography className={classes.nameService}>{data.name}</Typography>
                <Typography className={classes.priceService}>{`${data.feeConvert}`}</Typography>
            </CardContent>
        </Card>
    )
}

export default memo(CardService)
