import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useModalAdd } from "./useModalAdd";
import { GET_ROOMS } from "../../../graphql-client/Tenant/query";
import { useAddTenant } from "../../../graphql-client/Tenant/mutation";
import { handleUploadImage } from "../../../services/common";
import { resizeImage } from "../../../util/ResizeImage";
import { validateAddTenant } from "../../../useCase/Tenant/AddUseCase";
import Input from "../../../components/Input/Input";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import TextArea from "../../../components/TextArea/TextArea";
import ButtonHome from "../../../components/ButtonHome/ButtonHome";
import AutoCompleteInput from "../../../components/AutoCompleteInput/AutoCompleteInput";
import Notification from "../../../components/Notification/Notification";
import UploadImage from "../../../components/UploadImage/UploadImage";
import DatePicker from "../../../components/DatePicker/DatePicker";
import {
    tenantText,
    validImageFile,
    today,
} from "../../../constants/Constants";

export default function ModalAdd({
    openAdd,
    closeAdd,
    page,
    size,
    status,
    searchText,
}) {
    const classes = useModalAdd();
    const { loading: loadingContracts, data: dataContracts } = useQuery(
        GET_ROOMS,
        { variables: { hasContract: true } }
    );
    const { mutateAddTenant } = useAddTenant();

    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [idIssueBy, setIdIssueBy] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [idIssueDate, setIdIssueDate] = useState(null);
    const [roomContract, setRoomContract] = useState(null);

    const [validationMessage, setValidationMessage] = useState({});
    const [dataSelected, setDataSelected] = useState(null);
    const [previews, setPreviews] = useState([]);
    const [raws, setRaws] = useState([]);
    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });
    console.log(dataSelected);

    async function handleChange(e) {
        const rawFile = e.target.files[0];
        if (!validImageFile.includes(rawFile.type)) {
            setNotify({
                ...tenantText.notify.errorImageFormat,
            });
        } else {
            let resizedFile = rawFile;
            try {
                resizedFile = await resizeImage(
                    rawFile,
                    1920,
                    1080,
                    "JPEG",
                    50,
                    0,
                    "file"
                );
            } catch (err) {}
            setPreviews([...previews, URL.createObjectURL(resizedFile)]);
            setRaws([...raws, resizedFile]);
        }
    }

    function deleteFile(i) {
        const deletedPreview = previews.filter((item, index) => index !== i);
        setPreviews(deletedPreview);
        const deletedRaw = raws.filter((item, index) => index !== i);
        setRaws(deletedRaw);
    }

    const isValid = validateAddTenant(
        fullName,
        phoneNumber,
        idNumber,
        email,
        address,
        idIssueBy,
        birthday,
        idIssueDate
    );

    const addTenantCases = async () => {
        switch (roomContract === null) {
            case true:
                await mutateAddTenant(
                    {
                        request: {
                            fullName: fullName,
                            phoneNumber: phoneNumber,
                            idNumber: idNumber,
                            email: email,
                            address: address,
                            imageUrls: await handleUploadImage(raws),
                            birthday: birthday,
                            idIssueDate: idIssueDate,
                            idIssueBy: idIssueBy,
                        },
                    },
                    page,
                    size,
                    status,
                    searchText
                );
                break;

            case false:
                await mutateAddTenant(
                    {
                        request: {
                            fullName: fullName,
                            phoneNumber: phoneNumber,
                            idNumber: idNumber,
                            email: email,
                            address: address,
                            imageUrls: await handleUploadImage(raws),
                            roomContractId: roomContract.id,
                            birthday: birthday,
                            idIssueDate: idIssueDate,
                            idIssueBy: idIssueBy,
                        },
                    },
                    page,
                    size,
                    status,
                    searchText
                );
                break;

            default:
                break;
        }
    };
    const handleAddTenant = (e) => {
        e.preventDefault();

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            addTenantCases();
            setNotify({
                ...tenantText.notify.add,
            });
            clearState();
            if (e.nativeEvent.submitter.id === "add-tenant") {
                closeAdd();
            }
        }
    };

    useEffect(() => {
        clearState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAdd]);

    useEffect(() => {
        if (!loadingContracts) {
            setDataSelected(
                dataContracts.myRooms.map((element) => {
                    return {
                        id: element.activeContractId,
                        name: element.name + " - " + element.motel.name,
                    };
                })
            );
        }
    }, [dataContracts, loadingContracts]);

    function clearState() {
        setValidationMessage({});
        setFullName("");
        setPhoneNumber("");
        setIdNumber("");
        setEmail("");
        setAddress("");
        setIdIssueBy("");
        setRoomContract(null);
        setBirthday(null);
        setIdIssueDate(null);
        setPreviews([]);
        setRaws([]);
    }

    return (
        <>
            {loadingContracts ? (
                <></>
            ) : (
                <ModalContainer
                    closeModal={closeAdd}
                    openModal={openAdd}
                    title={tenantText.modalTenant.titleAdd}
                >
                    <form className={classes.form} onSubmit={handleAddTenant}>
                        <Grid container className={classes.container}>
                            <Grid item xs={3.75}>
                                <Input
                                    text={tenantText.fullName.label}
                                    star={true}
                                    placeholder={
                                        tenantText.fullName.placeholder
                                    }
                                    error={validationMessage.fullName}
                                    changed={(e) => setFullName(e.target.value)}
                                    value={fullName}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2.75}>
                                <Input
                                    text={tenantText.phoneNumber.label}
                                    star={true}
                                    placeholder={
                                        tenantText.phoneNumber.placeholder
                                    }
                                    error={validationMessage.phoneNumber}
                                    changed={(e) =>
                                        setPhoneNumber(e.target.value)
                                    }
                                    value={phoneNumber}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2.75}>
                                <Input
                                    text={tenantText.email.label}
                                    placeholder={tenantText.email.placeholder}
                                    error={validationMessage.email}
                                    changed={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2}>
                                <DatePicker
                                    label={tenantText.birthday.label}
                                    value={birthday}
                                    maxDate={new Date(today)}
                                    changed={(valueDate) => {
                                        if (valueDate) {
                                            setBirthday(
                                                moment(valueDate).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );
                                        } else {
                                            setBirthday(valueDate);
                                        }
                                    }}
                                    error={validationMessage.birthday}
                                />
                            </Grid>

                            <Grid item xs={3.75}>
                                <AutoCompleteInput
                                    label={tenantText.roomForHire.label}
                                    placeholder={
                                        tenantText.roomForHire.placeholder
                                    }
                                    data={dataSelected}
                                    changed={(_event, selected) => {
                                        setRoomContract(selected);
                                    }}
                                    field="name"
                                    defaultValue={roomContract}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2.75}>
                                <Input
                                    text={tenantText.idNumber.label}
                                    placeholder={
                                        tenantText.idNumber.placeholder
                                    }
                                    error={validationMessage.idNumber}
                                    changed={(e) => setIdNumber(e.target.value)}
                                    value={idNumber}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2.75}>
                                <Input
                                    text={tenantText.idIssueBy.label}
                                    placeholder={
                                        tenantText.idIssueBy.placeholder
                                    }
                                    error={validationMessage.idIssueBy}
                                    changed={(e) =>
                                        setIdIssueBy(e.target.value)
                                    }
                                    value={idIssueBy}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2}>
                                <DatePicker
                                    label={tenantText.idIssueDate.label}
                                    value={idIssueDate}
                                    maxDate={new Date(today)}
                                    changed={(valueDate) => {
                                        if (valueDate) {
                                            setIdIssueDate(
                                                moment(valueDate).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );
                                        } else {
                                            setIdIssueDate(valueDate);
                                        }
                                    }}
                                    error={validationMessage.idIssueDate}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.label}>
                                    {tenantText.idImages.label}
                                </Typography>
                                <Box className={classes.UploadImageContainer}>
                                    <UploadImage
                                        previews={previews}
                                        raws={raws}
                                        changed={handleChange}
                                        deleted={deleteFile}
                                        maxImage={
                                            process.env
                                                .REACT_APP_MAX_UPLOAD_ID_IMAGE
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={0.5}></Grid>
                            <Grid item xs={5.5}>
                                <TextArea
                                    label={tenantText.address.label}
                                    placeholder={tenantText.address.placeholder}
                                    error={validationMessage.address}
                                    changed={(e) => setAddress(e.target.value)}
                                    value={address}
                                />
                            </Grid>
                            <Grid item xs={7.75}></Grid>
                            <Grid item xs={2} className={classes.button}>
                                <ButtonHome
                                    type="submit"
                                    text={
                                        tenantText.modalTenant.buttonContinueAdd
                                    }
                                    id="add-more-tenant"
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2} className={classes.button}>
                                <ButtonHome
                                    type="submit"
                                    text={tenantText.modalTenant.buttonAdd}
                                    id="add-tenant"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalContainer>
            )}
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
}
