import { makeStyles } from "@mui/styles";
export const useTextArea = makeStyles({
    hiddenIt: {
        display: "none",
    },
    labelError: {
        color: "#f7685b",
        fontSize: "12px",
        display: "flex",
        marginTop: "5px",
    },
    iconError: {
        fontSize: "14px",
        position: "relative",
        top: "15%",
        marginRight: "8px",
    },
    inputError: {
        border: "1px solid #f7685b",
        borderRadius: "5px",
        padding: "10px 20px 10px 20px",
        minHeight: "107px",
        "& .MuiInputBase-input::placeholder": {
            color: "#C2CFE0",
        },
    },
    label: {
        marginBottom: "11px",
        fontSize: "14px",
        lineHeight: "16px",
    },
    input: {
        border: "1px solid #E8E8E8",
        borderRadius: "5px",
        padding: "10px 20px 10px 20px",
        minHeight: "107px",
        "& .MuiInputBase-input::placeholder": {
            color: "#C2CFE0",
            opacity: 1,
        },
    },
    formControl: {
        width: "100%",
        height: "160px",
    },
    star: {
        color: "#f7685b",
    },
});
