import {gql} from '@apollo/client';

export const QUERY_TRANSACTIONS_BY_PAGE = gql`
    query GetTransactionsByDate($startDate: Date, $endDate: Date, $page: Int!, $size: Int!, $motelIds: [Int!], $groupTypes: [TransactionGroupType], $ownerIds: [Int], $ownerTypes: [OwnerType]) {
  transactionsGroupByDate(startDate: $startDate, endDate: $endDate, page: $page, size: $size, motelIds: $motelIds, groupTypes: $groupTypes, ownerIds: $ownerIds, ownerTypes: $ownerTypes) {
    total
    page
    size
    totalPages
    results {
      amount
      date
      transactions {
        id
        date
        amount
        ownerType
        note
        group {
          id
          name
          type
        }
        tenant {
          id
          fullName
        }
        room {
          id
          name
          motel {
            id
            name
          }
        }
        motel {
          id
          name
        }
      }
    }
  }
  getTransactionSummary(startDate: $startDate, endDate: $endDate, motelIds: $motelIds) {
    income
    outcome
    amount
  }
}


`;