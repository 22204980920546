/* eslint-disable */
import React, { useState, useEffect, memo } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Input from "../../../components/Input/Input";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import ButtonSelectIcon from "../ButtonSelectIcon/ButtonSelectIcon";
import SelectInput from "../../../components/SelectInput/SelectInput";
import ButtonHome from "../../../components/ButtonHome/ButtonHome";
import Notification from "../../../components/Notification/Notification";

import { useModalAdd } from "./useModalAdd";
import { useAddService } from "../../../graphql-client/Services/mutation";
import { validateAddService } from "../../../useCase/Service/AddUseCase";
import { feeTypeList } from "../../../constants/Constants";
import { serviceText } from "../../../constants/Constants";
import LightBulb from "../../../assets/LightBulb.png";
import TextArea from "../../../components/TextArea/TextArea";

export default memo(function ModalAdd({ openAdd, closeAdd }) {
    const classes = useModalAdd();
    const [name, setName] = useState("");
    const [icon, setIcon] = useState(LightBulb);
    const [unit, setUnit] = useState("");
    const [fee, setFee] = useState(0);
    const [description, setDescription] = useState("");
    const [monthlyBase, setMonthlyBase] = useState(true);
    const [feeType, setFeeType] = useState("");

    const [validationMessage, setValidationMessage] = useState({});
    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });
    const [disableInput, setDisableInput] = useState(false);
    const [disableCheckBox, setDisableCheckBox] = useState(false);

    const { mutateAddService, dataAddService, errorAddService } =
        useAddService();

    const handleSelectChange = (selectedValue) => {
        setFeeType(selectedValue);
    };

    useEffect(() => {
        if (openAdd === true) {
            clearState();
        }
    }, [openAdd]);

    const clearState = () => {
        setValidationMessage({});
        setName("");
        setUnit("");
        setDescription("");
        setIcon(LightBulb);
        setFee(0);
        setMonthlyBase(false);
        setFeeType("");
        setDisableCheckBox(true);
        setDisableInput(true);
    };

    useEffect(() => {
        switch (feeType) {
            case "unit":
                {
                    setDisableInput(false);
                    setDisableCheckBox(true);
                    setMonthlyBase(false);
                    setUnit("");
                }
                break;

            case "person":
                {
                    setDisableInput(true);
                    setDisableCheckBox(false);
                    setMonthlyBase(true);
                    setUnit(" ");
                }
                break;

            case "room":
                {
                    setDisableInput(true);
                    setDisableCheckBox(false);
                    setMonthlyBase(true);
                    setUnit(" ");
                }
                break;

            case "quantity":
                {
                    setDisableInput(true);
                    setDisableCheckBox(true);
                    setMonthlyBase(false);
                    setUnit(" ");
                }
                break;

            case "other": {
                setDisableInput(false);
                setDisableCheckBox(false);
                setMonthlyBase(true);
                setUnit("");
            }
        }
    }, [feeType]);

    const handleAddService = async (e) => {
        e.preventDefault();

        const isValid = validateAddService(
            name,
            icon,
            feeType,
            unit,
            fee,
            description
        );

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            setValidationMessage(isValid.msg);
            await mutateAddService({
                request: {
                    name: name,
                    unit: unit,
                    fee: +fee,
                    description: description,
                    monthlyBase: monthlyBase,
                    feeType: feeType,
                    icon: icon,
                },
            });
            setNotify({
                ...serviceText.notify.add,
            });
            closeAdd();
        }
    };

    const handleContinueAdd = async (e) => {
        e.preventDefault();

        const isValid = validateAddService(
            name,
            icon,
            feeType,
            unit,
            fee,
            description
        );

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            setValidationMessage(isValid.msg);
            await mutateAddService({
                request: {
                    name: name,
                    unit: unit,
                    fee: +fee,
                    description: description,
                    monthlyBase: monthlyBase,
                    feeType: feeType,
                    icon: icon,
                },
            });
            setNotify({
                ...serviceText.notify.add,
            });
            clearState();
        }
    };
    return (
        <>
            <ModalContainer
                closeModal={closeAdd}
                openModal={openAdd}
                title={serviceText.modalAddService.title}
            >
                <form onSubmit={handleAddService}>
                    <Grid container className={classes.container}>
                        <Grid item xs={6}>
                            <Input
                                text={serviceText.inputNameService.label}
                                type={serviceText.inputNameService.type}
                                placeholder={
                                    serviceText.inputNameService.placeholder
                                }
                                star={true}
                                changed={(e) => setName(e.target.value)}
                                error={validationMessage.name}
                                value={name}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} className={classes.button}>
                            <ButtonSelectIcon
                                text={serviceText.inputIconService.label}
                                changed={(e) => setIcon(e.target.src)}
                                imgIcon={icon}
                                error={validationMessage.icon}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput
                                label={serviceText.inputFeeType.label}
                                star={true}
                                data={feeTypeList}
                                placeholder={
                                    serviceText.inputFeeType.placeholder
                                }
                                onSelectChange={handleSelectChange}
                                error={validationMessage.feeType}
                                defaultValue={feeType}
                                field="name"
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5}>
                            <Input
                                text={serviceText.inputUnit.label}
                                type={serviceText.inputUnit.label}
                                placeholder={serviceText.inputUnit.placeholder}
                                star={true}
                                changed={(e) => setUnit(e.target.value)}
                                error={validationMessage.unit}
                                isDisabled={disableInput}
                                value={unit}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                text={serviceText.inputFee.label}
                                type={serviceText.inputFee.type}
                                placeholder={serviceText.inputFee.placeholder}
                                star={true}
                                changed={(e) => setFee(e.target.value)}
                                error={validationMessage.fee}
                                value={fee}
                            />
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={5} className={classes.button}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={monthlyBase}
                                        disabled={disableCheckBox}
                                        onChange={(e) =>
                                            setMonthlyBase(e.target.checked)
                                        }
                                        className={classes.checkBox}
                                    />
                                }
                                label={serviceText.inputMonthlyBase.label}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextArea
                                label={serviceText.inputDescription.label}
                                placeholder={
                                    serviceText.inputDescription.placeholder
                                }
                                changed={(e) => setDescription(e.target.value)}
                                error={validationMessage.description}
                                value={description}
                            />
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid
                            item
                            xs={6}
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <ButtonHome
                                type="submit"
                                text={
                                    serviceText.modalAddService
                                        .buttonContinueAdd
                                }
                                onClick={handleContinueAdd}
                            />
                            <ButtonHome
                                type="submit"
                                text={serviceText.modalAddService.buttonAdd}
                                onClick={handleAddService}
                            />
                        </Grid>
                    </Grid>
                </form>
            </ModalContainer>
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
});
