import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import EmptyIcon from '../../../assets/Icons/EmptyIcon';
import ItemTenant from "./ItemTenant"
import { useListTenant } from './useListTenant';


const ListTenant = ({ listTenants }) => {
    const classes = useListTenant()

    return (
        <Box className={classes.containerList}>
            <Typography className={classes.title}>Người thuê</Typography>
            {
                listTenants.length ?
                    <List sx={{ width: '100%', p: 0 }}>
                        {listTenants.map((value, index) => {
                            return (
                                <ItemTenant key={index} value={value} />
                            )
                        })}
                    </List>
                    :
                    <EmptyIcon />
            }
        </Box>

    )
}

export default ListTenant
