import Resizer from "react-image-file-resizer";

export const resizeImage = (
    path,
    maxWidth,
    maxHeight,
    format,
    quality,
    rotation,
    outputType
) =>
    new Promise((resolve) => {
        try {
            Resizer.imageFileResizer(
                path,
                maxWidth,
                maxHeight,
                format,
                quality,
                rotation,
                (uri) => {
                    resolve(uri);
                },
                outputType
            );
        } catch (err) {}
    });
