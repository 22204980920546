import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/client";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Notification from "../../../components/Notification/Notification";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Paging from "../../../components/Paging/Paging";
import Table from "./Table/Table";
import { useContractTerms } from "./useContractTerms";
import ListMotel from "./ListContent/ListContent";
import { headCellContractTerm } from "../../../constants/Constants";
import { CONTRACT_TERMS } from "../../../graphql-client/ContractTerms/query";
import { HomeContext } from "../../../layout/HomeLayout/HomeLayout";
import { SearchByKeyword } from "../../../util/SearchByKeyword";
import { contractTermsText } from "../../../constants/Constants";

const ContractTerms = () => {
    const classes = useContractTerms();
    const [ListTerm, setListTerm] = useState(
        contractTermsText.arrayListTermType
    );
    const [listTermId, setListTermId] = useState(0);
    const [selectedType, setSelectedType] = useState("");
    const [page, setPage] = useState(1);
    const [showData, setShowData] = useState([]);
    const [totalRep, setTotalRep] = useState(0);
    const searchText = useContext(HomeContext).dbValue;

    const { loading: loadingContractTerms, data: dataContractTerms } =
        useQuery(CONTRACT_TERMS);

    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    const handleShowData = (data) => {
        const sameTypeData = data.filter((item) => item.type === selectedType);
        const searchData = SearchByKeyword(sameTypeData, "content", searchText);
        setTotalRep(searchData.length);
        return searchData.reverse().slice((page - 1) * 12, page * 12);
    };

    const setTotalNumber = (data) => {
        ListTerm.forEach((term) => {
            const ar = data.filter((item) => item.type === term.type);
            term.total = ar.length;
        });
    };

    useEffect(() => {
        if (!loadingContractTerms) {
            const getData = dataContractTerms.contractTerms;
            setTotalNumber(getData);
            setShowData(handleShowData(getData));
        }
        setSelectedType(ListTerm[listTermId].type);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingContractTerms, dataContractTerms, selectedType, page]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }} className={classes.container}>
                <Grid container spacing={2} style={{ height: "100%" }}>
                    <Grid item xs={3} style={{ height: "100%" }}>
                        <Box className={classes.containerMotel}>
                            <Box>
                                <ListMotel
                                    data={ListTerm}
                                    clicked={(item) => setListTermId(item)}
                                    selectedId={listTermId}
                                    selectedType={setSelectedType}
                                />
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={9}
                        style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Grid item xs={12} className={classes.gridTable}>
                            <Grid
                                item
                                xs={12}
                                className={classes.gridItemTable}
                            >
                                <Table
                                    headCells={headCellContractTerm}
                                    rowsData={showData}
                                    setNotify={setNotify}
                                    setConfirmDialog={setConfirmDialog}
                                    page={page}
                                    setPage={setPage}
                                    selectedType={selectedType}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.gridItemPaging}
                            >
                                <Paging
                                    totalPages={Math.ceil(totalRep / 12)}
                                    currentPage={page}
                                    onChangePage={(event, value) => {
                                        setPage(value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
};

export default ContractTerms;
