import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useStyles } from "./useSearchInput";
import { useLocation } from "react-router-dom";
import { placeholderSearchHome } from "../../constants/Constants";
import { memo } from "react";

const SearchInput = ({ changed, value }) => {
    const classes = useStyles();
    const location = useLocation();

    //get redirect
    const redirect = location.pathname.split("/");

    //find placeholder
    const placeholder = Object.keys(placeholderSearchHome).filter(
        (value) => value === redirect[1] || value === redirect[2]
    )[0];

    return (
        <TextField
            value={value}
            variant="standard"
            margin="normal"
            required
            fullWidth
            autoFocus
            placeholder={placeholderSearchHome[placeholder]}
            onChange={changed}
            className={classes.textField}
            InputProps={{
                classes: { input: classes.input },
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon className={classes.icon} />
                    </InputAdornment>
                ),
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="start">
                        <NotificationsNoneOutlinedIcon
                            className={classes.icon}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default memo(SearchInput);
