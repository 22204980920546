import { makeStyles } from "@mui/styles";

export const useTabItem = makeStyles({
    tabList: {
        "& .Mui-selected": {
            color: "rgba(4, 195, 92, 1)",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "rgba(4, 195, 92, 1)",
        },
    },
});
