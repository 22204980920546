import dataCityAndDistrict from "../assets/tinh_thanh.json";
import { v4 as uuidv4 } from "uuid";

export const getCity = () => {
    let result = dataCityAndDistrict.map((a) => {
        return { id: uuidv4(), cityName: a.name };
    });
    return result;
};

export const getDistrict = (city) => {
    let result;
    if (city) {
        result = dataCityAndDistrict
            .filter((a) => a.name === city)[0]
            ["quan-huyen"].map((a) => {
                return {
                    id: uuidv4(),
                    districtName: a.name_with_type,
                };
            });
        return result;
    } else {
        return [];
    }
};
