import {PlusOutlined} from '@ant-design/icons';
import {gql, useQuery} from '@apollo/client';
import {Button, Col, Divider, FloatButton, Form, Input, InputNumber, Modal, Row, Space, Table, Tabs} from 'antd';
import {useForm} from 'antd/es/form/Form';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AmountComponent} from '../../components/AmountComponent/AmountComponent';
import {NumberInput} from '../../components/SearchableSelect';
import {normalizeRequestObject} from '../../util/Request';
import {ModalTransactionDetail, SelectMotel, TransactionTable} from '../Accounting/Transaction/Transaction';

const QUERY_DEBTS = gql`
    query Debts($motelIds: [Int!], $debtType: DebtType!){
        debtsBySubject(debtType: $debtType, motelIds: $motelIds){
            subject{
                ownerType
                ownerId
                ownerName
                room{
                    id
                    name
                    motel{
                        id
                        name
                    }

                }
                roomContract{
                    id
                    number
                }
                tenant{
                    id
                    fullName
                }
            }
            nextTransactionGroup{
                id
                name
            }
            amount
        }
    }
`;

function ModalDebtDetail({subject, open, onCancel}) {
    const {t} = useTranslation();
    const {data: debtSubjectData, loading} = useQuery(QUERY_GET_DEBT_SUBJECT, {
        variables: subject, skip: !subject,
    });

    const debtSubject = debtSubjectData?.getAccountingSubject;
    console.log(debtSubject);
    const [form] = Form.useForm();

    useEffect(() => {
        if (debtSubject) {
            form.setFieldsValue(debtSubject);
        }
    }, [
        JSON.stringify(debtSubject)]);
    const ownerIds = debtSubject ? [debtSubject?.subject.ownerId] : null;
    const ownerTypes = debtSubject ? [debtSubject?.subject.ownerType] : null;

    const [showModal, setShowModal] = useState(false);

    function openModal() {
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    return <Modal open={open} onCancel={onCancel} title={t('debt.detail')} width={800}>
        <Row>
            <Col>
                <Form form={form} layout={'vertical'}>
                    <Row gutter={12}>
                        <Col>
                            <Form.Item name={['subject', 'ownerName']} label={t('debt.object')}>
                                <Input disabled/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name={['subject', 'tenant', 'fullName']} label={t('debt.tenant')}>
                                <NumberInput disabled/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col>
                            <Form.Item name={['amount']} label={t('debt.amount')}>
                                <NumberInput disabled/>
                            </Form.Item>
                        </Col>
                        <Col style={{display: 'flex', alignItems: 'end', marginBottom: 10}}>
                            <Button disabled={debtSubject?.amount === 0} onClick={() => openModal()} type={'primary'}>{t('debt.collect')}</Button>
                        </Col>
                    </Row>

                </Form>
            </Col>
        </Row>
        <Divider orientation={'left'}>{t('debt.transactionList')}</Divider>
        <Row>
            <Col span={24}>
                {debtSubject && <TransactionTable groupTypes={['debt_collection', 'loan_payment', 'receivable']}
                                                  ownerIds={ownerIds}
                                                  ownerTypes={ownerTypes}/>}
            </Col>
        </Row>
        <ModalTransactionDetail open={showModal} onCancel={closeModal}
                                groupId={parseInt(debtSubject?.nextTransactionGroup?.id)}
                                amount={debtSubject?.amount}
                                ownerType={debtSubject?.subject.ownerType}
                                ownerId={parseInt(debtSubject?.subject.ownerId)}
                                roomContractId={parseInt(debtSubject?.subject.roomContract?.id)}
                                roomId={parseInt(debtSubject?.subject.room?.id)}
                                userId={parseInt(debtSubject?.subject.user?.id)}
                                tenantId={parseInt(debtSubject?.subject.tenant?.id)}
        />
    </Modal>;
}

const QUERY_GET_DEBT_SUBJECT = gql`
    query GetDebtSubject($ownerId: Int!, $ownerType: OwnerType!){
        getAccountingSubject(subject: {ownerId: $ownerId, ownerType: $ownerType}){
            amount
            subject{
                ownerId
                ownerType
                ownerName
                roomContract {
                    id
                }
                user {
                    id
                }
                room {
                    id
                }
                tenant {
                    id
                    fullName
                }
            }
            type
            nextTransactionGroup{
                id
            }
        }
    }
`;

function TabReceivable({debtType, motelIds}) {
    const {t} = useTranslation();
    const debtsReq = normalizeRequestObject({
        debtType, motelIds,
    });
    const {data: debtsData, loading, refetch} = useQuery(QUERY_DEBTS, {
        variables: debtsReq,
    });

    useEffect(() => {
        refetch();
    }, [
        JSON.stringify(debtsReq)]);

    const debts = debtsData?.debtsBySubject;

    const [showModal, setShowModal] = useState(false);

    function closeModal() {
        setShowModal(false);
    }

    const [selectedDebtSubject, setSelectedDebtSubject] = useState();

    function showDebtDetail(subject) {
        setShowModal(true);
        setSelectedDebtSubject(subject);
    }

    return <>
        <Table
            loading={loading}
            dataSource={debts}
            columns={[
                {
                    title: '#', render: (value, record, index) => {
                        return <a href={'#'} onClick={() => {
                            showDebtDetail(record.subject);
                        }}>{index + 1}</a>;
                    },
                }, {
                    title: t('debt.object'), dataIndex: ['subject', 'ownerName'],
                }, {
                    title: t('debt.amount'), dataIndex: ['amount'], render: (value, record, index) => {
                        return <AmountComponent value={value}/>;
                    },
                }]}
        />
        <ModalDebtDetail open={showModal} onCancel={closeModal} subject={selectedDebtSubject}/>
    </>;
}

function Debt() {
    let {t} = useTranslation();
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue({
            month: dayjs(new Date()),
        });
    }, []);

    const motelIds = Form.useWatch('motelIds', form);
    const motels = motelIds?.length === 0 ? null : motelIds;
    const items = [
        {
            key: 'receivable',
            label: t('debt.receivable'),
            children: <TabReceivable motelIds={motels} debtType={'RECEIVABLE'}/>,
        }, {
            key: 'completed',
            label: t('debt.completed'),
            children: <TabReceivable motelIds={motels} debtType={'COMPLETED'}/>,
        },

    ];
    const [showModal, setShowModal] = useState(false);

    function closeModal() {
        setShowModal(false);
    }

    return <>
        <div>
            <Form form={form} layout={'vertical'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Space>
                    <Form.Item label={t('motel')} name={'motelIds'} style={{minWidth: 200}}>
                        <SelectMotel mode={'multiple'}/>
                    </Form.Item>
                </Space>
            </Form>
        </div>
        <Tabs defaultActiveKey="initial" items={items}/>
        <FloatButton type={'primary'} icon={<PlusOutlined/>} onClick={() => setShowModal(true)}/>
        <ModalTransactionDetail onCancel={closeModal} open={showModal} groupTypes={['receivable']}/>
    </>;
}

export default Debt;