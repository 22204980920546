import { validateEmailUtil } from "../../util/ValidateEmail";
import { alertPassword, alertEmail } from "../../constants/Constants";

export const validateSignIn = (email, password) => {
    let msg = {};

    if (!email) {
        msg.email = alertEmail.blank;
    } else {
        if (!validateEmailUtil(email)) {
            msg.email = alertEmail.validate;
        }
    }

    if (!password) {
        msg.password = alertPassword.blank;
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
};
