import { makeStyles } from "@mui/styles";

export const useContractTerms = makeStyles({
    container: {
        overflow: "hidden",
        height: "calc(100% + 16px)",
    },
    containerDetail: {
        height: "100%",
    },
    containerMotel: {
        height: "100%",
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        boxShadow: "0px 6px 18px 0px #0000000f",
    },
    containerList: {
        height: "calc(100% - 64px)",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    buttonContainer: {
        height: "64px",
        display: "flex",
        alignItems: "center",
        padding: "0 5%",
    },
    gridTable: {
        backgroundColor: "#FFFFFF",
        height: "100%",
        width: "100%",
        borderRadius: "5px",
    },
    gridItemTable: {
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        height: "calc(100% - 50px)",
    },
    gridItemPaging: {
        height: "50px",
    },
});
