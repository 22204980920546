import { appRouter } from "./AppRouter";
import moment from "moment";

export const textEmail = {
    text: "Email",
    name: "email",
    type: "text",
    placeholder: "Nhập email của bạn",
};

export const textPassword = {
    text: "Mật khẩu",
    name: "password",
    type: "password",
    placeholder: "Nhập mật khẩu của bạn",
};

export const textFullName = {
    text: "Họ và tên",
    name: "fullName",
    type: "text",
    placeholder: "Nhập họ và tên của bạn",
};

export const textPhoneNumber = {
    text: "Số điện thoại",
    name: "phoneNumber",
    type: "text",
    placeholder: "Nhập số điện thoại của bạn",
};

export const textTotalRoom = {
    text: "Tổng số phòng cho thuê",
    name: "totalRoom",
    type: "text",
    placeholder: "Nhập tổng số phòng cho thuê của bạn",
};

export const textRegion = [
    { name: "region", text: "Miền Bắc", value: "north" },
    { name: "region", text: "Miền Trung", value: "middle" },
    { name: "region", text: "Miền Nam", value: "south" },
];

export const textConfirmCode = {
    text: "Mã xác nhận",
    name: "",
    type: "text",
    placeholder: "Nhập mã xác nhận",
};

export const textNewPassword = {
    text: "Mật khẩu mới",
    name: "",
    type: "password",
    placeholder: "Nhập mật khẩu mới",
};

export const alertPassword = { blank: "Mật khẩu không được để trống" };

export const alertEmail = {
    blank: "Email không được để trống",
    validate: "Định dạng email không đúng",
};

export const alertFullName = {
    blank: "Họ và tên không được để trống",
};

export const alertPhoneNumber = {
    blank: "Số điện thoại không được để trống",
};

export const alertTotalRoom = {
    blank: "Tổng số phòng không được để trống không được để trống",
};

export const alertAgreeTerms = {
    noAgreeTerms: "Chưa đồng ý các điều khoản",
};

export const alertCode = {
    blank: "Mã xác nhận không được để trống",
};

export const alertNewPassword = {
    blank: "Mật khẩu mới không được để trống",
};

export const textSignIn = {
    titleSignIn: "Đăng nhập",
    rememberSignIn: "Ghi nhớ đăng nhập",
    forgotPassword: "Quên mật khẩu?",
    haveAccount: "Bạn có tài khản chưa?",
    signUpNow: "Đăng kí ngay",
    textButton: "Đăng nhập",
};

export const agreeTerms = {
    label: "Tôi đồng ý với chính sách & điều khoản của Lalahome",
    value: false,
};

export const textSignUp = {
    titleSignUp: "Đăng ký",
    textButton: "Đăng ký",
};

export const textForgotPassword = {
    titleForgotPassword: "Quên mật khẩu",
    textWarning: `Nhập email để lấy lại mật khẩu, hệ thống sẽ gửi mã xác nhận vào
                email của bạn. Hãy kiểm tra hòm thư và làm theo hướng dẫn.`,
    textButtonConfirmCode: "Gửi mã",
    textButtonConfirmChange: "Thay đổi mật khẩu",
};

export const successCode = {
    text: "Đã gửi mã lên email của bạn.",
};

export const successNewPassword = {
    text: "Thay đổi mật khẩu thành công.",
};

export const navigation = {
    dashboard: {
        link: appRouter.dashboard,
        text: "Dashboard",
    },
    roomStatement: {
        link: appRouter.roomStatement,
        text: "Chốt điện nước",
    },
    invoice: {
        link: appRouter.invoice,
        text: "Hóa đơn",
    },
    deposit: {
        link: appRouter.deposit,
        text: "Cọc giữ chỗ",
    },
    debt: {
        link: appRouter.debt,
        text: "Sổ nợ",
    },
    contract: {
        link: appRouter.contract,
        text: "Hợp đồng",
    },
    accounting: {
        link: appRouter.accounting,
        text: "Thu chi",
    },
    tenant: {
        link: appRouter.tenant,
        text: "Người thuê",
    },
    motel: {
        link: appRouter.motel,
        text: "Tòa nhà",
    },
    room: {
        link: appRouter.room,
        text: "Phòng",
    },
    service: {
        link: appRouter.service,
        text: "Dịch vụ",
    },
    logout: {
        link: appRouter.signIn,
        text: "Đăng xuất",
    },
};

export const placeholderSearchHome = {
    dashboard: "Tìm kiếm bất kỳ những gì bạn muốn",
    service: "Tìm kiếm bằng tên dịch vụ",
    motel: "Tìm kiếm bởi tên hoặc địa chỉ nhà ...",
    room: "Tìm kiếm bởi tên hoặc địa chỉ nhà ...",
    tenant: "Tìm kiếm người thuê ...",
    representatives: "Tìm kiếm người đại diện ...",
    contract: "Tìm kiếm theo số hợp đồng, phòng, toà nhà ...",
};

export const headCellServices = [
    {
        id: "name",
        label: "Tên dịch vụ",
    },
    {
        id: "feeType",
        label: "Đơn vị",
    },
    {
        id: "fee",
        label: "Phí dịch vụ",
    },
    {
        id: "updatedAt",
        label: "Cập nhật",
    },
    {
        id: "description",
        label: "Ghi chú",
    },
];

export const feeTypeList = [
    {
        id: "unit",
        name: "Lũy tiến theo chỉ số đồng hồ",
    },
    {
        id: "person",
        name: "Người",
    },
    {
        id: "room",
        name: "Phòng",
    },
    {
        id: "quantity",
        name: "Số lần sử dụng",
    },
    {
        id: "other",
        name: "Khác",
    },
];

export const serviceText = {
    modalAddService: {
        title: "Thêm dịch vụ",
        buttonAdd: "Thêm dịch vụ",
        buttonContinueAdd: "Tiếp tục thêm dịch vụ",
    },
    modalDetailAndUpdateService: {
        title: "Chi tiết",
        button: "Cập nhật",
    },
    inputNameService: {
        label: "Tên dịch vụ",
        placeholder: "Điện, nước, thang máy, bảo vệ",
        type: "text",
        alertBlank: "Tên dịch vụ không được để trống",
        alertExceed: "Tên dịch vụ không được vượt quá 25 kí tự",
    },
    inputIconService: {
        label: "Chọn ảnh đại diện",
        placeholder: "Tìm kiếm theo tên",
        alertBlank: "Hãy chọn hình đại diện",
    },
    inputFeeType: {
        label: "Thu phí dựa trên",
        placeholder: "Lũy tiến theo chỉ số, phòng, người, số lần",
        alertBlank: "Hãy chọn kiểu phí dịch vụ",
    },
    inputUnit: {
        label: "Đơn vị đo",
        placeholder: "Kwh, m3,...",
        type: "text",
        alertBlank: "Đơn vị không được để trống",
        alertExceed: "Đơn vị không được vượt quá 10 kí tự",
    },
    inputFee: {
        label: "Phí dịch vụ",
        placeholder: "Nhập phí dịch vụ",
        type: "text",
        alertExceed: "Phí dịch vụ không được vượt quá 10 kí tự",
        alertNotNumber: "Phí dịch vụ phải là số",
    },
    inputMonthlyBase: {
        label: "Thu theo tháng",
    },
    inputDescription: {
        label: "Ghi chú",
        placeholder: "Nhập ghi chú",
        type: "text",
        alertExceed: "Ghi chú không được vượt quá 100 kí tự",
    },
    notify: {
        add: {
            isOpen: true,
            title: "Thêm thành công",
            message: "Dịch vụ đã được thêm thành công",
            type: "success",
        },
        delete: {
            isOpen: true,
            title: "Xóa thành công",
            message: "Dịch vụ đã được xóa thành công",
            type: "success",
        },
        update: {
            isOpen: true,
            title: "Cập nhật thành công",
            message: "Dịch vụ đã được cập nhật thành công",
            type: "success",
        },
    },
    dialog: {
        delete: {
            isOpen: true,
            title: "Xác nhận",
            subTitle: "Bạn có chắc chắn muốn xóa dịch vụ này?",
        },
    },
};

const dataSelectDay = [
    { id: "1", text: "1" },
    { id: "2", text: "2" },
    { id: "3", text: "3" },
    { id: "4", text: "4" },
    { id: "5", text: "5" },
    { id: "6", text: "6" },
    { id: "7", text: "7" },
    { id: "8", text: "8" },
    { id: "9", text: "9" },
    { id: "10", text: "10" },
    { id: "11", text: "11" },
    { id: "12", text: "12" },
    { id: "13", text: "13" },
    { id: "14", text: "14" },
    { id: "15", text: "15" },
    { id: "16", text: "16" },
    { id: "17", text: "17" },
    { id: "18", text: "18" },
    { id: "19", text: "19" },
    { id: "20", text: "20" },
    { id: "21", text: "21" },
    { id: "22", text: "22" },
    { id: "23", text: "23" },
    { id: "24", text: "24" },
    { id: "25", text: "25" },
    { id: "26", text: "26" },
    { id: "27", text: "27" },
    { id: "28", text: "28" },
    { id: "29", text: "29" },
    { id: "30", text: "30" },
    { id: "0", text: "Ngày cuối tháng" },
];

export const motelText = {
    modalMotel: {
        titleAdd: "Thêm tòa nhà",
        titleUpdate: "Cập nhật tòa nhà",
        tabItems: [
            { label: "Dịch vụ có phí", value: "1" },
            { label: "Dịch vụ miễn phí", value: "2" },
            { label: "Tiện ích tòa nhà", value: "3" },
            { label: "Mô tả", value: "4" },
            { label: "Lưu ý tòa nhà", value: "5" },
            { label: "Ghi chú cho hóa đơn", value: "6" },
        ],
        buttonAdd: "Thêm tòa nhà",
        buttonContinueAdd: "Tiếp tục thêm tòa nhà",
        buttonUpdate: "Cập nhật tòa nhà",
    },
    inputNameMotel: {
        label: "Nhập tên tòa nhà",
        placeholder: "Nhập tên tòa nhà",
        type: "text",
        alertBlank: "Vui lòng nhập tên toàn nhà",
        alertExceed: "Tên toàn nhà không được vượt quá 100 kí tự",
    },
    inputTotalFloor: {
        label: "Số tầng",
        placeholder: "Tầng",
        type: "text",
        alertBlank: "Vui lòng nhập tổng số tầng",
        alertNotNumber: "Số tầng phải là số",
        alertExceed: "Số tầng không được vượt quá 3 kí tự",
    },
    inputManager: {
        label: "Quản lý toàn nhà",
        placeholder: "Quản lý toàn nhà",
        type: "text",
    },
    inputAddress: {
        label: "Địa chỉ",
        placeholder: "Địa chỉ",
        type: "text",
        alertBlank: "Vui lòng nhập địa chỉ tòa nhà",
        alertExceed: "Địa chỉ tòa nhà không được vượt quá 200 kí tự",
    },
    inputCity: {
        label: "Thành phố",
        placeholder: "Thành phố",
        type: "text",
        alertBlank: "Vui lòng chọn Tỉnh/Thành phố",
    },
    inputDistrict: {
        label: "Quận/Huyện",
        placeholder: "Quận/Huyện",
        type: "text",
        alertBlank: "Vui lòng chọn Quận/Huyện",
    },
    inputOpenTime: {
        label: "Mở cửa",
        placeholder: "Mở cửa",
        type: "time",
    },
    inputCloseTime: {
        label: "Đóng cửa",
        placeholder: "Đóng cửa",
        type: "time",
    },
    inputEndDayOfMonth: {
        label: "Ngày chốt tiền",
        placeholder: "Ngày chốt tiền",
        type: "text",
        data: dataSelectDay,
        alertBlank: "Vui lòng chọn ngày chốt tiền phòng",
    },
    inputDayLeaveNumber: {
        label: "Báo chuyển trước",
        placeholder: "Số ngày",
        type: "text",
        alertBlank: "Vui lòng nhập số ngày cần báo trước khi chuyển",
        alertNotNumber: "Báo chuyển trước phải là số",
        alertExceed: "Báo chuyển trước không được vượt quá 3 kí tự",
    },
    inputStartPaidDay: {
        label: "Ngày bắt đầu nộp tiền",
        placeholder: "Ngày bắt đầu nộp tiền",
        type: "text",
        data: dataSelectDay,
        alertBlank: "Vui lòng nhập số ngày bắt đầu nộp tiền phòng",
    },
    inputEndPaidDay: {
        label: "Ngày kết thúc nộp tiền",
        placeholder: "Ngày kết thúc nộp tiền",
        type: "text",
        data: dataSelectDay,
        alertBlank: "Vui lòng nhập số ngày kết thúc nộp tiền phòng",
    },
    textAreaDescription: {
        placeholder: "Nhập mô tả",
        alertBlank: "Vui lòng nhập mô tả cho tòa nhà",
        alertExceed: "Mô tả cho tòa nhà không được vượt quá 2000 kí tự",
    },
    textAreaNote: {
        placeholder: "Nhập lưu ý tòa nhà",
    },
    textAreaInvoiceNote: {
        placeholder: "Ghi chú cho hóa đơn",
    },
    buttonDelete: "Xóa",
    buttonUpdate: "Cập nhật",
    totalRoom: "Phòng",
    totalTenant: "Người thuê",
    totalFloor: "Số tầng",
    fee: "Phí thuê",
    manager: "Quản lý",
    paidService: "Dịch vụ có phí",
    freeService: "Dịch vụ miễn phí",
    systemServices: "Tiện ích tòa nhà",
    description: "Mô tả",
    note: "Lưu ý cho người thuê nhà",
    invoiceNote: "Ghi chú cho hóa đơn",
    notify: {
        add: {
            isOpen: true,
            title: "Thêm thành công",
            message: "Tòa nhà đã được thêm thành công",
            type: "success",
        },
        delete: {
            isOpen: true,
            title: "Xóa thành công",
            message: "Tòa nhà đã được xóa thành công",
            type: "success",
        },
        update: {
            isOpen: true,
            title: "Cập nhật thành công",
            message: "Tòa nhà đã được cập nhật thành công",
            type: "success",
        },
    },
    dialog: {
        delete: {
            isOpen: true,
            title: "Xác nhận",
            subTitle: "Bạn có chắc chắn muốn xóa tòa nhà này?",
        },
    },
};
export const headCellTenants = [
    {
        id: "name",
        label: "Tên người thuê",
    },
    {
        id: "roomName",
        label: "Phòng",
    },
    {
        id: "phoneNumber",
        label: "Số điện thoại",
    },
    {
        id: "idNumber",
        label: "CMND/CCCD",
    },
    {
        id: "idIssueDate",
        label: "Cấp ngày",
    },
    {
        id: "address",
        label: "Địa chỉ",
    },
];
export const tenantText = {
    navigation: {
        assigned_room: "Đã có phòng",
        not_assigned_room: "Chưa có phòng",
        canceled: "Đã thanh lý",
    },
    tenantList: {
        addButton: "Thêm người thuê",
        updateLabel: "Cập nhật người thuê",
        dataEmpty: "Không có dữ liệu",
    },
    confirmDialog: {
        title: "Bạn có chắc chắn xóa bản ghi này không?",
        subTitle: "Bạn không thể hoàn tác thao tác này",
    },
    modalTenant: {
        titleAdd: "Thêm người thuê",
        titleUpdate: "Cập nhật người thuê",
        buttonAdd: "Thêm người thuê",
        buttonContinueAdd: "Lưu và tiếp tục",
        buttonUpdate: "Cập nhật người thuê",
    },
    notify: {
        add: {
            isOpen: true,
            title: "Thêm thành công",
            message: "Thêm người thuê thành công",
            type: "success",
        },
        delete: {
            isOpen: true,
            title: "Xóa thành công",
            message: "Xóa người thuê thành công",
            type: "success",
        },
        update: {
            isOpen: true,
            title: "Cập nhật thành công",
            message: "Cập nhật người thuê thành công",
            type: "success",
        },
        errorImageFormat: {
            isOpen: true,
            title: "Đã có lỗi xảy ra",
            message: "Ảnh định dạng đuôi nên là .png, .jpg, .jpeg",
            type: "error",
        },
        errorDeleteTenant: "Xóa thất bại",
    },
    fullName: {
        label: "Họ và tên",
        placeholder: "Nhập họ và tên",
    },
    phoneNumber: {
        label: "Số điện thoại",
        placeholder: "Nhập số điện thoại",
    },
    email: {
        label: "Email",
        placeholder: "Nhập email",
    },
    birthday: {
        label: "Ngày sinh",
    },
    roomForHire: {
        label: "Chọn phòng thuê",
        placeholder: "Chọn phòng thuê có sẵn",
    },
    idNumber: {
        label: "Số CMND/CCCD",
        placeholder: "Nhập số CMND/CCCD",
    },
    idIssueBy: {
        label: "Nơi cấp",
        placeholder: "Nhập nơi cấp CMND/CCCD",
    },
    idIssueDate: {
        label: "Ngày cấp",
    },
    idImages: {
        label: "Ảnh CMND/CCCD",
    },
    address: {
        label: "Địa chỉ",
        placeholder: "Nhập địa chỉ của người thuê",
    },
};

export const roomText = {
    modalRoom: {
        titleAdd: "Thêm phòng",
        titleUpdate: "Cập nhật phòng",
        tabItems: [
            { label: "Dịch vụ", value: "1" },
            { label: "Ảnh phòng", value: "2" },
            { label: "Tiện ích phòng", value: "3" },
            { label: "Mô tả phòng", value: "4" },
            { label: "Lưu ý cho người thuê", value: "5" },
        ],
        buttonAdd: "Thêm phòng",
        buttonContinueAdd: "Tiếp tục thêm phòng",
        buttonUpdate: "Cập nhật phòng",
    },
    inputNameRoom: {
        label: "Tên phòng",
        placeholder: "Nhập tên phòng",
        type: "text",
        alertBlank: "Vui lòng nhập tên phòng",
        alertExceed: "Tên phòng không được vượt quá 100 kí tự",
    },
    inputFloorNumber: {
        label: "Tầng",
        placeholder: "Tầng",
        type: "text",
        alertBlank: "Vui lòng nhập tầng",
        alertExceed: "Tầng không được vượt quá 3 kí tự",
        alertNotNumber: "Tầng phải là số",
    },
    inputPrice: {
        label: "Giá phòng dự kiến",
        placeholder: "Giá phòng dự kiến",
        type: "text",
        alertBlank: "Vui lòng nhập giá phòng dự kiến",
        alertExceed: "Giá phòng dự kiến không được vượt quá 10 kí tự",
        alertNotNumber: "Giá phòng dự kiến phải là số",
    },
    inputTotalBedRoom: {
        label: "Số phòng ngủ",
        placeholder: "Số phòng ngủ",
        type: "text",
        alertBlank: "Vui lòng nhập số phòng ngủ",
        alertExceed: "Số phòng ngủ không được vượt quá 3 kí tự",
        alertNotNumber: "Số phòng ngủ phải là số",
    },
    inputTotalGuestRoom: {
        label: "Số phòng khách",
        placeholder: "Số phòng khách",
        type: "text",
        alertBlank: "Vui lòng nhập số phòng khách",
        alertExceed: "Số phòng khách không được vượt quá 3 kí tự",
        alertNotNumber: "Số phòng khách phải là số",
    },
    inputArea: {
        label: "Diện tích (m2)",
        placeholder: "Diện tích",
        type: "text",
        alertBlank: "Vui lòng nhập diện tích",
        alertExceed: "Diện tich không được vượt quá 10 kí tự",
        alertNotNumber: "Diện tich phải là số",
    },
    inputMaxTenant: {
        label: "Giới hạn số người thuê",
        placeholder: "Giới hạn số người thuê",
        type: "text",
        alertBlank: "Vui lòng nhập giới hạn người thuê",
        alertExceed: "Giới hạn người thuê không được vượt quá 3 kí tự",
        alertNotNumber: "Giới hạn người thuê phải là số",
    },
    inputDeposit: {
        label: "Tiền đặt cọc",
        placeholder: "Tiền đặt cọc",
        type: "text",
        alertBlank: "Vui lòng nhập tiền đặt cọc",
        alertExceed: "Tiền đặt cọc không được vượt quá 10 kí tự",
        alertNotNumber: "Tiền đặt cọc phải là số",
    },
    radioGender: {
        label: "Đối tượng",
        valueRadio: [
            {
                id: "male",
                name: "Nam",
            },
            {
                id: "female",
                name: "Nữ",
            },
            {
                id: "",
                name: "Tất cả các đối tượng",
            },
        ],
    },
    textAreaDescription: {
        placeholder: "Nhập mô tả cho phòng",
        type: "text",
        alertExceed: "Mô tả cho phòng không được vượt quá 2000 kí tự",
    },
    textAreaNote: {
        placeholder: "Nhập lưu ý cho phòng",
        type: "text",
        alertExceed: "Lưu ý cho phòng không được vượt quá 2000 kí tự",
    },
    area: "Diện tích",
    bedRoom: "Phòng ngủ",
    guestRoom: "Phòng khách",
    maxTenant: "Người tối đa",
    deposit: "Tiền cọc",
    paidService: "Dịch vụ có phí",
    freeService: "Dịch vụ miễn phí",
    systemServices: "Tiện ích phòng",
    furnitureStatus: "Nội thất",
    description: "Mô tả phòng (Dùng cho đẩy phòng)",
    note: "Lưu ý dành cho người thuê phòng",
    notify: {
        add: {
            isOpen: true,
            title: "Thêm thành công",
            message: "Phòng đã được thêm thành công",
            type: "success",
        },
        delete: {
            isOpen: true,
            title: "Xóa thành công",
            message: "Phòng đã được xóa thành công",
            type: "success",
        },
        update: {
            isOpen: true,
            title: "Cập nhật thành công",
            message: "Phòng đã được cập nhật thành công",
            type: "success",
        },
        error: {
            isOpen: true,
            title: "Đã có lỗi xảy ra",
            message: "Sai định dạng ảnh",
            type: "error",
        },
    },
    dialog: {
        delete: {
            isOpen: true,
            title: "Xác nhận",
            subTitle: "Bạn có chắc chắn muốn xóa phòng này?",
        },
        notMotel: {
            isOpen: true,
            title: "Bạn chưa có tòa nhà",
            subTitle: "Bạn có muốn chuyển sang toàn nhà không?",
        },
    },
};
export const validImageFile = ["image/png", "image/jpeg", "image/jpg"];
export const today = moment().format("YYYY-MM-DD");
export const tenantValidate = {
    list: {
        roomNameNull: "Chưa thêm vào phòng",
        nullMessage: "Chưa có",
    },
    fullName: {
        blank: "Họ và tên không được để trống.",
        overLimit: "Họ và tên vượt quá 200 kí tự.",
    },
    phoneNumber: {
        blank: "Số điện thoại không được để trống.",
        notNumber: "Đây không phải là số điện thoại.",
        overLimit: "Số điện thoại vượt quá 15 kí tự.",
    },
    idNumber: {
        overLimit: "CMND/CCCD vượt quá 20 kí tự.",
        notNumber: "Đây không phải là số CMND/CCCD.",
    },
    address: {
        overLimit: "Địa chỉ người thuê vượt quá 200 kí tự.",
    },
    idIssueBy: {
        overLimit: "Nơi cấp CMND/CCCD vượt quá 100 kí tự.",
    },
    birthday: {
        invalidDate: "Sai định dạng ngày sinh.",
        afterDate: "Bạn đến từ tương lai.",
    },
    idIssueDate: {
        invalidDate: "Sai định dạng ngày cấp.",
        afterDate: "Ngày cấp phải nhỏ hơn hôm nay.",
    },
};

export const contractText = {
    navigation: {
        active: "Đang hoạt động",
        overdue: "Quá hạn",
        canceled: "Đã thanh lý",
        representatives: "Đại diện",
        contractTerms: "Điều khoản",
    },
};

export const accountingText = {
    navigation: {
        transaction: "Giao dịch",
        transactionGroup: "Nhóm giao dịch",
    },
};


export const headCellRepresentatives = [
    {
        id: "name",
        label: "Tên người thuê",
    },
    {
        id: "phoneNumber",
        label: "SĐT",
    },
    {
        id: "birthday",
        label: "Ngày sinh",
    },
    {
        id: "idNumber",
        label: "CMND/CCCD",
    },
    {
        id: "idIssueDate",
        label: "Cấp ngày",
    },
    {
        id: "address",
        label: "Địa chỉ",
    },
    {
        id: "isDefault",
        label: "",
    },
];

export const representativesText = {
    modalRepresentatives: {
        titleAdd: "Thêm người đại diện",
        titleUpdate: "Cập nhật người đại diện",
        // tabItems: [
        //     { label: "Dịch vụ", value: "1" },
        //     { label: "Ảnh phòng", value: "2" },
        //     { label: "Tiện ích phòng", value: "3" },
        //     { label: "Mô tả phòng", value: "4" },
        //     { label: "Lưu ý cho người thuê", value: "5" },
        // ],
        buttonAdd: "Thêm người đại diện",
        buttonContinueAdd: "Lưu và tiếp tục",
        buttonUpdate: "Cập nhật người đại diện",
    },
    inputName: {
        label: "Họ và tên",
        placeholder: "Họ và tên",
        type: "text",
        alertBlank: "Vui lòng nhập họ và tên",
        alertNotNumber: "Đây không phải là số điện thoại.",
        alertExceed: "Họ và tên không được vượt quá 200 kí tự",
    },
    inputPhoneNumber: {
        label: "SĐT",
        placeholder: "SĐT",
        type: "text",
        alertBlank: "Vui lòng nhập số điện thoại",
        alertExceed: "số điện thoại không được vượt quá 15 kí tự",
        alertNotNumber: "Số điện thoại phải là số",
    },
    inputBirthday: {
        label: "Ngày sinh",
        placeholder: "Ngày sinh",
        type: "text",
        invalidDate: "Sai định dạng ngày sinh.",
        alertBlank: "Vui lòng nhập ngày sinh",
        afterDate: "Bạn đến từ tương lai.",
    },
    inputIdNumber: {
        label: "Số CMND/CCCD",
        placeholder: "CMND/CCCD",
        type: "text",
        alertBlank: "Vui lòng nhập số CMND/CCCD .",
        alertExceed: "CMND/CCCD vượt quá 20 kí tự.",
        alertNotNumber: "CMND/CCCD phải là số",
    },

    inputIdDate: {
        label: "Ngày cấp",
        placeholder: "Ngày cấp CMND/CCCD",
        type: "text",
        invalidDate: "Sai định dạng ngày cấp.",
        alertBlank: "Vui lòng nhập ngày cấp",
        afterDate: "Ngày cấp phải nhỏ hơn hôm nay.",
    },
    textAreaIdAt: {
        label: "Nơi cấp CMND/CCCD",
        placeholder: "Nơi cấp CMND/CCCD",
        alertBlank: "Vui lòng nhập nơi cấp CMND/CCCD.",
        alertExceed: "Nơi cấp CMND/CCCD không được vượt quá 2000 kí tự",
    },
    textAreaAddress: {
        label: "Nơi đăng ký hộ khẩu",
        placeholder: "Nơi đăng ký hộ khẩu",
        alertBlank: "Vui lòng nhập nơi đăng ký hộ khẩu.",
        alertExceed: "Nơi đăng ký hộ khẩu không được vượt quá 2000 kí tự",
    },
    notify: {
        add: {
            isOpen: true,
            title: "Thêm thành công",
            message: "Người đại diện đã được thêm thành công",
            type: "success",
        },
        delete: {
            isOpen: true,
            title: "Xóa thành công",
            message: "Người đại diện đã được xóa thành công",
            type: "success",
        },
        update: {
            isOpen: true,
            title: "Cập nhật thành công",
            message: "Người đại diện đã được cập nhật thành công",
            type: "success",
        },
    },
    dialog: {
        delete: {
            isOpen: true,
            title: "Xác nhận",
            subTitle: "Bạn có chắc chắn muốn xóa người đại diện này?",
        },
        changeDefaultRepresentative: {
            isOpen: true,
            title: "Xác nhận",
            subTitle: "Bạn có chắc chắn muốn thay đổi người đại diện này?",
        },
    },
};

export const headCellContractTerm = [
    {
        id: "content",
        label: "Nội dung chuyển khoản",
    },
];

export const contractTermsText = {
    modalContractTerms: {
        titleAdd: "Thêm điều khoản",
        titleUpdate: "Cập nhật điều khoản",
        buttonAdd: "Thêm điều khoản",
        buttonContinueAdd: "Lưu và tiếp tục",
        buttonUpdate: "Cập nhật điều khoản",
    },
    selectTypeTerm: [
        { id: 1, title: "Bên A", subTitle: "Cho thuê", type: "owner" },
        { id: 2, title: "Bên B", subTitle: "Người thuê", type: "tenant" },
        { id: 3, title: "Điều khoản chung", subTitle: "", type: "both" },
    ],
    textAreaContext: {
        label: "Nội dung điều khoản",
        placeholder: "Nội dung",
        alertBlank: "Vui lòng nhập nội dung điều khoản.",
        alertExceed: "Nội dung điều khoản không được vượt quá 2000 kí tự",
    },
    textListContractTerm: {
        buttonAdd: "Thêm điều khoản",
        selected: "đã chọn",
    },
    arrayListTermType: [
        {
            id: 0,
            type: "owner",
            title: "Bên A",
            subTitle: "Cho thuê",
            total: 0,
        },
        {
            id: 1,
            type: "tenant",
            title: "Bên B",
            subTitle: "Người thuê",
            total: 0,
        },
        {
            id: 2,
            type: "both",
            title: "Điều khoản chung",
            subTitle: "",
            total: 0,
        },
    ],
    notify: {
        add: {
            isOpen: true,
            title: "Thêm thành công",
            message: "Điều khoản đã được thêm thành công",
            type: "success",
        },
        delete: {
            isOpen: true,
            title: "Xóa thành công",
            message: "Điều khoản đã được xóa thành công",
            type: "success",
        },
        update: {
            isOpen: true,
            title: "Cập nhật thành công",
            message: "Điều khoản đã được cập nhật thành công",
            type: "success",
        },
    },
    dialog: {
        delete: {
            isOpen: true,
            title: "Xác nhận",
            subTitle: "Bạn có chắc chắn muốn xóa điều khoản này?",
        },
    },
};

export const NotFoundText = {
    header: "404",
    body: "Xin lỗi. Nội dung bạn tìm kiếm không tồn tại!",
    linkToHome: "Về trang chủ",
    linkToFacebook: "https://www.facebook.com/LaLaHomeApp/",
};

export const notifyLostConnect = {
    isOpen: true,
    title: "Đã xảy ra lỗi",
    message: "Kết nỗi mạng yêú. Hãy kiểm tra đường truyền và thử lại",
    type: "error",
};
