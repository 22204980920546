import { makeStyles } from "@mui/styles";

export const useItemListContent = makeStyles({
    item: {
        width: "100%",
        height: "75px",
        borderBottom: "1px solid rgba(235, 239, 242, 1)",
        justifyContent: "space-between",
        textOverflow: "hidden",
        padding: "16px",
        borderRight: "2px solid transparent",
        "&:hover": {
            cursor: "pointer",
        },
    },
    active: {
        borderRight: "2px solid #04C45C",
    },
    title: {
        color: "#04C35C",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    subTitle: {
        color: "#192A3E",
        fontSize: "12px",
        lineHeight: "18px",
        letterSpacing: "0.01e",
    },
    content: {
        minWidth: 0,
        width: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    counter: {
        width: "20%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        "& .MuiListItemText-primary": {
            color: "#90A0B7",
            fontSize: "12px",
            lineHeight: "15px",
            textAlign: "right",
            letterSpacing: "0.01em",
        },
    },
});
