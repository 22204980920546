import { gql, useMutation } from "@apollo/client";
import { MY_MOTELS, GET_MOTELS } from "./query";

const DELETE_MOTEL = gql`
    mutation deleteMotel($id: Int!) {
        deleteMotel(id: $id) {
            ok
        }
    }
`;

function useDeleteMotel() {
    const [mutateDeleteMotel, { data, loading, error }] =
        useMutation(DELETE_MOTEL);

    const handleDeleteMotel = async (variables) => {
        try {
            await mutateDeleteMotel({
                variables,
                refetchQueries: [{ query: MY_MOTELS }],
            });
        } catch (error) {}
    };

    return {
        mutateDeleteMotel: handleDeleteMotel,
        dataDeleteMotel: data,
        loadingDeleteMotel: loading,
        errorDeleteMotel: error,
    };
}

const ADD_MOTEL = gql`
    mutation AddMotel($request: AddMotelRequest) {
        addMotel(request: $request) {
            ok
        }
    }
`;

function useAddMotel() {
    const [mutateAddMotel, { data, loading, error }] = useMutation(ADD_MOTEL);

    const handleAddMotel = async (variables) => {
        try {
            await mutateAddMotel({
                variables,
                refetchQueries: [{ query: MY_MOTELS }],
            });
        } catch (error) {}
    };

    return {
        mutateAddMotel: handleAddMotel,
        dataAddMotel: data,
        loadingAddMotel: loading,
        errorAddMotel: error,
    };
}

const UPDATE_MOTEL = gql`
    mutation UpdateMotel($motel: UpdateMotelRequest!, $id: Int!) {
        updateMotel(motel: $motel, id: $id) {
            ok
        }
    }
`;

function useUpdateMotel() {
    const [mutateUpdateMotel, { data, loading, error }] =
        useMutation(UPDATE_MOTEL);

    const handleUpdateMotel = async (variables, id) => {
        try {
            await mutateUpdateMotel({
                variables,
                refetchQueries: [
                    { query: GET_MOTELS, variables: { motelId: +id } },
                ],
            });
        } catch (error) {}
    };

    return {
        mutateUpdateMotel: handleUpdateMotel,
        dataUpdateMotel: data,
        loadingUpdateMotel: loading,
        errorUpdateMotel: error,
    };
}

export { useDeleteMotel, useAddMotel, useUpdateMotel };
