import {gql, useMutation} from '@apollo/client';
import {MUTATION_CREATE_STATEMENT} from '../Services/mutation';

export const MUTATION_UPDATE_STATEMENT = gql`
    mutation UpdateStatement($req: UpdateRoomStatementReq!, $statementId: Int!){
        updateRoomStatement(statementId: $statementId, request: $req){
            ok
        }
    }
`;

export function useUpdateStatement() {
    const [mutateUpdateStatement, {loading}] = useMutation(MUTATION_UPDATE_STATEMENT, {
        refetchQueries: [],
    });

    async function updateStatement(statementId, req) {
        await mutateUpdateStatement({
            variables: {
                statementId, req,
            },
            refetchQueries: [
                'RoomStatements',
                'GetStatement',
                'RoomsByStatementStatus',
            ]
        });
    }

    return {
        updateStatement, loading,
    };
}

export const MUTATION_DELETE_STATEMENT = gql`
    mutation DeleteStatement($id: Int!) {
        deleteRoomStatement(id: $id){
            ok
        }
    }
`;

export function useDeleteStatement() {
    const [mutateDeleteStatement, {loading}] = useMutation(MUTATION_DELETE_STATEMENT);

    async function deleteStatement(statementId) {
        await mutateDeleteStatement({
            variables: {
                id: statementId,
            }, refetchQueries: [
                'RoomStatements',
                'GetStatement',
                'RoomsByStatementStatus',
            ],
        });
    }

    return {
        deleteStatement, loading,
    };
}

export function useCreateStatement() {
    const [mutateCreateStatement, {loading: createLoading}] = useMutation(MUTATION_CREATE_STATEMENT);

    async function createStatement(values) {
        await mutateCreateStatement({
            variables: {
                newStatement: values,
            },
            refetchQueries: [
                'RoomStatements',
                'GetStatement',
                'RoomsByStatementStatus',
            ],
        });
    }

    return {
        createStatement, createLoading,
    };
}