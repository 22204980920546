import {gql, useMutation} from '@apollo/client';

export const MUTATION_DELETE_TRANSACTION = gql`
    mutation DeleteTransaction($id: Int!) {
        deleteTransaction(id: $id) {
            ok
            __typename
        }
    }
`;

export function useDeleteTransaction() {
    const [mutateDeleteTransaction, {loading}] = useMutation(MUTATION_DELETE_TRANSACTION, {
        refetchQueries: [
            'GetTransactionsByDate',
        ],
    });

    async function deleteTransaction(id) {
        await mutateDeleteTransaction({
            variables: {
                id,
            },
        });
    }

    return {
        deleteTransaction,
        loading,
    };
}