import { makeStyles } from "@mui/styles";

export const useRepresentative = makeStyles((theme) => ({
    box: {
        padding: 0,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FFFFFF",
        // height: "calc(100% + 16px)",
    },
    gridContainer: {
        margin: "0px",
        "& .MuiGrid-root": {
            padding: "0",
        },
        height: "98%",
        width: "100%",
    },
    gridTable: {
        backgroundColor: "#FFFFFF",
        height: "100%",
        width: "100%",
        borderRadius: "5px",
    },
    gridItemTable: {
        backgroundColor: "#FFFFFF",
        borderRadius: "5px",
        height: "calc(100% - 50px)",
    },
    gridItemPaging: {
        height: "50px",
    },
}));
