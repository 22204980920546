import React, { useState, useEffect, memo } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import ButtonHome from "../../../components/ButtonHome/ButtonHome";
import BoxNumber from "../../../components/BoxNumber/BoxNumber";
import EmptyIcon from "../../../assets/Icons/EmptyIcon";
import ModalUpdate from "../ModalUpdate/ModalUpdate";
import { useTable } from "./useTable";
import { useDeleteTenant } from "../../../graphql-client/Tenant/mutation";
import {
    validateNullRoomName,
    validateNullAddress,
    validateNullIdNumber,
    validateNullIdDate,
} from "../../../useCase/Tenant/TableUseCase";
import { saveState, loadState } from "../../../util/LocalStateSave";
import { checkIncludeArray, removeItems } from "../../../util/ArrayHandle.js";
import { tenantText } from "../../../constants/Constants";
import { useLayoutEffect } from "react";

const EnhancedTableHead = memo(({ onSelectAllClick, rowCount, headCells }) => {
    const classes = useTable();
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        checked={rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "header",
                        }}
                        className={classes.checkBox}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        className={classes.headCell}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
});

const EnhancedTableToolbar = memo(({ numSelected, removed, setOpen }) => {
    const classes = useTable();
    const handleOpen = () => {
        setOpen(true);
        return true;
    };
    return (
        <Toolbar className={classes.toolbar}>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                }}
            >
                <BoxNumber number={numSelected} />
                <Typography
                    className={classes.typography}
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} đã chọn
                </Typography>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton
                            className={classes.iconButton}
                            onClick={removed}
                        >
                            <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Box>
            <Box style={{ width: "15%" }}>
                <ButtonHome
                    text={tenantText.tenantList.addButton}
                    onClick={handleOpen}
                />
            </Box>
        </Toolbar>
    );
});

const EnhancedTable = ({
    headCells,
    rowsData,
    page,
    size,
    status,
    searchText,
    setNotify,
    setConfirmDialog,
    setPage,
    setOpen,
}) => {
    const classes = useTable();
    const [selected, setSelected] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const { mutateDeleteTenant, errorDeleteTenant, dataDeleteTenant } =
        useDeleteTenant();
    const [openUpdate, setOpenUpdate] = useState(false);

    const handleOpen = (id) => {
        setOpenUpdate(true);
        setSelectedItem(id);
    };

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setSelectedItem(null);
    };

    useLayoutEffect(() => {
        setSelected(loadState("selected"));
    }, []);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rowsData.map((n) => n.id);
            const uniqueSelected = [...new Set(selected.concat(newSelected))];
            saveState(uniqueSelected, "selected");
            setSelected(uniqueSelected);
            return;
        }

        const spliceSelected = removeItems(
            rowsData.map((n) => n.id),
            loadState("selected")
        );
        saveState(spliceSelected, "selected");
        setSelected(spliceSelected);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        saveState(newSelected, "selected");
        setSelected(loadState("selected"));
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleRemoved = () => {
        setConfirmDialog({
            isOpen: true,
            title: tenantText.confirmDialog.title,
            subTitle: tenantText.confirmDialog.subTitle,
            onConfirm: () => {
                let listRemove = selected.map((element) => parseInt(element));
                listRemove.forEach((element) => {
                    mutateDeleteTenant(
                        { id: element },
                        page,
                        size,
                        status,
                        searchText
                    );
                });
                saveState([], "selected");
                setSelected([]);
                setConfirmDialog({
                    isOpen: false,
                    title: "",
                    subTitle: "",
                });
            },
        });
    };

    useEffect(() => {
        if (errorDeleteTenant)
            setNotify({
                isOpen: true,
                title: tenantText.notify.errorDeleteTenant,
                message: errorDeleteTenant.message,
                type: "error",
            });
    }, [errorDeleteTenant, setNotify]);

    useEffect(() => {
        if (dataDeleteTenant) setNotify({ ...tenantText.notify.delete });
    }, [dataDeleteTenant, setNotify]);

    useEffect(() => {
        if (rowsData.length === 0 && page !== 1) {
            let lastPage = page - 1;
            setPage(lastPage);

            setSelected(loadState("selected"));
        }
    }, [rowsData, setPage, page, selected]);

    return (
        <>
            <Paper sx={{ width: "100%", mb: 2 }} className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    selectedList={selected}
                    removed={handleRemoved}
                    setOpen={setOpen}
                />
                <Divider />
                {rowsData.length === 0 ? (
                    <>
                        <Box className={classes.emptyData}>
                            <EmptyIcon />
                        </Box>
                    </>
                ) : (
                    <>
                        <TableContainer className={classes.tableContainer}>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-label="sticky table"
                                stickyHeader={true}
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    onSelectAllClick={handleSelectAllClick}
                                    rowCount={checkIncludeArray(
                                        rowsData.map((n) => n.id),
                                        loadState("selected")
                                    )}
                                    headCells={headCells}
                                />
                                <TableBody>
                                    {rowsData.map((row, index) => {
                                        const isItemSelected = isSelected(
                                            row.id
                                        );
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                                className={classes.tableRow}
                                            >
                                                <TableCell
                                                    padding="checkbox"
                                                    width="5%"
                                                >
                                                    <Checkbox
                                                        onClick={(event) =>
                                                            handleClick(
                                                                event,
                                                                row.id
                                                            )
                                                        }
                                                        className={
                                                            classes.checkBox
                                                        }
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    width="18%"
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    onClick={() =>
                                                        handleOpen(row.id)
                                                    }
                                                    className={
                                                        classes.tableCell
                                                    }
                                                >
                                                    {row.fullName}
                                                </TableCell>
                                                <TableCell
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    width="18%"
                                                    onClick={() =>
                                                        handleOpen(row.id)
                                                    }
                                                >
                                                    {validateNullRoomName(
                                                        row.room
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    width="12%"
                                                    onClick={() =>
                                                        handleOpen(row.id)
                                                    }
                                                >
                                                    {row.phoneNumber}
                                                </TableCell>
                                                <TableCell
                                                    width="12%"
                                                    onClick={() =>
                                                        handleOpen(row.id)
                                                    }
                                                >
                                                    {validateNullIdNumber(
                                                        row.idNumber
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    width="11%"
                                                    onClick={() =>
                                                        handleOpen(row.id)
                                                    }
                                                >
                                                    {validateNullIdDate(
                                                        row.idIssueDate
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    width="29%"
                                                    className={
                                                        classes.tableCell
                                                    }
                                                    onClick={() =>
                                                        handleOpen(row.id)
                                                    }
                                                >
                                                    {validateNullAddress(
                                                        row.address
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </Paper>
            <ModalUpdate
                closeAdd={handleCloseUpdate}
                openAdd={openUpdate}
                tenantId={selectedItem}
                page={page}
                size={size}
                status={status}
                searchText={searchText}
            ></ModalUpdate>
        </>
    );
};

export default memo(EnhancedTable);
