import Typography from 'antd/es/typography/Typography';
import React from 'react';
import {formatNumber} from '../../util/Number';

export function AmountComponent({value}) {
    if (value >= 0) {
        return <Typography.Text type={'success'}>{formatNumber(value)}</Typography.Text>;
    } else {
        return <Typography.Text type={'warning'}>{formatNumber(value)}</Typography.Text>;
    }
}