import { memo } from 'react'
import styles from "./AuthLayout.module.css";
import Header from "../../components/Header/Header";
import { Outlet } from "react-router";

import background from "../../assets/background-login-register.jpg";

const AuthLayout = ({ children }) => {
    return (
        <>
            <img
                className={styles.background}
                src={background}
                alt="background"
            />
            <Header />
            {children}
            <Outlet />
        </>
    );
};

export default memo(AuthLayout);
