import dayjs from 'dayjs';

const transform = (obj, predicate) => {
    return Object.keys(obj).reduce((memo, key) => {
        if (predicate(obj[key], key)) {
            memo[key] = obj[key];
        }
        return memo;
    }, {});
};

function isDateType(startDate) {
    return dayjs.isDayjs(startDate);
}

function isFileUploadType(obj) {
    if (typeof obj === 'object' && obj) {
        if ('uid' in obj && 'status' in obj){
            return true
        }
    }
    return false;
}

export function normalizeRequestObject(obj) {
    if (typeof obj === 'object') {
        // iterating over the object using for..in
        for (var keys in obj) {
            //checking if the current value is an object itself
            if (isDateType(obj[keys])) {
                obj[keys] = obj[keys].format('YYYY-MM-DD');
            } else if (typeof obj[keys] === 'object') {
                if (isFileUploadType(obj[keys])) {
                    obj[keys] = obj[keys].url || obj[keys].response?.result.fileId;
                } else {
                    // if so then again calling the same function
                    normalizeRequestObject(obj[keys]);
                }

            }
        }
    }
    return obj;
}

export const omit = (obj, items) => transform(obj, (value, key) => !items.includes(key));

export function convertInt(value) {
    if (value) {
        return Number.parseInt(value);
    }
    return value;

}
