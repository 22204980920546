import { makeStyles } from "@mui/styles";
export const useUploadImage = makeStyles({
    label: {
        marginBottom: "8px",
    },
    closeButton: {
        height: 0,
        width: "10%",
        boxShadow: "none",
        padding: 0,
        top: "-37%",
        left: "-12%",
        position: "relative",
    },
    iconClose: {
        height: "20px",
        width: "20px",
    },
});
