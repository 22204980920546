import React, { memo } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { CardInfo } from "./useCardInfo";

export default memo(function ReviewCard({ title, quantity, path, imgAva }) {
    const classes = CardInfo();
    return (
        <Card className={classes.reviewCard}>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Avatar
                        className={imgAva ? classes.imgAva : classes.avatar}
                        aria-label="recipe"
                        src={path}
                    />
                }
                title={title}
                subheader={quantity}
            />
        </Card>
    );
})
