import {gql} from '@apollo/client';

export const QUERY_ROOMS_BY_INVOICE_STATUS = gql`
    query GetRoomsByInvoiceStatus($startDate: Date, $endDate: Date, $motelId: Int, $hasInvoice: Boolean) {
        roomsByInvoiceStatus(startDate: $startDate, endDate: $endDate, motelId: $motelId, hasInvoice: $hasInvoice){
            id
            name
            representativeTenant{
                id
                fullName
            }
            activeContractId
            motel{
                id
                name
            }
        }
    }
`;
export const QUERY_GET_NEW_ROOM_INVOICE = gql`
    query GetNewInvoice($invoiceDate: Date!, $startDate: Date, $endDate: Date, $roomContractId: Int!, $roomStatementId: Int) {
        getNewRoomInvoice(invoiceDate:$invoiceDate, startDate: $startDate, endDate: $endDate, roomContractId: $roomContractId, roomStatementId: $roomStatementId) {
            date
            startPaidDate
            endPaidDate
            roomContract{
                id
                startPaidDate
            }
            isFirstInvoice
            isFirstCycleMonthInvoice
            roomPrice
            roomPriceStartDate
            roomPriceEndDate
            roomPriceFactor
            contractDeposit
            prepaidDeposit

            servicePriceStartDate
            servicePriceEndDate
            servicePriceFactor

            serviceItems {
                service {
                    id
                    name
                    fee
                    feeType
                    unit
                }
                lastIndex
                index
                quantity
                price
                isActive
                imageUrl
            }
            totalDays
            realDays
            contractDeposit
            prepaidDeposit
            refundDeposit

        }
    }

`;
export const QUERY_GET_ROOM_INVOICE = gql`
    query GetInvoice($invoiceId: Int!) {
        getRoomInvoice(id: $invoiceId) {
            date
            startPaidDate
            endPaidDate
            previewUrl
            note
            roomContract {
                id
                startPaidDate
            }
            room {
                id
                name
                motel {
                    id
                    name
                }
            }

            isFirstCycleMonthInvoice
            roomPrice
            roomPriceStartDate
            roomPriceEndDate
            roomPriceFactor
            contractDeposit
            prepaidDeposit
            servicePriceStartDate
            servicePriceEndDate
            servicePriceFactor
            invoiceItems {
                service {
                    id
                    name
                    fee
                    feeType
                    unit
                }
                lastIndex
                index
                totalUnit
                price
                isActive
                imageUrl
                total
            }
            contractDeposit
            prepaidDeposit
            refundDeposit
            total
        }
    }

`;

export const QUERY_GET_LITE_ROOM_INVOICE_WITH_TENANTS = gql`
    query GetInvoice($invoiceId: Int!) {
        getRoomInvoice(id: $invoiceId) {
            id
            roomContract {
                id
                startPaidDate
                tenants {
                    id
                    fullName
                }
            }
            total
        }
    }

`;
export const QUERY_ROOM_STATEMENTS_LITE = gql`
    query RoomStatements($roomId: Int!, $endDate: Date!, $limit: Int) {
        roomStatements(roomId: $roomId, endDate: $endDate, limit: $limit){
            id
            statementAt
        }
    }
`;
export const QUERY_ROOM_INVOICES = gql`
    query RoomInvoices($startDate: Date!, $endDate: Date!, $motelId: Int, $status: InvoiceStatus) {
        roomInvoices(startDate: $startDate, endDate: $endDate, motelId: $motelId, status: $status) {
            id
            type
            status
            totalServicePrice
            totalUnpaid
            total
            totalPaid
            roomPrice
            roomContract {
                representativeTenant {
                    id
                    fullName
                }
            }
            room {
                id
                name
                motel {
                    id
                    name
                }
            }
            createdAt
            creator{
                id
                fullName
            }
        }
    }

`;