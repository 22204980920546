import dayjs from 'dayjs';
import moment from 'moment';

export function formatDate(dt) {
 if (dt) {
  return moment(dt).format('DD-MM-YYYY');
 }
 return '';
}

export function formatDateTime(dt) {
    if (dt) {
        return moment(dt).format('DD-MM-YYYY HH:MM:SS');
    }
    return '';
}

export function parseDate(dt) {
    if (dt) {
        return dayjs(dt, 'YYYY-MM-DD');
    }
    return null;
}