import { gql } from "@apollo/client";

const MY_MOTELS = gql`
    query {
        myMotels {
            id
            name
            address
            city
            district
            rentFee
            totalRoom
        }
    }
`;

const GET_MOTELS = gql`
    query getMotel($motelId: Int!) {
        getMotel(motelId: $motelId) {
            id
            name
            description
            address
            city
            district
            openTime
            closeTime
            invoiceNote
            note
            owner {
                id
                avatar
            }
            totalFloor
            totalTenant
            totalRoom
            rentFee
            managers {
                id
                avatar
                fullName
            }
            services {
                id
                name
                icon
                feeType
                fee
                unit
            }
            allSystemServices {
                isActive
                service {
                    id
                    name
                }
            }
            endDayOfMonth
            dayLeaveNumber
            startPaidDay
            endPaidDay
        }
    }
`;

const CURRENT_ENTERPRISE_USERS = gql`
    query {
        currentEnterpriseUsers {
            id
            fullName
            avatar
        }
    }
`;

const SYSTEM_SERVICES = gql`
    query {
        systemServices(serviceType: MOTEL) {
            id
            name
            type
        }
    }
`;

export { MY_MOTELS, GET_MOTELS, CURRENT_ENTERPRISE_USERS, SYSTEM_SERVICES };
