import { makeStyles } from "@mui/styles";

export const useInfoRoom = makeStyles({
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.06)",
        // height: "10%",
        width: "100%",
    },
    cardHeader: {
        width: "75%",
        "& .MuiCardHeader-subheader": {
            color: "#c2cfe0",
            fontWeight: "700",
            fontSize: "14px",
        },
        "@media screen and (max-width: 1280px)": {
            padding: "8px",
        },
    },
    nameRoom: {
        color: "rgba(25, 42, 62, 1)",
        fontWeight: "700",
        wordWrap: "break-word",
        width: "60%",
    },
    statusRoom: {
        color: "rgba(4, 195, 92, 1)",
        fontWeight: "400",
        fontSize: "14px",
    },
});
