import { makeStyles } from "@mui/styles";
export const useModalAdd = makeStyles({
    container: {
        padding: "30px 16px 30px 16px",

        "&> .MuiGrid-item": {
            padding: 0,
        },
    },
});
