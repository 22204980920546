import { makeStyles } from "@mui/styles";

export const useRoom = makeStyles({
    container: {
        overflow: "hidden",
        height: "calc(100% + 16px)",
        // paddingBottom: "1%",
        // "@media screen and (max-width: 1280px)": {
        //     paddingBottom: "3%",
        // },
    },
    containerRoom: {
        backgroundColor: "white",
        height: "100%",
        boxShadow: "0px 6px 18px 0px #0000000f",
        borderRadius: "5px",
        "& > .MuiFormControl-root > .MuiInputBase-root": {
            borderRadius: "0px",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
        },
    },
    containerDetail: {
        height: "100%",
        overflow: "scroll",
    },
    containerList: {
        height: "calc(100% - 114px)",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // overflow: "auto",
        // "@media screen and (max-width: 1280px)": {
        //     height: "calc(100 - %",
        // },
        // "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
        //     height: "82.5%",
        // },
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        padding: "0 5%",
        height: "64px",
        // height: "calc(100% - 50px - 86.6%)",
        // "@media screen and (max-width: 1280px)": {
        //     height: "calc(100% - 50px - 75%)",
        // },
        // "@media screen and (min-width: 1281px) and (max-width: 1919px)": {
        //     height: "calc(100% - 50px - 82.5%)",
        // },
    },
});
