import { makeStyles } from "@mui/styles";
export const useModalUpdate = makeStyles((theme) => ({
    form: {
        [theme.breakpoints.down("md")]: {
            width: "600px",
            overflow: "auto",
        },
        [theme.breakpoints.between("md", "lg")]: {
            width: "800px",
            overflow: "auto",
        },
        [theme.breakpoints.between("lg", "xl")]: {
            width: "1000px",
            overflow: "auto",
        },
        [theme.breakpoints.up("xl")]: {
            width: "1300px",
            overflow: "auto",
        },
    },
    container: {
        padding: "30px 40px 30px 20px",
        "&> .MuiGrid-item": {
            padding: 0,
        },
    },
    button: {
        marginTop: "30px",
    },
    label: {
        marginBottom: "11px",
        fontSize: "14px",
        lineHeight: "16px",
    },
    UploadImageContainer: {
        width: "100%",
        height: "107px",
        padding: "15px 15px 0px 15px",
        border: "1px solid #e8e8e8",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "row",
        overflowX: "auto",
        overflowY: "hidden",

        "&::-webkit-scrollbar": {
            height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#979ea8",
            borderRadius: "10px",
        },
    },
}));
