const convertFeeType = (service) => {
    switch (service.feeType) {
        case "person":
            return {
                ...service,
                feeTypeConvert: "Người",
                feeConvert: `${
                    service.fee === 0
                        ? "Miễn phí"
                        : `${new Intl.NumberFormat().format(
                              service.fee
                          )}/người${service.monthlyBase ? "/tháng" : ""}`
                }`,
            };
        case "room":
            return {
                ...service,
                feeTypeConvert: "Phòng",
                feeConvert: `${
                    service.fee === 0
                        ? "Miễn phí"
                        : `${new Intl.NumberFormat().format(
                              service.fee
                          )}/phòng${service.monthlyBase ? "/tháng" : ""}`
                }`,
            };
        case "quantity":
            return {
                ...service,
                feeTypeConvert: "Lần",
                feeConvert: `${
                    service.fee === 0
                        ? "Miễn phí"
                        : `${new Intl.NumberFormat().format(service.fee)}/lần${
                              service.monthlyBase ? "/tháng" : ""
                          }`
                }`,
            };
        case "unit":
            return {
                ...service,
                feeTypeConvert: service.unit,
                feeConvert: `${
                    service.fee === 0
                        ? "Miễn phí"
                        : `${new Intl.NumberFormat().format(service.fee)}/${
                              service.unit
                          }${service.monthlyBase ? "/tháng" : ""}`
                }`,
            };
        default:
            return {
                ...service,
                feeTypeConvert: service.unit,
                feeConvert: `${
                    service.fee === 0
                        ? "Miễn phí"
                        : `${new Intl.NumberFormat().format(service.fee)}/${
                              service.unit
                          }${service.monthlyBase ? "/tháng" : ""}`
                }`,
            };
    }
};

export function convertServicesData(services) {
    const dataConvert = [];
    services.forEach(function (service) {
        dataConvert.push(convertFeeType(service));
    });
    return dataConvert;
}
