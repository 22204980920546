import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {CookiesProvider} from 'react-cookie';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import App from './App';
import './index.css';
import i18n from './locales/i18n';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<React.StrictMode>
    <CookiesProvider>
        <I18nextProvider i18n={i18n}>
            <App/>
        </I18nextProvider>
    </CookiesProvider>
</React.StrictMode>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
