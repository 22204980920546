import React from 'react';
import {useTranslation} from 'react-i18next';
import {SearchableSelect} from '../SearchableSelect';

export function SelectPaymentMethod({...rest}) {
    const {t} = useTranslation();
    const paymentOptions = [
        {
            label: t('payment.cash'), value: 'cash',
        }, {
            label: t('payment.bankTransfer'), value: 'bank_transfer',
        }];

    return <SearchableSelect options={paymentOptions} {...rest}/>;
}