import moment from "moment";
import { representativesText } from "../../constants/Constants";
import { validatePhoneNumber } from "../../util/ValidatePhoneNumber";
import { validateNumber } from "../../util/ValidateNumber";

const INVALID_DATE = "Invalid date";
const FULL_NAME_LIMIT = 200;
const PHONE_NUMBER_LIMIT = 15;
const ID_NUMBER_LIMIT = 20;
const TODAY = moment();

function validateNullBirthday(birthday) {
    if (birthday === null || birthday.length === 0) return "Chưa có";
    else return moment(birthday).format("DD-MM-YYYY");
}
function validateNullIdDate(idDate, idAt) {
    if (idDate === null || idDate.length === 0) return "Chưa có";
    else return `${moment(idDate).format("DD-MM-YYYY")} tại ${idAt}`;
}

function validateAddRepresentative(
    fullName,
    phoneNumber,
    birthday,
    idNumber,
    idDate,
    idAt,
    address
) {
    let msg = {};
    if (!fullName) {
        msg.fullName = representativesText.inputName.alertBlank;
    }

    if (fullName.length > FULL_NAME_LIMIT) {
        msg.fullName = representativesText.inputName.alertExceed;
    }

    if (!validatePhoneNumber(phoneNumber)) {
        msg.phoneNumber = representativesText.inputPhoneNumber.alertNotNumber;
    }

    if (!phoneNumber) {
        msg.phoneNumber = representativesText.inputPhoneNumber.alertBlank;
    }

    if (phoneNumber.length > PHONE_NUMBER_LIMIT) {
        msg.phoneNumber = representativesText.inputPhoneNumber.alertExceed;
    }

    if (birthday) {
        if (birthday === INVALID_DATE) {
            msg.birthday = representativesText.inputBirthday.invalidDate;
        } else if (moment(birthday).isAfter(TODAY)) {
            msg.birthday = representativesText.inputBirthday.afterDate;
        }
    } else {
        msg.birthday = representativesText.inputBirthday.alertBlank;
    }

    if (!idNumber) {
        msg.idNumber = representativesText.inputIdNumber.alertBlank;
    }

    if (idNumber.length > ID_NUMBER_LIMIT) {
        msg.idNumber = representativesText.inputIdNumber.alertExceed;
    }
    if (!validateNumber(idNumber)) {
        msg.idNumber = representativesText.inputIdNumber.alertNotNumber;
    }

    if (idDate) {
        if (idDate === INVALID_DATE) {
            msg.idDate = representativesText.inputIdDate.invalidDate;
        } else if (moment(idDate).isAfter(TODAY)) {
            msg.idDate = representativesText.inputIdDate.afterDate;
        }
    } else {
        msg.idDate = representativesText.inputIdDate.alertBlank;
    }

    if (!idAt) {
        msg.idAt = representativesText.textAreaIdAt.alertBlank;
    }

    if (idAt.length > 2000) {
        msg.idAt = representativesText.textAreaIdAt.alertExceed;
    }

    if (!address) {
        msg.address = representativesText.textAreaAddress.alertBlank;
    }

    if (address.length > 2000) {
        msg.address = representativesText.textAreaAddress.alertExceed;
    }

    if (Object.keys(msg).length > 0) return { isValid: false, msg };
    return { isValid: true, msg };
}
export { validateNullBirthday, validateNullIdDate, validateAddRepresentative };
