import { makeStyles } from "@mui/styles";

export const useConfirmDialog = makeStyles({
    dialog: {
        "& .MuiPaper-root": {
            height: "194px",
            width: "456px",
        },
    },
    title: {
        color: "rgba(84, 89, 94, 1)",
        fontSize: "20px",
        fontWeight: 600,
        marginBottom: "8px",
    },
    subTitle: {
        color: "rgba(84, 89, 94, 0.6)",
        fontSize: "14px",
    },
    dialogTitle: {
        textAlign: "center",
    },
    dialogContent: {
        textAlign: "center",
        paddingTop: "18px",
    },
    dialogAction: {
        padding: "18px 40px 18px 40px",
        justifyContent: "space-between",
        alignItems: "center",
    },
    titleIcon: {
        color: "#F7685B",
        "&:hover": {
            cursor: "default",
        },
        "& .MuiSvgIcon-root": {
            fontSize: "4rem",
        },
    },
    buttonYes: {
        backgroundColor: "#F7685B",
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: 500,
        color: "#fff",
        padding: "14px 20px",
        height: "44px",
        width: "180px",
        "&:hover": { backgroundColor: "#F7685B" },
    },
    buttonNo: {
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: 500,
        border: "1px solid #4F4F4F",
        color: "rgba(79, 79, 79, 1)",
        padding: "14px 20px",
        height: "44px",
        width: "180px",
        "&:hover": { backgroundColor: "#FFFFFF" },
    },
});
