import { makeStyles } from "@mui/styles";

export const useListRoom = makeStyles({
    container: {
        height: "100%",
        backgroundColor: "#FFFFFF",
        width: "100%",
    },
    itemRoom: {
        width: "100%",
    },
    floor: {
        backgroundColor: "#E5FDF0",
        height: "35px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "16px",
        color: "#04C35C",
        fontWeight: "700",
        fontSize: "20px",
    },
    detailRoom: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "93%",
    },
    detailRoomTop: {
        display: "flex",
        width: "100%",
        alignItems: "baseline",
        justifyContent: "space-between",
    },
    detailRoomBottom: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "10px",
        gap: "2px",
        "& > p": {
            fontSize: "14px",
            color: "rgba(144, 160, 183, 1)",
        },
    },
    nameRoom: {
        width: "40%",
        minWidth: 0,
        "& .MuiTypography-root": {
            fontSize: "14px",
            color: "rgba(51, 77, 110, 1)",
            fontWeight: "600",
        },
    },
    price: {
        width: "35%",
        fontSize: "12px",
        color: "#04C35C",
    },
});
