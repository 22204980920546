import Pagination from "@mui/material/Pagination";
import { usePaging } from "./usePaging";
import { memo } from "react";
const Paging = ({ totalPages, currentPage, onChangePage }) => {
    const classes = usePaging();
    return (
        <Pagination
            className={classes.pagination}
            count={totalPages}
            shape="rounded"
            variant="outlined"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={onChangePage}
        />
    );
};
export default memo(Paging);
