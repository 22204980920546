import React, { useState, useEffect } from "react";
import ModalContainer from "../../../../components/ModalContainer/ModalContainer";
import { representativesText, today } from "../../../../constants/Constants";
import styles from "./ModalAdd.module.css";
import ButtonHome from "../../../../components/ButtonHome/ButtonHome";
import { useModalAdd } from "./useModalAdd";
import Notification from "../../../../components/Notification/Notification";
import Input from "../../../../components/Input/Input";
import TextArea from "../../../../components/TextArea/TextArea";
import { useAddRepresentative } from "../../../../graphql-client/Representatives/mutation";
import moment from "moment";

import Grid from "@mui/material/Grid";
import DatePicker from "../../../../components/DatePicker/DatePicker";
import { validateAddRepresentative } from "../../../../useCase/Representative/useCaseRepresentative";

const ModalAdd = ({ openAdd, closeAdd }) => {
    const classes = useModalAdd();
    const { mutateAddRepresentative } = useAddRepresentative();
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [address, setAddress] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [idDate, setIdDate] = useState(null);
    const [idAt, setIdAt] = useState("");
    const [validationMessage, setValidationMessage] = useState({});

    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    useEffect(() => {
        if (openAdd === true) {
            clearState();
        }
    }, [openAdd]);

    const clearState = () => {
        setValidationMessage({});
        setFullName("");
        setPhoneNumber("");
        setBirthday(null);
        setAddress("");
        setIdNumber("");
        setIdDate(null);
        setIdAt("");
    };

    const handleAddContract = async (e) => {
        e.preventDefault();

        const isValid = validateAddRepresentative(
            fullName,
            phoneNumber,
            birthday,
            idNumber,
            idDate,
            idAt,
            address
        );

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            await mutateAddRepresentative({
                req: {
                    fullName: fullName,
                    phoneNumber: phoneNumber,
                    birthday: birthday,
                    idNumber: idNumber,
                    idDate: idDate,
                    idAt: idAt,
                    address: address,
                },
            });
            setNotify({
                ...representativesText.notify.add,
            });
            closeAdd();
        }
    };
    const handleContinueAdd = async (e) => {
        e.preventDefault();
        const isValid = validateAddRepresentative(
            fullName,
            phoneNumber,
            birthday,
            idNumber,
            idDate,
            idAt,
            address
        );

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            await mutateAddRepresentative({
                req: {
                    fullName: fullName,
                    phoneNumber: phoneNumber,
                    birthday: birthday,
                    idNumber: idNumber,
                    idDate: idDate,
                    idAt: idAt,
                    address: address,
                },
            });
            setNotify({
                ...representativesText.notify.add,
            });
            clearState();
        }
    };

    return (
        <>
            <ModalContainer
                closeModal={closeAdd}
                openModal={openAdd}
                title={representativesText.modalRepresentatives.titleAdd}
            >
                <form onSubmit={handleAddContract} className={styles.form}>
                    <Grid container className={classes.container}>
                        <Grid item xs={11 / 3}>
                            <Input
                                text={representativesText.inputName.label}
                                type={representativesText.inputName.type}
                                placeholder={
                                    representativesText.inputName.placeholder
                                }
                                star={true}
                                changed={(e) => setFullName(e.target.value)}
                                error={validationMessage.fullName}
                                value={fullName}
                            />
                        </Grid>

                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={11 / 3}>
                            <Input
                                text={
                                    representativesText.inputPhoneNumber.label
                                }
                                type={representativesText.inputPhoneNumber.type}
                                placeholder={
                                    representativesText.inputPhoneNumber
                                        .placeholder
                                }
                                star={true}
                                changed={(e) => setPhoneNumber(e.target.value)}
                                error={validationMessage.phoneNumber}
                                value={phoneNumber}
                            />
                        </Grid>

                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={11 / 3}>
                            <DatePicker
                                label={representativesText.inputBirthday.label}
                                value={birthday}
                                maxDate={new Date(today)}
                                changed={(valueDate) => {
                                    if (valueDate) {
                                        setBirthday(
                                            moment(valueDate).format(
                                                "YYYY-MM-DD"
                                            )
                                        );
                                    } else {
                                        setBirthday(valueDate);
                                    }
                                }}
                                error={validationMessage.birthday}
                                star={true}
                            />
                        </Grid>

                        <Grid item xs={11 / 3}>
                            <Input
                                text={representativesText.inputIdNumber.label}
                                type={representativesText.inputIdNumber.type}
                                placeholder={
                                    representativesText.inputIdNumber
                                        .placeholder
                                }
                                star={true}
                                changed={(e) => setIdNumber(e.target.value)}
                                error={validationMessage.idNumber}
                                value={idNumber}
                            />
                        </Grid>

                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={11 / 3}>
                            <DatePicker
                                label={representativesText.inputIdDate.label}
                                value={idDate}
                                maxDate={new Date(today)}
                                changed={(valueDate) => {
                                    if (valueDate) {
                                        setIdDate(
                                            moment(valueDate).format(
                                                "YYYY-MM-DD"
                                            )
                                        );
                                    } else {
                                        setIdDate(valueDate);
                                    }
                                }}
                                error={validationMessage.idDate}
                                star={true}
                            />
                        </Grid>

                        <Grid item xs={25 / 6}></Grid>

                        <Grid item xs={5.75}>
                            <TextArea
                                label={representativesText.textAreaIdAt.label}
                                placeholder={
                                    representativesText.textAreaIdAt.placeholder
                                }
                                changed={(e) => setIdAt(e.target.value)}
                                error={validationMessage.idAt}
                                value={idAt}
                                star={true}
                            />
                        </Grid>

                        <Grid item xs={0.5}></Grid>

                        <Grid item xs={5.75}>
                            <TextArea
                                label={
                                    representativesText.textAreaAddress.label
                                }
                                placeholder={
                                    representativesText.textAreaAddress
                                        .placeholder
                                }
                                changed={(e) => setAddress(e.target.value)}
                                error={validationMessage.address}
                                value={address}
                                star={true}
                            />
                        </Grid>

                        <Grid item xs={8}></Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <ButtonHome
                                type="submit"
                                text={
                                    representativesText.modalRepresentatives
                                        .buttonContinueAdd
                                }
                                onClick={handleContinueAdd}
                            />
                            <ButtonHome
                                type="submit"
                                text={
                                    representativesText.modalRepresentatives
                                        .buttonAdd
                                }
                                onClick={handleAddContract}
                            />
                        </Grid>
                    </Grid>
                </form>
            </ModalContainer>
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
};

export default ModalAdd;
