import {gql, useMutation} from '@apollo/client';
import {GET_SERVICES} from './query';

const DELETE_SERVICE = gql`
    mutation deleteService($serviceId: Int!) {
        deleteService(serviceId: $serviceId) {
            ok
        }
    }
`;

export const MUTATION_CREATE_STATEMENT = gql`
    mutation addNewStatement($newStatement: AddRoomStatementReq){
        addRoomStatement(request: $newStatement){
            ok
        }
    }
`;

function useDeleteService() {
    const [mutateDeleteService, { data, loading, error }] =
        useMutation(DELETE_SERVICE);

    const handleDeleteService = async (variables) => {
        try {
            await mutateDeleteService({
                variables,
                refetchQueries: [{ query: GET_SERVICES }],
            });
            // return;
        } catch (error) {}
    };

    return {
        mutateDeleteService: handleDeleteService,
        dataDeleteService: data,
        loadingDeleteService: loading,
        errorDeleteService: error,
    };
}

const UPDATE_SERVICE = gql`
    mutation updateService($request: UpdateServiceReq!, $serviceId: Int!) {
        updateService(request: $request, serviceId: $serviceId) {
            ok
        }
    }
`;

function useUpdateService() {
    const [mutateUpdateService, { data, loading, error }] =
        useMutation(UPDATE_SERVICE);

    const handleUpdateService = async (variables) => {
        try {
            await mutateUpdateService({
                variables,
                refetchQueries: [{ query: GET_SERVICES }],
            });
        } catch (error) {}
    };

    return {
        mutateUpdateService: handleUpdateService,
        dataUpdateService: data,
        loadingUpdateService: loading,
        errorUpdateService: error,
    };
}

const ADD_SERVICE = gql`
    mutation AddService($request: AddServiceReq) {
        addService(request: $request) {
            ok
        }
    }
`;

function useAddService() {
    const [mutateAddService, { data, loading, error }] =
        useMutation(ADD_SERVICE);

    const handleAddService = async (variables) => {
        try {
            await mutateAddService({
                variables,
                refetchQueries: [{ query: GET_SERVICES }],
            });
        } catch (err) {
            console.log(err);
        }
    };
    return {
        mutateAddService: handleAddService,
        dataAddService: data,
        loadingAddService: loading,
        errorAddService: error,
    };
}

export { useDeleteService, useUpdateService, useAddService };
