import { makeStyles } from "@mui/styles";

export const useItemTenant = makeStyles({
    name: {
        fontWeight: "700",
    },
    phoneNumber: {
        color: "rgba(4, 195, 92, 1)",
        fontSize: "14px",
    },
});
