/* eslint-disable */
import React, { useState, useEffect, memo } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useQuery } from "@apollo/client";

import { useModalDetail } from "./useModalDetail";
import Input from "../../../components/Input/Input";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import ButtonSelectIcon from "../ButtonSelectIcon/ButtonSelectIcon";
import SelectInput from "../../../components/SelectInput/SelectInput";
import { GET_SERVICE } from "../../../graphql-client/Services/query";
import { useUpdateService } from "../../../graphql-client/Services/mutation";
import { feeTypeList } from "../../../constants/Constants";
import { serviceText } from "../../../constants/Constants";
import ButtonHome from "../../../components/ButtonHome/ButtonHome";
import { validateDetailService } from "../../../useCase/Service/DetailUseCase";
import TextArea from "../../../components/TextArea/TextArea";
import Notification from "../../../components/Notification/Notification";

export default memo(function ModalDetail({ openAdd, closeAdd, serviceId }) {
    const {
        loading: loadingServiceData,
        data: getServiceData,
        error: errorServiceError,
    } = useQuery(GET_SERVICE, {
        variables: {
            serviceId: Number(serviceId),
        },
        skip: serviceId === null,
        fetchPolicy: "no-cache",
    });
    const {
        mutateUpdateService,
        dataUpdateService,
        loadingUpdateService,
        errorUpdateService,
    } = useUpdateService();

    const [nameService, setNameService] = useState(" ");
    const [unit, setUnit] = useState("");
    const [fee, setFee] = useState(0);
    const [description, setDescription] = useState(" ");
    const [monthlyBase, setMonthlyBase] = useState();
    const [feeType, setFeeType] = useState("");
    const [icon, setIcon] = useState(" ");
    const [validationMessage, setValidationMessage] = useState({});
    const [disableInput, setDisableInput] = useState();
    const [disableCheckBox, setDisableCheckBox] = useState();
    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    const classes = useModalDetail();

    useEffect(() => {
        setValidationMessage({});
        if (getServiceData) {
            const { name, unit, fee, description, monthlyBase, feeType, icon } =
                getServiceData.getService;
            setNameService(name);
            setUnit(unit ? unit : " ");
            setFee(fee);
            setDescription(description);
            setMonthlyBase(monthlyBase);
            setFeeType(feeType);
            switch (feeType) {
                case "unit":
                    {
                        setDisableInput(false);
                        setDisableCheckBox(true);
                    }
                    break;

                case "person":
                    {
                        setDisableInput(true);
                        setDisableCheckBox(false);
                    }
                    break;
                case "room":
                    {
                        setDisableInput(true);
                        setDisableCheckBox(false);
                    }
                    break;
                case "quantity":
                    {
                        setDisableInput(true);
                        setDisableCheckBox(true);
                    }
                    break;

                case "other": {
                    setDisableInput(false);
                    setDisableCheckBox(false);
                }
            }
            setIcon(icon);
        }
    }, [getServiceData]);

    const handelSelectChanged = (selectedValue) => {
        setFeeType(selectedValue);
        setValidationMessage({});
        switch (selectedValue) {
            case "unit":
                {
                    setDisableInput(false);
                    setDisableCheckBox(true);
                    setMonthlyBase(false);
                    setUnit("");
                }
                break;

            case "person":
                {
                    setDisableInput(true);
                    setDisableCheckBox(false);
                    setMonthlyBase(true);
                    setUnit(" ");
                }
                break;
            case "room":
                {
                    setDisableInput(true);
                    setDisableCheckBox(false);
                    setMonthlyBase(true);
                    setUnit(" ");
                }
                break;
            case "quantity":
                {
                    setDisableInput(true);
                    setDisableCheckBox(true);
                    setMonthlyBase(false);
                    setUnit(" ");
                }
                break;

            case "other": {
                setDisableInput(false);
                setDisableCheckBox(false);
                setMonthlyBase(true);
                setUnit("");
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateDetailService(
            nameService,
            fee,
            unit,
            description
        );
        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            await mutateUpdateService({
                request: {
                    name: nameService,
                    unit: unit,
                    fee: +fee,
                    description: description,
                    monthlyBase: monthlyBase,
                    feeType: feeType,
                    icon: icon,
                },
                serviceId: serviceId,
            });
            setNotify({
                ...serviceText.notify.update,
            });
            closeAdd();
        }
    };

    return (
        <>
            {loadingUpdateService && <></>}
            {loadingServiceData ? (
                <></>
            ) : (
                <ModalContainer
                    closeModal={closeAdd}
                    openModal={openAdd}
                    title={serviceText.modalDetailAndUpdateService.title}
                >
                    <form onSubmit={handleSubmit}>
                        <Grid container className={classes.container}>
                            <Grid item xs={6}>
                                <Input
                                    text={serviceText.inputNameService.label}
                                    type={serviceText.inputNameService.type}
                                    placeholder={
                                        serviceText.inputNameService.placeholder
                                    }
                                    star={true}
                                    value={nameService}
                                    changed={(e) =>
                                        setNameService(e.target.value)
                                    }
                                    error={validationMessage.name}
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={5} className={classes.button}>
                                <ButtonSelectIcon
                                    text={serviceText.inputIconService.label}
                                    defaultValue={icon}
                                    changed={(e) => setIcon(e.target.src)}
                                    imgIcon={icon}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput
                                    label={serviceText.inputFeeType.label}
                                    star={true}
                                    data={feeTypeList}
                                    placeholder={
                                        serviceText.inputFeeType.placeholder
                                    }
                                    defaultValue={feeType}
                                    onSelectChange={(selectedValue) =>
                                        handelSelectChanged(selectedValue)
                                    }
                                    field="name"
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={5}>
                                <Input
                                    text={serviceText.inputUnit.label}
                                    type={serviceText.inputUnit.type}
                                    placeholder=""
                                    star={true}
                                    value={unit}
                                    changed={(e) => setUnit(e.target.value)}
                                    error={validationMessage.unit}
                                    isDisabled={disableInput}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    text={serviceText.inputFee.label}
                                    type={serviceText.inputFee.type}
                                    placeholder={
                                        serviceText.inputFee.placeholder
                                    }
                                    star={true}
                                    value={fee}
                                    changed={(e) => setFee(e.target.value)}
                                    error={validationMessage.fee}
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={5} className={classes.button}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) =>
                                                setMonthlyBase(e.target.checked)
                                            }
                                            disabled={disableCheckBox}
                                            className={classes.checkBox}
                                            checked={monthlyBase}
                                        />
                                    }
                                    label={serviceText.inputMonthlyBase.label}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextArea
                                    label={serviceText.inputDescription.label}
                                    placeholder={
                                        serviceText.inputDescription.placeholder
                                    }
                                    changed={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    error={validationMessage.description}
                                />
                            </Grid>
                            <Grid item xs={8}></Grid>
                            <Grid item xs={4}>
                                <ButtonHome
                                    type="submit"
                                    text={
                                        serviceText.modalDetailAndUpdateService
                                            .button
                                    }
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalContainer>
            )}
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
});
