import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useModalUpdate } from "./useModalUpdate";

import { GET_ROOMS, GET_TENANT } from "../../../graphql-client/Tenant/query";
import { useUpdateTenant } from "../../../graphql-client/Tenant/mutation";
import { handleUploadImage } from "../../../services/common";
import { resizeImage } from "../../../util/ResizeImage";
import { validateAddTenant } from "../../../useCase/Tenant/AddUseCase";
import Input from "../../../components/Input/Input";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import TextArea from "../../../components/TextArea/TextArea";
import {
    tenantText,
    validImageFile,
    today,
} from "../../../constants/Constants";
import ButtonHome from "../../../components/ButtonHome/ButtonHome";
import AutoCompleteInput from "../../../components/AutoCompleteInput/AutoCompleteInput";
import UploadImage from "../../../components/UploadImage/UploadImage";
import Notification from "../../../components/Notification/Notification";
import DatePicker from "../../../components/DatePicker/DatePicker";

export default function ModalUpdate({
    openAdd,
    closeAdd,
    tenantId,
    page,
    size,
    status,
    searchText,
}) {
    const classes = useModalUpdate();
    const { loading: loadingGetTenant, data: dataGetTenant } = useQuery(
        GET_TENANT,
        {
            variables: {
                id: Number(tenantId),
            },
            skip: tenantId === null,
            fetchPolicy: "no-cache",
        }
    );
    const { loading: loadingContracts, data: dataContracts } = useQuery(
        GET_ROOMS,
        { variables: { hasContract: true } }
    );
    const { mutateUpdateTenant } = useUpdateTenant();

    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [idNumber, setIdNumber] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [idIssueBy, setIdIssueBy] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [idIssueDate, setIdIssueDate] = useState(null);
    const [roomContract, setRoomContract] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);

    const [validationMessage, setValidationMessage] = useState({});
    const [dataSelected, setDataSelected] = useState(null);
    const [previews, setPreviews] = useState([]);
    const [raws, setRaws] = useState([]);

    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    useEffect(() => {
        clearState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAdd]);

    useEffect(() => {
        setValidationMessage({});
        if (dataGetTenant) {
            const {
                fullName,
                phoneNumber,
                idNumber,
                email,
                address,
                idIssueBy,
                roomContractId,
                birthday,
                idIssueDate,
                imageUrls,
            } = dataGetTenant.getTenant;
            setFullName(fullName);
            setPhoneNumber(phoneNumber);
            setIdNumber(idNumber);
            setEmail(email);
            setAddress(address);
            setIdIssueBy(idIssueBy);
            roomContractId
                ? setRoomContract(itemSelected(roomContractId)[0])
                : setRoomContract(null);
            setBirthday(birthday);
            setIdIssueDate(idIssueDate);
            if (imageUrls) setPreviews(imageUrls);
            if (imageUrls) setImageUrls(imageUrls);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGetTenant]);

    async function handleChange(e) {
        const rawFile = e.target.files[0];
        if (!validImageFile.includes(rawFile.type)) {
            setNotify({
                ...tenantText.notify.errorImageFormat,
            });
        } else {
            let resizedFile = rawFile;
            try {
                resizedFile = await resizeImage(
                    rawFile,
                    1920,
                    1080,
                    "JPEG",
                    50,
                    0,
                    "file"
                );
            } catch (err) {}
            setPreviews([...previews, URL.createObjectURL(resizedFile)]);
            setRaws([...raws, resizedFile]);
        }
    }

    function deleteFile(i) {
        const deletedPreview = previews.filter((item, index) => index !== i);
        setPreviews(deletedPreview);
        const deletedRaw = raws.filter(
            (item, index) => index !== i - imageUrls.length
        );
        setRaws(deletedRaw);
        const deletedImageUrls = imageUrls.filter((item, index) => index !== i);
        setImageUrls(deletedImageUrls);
    }

    const isValid = validateAddTenant(
        fullName,
        phoneNumber,
        idNumber,
        email,
        address,
        idIssueBy,
        birthday,
        idIssueDate
    );

    const handleUpdateTenant = async (e) => {
        e.preventDefault();

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            await updateTenantCases();
            setNotify({
                ...tenantText.notify.update,
            });
            clearState();
            closeAdd();
        }
    };

    const updateTenantCases = async () => {
        let imgArr = await handleUploadImage(raws);
        switch (roomContract === null) {
            case true:
                mutateUpdateTenant(
                    {
                        id: tenantId,
                        request: {
                            fullName: fullName,
                            phoneNumber: phoneNumber,
                            idNumber: idNumber,
                            email: email,
                            address: address,
                            imageUrls: imageUrls.concat(imgArr),
                            birthday: birthday,
                            idIssueDate: idIssueDate,
                            idIssueBy: idIssueBy,
                        },
                    },
                    page,
                    size,
                    status,
                    searchText
                );
                break;

            case false:
                mutateUpdateTenant(
                    {
                        id: tenantId,
                        request: {
                            fullName: fullName,
                            phoneNumber: phoneNumber,
                            idNumber: idNumber,
                            email: email,
                            address: address,
                            imageUrls: imageUrls.concat(imgArr),
                            roomContractId: roomContract.id,
                            birthday: birthday,
                            idIssueDate: idIssueDate,
                            idIssueBy: idIssueBy,
                        },
                    },
                    page,
                    size,
                    status,
                    searchText
                );
                break;

            default:
                break;
        }
    };
    useEffect(() => {
        if (dataContracts) {
            setDataSelected(
                dataContracts.myRooms.map((element) => {
                    return {
                        id: element.activeContractId,
                        name: element.name + " - " + element.motel.name,
                    };
                })
            );
        }
    }, [dataContracts]);

    function clearState() {
        setValidationMessage({});
        setFullName("");
        setPhoneNumber("");
        setIdNumber("");
        setEmail("");
        setAddress("");
        setIdIssueBy("");
        setRoomContract(null);
        setBirthday(null);
        setIdIssueDate(null);
        setPreviews([]);
        setRaws([]);
    }
    const itemSelected = (selectedId) => {
        return dataSelected.filter((element) => element.id === selectedId);
    };

    return (
        <>
            {loadingContracts || loadingGetTenant ? (
                <></>
            ) : (
                <ModalContainer
                    closeModal={closeAdd}
                    openModal={openAdd}
                    title={tenantText.modalTenant.titleUpdate}
                >
                    <form
                        className={classes.form}
                        onSubmit={handleUpdateTenant}
                    >
                        <Grid container className={classes.container}>
                            <Grid item xs={3.75}>
                                <Input
                                    text={tenantText.fullName.label}
                                    star={true}
                                    placeholder={
                                        tenantText.fullName.placeholder
                                    }
                                    error={validationMessage.fullName}
                                    changed={(e) => setFullName(e.target.value)}
                                    value={fullName}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2.75}>
                                <Input
                                    text={tenantText.phoneNumber.label}
                                    star={true}
                                    placeholder={
                                        tenantText.phoneNumber.placeholder
                                    }
                                    error={validationMessage.phoneNumber}
                                    changed={(e) =>
                                        setPhoneNumber(e.target.value)
                                    }
                                    value={phoneNumber}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2.75}>
                                <Input
                                    text={tenantText.email.label}
                                    placeholder={tenantText.email.placeholder}
                                    error={validationMessage.email}
                                    changed={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2}>
                                <DatePicker
                                    label={tenantText.birthday.label}
                                    maxDate={new Date(today)}
                                    value={birthday}
                                    changed={(valueDate) => {
                                        if (valueDate) {
                                            setBirthday(
                                                moment(valueDate).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );
                                        } else {
                                            setBirthday(valueDate);
                                        }
                                    }}
                                    error={validationMessage.birthday}
                                />
                            </Grid>

                            <Grid item xs={3.75}>
                                <AutoCompleteInput
                                    label={tenantText.roomForHire.label}
                                    placeholder={
                                        tenantText.roomForHire.placeholder
                                    }
                                    data={dataSelected}
                                    field="name"
                                    changed={(_event, selected) => {
                                        setRoomContract(selected);
                                    }}
                                    defaultValue={roomContract}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2.75}>
                                <Input
                                    text={tenantText.idNumber.label}
                                    placeholder={
                                        tenantText.idNumber.placeholder
                                    }
                                    error={validationMessage.idNumber}
                                    changed={(e) => setIdNumber(e.target.value)}
                                    value={idNumber}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2.75}>
                                <Input
                                    text={tenantText.idIssueBy.label}
                                    placeholder={
                                        tenantText.idIssueBy.placeholder
                                    }
                                    error={validationMessage.idIssueBy}
                                    changed={(e) =>
                                        setIdIssueBy(e.target.value)
                                    }
                                    value={idIssueBy}
                                />
                            </Grid>
                            <Grid item xs={0.25}></Grid>
                            <Grid item xs={2}>
                                <DatePicker
                                    label={tenantText.idIssueDate.label}
                                    maxDate={new Date(today)}
                                    value={idIssueDate}
                                    changed={(valueDate) => {
                                        if (valueDate) {
                                            setIdIssueDate(
                                                moment(valueDate).format(
                                                    "YYYY-MM-DD"
                                                )
                                            );
                                        } else {
                                            setIdIssueDate(valueDate);
                                        }
                                    }}
                                    error={validationMessage.idIssueDate}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.label}>
                                    {tenantText.idImages.label}
                                </Typography>
                                <Box className={classes.UploadImageContainer}>
                                    <UploadImage
                                        previews={previews}
                                        raws={raws}
                                        changed={handleChange}
                                        deleted={deleteFile}
                                        maxImage={
                                            process.env
                                                .REACT_APP_MAX_UPLOAD_ID_IMAGE
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={0.5}></Grid>
                            <Grid item xs={5.5}>
                                <TextArea
                                    label={tenantText.address.label}
                                    placeholder={tenantText.address.placeholder}
                                    error={validationMessage.address}
                                    changed={(e) => setAddress(e.target.value)}
                                    value={address}
                                />
                            </Grid>
                            <Grid item xs={10}></Grid>
                            <Grid item xs={2} className={classes.button}>
                                <ButtonHome
                                    type="submit"
                                    text={tenantText.modalTenant.buttonUpdate}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalContainer>
            )}
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
}
