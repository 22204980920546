import { Box, Divider, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import EmptyIcon from "../../../assets/Icons/EmptyIcon";
import RoomIcon from "../../../assets/Icons/RoomIcon";

import BasicChip from "../../../components/Chip/BasicChip";
import { useListRoom } from "./useListRoom";

const ListRoom = ({ data }) => {
    const classes = useListRoom();
    let result = data.map(({ floorNumber }) => floorNumber);

    let floorMotel = [...new Set(result)].sort((a, b) => {
        return a - b;
    });

    const filterRoomByFloorNumber = (floorNumber) => {
        return data.filter((item) => item.floorNumber === floorNumber);
    };

    return (
        <>
            {data.length > 0 ? (
                <Box
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                >
                    {floorMotel.map((value, index) => {
                        return (
                            <Box key={index} className={classes.itemRoom}>
                                <Typography className={classes.floor}>
                                    Tầng {value}
                                </Typography>
                                {filterRoomByFloorNumber(value).map(
                                    (value, index) => {
                                        return (
                                            <div key={index}>
                                                <ListItem
                                                    style={{
                                                        height: "auto",
                                                        padding: "8px",
                                                    }}
                                                >
                                                    <ListItemAvatar
                                                        style={{
                                                            minWidth: "0",
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        <RoomIcon fill="#c2cfe0" />
                                                    </ListItemAvatar>
                                                    <Box
                                                        className={
                                                            classes.detailRoom
                                                        }
                                                    >
                                                        <Box
                                                            className={
                                                                classes.detailRoomTop
                                                            }
                                                        >
                                                            <Box
                                                                className={
                                                                    classes.nameRoom
                                                                }
                                                            >
                                                                <Typography>
                                                                    {value.name}
                                                                </Typography>
                                                            </Box>

                                                            <Typography
                                                                className={
                                                                    classes.price
                                                                }
                                                            >{`${new Intl.NumberFormat().format(
                                                                value.price
                                                            )} đ`}</Typography>
                                                            <div
                                                                className={
                                                                    classes.containerChip
                                                                }
                                                            >
                                                                <BasicChip
                                                                    title={
                                                                        value.status ===
                                                                        "available"
                                                                            ? "Trống"
                                                                            : "Đã thuê"
                                                                    }
                                                                    isActive={
                                                                        value.status ===
                                                                        "available"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </div>
                                                        </Box>
                                                        <Box
                                                            className={
                                                                classes.detailRoomBottom
                                                            }
                                                        >
                                                            <Typography>
                                                                Người thuê:{" "}
                                                                {
                                                                    value.totalTenant
                                                                }
                                                            </Typography>
                                                            <Typography>
                                                                Hóa đơn:{" "}
                                                                {
                                                                    value.totalOverdueInvoice
                                                                }
                                                            </Typography>
                                                            <Typography>
                                                                Vấn đề:{" "}
                                                                {value.totalLowIssue +
                                                                    value.totalMediumIssue +
                                                                    value.totalHighIssue}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        );
                                    }
                                )}
                            </Box>
                        );
                    })}
                </Box>
            ) : (
                <EmptyIcon />
            )}
        </>
    );
};

export default ListRoom;
