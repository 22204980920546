import { makeStyles } from "@mui/styles";

export const useInfoMotel = makeStyles({
    card: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "0px 6px 18px 0px rgba(0, 0, 0, 0.06)",
        height: "77px",
        width: "100%",
    },
    cardHeader: {
        width: "75%",
    },
    cardActions: {
        width: "25%",
    },
    nameMotel: {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "1000",
        lineHeight: "23px",
        letterSpacing: "0.01em",
    },
    time: {
        color: "#04C35C",
        fontSize: "14px",
    },
});
