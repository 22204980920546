import React, { useState, useContext, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";

import { SEARCH_TENANT } from "../../graphql-client/Tenant/query";
import { headCellTenants } from "../../constants/Constants.js";
import Loading from "../../components/Loading/Loading";
import Notification from "../../components/Notification/Notification";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import Paging from "../../components/Paging/Paging";
import ModalAdd from "./ModalAdd/ModalAdd";

import Table from "./Table/Table";
import { useTenant } from "./useTenant";
import { saveState } from "../../util/LocalStateSave";
import { HomeContext } from "../../layout/HomeLayout/HomeLayout";

const Tenant = () => {
    const SIZE = 12;
    const location = useLocation();
    const pathname = location.pathname;
    const redirect = pathname.split("/");
    const classes = useTenant();
    const [page, setPage] = useState(1);

    const [open, setOpen] = useState(false);
    const context = useContext(HomeContext);
    const { loading: loadingTenants, data: dataTenants } = useQuery(
        SEARCH_TENANT,
        {
            variables: {
                page: page,
                size: SIZE,
                statues: redirect[2],
                searchText: context.dbValue,
            },
            fetchPolicy: "network-only",
        }
    );

    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });

    useEffect(() => {
        setPage(1);
        saveState([], "selected");
    }, [pathname]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box sx={{ flexGrow: 1 }} className={classes.box}>
                {loadingTenants ? (
                    <Loading />
                ) : (
                    <Grid
                        container
                        spacing={2}
                        className={classes.gridContainer}
                    >
                        <Grid item xs={12} className={classes.gridTable}>
                            <Grid
                                item
                                xs={12}
                                className={classes.gridItemTable}
                            >
                                <Table
                                    headCells={headCellTenants}
                                    rowsData={dataTenants.searchTenants.results}
                                    page={page}
                                    size={SIZE}
                                    totalPages={
                                        dataTenants.searchTenants.totalPages
                                    }
                                    status={redirect[2]}
                                    searchText={context.dbValue}
                                    setPage={setPage}
                                    setNotify={setNotify}
                                    setConfirmDialog={setConfirmDialog}
                                    open={open}
                                    setOpen={setOpen}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.gridItemPaging}
                            >
                                <Paging
                                    totalPages={
                                        dataTenants.searchTenants.totalPages
                                    }
                                    currentPage={page}
                                    onChangePage={(event, value) => {
                                        setPage(value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Box>

            <ModalAdd
                closeAdd={handleClose}
                openAdd={open}
                page={page}
                size={SIZE}
                status={redirect[2]}
                searchText={context.dbValue}
            />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
};

export default Tenant;
