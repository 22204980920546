import React, { useState, useEffect } from "react";
import ModalContainer from "../../../../components/ModalContainer/ModalContainer";
import { contractTermsText } from "../../../../constants/Constants";
import styles from "./ModalAdd.module.css";
import ButtonHome from "../../../../components/ButtonHome/ButtonHome";
import { useModalAdd } from "./useModalAdd";
import Notification from "../../../../components/Notification/Notification";

import TextArea from "../../../../components/TextArea/TextArea";
import SelectTypeTerm from "../SelectTypeTerm/SelectTypeTerm";

import { useAddContractTerm } from "../../../../graphql-client/ContractTerms/mutations";

import Grid from "@mui/material/Grid";
import { validateAddContractTerm } from "../../../../useCase/ContractTerm/useContractTerm";

const ModalAdd = ({ openAdd, closeAdd }) => {
    const classes = useModalAdd();
    const { mutateAddContractTerm } = useAddContractTerm();
    const [content, setContent] = useState("");
    const [type, setType] = useState("owner");

    const [validationMessage, setValidationMessage] = useState({});

    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    useEffect(() => {
        if (openAdd === true) {
            clearState();
        }
    }, [openAdd]);

    const clearState = () => {
        setValidationMessage({});
        setContent("");
        setType("owner");
    };

    const handleAddContractTerm = async (e) => {
        e.preventDefault();

        const isValid = validateAddContractTerm(content);

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            await mutateAddContractTerm({
                req: {
                    type: type,
                    content: content,
                },
            });
            setNotify({
                ...contractTermsText.notify.add,
            });
            closeAdd();
        }
    };
    const handleContinueAdd = async (e) => {
        e.preventDefault();
        const isValid = validateAddContractTerm(content);

        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            await mutateAddContractTerm({
                req: {
                    type: type,
                    content: content,
                },
            });
            setNotify({
                ...contractTermsText.notify.add,
            });
            clearState();
        }
    };

    return (
        <>
            <ModalContainer
                closeModal={closeAdd}
                openModal={openAdd}
                title={contractTermsText.modalContractTerms.titleAdd}
            >
                <form onSubmit={handleAddContractTerm} className={styles.form}>
                    <Grid container className={classes.container}>
                        <Grid item xs={12}>
                            <SelectTypeTerm
                                data={contractTermsText.selectTypeTerm}
                                clicked={(value) => setType(value)}
                                selected={type}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextArea
                                label={contractTermsText.textAreaContext.label}
                                placeholder={
                                    contractTermsText.textAreaContext
                                        .placeholder
                                }
                                changed={(e) => setContent(e.target.value)}
                                error={validationMessage.content}
                                value={content}
                                star={true}
                            />
                        </Grid>

                        <Grid item xs={8}></Grid>

                        <Grid
                            item
                            xs={4}
                            style={{
                                display: "flex",
                                gap: "20px",
                            }}
                        >
                            <ButtonHome
                                type="submit"
                                text={
                                    contractTermsText.modalContractTerms
                                        .buttonContinueAdd
                                }
                                onClick={handleContinueAdd}
                            />
                            <ButtonHome
                                type="submit"
                                text={
                                    contractTermsText.modalContractTerms
                                        .buttonAdd
                                }
                                onClick={handleAddContractTerm}
                            />
                        </Grid>
                    </Grid>
                </form>
            </ModalContainer>
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
};

export default ModalAdd;
