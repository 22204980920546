import React, { memo } from "react";
import { Box, Card, Typography } from "@mui/material";
import { useSelectTypeTerm } from "./useSelectTypeTerm";

const SelectTypeTerm = ({ data, clicked, selected }) => {
    const classes = useSelectTypeTerm();
    return (
        <Box className={classes.container}>
            {data.map((value) => {
                return (
                    <Card
                        key={value.id}
                        className={`${classes.card} ${
                            selected === value.type ? classes.activeCard : ""
                        }`}
                        onClick={() => clicked(value.type)}
                    >
                        <Typography
                            className={`${classes.title} ${
                                selected === value.type
                                    ? classes.activeTitle
                                    : ""
                            }`}
                        >
                            {value.title}
                        </Typography>
                        <Typography className={classes.subTitle}>
                            {value.subTitle}
                        </Typography>
                    </Card>
                );
            })}
        </Box>
    );
};

export default memo(SelectTypeTerm);
