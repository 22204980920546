import React, { useState, createContext, memo, useEffect } from "react";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import HeaderHome from "../../components/HeaderHome/HeaderHome";
import DrawerHome from "../../components/DrawerHome/DrawerHome";
import { Outlet } from "react-router";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { useDebounce } from "../../util/useDebounce";
import { useLocation } from "react-router-dom";

import { GET_ME } from "../../graphql-client/Auth/query"
import styles from "./HomeLayout.module.css"

export const HomeContext = createContext();

export default memo(function PermanentDrawerLeft() {
    const [value, setValue] = useState("");
    const [dbValue, saveToDb] = useState("");
    const debouncedSave = useDebounce((nextValue) => saveToDb(nextValue), 500);

    const handelChanged = (e) => {
        const { value: nextValue } = e.target;
        setValue(nextValue);
        debouncedSave(nextValue);
    };
    const location = useLocation();
    const redirect = location.pathname.split("/")[1];
    const { data: dataGetMe } = useQuery(GET_ME)


    useEffect(() => {
        setValue("");
        saveToDb("");
    }, [redirect]);

    const theme = useTheme();

    return (
        <ThemeProvider theme={theme}>
            <HomeContext.Provider
                value={{ dbValue, dataGetMe }}
            >
                <Box
                    sx={{
                        display: "flex",
                        height: "100vh",
                        overflowY: "hidden",
                    }}
                >
                    <CssBaseline />
                    <HeaderHome changed={handelChanged} value={value} />
                    <DrawerHome />
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, height: "100%", background: "#f5f6f8", p: 2 }}
                    >
                        <Toolbar style={{ minHeight: "50px" }} />
                        <div className={styles.container}>
                            <Outlet />

                        </div>
                    </Box>
                </Box>
            </HomeContext.Provider>
        </ThemeProvider>
    );
});
