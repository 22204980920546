import { makeStyles } from "@mui/styles";

export const useListTenant = makeStyles({
    title: {
        fontSize: "15px",
        color: "#192A3E",
        fontWeight: "700",
    },
    containerList: {
        padding: "8px",
        backgroundColor: "#fff",
        boxShadow: "0px 6px 18px 0px #0000000F",
        borderRadius: "5px",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "16px",
    },
});
