import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import styles from "./SignUp.module.css";
import Button from "../../components/Button/Button";
import FormContainer from "../../components/FormContainer/FormContainer";
import Input from "../../components/Input/Input";
import Radio from "../../components/Radio/Radio";
import {
    textEmail,
    textPassword,
    textFullName,
    textPhoneNumber,
    textTotalRoom,
    textRegion,
    textSignUp,
} from "../../constants/Constants";

import { useSignUp } from "../../graphql-client/Auth/mutations";

import { catchGraphQlErrors } from "../../util/CatchGraphQLErrors";
import { validateSignUp } from "../../useCase/Auth/SignUpUseCase";

const SignUp = () => {
    const { mutateSignUp, errorSignUp } = useSignUp();
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [totalRoom, setTotalRoom] = useState(0);
    const [region, setRegion] = useState(textRegion[0].value);
    const [isChecked, setIsChecked] = useState(false);
    const [validationMessage, setValidationMessage] = useState({});
    const [alert, setAlert] = useState();

    useEffect(() => {
        if (errorSignUp) {
            setAlert(catchGraphQlErrors(errorSignUp));
        }
    }, [errorSignUp]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = validateSignUp(
            email,
            password,
            phoneNumber,
            fullName,
            totalRoom,
            isChecked
        );
        setAlert("");

        if (!isValid) {
            setValidationMessage(isValid.msg);
        } else {
            setValidationMessage(isValid.msg);
            mutateSignUp({
                fullName: fullName,
                password: password,
                phoneNumber: phoneNumber,
                email: email,
                region: region,
                totalRoom: +totalRoom,
            });
        }
    };

    return (
        <>
            <FormContainer text={textSignUp.titleSignUp}>
                <Form onSubmit={handleSubmit}>
                    <Input
                        text={textFullName.text}
                        name={textFullName.name}
                        type={textFullName.type}
                        placeholder={textFullName.placeholder}
                        error={validationMessage.fullName}
                        changed={(e) => setFullName(e.target.value)}
                        star={true}
                    />
                    <Input
                        text={textEmail.text}
                        name={textEmail.name}
                        type={textEmail.type}
                        placeholder={textEmail.placeholder}
                        error={validationMessage.email}
                        changed={(e) => setEmail(e.target.value)}
                        star={true}
                    />
                    <Input
                        text={textPhoneNumber.text}
                        name={textPhoneNumber.name}
                        type={textPhoneNumber.type}
                        placeholder={textPhoneNumber.placeholder}
                        error={validationMessage.phoneNumber}
                        changed={(e) => setPhoneNumber(e.target.value)}
                        star={true}
                    />

                    <Input
                        text={textPassword.text}
                        name={textPassword.name}
                        type={textPassword.type}
                        placeholder={textPassword.placeholder}
                        error={validationMessage.password}
                        changed={(e) => setPassword(e.target.value)}
                        star={true}
                    />
                    <Input
                        text={textTotalRoom.text}
                        name={textTotalRoom.name}
                        type={textTotalRoom.type}
                        placeholder={textTotalRoom.placeholder}
                        error={validationMessage.totalRoom}
                        changed={(e) => setTotalRoom(e.target.value)}
                        star={true}
                    />
                    <div className={styles.containerRadios}>
                        {textRegion.map((value, index) => (
                            <Radio
                                key={index}
                                name={value.name}
                                value={value.value}
                                isSelected={region === value.value}
                                changed={(e) => setRegion(e.target.value)}
                                text={value.text}
                            />
                        ))}
                    </div>

                    <div className={styles.containerCheckbox}>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => setIsChecked(e.target.checked)}
                            className={styles.checkbox}
                            style={{ marginRight: "10px" }}
                        />
                        <p className={styles.checkboxLabel}>
                            Tôi đồng ý với{" "}
                            <a href="https://github.com/">chính sách</a> &{" "}
                            <a href="https://github.com/">điều khoản</a> của
                            Lalahome.
                        </p>
                    </div>

                    <div className={styles.button}>
                        <p className={styles.alertLabel}>{alert}</p>
                        <Button text={textSignUp.textButton} />
                    </div>
                </Form>
            </FormContainer>
        </>
    );
};

export default SignUp;
