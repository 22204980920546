import React, { useState, memo } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import BoxNumber from "../../../components/BoxNumber/BoxNumber";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Notification from "../../../components/Notification/Notification";
import ButtonHome from "../../../components/ButtonHome/ButtonHome";
import ModalAdd from "../ModalAdd/ModalAdd";
import ModalDetail from "../ModalDetail/ModalDetail";
import { useTable } from "./useTable";
import { useDeleteService } from "../../../graphql-client/Services/mutation";
import { serviceText } from "../../../constants/Constants";
import EmptyIcon from "../../../assets/Icons/EmptyIcon";

const EnhancedTableHead = memo(
    ({ onSelectAllClick, numSelected, rowCount, headCells }) => {
        const classes = useTable();
        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                "aria-label": "select all desserts",
                            }}
                            className={classes.checkBox}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={
                                headCell.disablePadding ? "none" : "normal"
                            }
                            className={classes.headCell}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
);

const EnhancedTableToolbar = memo(({ numSelected, removed }) => {
    const classes = useTable();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        return true;
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Toolbar className={classes.toolbar}>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "24px",
                }}
            >
                <BoxNumber number={numSelected} />
                <Typography
                    className={classes.typography}
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} đã chọn
                </Typography>
                {numSelected > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton
                            className={classes.iconButton}
                            onClick={removed}
                        >
                            <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </Box>

            <Box style={{ width: "15%" }}>
                <ButtonHome text="Thêm dịch vụ" onClick={handleOpen} />
                <ModalAdd closeAdd={handleClose} openAdd={open}></ModalAdd>
            </Box>
        </Toolbar>
    );
});

const EnhancedTable = ({ headCells, rowsData }) => {
    const classes = useTable();
    const [selected, setSelected] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const { mutateDeleteService, errorDeleteService } = useDeleteService();
    const [open, setOpen] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    const handleOpen = (id) => {
        setOpen(true);
        setSelectedItem(id);
    };
    const handleClose = () => {
        setOpen(false);
        setSelectedItem(null);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rowsData.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleRemoved = () => {
        setConfirmDialog({
            ...serviceText.dialog.delete,
            onConfirm: () => {
                let listRemove = selected.map((element) => parseInt(element));
                listRemove.forEach((element) => {
                    mutateDeleteService({ serviceId: element });
                });
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                setNotify({
                    ...serviceText.notify.delete,
                });
                setSelected([]);
            },
        });
    };

    return (
        <Box className={classes.box}>
            <Paper
                sx={{ width: "100%", overflow: "hidden", height: "100%" }}
                className={classes.paper}
            >
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    selectedList={selected}
                    removed={handleRemoved}
                />
                <Divider />
                {rowsData.length > 0 ? (
                    <TableContainer sx={{ maxHeight: "calc(100% - 64px)" }}>
                        <Table
                            sx={{ minWidth: 750 }}
                            stickyHeader={true}
                            aria-label="sticky table"
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={rowsData.length}
                                headCells={headCells}
                            />

                            <TableBody>
                                {rowsData.map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                            className={classes.tableRow}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <TableCell
                                                padding="checkbox"
                                                width="5%"
                                            >
                                                <Checkbox
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.id
                                                        )
                                                    }
                                                    className={classes.checkBox}
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby":
                                                            labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                width="25%"
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                onClick={() =>
                                                    handleOpen(row.id)
                                                }
                                            >
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        gap: "12px",
                                                    }}
                                                >
                                                    <Avatar
                                                        variant="square"
                                                        alt="icon"
                                                        src={row.icon}
                                                        sx={{
                                                            width: 24,
                                                            height: 24,
                                                        }}
                                                    />
                                                    <Typography>
                                                        {" "}
                                                        {row.name}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                width="13%"
                                                onClick={() =>
                                                    handleOpen(row.id)
                                                }
                                            >
                                                {row.feeTypeConvert}
                                            </TableCell>
                                            <TableCell
                                                width="15%"
                                                onClick={() =>
                                                    handleOpen(row.id)
                                                }
                                            >
                                                {row.feeConvert}
                                            </TableCell>
                                            <TableCell
                                                width="12%"
                                                onClick={() =>
                                                    handleOpen(row.id)
                                                }
                                            >
                                                {moment(row.updatedAt).format(
                                                    "DD-MM-YYYY"
                                                )}
                                            </TableCell>
                                            <TableCell
                                                width="36%"
                                                onClick={() =>
                                                    handleOpen(row.id)
                                                }
                                            >
                                                {row.description}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <EmptyIcon />
                )}

                <ModalDetail
                    closeAdd={handleClose}
                    openAdd={open}
                    serviceId={selectedItem}
                ></ModalDetail>
            </Paper>
            <Notification notify={notify} setNotify={setNotify} />
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </Box>
    );
};

export default memo(EnhancedTable);
