
import React from 'react';
import { Link } from 'react-router-dom';
import { NotFoundText, navigation } from "../../constants/Constants"

import styles from "./NotFound.module.css"

const NotFound = () => {

    return <div className={styles.container}>
        <p className={styles.header}>{NotFoundText.header}</p>
        <p className={styles.body}>{NotFoundText.body}</p>
        <div className={styles.containerButton}>
            <Link className={styles.linkToHome} to={navigation.dashboard.link}>
                <p>{NotFoundText.linkToHome}</p>
            </Link>
            <a className={styles.linkToFacebook} href={NotFoundText.linkToFacebook}><p>Liên hệ LalaHome</p></a>
        </div>
    </div>;
};

export default NotFound;
