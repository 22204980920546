import React, { memo } from 'react';
import { Checkbox, Card, CardContent, Typography, Box, Avatar, CardHeader, FormControlLabel } from '@mui/material';
import { useCheckboxService } from "./useCheckboxService"

const CheckboxService = ({ data, changed, isItemSelected, }) => {

    const classes = useCheckboxService()

    return (
        <Box className={classes.box}>
            {
                data.icon ?
                    <>
                        <Checkbox
                            value={data.id}
                            onChange={() => changed(data.id)}
                            checked={isItemSelected}
                            className={classes.checkBox}
                            disableRipple
                        // defaultValue
                        />
                        <Card className={classes.card}>
                            <CardHeader
                                avatar={
                                    <Avatar src={data.icon} aria-label="recipe" sx={{ width: 24, height: 24 }} />
                                }
                                className={classes.cardMedia}
                                style={{ padding: 0 }}
                            />
                            <CardContent className={classes.cardContent}>
                                <Typography className={classes.nameService}>{data.name}</Typography>
                                <Typography className={classes.priceService}>{data.feeConvert}</Typography>
                            </CardContent>
                        </Card>
                    </>
                    :
                    <FormControlLabel className={`${classes.checkBoxChip} ${isItemSelected ? classes.active : ""}`}
                        control={
                            <Checkbox disableRipple value={data.id} checked={isItemSelected} onChange={() => changed(data.id)} />
                        }
                        label={data.name}
                    />
            }

        </Box>
    )
}

export default memo(CheckboxService)
