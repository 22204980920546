import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {gql, useMutation, useQuery} from '@apollo/client';
import {
    Button,
    Card,
    Col,
    Divider,
    FloatButton,
    Form,
    Input,
    Modal,
    notification,
    Popconfirm,
    Row,
    Space,
    Table, Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactMultiEmail} from 'react-multi-email';
import {AmountComponent} from '../../../components/AmountComponent/AmountComponent';
import {CustomDatePicker} from '../../../components/CustomDatePicker';
import {RoomName} from '../../../components/Room';
import {NumberInput, SearchableSelect} from '../../../components/SearchableSelect';
import {SelectPaymentMethod} from '../../../components/SelectPaymentMethod/SelectPaymentMethod';
import UploadImages from '../../../components/UploadImages';
import {useDeleteTransaction} from '../../../graphql-client/Accounting/mutation';
import {QUERY_TRANSACTIONS_BY_PAGE} from '../../../graphql-client/Accounting/query';
import {GET_ME} from '../../../graphql-client/Auth/query';
import {MY_MOTELS} from '../../../graphql-client/Motel/query';
import {GET_ROOMS} from '../../../graphql-client/Tenant/query';
import {formatDate, parseDate} from '../../../util/Date';
import {parseImageUrl} from '../../../util/Image';
import {formatNumber, zeroPad} from '../../../util/Number';
import {normalizeRequestObject} from '../../../util/Request';
import {SelectTransactionGroup} from '../TransactionGroup/AccountingGroup';
import 'react-multi-email/dist/style.css';

const REVENUE_GROUP_TYPES = ['revenue', 'debt_collection'];

export function SelectRoom({hasContract, ...rest}) {
    const {data: dataRooms} = useQuery(GET_ROOMS, {variables: {hasContract}});
    const options = dataRooms?.myRooms?.map(item => {
        return {
            label: `${item.name} - ${item.motel.name}`, value: parseInt(item.id),
        };
    });

    return <SearchableSelect options={options} {...rest}/>;
}

const QUERY_GET_TRANSACTION = gql`
    query GetTransactionDetail($id: Int!) {
        getTransaction(id: $id) {
            id
            createdAt
            date
            amount
            ownerType
            note
            groupId
            roomContractId
            paymentMethod
            motelId
            roomId
            createdBy {
                id
                fullName
            }
            room {
                id
                name
                motel {
                    id
                    name
                }
            }
            roomInvoice {
                id
                number
            }
            imageUrls
            roomContract {
                id
                number
            }
            group {
                id
                name
                type
                __typename
            }
            tenant {
                id
                fullName
                __typename
            }
            room {
                id
                name
                motel {
                    id
                    name
                    __typename
                }
                __typename
            }
            motel {
                id
                name
                __typename
            }
            __typename
        }
    }

`;

const MUTATION_UPDATE_TRANSACTION = gql`
    mutation UpdateTransaction($id: Int!, $request: UpdateTransactionReq) {
        updateTransaction(id: $id, request: $request) {
            ok
            __typename
        }
    }
`;

const MUTATION_DOWNLOAD_ACCOUNTING_REPORT = gql`
    mutation DownloadAccountingReport($req: AddTransactionExportTaskReq) {
      addTransactionExportTask(req: $req) {
        ok
        __typename
      }
    }

`;

function useDownloadAccountingReport() {
    const [mutateDownload, {loading}] = useMutation(MUTATION_DOWNLOAD_ACCOUNTING_REPORT, {});

    async function downloadReport(request) {
        await mutateDownload({
            variables: {
                req: request,
            },
        });
    }

    return {
        downloadReport, loading,
    };
}

function useUpdateMutation() {
    const [mutateUpdateTransaction, {loading}] = useMutation(MUTATION_UPDATE_TRANSACTION, {
        refetchQueries: [
            'GetTransactionsByDate', 'Debts', 'GetDebtSubject'],
    });

    async function updateTransaction(id, request) {
        await mutateUpdateTransaction({
            variables: {
                id, request,
            },
        });
    }

    return {
        updateTransaction, loading,
    };
}

const MUTATION_ADD_TRANSACTION = gql`
    mutation AddTransaction($request: AddTransactionReq) {
        addTransaction(request: $request) {
            ok
            __typename
        }
    }
`;

function useAddTransaction() {
    const [mutateAddTransaction, {loading}] = useMutation(MUTATION_ADD_TRANSACTION, {
        refetchQueries: [
            'GetTransactionsByDate', 'Debts', 'GetDebtSubject'],
    });

    async function addTransaction(request) {
        await mutateAddTransaction({
            variables: {
                request,
            },
        });
    }

    return {
        addTransaction, loading,
    };
}

export function ModalTransactionDetail({
                                           open,
                                           onCancel,
                                           transactionID,
                                           groupTypes,
                                           groupId,
                                           ownerId,
                                           ownerType: defaultOwnerType,
                                           amount,
                                           roomContractId,
                                           roomId,
                                           userId,
                                           tenantId,
                                       }) {

    const getTransactionRequest = normalizeRequestObject({
        id: transactionID,
    });
    const {data: transactionData, loading, refetch} = useQuery(QUERY_GET_TRANSACTION, {
        variables: getTransactionRequest, skip: !transactionID,
    });

    useEffect(() => {
        if (transactionID) {
            refetch(transactionID);
        }
    }, [
        JSON.stringify(getTransactionRequest)]);

    const transaction = transactionData?.getTransaction;

    const initialState = {
        ownerType: defaultOwnerType || 'room_contract',
        date: dayjs(new Date()),
        ownerId: ownerId,
        groupId,
        roomContractId,
        tenantId,
        userId,
        roomId,
        amount: amount || 0,
    };
    const [initialValues, setInitialValues] = useState();
    useEffect(() => {
        form.setFieldsValue({...initialState});
    }, [JSON.stringify(initialState)]);

    useEffect(() => {
        if (transaction) {
            setInitialValues({
                ...transaction,
                date: parseDate(transaction.date),
                imageUrls: transaction.imageUrls?.map(url => parseImageUrl(url)),
            });
        }
    }, [
        JSON.stringify(transaction)]);

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [JSON.stringify(initialValues)]);

    const [form] = Form.useForm();
    const {t} = useTranslation();
    const ownerTypeOptions = [
        {
            label: t('transaction.ownerRoom'), value: 'room',
        }, {
            label: t('transaction.ownerContract'), value: 'room_contract',
        }, {
            label: t('transaction.ownerMotel'), value: 'motel',
        }, {
            label: t('transaction.ownerOther'), value: 'enterprise',
        },

    ];

    const {data: dataRooms} = useQuery(GET_ROOMS, {
        variables: {
            hasContract: true,
        },
    });
    const contractOptions = dataRooms?.myRooms?.map(item => {
        return {
            label: `#${zeroPad(item.activeContractId, 6)} (${item.name} - ${item.motel.name})`,
            value: parseInt(item.activeContractId),
        };
    });

    const ownerType = Form.useWatch('ownerType', form);
    const {updateTransaction, loading: loadingUpdate} = useUpdateMutation();
    const {addTransaction, loading: loadingAdd} = useAddTransaction();

    async function handleSubmit() {
        const values = await form.validateFields();
        if (transactionID) {
            try {
                await updateTransaction(transactionID, normalizeRequestObject(values));
                notification.success({
                    message: t('transaction.updateSuccess'),
                });
                onCancel();

            } catch (e) {
                notification.error({
                    message: t('transaction.updateFailed'),
                });
            }
        } else {
            try {
                await addTransaction(normalizeRequestObject(values));
                notification.success({
                    message: t('transaction.createSuccess'),
                });
                onCancel();

            } catch (e) {
                notification.error({
                    message: t('transaction.createFailed'),
                });
            }
        }
    }

    const title = transactionID ? t('transaction.update') : t('transaction.create');
    return <Modal onCancel={onCancel} open={open} title={title} destroyOnClose onOk={handleSubmit}
                  confirmLoading={loadingUpdate || loadingAdd}>
        <Form form={form} layout={'vertical'}>
            <Form.Item label={t('transaction.date')} name={'date'} rules={[{required: true}]}>
                <CustomDatePicker/>
            </Form.Item>
            <Form.Item label={t('transaction.amount')} name={'amount'} rules={[{required: true}]}>
                <NumberInput/>
            </Form.Item>
            <Form.Item label={t('transaction.group')} name={'groupId'} rules={[{required: true}]}>
                <SelectTransactionGroup onlyRoot={false} groupTypes={groupTypes}/>
            </Form.Item>
            <Form.Item label={t('transaction.paymentMethod')} name={'paymentMethod'}>
                <SelectPaymentMethod/>
            </Form.Item>
            <Form.Item label={t('transaction.ownerType')} name={'ownerType'} rules={[{required: true}]}>
                <SearchableSelect options={ownerTypeOptions}/>
            </Form.Item>
            {ownerType === 'room_contract' &&
                <Form.Item label={t('transaction.roomContract')} name={'roomContractId'} rules={[{required: true}]}>
                    <SearchableSelect options={contractOptions}/>
                </Form.Item>}
            {ownerType === 'motel' && <Form.Item label={t('motel')} name={'motelId'} rules={[{required: true}]}>
                <SelectMotel/>
            </Form.Item>}
            {ownerType === 'room' && <Form.Item label={t('room')} name={'roomId'} rules={[{required: true}]}>
                <SelectRoom/>
            </Form.Item>}
            <Form.Item label={t('transaction.note')} name={'note'}>
                <Input.TextArea/>
            </Form.Item>
            <UploadImages name={'imageUrls'} label={t('transaction.image')}/>
        </Form>
    </Modal>;
}

export const TransactionTable = ({startDate, endDate, motelIds, groupTypes, ownerIds, ownerTypes}) => {
    const {t} = useTranslation();

    const {deleteTransaction, loading: deleteLoading} = useDeleteTransaction();

    const pageSize = 10;
    const [filterData, setFilterData] = useState({});
    const request = normalizeRequestObject({
        page: 1,
        size: pageSize,
        startDate: startDate,
        endDate: endDate,
        motelIds: motelIds,
        groupTypes,
        ownerIds,
        ownerTypes, ...filterData,
    });

    const {data: transactionsData, loading, refetch} = useQuery(QUERY_TRANSACTIONS_BY_PAGE, {
        variables: request,
    });

    useEffect(() => {
        if (startDate && endDate) {
            refetch(request);
        }
    }, [
        JSON.stringify(request)]);
    const transactionsByDate = transactionsData?.transactionsGroupByDate?.results;
    const summaryData = transactionsData?.getTransactionSummary;

    async function handleDeleteTransaction(transactionID) {
        try {
            await deleteTransaction(transactionID);
            notification.success({
                message: t('transaction.deleteSuccess'),
            });
        } catch (e) {
            notification.error({
                message: t('transaction.deleteFailed'),
            });
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [selectedTransactionID, setSelectedTransactionID] = useState();

    function openTransactionDetail(id) {
        setSelectedTransactionID(id);
        setShowModal(true);
    }

    function closeModal() {
        setSelectedTransactionID(null);
        setShowModal(false);
    }

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: '#', dataIndex: ['id'], render: (value, record) => {
                    return <a href={'#'} onClick={() => openTransactionDetail(value)}>{value}</a>;
                },
            }, {
                title: t('transaction.group'), dataIndex: ['group', 'name'], key: 'date',
            }, {
                title: t('transaction.object'), render: (value, record) => {
                    if (record.ownerType === 'room' || record.ownerType === 'room_contract') {
                        return <RoomName room={record.room}/>;
                    }
                    if (record.ownerType === 'tenant') {
                        return record.tenant?.fullName;
                    }
                    if (record.ownerType === 'motel') {
                        return record.motel?.name;
                    }
                },
            }, {
                title: t('transaction.amount'), dataIndex: 'amount', render: (value, record) => {
                    if (!REVENUE_GROUP_TYPES.includes(record.group?.type)) {
                        value = -value;
                    }
                    return <AmountComponent value={value}/>;
                },
            }, {
                title: t('transaction.note'), dataIndex: 'note',
            }, {
                title: t('transaction.action'), render: (value, record) => {
                    return <Popconfirm title={t('transaction.deleteConfirm')} onConfirm={() => {
                        handleDeleteTransaction(record.id);
                    }}><DeleteOutlined/></Popconfirm>;
                },
            }];

        return <Table columns={columns} dataSource={record.transactions} pagination={false}/>;
    };
    const columns = [
        {
            title: t('transaction.date'), dataIndex: 'date', render: (value) => {
                return formatDate(value);
            },
        }, {
            title: t('transaction.amount'), dataIndex: 'amount', render: (value) => {
                return <AmountComponent value={value}/>;
            },
        }];
    return (<>
        <Row gutter={12}>
            <Col span={8} >
                <Card title={t('transaction.income')} bordered={false}>
                    <Typography.Text type={'success'}>{formatNumber(summaryData?.income) || 0}</Typography.Text>
                </Card>

            </Col>
            <Col span={8}>
                <Card title={t('transaction.outcome')} bordered={false}>
                    <Typography.Text type={'warning'}>{formatNumber(summaryData?.outcome) || 0}</Typography.Text>
                </Card>
            </Col>
            <Col span={8}>
                <Card title={t('transaction.return')} bordered={false}>
                    <Typography.Text type={'secondary'}>{formatNumber(summaryData?.amount) || 0}</Typography.Text>
                </Card>
            </Col>
        </Row>
        <Divider />
        <Table
            // style={{
            //     width: '100%'
            // }}
            rowKey={(record) => {
                return record.date;
            }}
            loading={loading || deleteLoading}
            columns={columns}
            expandable={{
                expandedRowRender, defaultExpandedRowKeys: ['0'],
            }}
            dataSource={transactionsByDate}
            pagination={{
                pageSize: transactionsData?.transactionsGroupByDate?.size,
                total: transactionsData?.transactionsGroupByDate?.total,
                showSizeChanger: true,
                onChange: function(page, pageSize) {
                    setFilterData({...filterData, page: page, size: pageSize});
                },
            }}
        />
        <ModalTransactionDetail open={showModal} onCancel={() => {
            closeModal();
        }} transactionID={selectedTransactionID}/>
    </>);
};

export function SelectMotel({...selectOptions}) {
    const {data: motelData} = useQuery(MY_MOTELS);
    const motels = motelData?.myMotels?.map(item => {
        return {
            label: item.name, value: parseInt(item.id),
        };
    });
    return <SearchableSelect options={motels} {...selectOptions} />;
}

const Transaction = () => {
    const [form] = Form.useForm();

    const {t} = useTranslation();
    const startDate = Form.useWatch('startDate', form);
    const endDate = Form.useWatch('endDate', form);
    const motelIds = Form.useWatch('motelIds', form);

    useEffect(() => {
        form.setFieldsValue({
            endDate: dayjs().endOf('month'), startDate: dayjs().startOf('month'),
        });
    }, []);

    const [showModal, setShowModal] = useState(false);

    function openModal() {
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    const [showReportModal, setShowReportModal] = useState(false);

    function openDownloadReportModal(group) {
        setShowReportModal(true);
    }

    function closeDownloadReportModal() {
        setShowReportModal(false);
    }

    const {downloadReport, loading} = useDownloadAccountingReport();

    async function handleSubmitReportRequest(emails) {
        if (!emails || emails.length === 0) {
            notification.error({message: t('transaction.pleaseInputAtLeastOneEmail')});
            return;
        }
        try {
            await downloadReport(normalizeRequestObject({
                startDate: startDate,
                endDate: endDate,
                motelIds,
                emails,
            }));
            notification.success({message: t('transaction.downloadSuccess')});
            setShowReportModal(false);

        } catch (e) {
            notification.error({message: t('transaction.downloadError'), description: e.message});

        }
    }

    const {data: getMeData} = useQuery(GET_ME);

    return <div>

        <Form form={form} layout={'vertical'} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Space>
                <Form.Item label={t('motel')} name={'motelIds'} style={{minWidth: 200}}>
                    <SelectMotel placeholder={t('pleaseSelectMotel')} mode="multiple"/>
                </Form.Item>
                <Form.Item label={t('dateTime.startDate')} name={'startDate'}>
                    <CustomDatePicker/>
                </Form.Item>
                <Form.Item label={t('dateTime.endDate')} name={'endDate'}>
                    <CustomDatePicker/>
                </Form.Item>
                <Form.Item label={'   '}>
                    <Button type="primary" onClick={() => openDownloadReportModal()}>{t(
                        'transaction.downloadReport')}</Button>
                </Form.Item>
            </Space>

        </Form>
        <TransactionTable startDate={startDate} endDate={endDate} motelIds={motelIds}/>
        <ModalTransactionDetail open={showModal} onCancel={closeModal}/>
        {getMeData && <ReportModal open={showReportModal} onCancel={() => closeDownloadReportModal()}
                                   defaultEmails={[getMeData?.me?.email]} onSubmit={handleSubmitReportRequest}
                                   loading={loading}/>}
        <FloatButton icon={<PlusOutlined/>} type={'primary'} onClick={() => openModal()}>{t(
            'transaction.create')}</FloatButton>

    </div>;
};

const ReportModal = ({open, onCancel, onSubmit, defaultEmails = [], loading}) => {
    const [emails, setEmails] = useState(defaultEmails);
    const [focused, setFocused] = useState(false);
    const {t} = useTranslation();
    return <Modal open={open} onCancel={onCancel} okText={t('transaction.sendReportRequest')}
                  title={t('transaction.downloadReport')}
                  confirmLoading={loading}
                  onOk={() => onSubmit(emails)}
    >

        <Form layout={'vertical'}>
            <Form.Item label={t('transaction.receivedEmail')}>
                <ReactMultiEmail
                    placeholder={t('transaction.inputEmail')}
                    emails={emails}
                    onChange={(_emails) => {
                        setEmails(_emails);
                    }}
                    autoFocus={true}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    getLabel={(email, index, removeEmail) => {
                        return (
                            <div data-tag key={index}>
                                <div data-tag-item>{email}</div>
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
                            </div>
                        );
                    }}
                />
            </Form.Item>
        </Form>
    </Modal>;
};

export default Transaction;
