import React, { useState, memo } from "react";

import { useQuery } from "@apollo/client";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";

import { useDebounce } from "../../../util/useDebounce.js";
import { useButtonUpload } from "./useButtonSelectIcon";
import { serviceText } from "../../../constants/Constants";
import { SEARCH_ICONS } from "../../../graphql-client/Services/query";

const ButtonUpload = ({ text, changed, imgIcon, error }) => {
    const classes = useButtonUpload();
    const [searchIconText, setSearchIconText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [dbValue, saveToDb] = useState("");
    const debouncedSave = useDebounce((nextValue) => saveToDb(nextValue), 500);
    const handleClickButton = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const {
        loading: loadingSearchIcons,
        error: errorSearchIcons,
        data: dataSearchIcons,
    } = useQuery(SEARCH_ICONS, {
        variables: { searchText: dbValue },
    });

    if (errorSearchIcons) return `Error! ${errorSearchIcons.message}`;

    const handleChangeSearchIcon = (e) => {
        const { value: nextValue } = e.target;
        setSearchIconText(nextValue);
        debouncedSave(nextValue);
    };

    return (
        <FormGroup className={classes.formGroup}>
            <Button
                aria-describedby={id}
                onClick={handleClickButton}
                className={classes.iconButton}
                disableRipple={true}
                startIcon={
                    <Avatar
                        className={classes.avatar}
                        variant="rounded"
                        src={imgIcon}
                    />
                }
            >
                {text}
            </Button>

            <Typography
                variant="subtitle1"
                component="div"
                className={`${classes.hiddenIt} ${error ? classes.labelError : ""
                    }`}
            >
                <i
                    className={`fa fa-exclamation-circle ${classes.iconError}`}
                ></i>
                {error}
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className={classes.popover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{ className: classes.popoverPaper }}
            >
                <Grid container columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}>
                    <Grid item xs={1} sm={2} md={3} lg={4} xl={5}>
                        <InputBase
                            value={searchIconText}
                            onChange={handleChangeSearchIcon}
                            className={classes.input}
                            placeholder={
                                serviceText.inputIconService.placeholder
                            }
                            startAdornment={<SearchIcon />}
                        />
                        <Divider />
                    </Grid>

                    {loadingSearchIcons ? (
                        <></>
                    ) : (
                        dataSearchIcons.searchIcons.map((item, index) => (
                            <Grid
                                className={classes.gridItem}
                                item
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                key={index}
                            >
                                <IconButton
                                    onClick={changed}
                                    className={classes.itemIcon}
                                    disableRipple={true}
                                    aria-label="delete"
                                >
                                    <Avatar
                                        variant="square"
                                        src={item.imageUrl}
                                    />
                                </IconButton>
                            </Grid>
                        ))
                    )}
                </Grid>
            </Popover>
        </FormGroup>
    );
};

export default memo(ButtonUpload);
