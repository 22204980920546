/* eslint-disable */
import React, { useState, useEffect, memo } from "react";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import styles from "./ModalUpdate.module.css";

import { useModalUpdate } from "./useModalUpdate";
// import Input from "../../../components/Input/Input";
import ModalContainer from "../../../../components/ModalContainer/ModalContainer";
// import ButtonSelectIcon from "../ButtonSelectIcon/ButtonSelectIcon";
// import SelectInput from "../../../components/SelectInput/SelectInput";
import { GET_CONTRACT_TERM } from "../../../../graphql-client/ContractTerms/query";
import { useUpdateContractTerm } from "../../../../graphql-client/ContractTerms/mutations";
import SelectTypeTerm from "../SelectTypeTerm/SelectTypeTerm";
import ButtonHome from "../../../../components/ButtonHome/ButtonHome";
import { validateAddContractTerm } from "../../../../useCase/ContractTerm/useContractTerm";
import TextArea from "../../../../components/TextArea/TextArea";
import Notification from "../../../../components/Notification/Notification";
import { contractTermsText } from "../../../../constants/Constants";
import { set } from "lodash";

export default memo(function ModalDetail({
    openAdd,
    closeAdd,
    contractTermId,
}) {
    const {
        loading: loadingGetContractTerm,
        data: dataGetContractTerm,
        error: errorGetContractTerm,
    } = useQuery(GET_CONTRACT_TERM, {
        variables: {
            id: +contractTermId,
        },
        skip: contractTermId === null,
        fetchPolicy: "no-cache",
    });
    const {
        mutateUpdateContractTerm,
        dataUpdateContractTerm,
        loadingUpdateContractTerm,
        errorUpdateContractTerm,
    } = useUpdateContractTerm();

    const [content, setContent] = useState("");
    const [type, setType] = useState("");
    const [validationMessage, setValidationMessage] = useState({});

    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });

    const classes = useModalUpdate();

    useEffect(() => {
        if (!loadingGetContractTerm && dataGetContractTerm) {
            setType(dataGetContractTerm.getContractTerm.type);
            setContent(dataGetContractTerm.getContractTerm.content);
        }
    }, [loadingGetContractTerm]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validateAddContractTerm(content);
        if (!isValid.isValid) {
            setValidationMessage(isValid.msg);
        } else {
            await mutateUpdateContractTerm(
                {
                    id: contractTermId,
                    req: {
                        type: type,
                        content: content,
                    },
                },
                contractTermId
            );
            setNotify({
                ...contractTermsText.notify.update,
            });
            closeAdd();
        }
    };

    return (
        <>
            {loadingGetContractTerm ? (
                <></>
            ) : (
                <ModalContainer
                    closeModal={closeAdd}
                    openModal={openAdd}
                    title={contractTermsText.modalContractTerms.titleUpdate}
                >
                    <form onSubmit={handleSubmit} className={styles.form}>
                        <Grid container className={classes.container}>
                            <Grid item xs={12}>
                                <SelectTypeTerm
                                    data={contractTermsText.selectTypeTerm}
                                    clicked={(value) => setType(value)}
                                    selected={type}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextArea
                                    label={
                                        contractTermsText.textAreaContext.label
                                    }
                                    placeholder={
                                        contractTermsText.textAreaContext
                                            .placeholder
                                    }
                                    changed={(e) => setContent(e.target.value)}
                                    error={validationMessage.content}
                                    value={content}
                                    star={true}
                                />
                            </Grid>

                            <Grid item xs={10}></Grid>

                            <Grid item xs={2}>
                                <ButtonHome
                                    type="submit"
                                    text={
                                        contractTermsText.modalContractTerms
                                            .buttonUpdate
                                    }
                                    onClick={handleSubmit}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalContainer>
            )}
            <Notification notify={notify} setNotify={setNotify} />
        </>
    );
});
