import { makeStyles } from "@mui/styles";

export const useDrawerHome = makeStyles({
    drawer: {
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
            border: "none",
            boxShadow: "6px 0px 18px 0px rgba(0, 0, 0, 0.06);",
        },
        zIndex: 2
    },
    toolbar: {
        display: "flex",
        borderBottom: "1px solid #EBEFF2",
        height: "50px",
        alignItems: "center",
        paddingLeft: "17px",
        gap: "8px",
        padding: 0,
        minHeight: "50px",
    },
    logo: {
        width: "26px",
        height: "26px",
        borderRadius: "2px",
    },
    content: {
        padding: 0,
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "20px",
        lineHeight: "30px",
        color: "#22242C",
        "&:last-child": {
            paddingBottom: 0,
        },
    },
});
