import React, { memo } from "react";

import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";

import { useTextArea } from "./useTextArea";

const TextArea = ({ label, changed, placeholder, error, value, star }) => {
    const classes = useTextArea();
    return (
        <FormControl className={classes.formControl}>
            <Typography variant="p" component="div" className={classes.label}>
                {label}
                {star ? <span className={classes.star}>&nbsp;*</span> : <></>}
            </Typography>
            <InputBase
                rows="3"
                multiline={true}
                className={error ? classes.inputError : classes.input}
                placeholder={placeholder}
                onChange={changed}
                value={value}
            ></InputBase>
            <Typography
                className={`${classes.hiddenIt} ${
                    error ? classes.labelError : ""
                }`}
            >
                <i
                    className={`fa fa-exclamation-circle ${classes.iconError}`}
                ></i>
                {error}
            </Typography>
        </FormControl>
    );
};

export default memo(TextArea);
