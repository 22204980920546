import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    textField: {
        marginTop: "8px",
    },
    icon: {
        color: "rgba(194, 207, 224, 1)",
    },
    input: {
        "&::placeholder": {
            color: "rgba(144, 160, 183, 1)",
        },
    },
});
