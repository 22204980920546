import { makeStyles } from "@mui/styles";

export const useNotifications = makeStyles({
    snackbar: {
        backgroundColor: "#fff",
        // width: "400px",
        // height: "80px",
        boxShadow:
            "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)",
        borderRadius: "8px",
        transition: "none",
    },
    boxContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        padding: "16px 40px 16px 0px",
    },
    iconClose: {
        top: "-25px",
        left: "100%",
    },
    iconSuccess: {
        backgroundColor: "rgba(229, 253, 240, 1)",
        marginRight: "24px",
    },
    iconError: {
        backgroundColor: "rgba(252, 221, 236, 1)",
        marginRight: "24px",
    },
    title: {
        color: "rgba(84, 89, 94, 1)",
        fontSize: "20px",
        fontWeight: "600",
    },
    subTitle: {
        color: "rgba(84, 89, 94, 0.6)",
        fontSize: "14px",
        fontWeight: "400",
    },
});
