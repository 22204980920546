import { makeStyles } from "@mui/styles";

export const useCheckboxService = makeStyles({
    box: {
        display: "flex",
        justifyContent: "flex-start",
        // width: "15%",
        gap: "8px",
        marginLeft: "12px",
    },
    checkBox: {
        padding: 0,
        "&.Mui-checked": {
            color: "rgba(4, 195, 92, 1);",
        },
        "&:hover": {
            backgroundColor: "#fff",
        },
    },
    card: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "none",
    },
    cardMedia: {
        width: "35px",
        height: "35px",
    },
    cardContent: {
        padding: "0px !important",
        margin: 0,
    },
    nameService: {
        color: "#334D6E",
        fontWeight: "700",
    },
    priceService: {
        color: "#FF3927",
        fontSize: "12px",
    },
    checkBoxChip: {
        "& > .MuiCheckbox-root": {
            display: "none",
        },
        "& > .MuiTypography-root": {
            padding: "4px 12px",
            borderRadius: "15px",
            backgroundColor: "rgba(196, 196, 196, 1)",
            color: "white",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "600",
        },
        // display: "none",
        // color: "white",
    },
    active: {
        "& > .MuiTypography-root": {
            backgroundColor: "#04C35C",
        },
    },
});
