import { Form } from "react-bootstrap";
import styles from "./Input.module.css";
import React, { memo } from "react";

const Input = ({
    text,
    name,
    type,
    placeholder,
    changed,
    error,
    star,
    value,
    maxValue,
    isDisabled,
}) => {
    return (
        <Form.Group className={styles.formGroup} controlId={name}>
            <Form.Label className={styles.label}>
                {text}{" "}
                <span className={`${styles.star} ${star ? styles.active : ""}`}>
                    *
                </span>
            </Form.Label>
            <input
                className={`${styles.input} ${error ? styles.active : ""}`}
                type={type}
                placeholder={placeholder}
                name={name}
                onChange={changed}
                value={value}
                disabled={isDisabled}
                max={maxValue}
            />
            <div className={`${styles.alert} ${error ? styles.active : ""}`}>
                <i className={`fa fa-exclamation-circle ${styles.icon}`}></i>
                <label className={styles.labelError}>{error}</label>
            </div>
        </Form.Group>
    );
};

export default memo(Input);
