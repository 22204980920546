import {gql, useMutation} from '@apollo/client';
import {normalizeRequestObject} from '../../util/Request';

export const MUTATION_CREATE_INVOICE = gql`
    mutation CreateInvoice($req: AddRoomInvoiceReq!) {
        addRoomInvoice(req: $req){
            createdInvoiceId
            ok
        }
    }
`;
export const MUTATION_UPDATE_INVOICE = gql`
    mutation UpdateInvoice($id: Int!, $req: UpdateRoomInvoiceReq!) {
        updateRoomInvoice(id: $id, req: $req) {
            ok
        }
    }
`;

export function useUpdateInvoice() {
    const [mutateUpdateInvoice, {loading}] = useMutation(MUTATION_UPDATE_INVOICE, {
        refetchQueries: [
            'GetRoomsByInvoiceStatus',
            'RoomInvoices',
        ],
    });

    async function updateInvoice(id, req) {
        await mutateUpdateInvoice({
            variables: {
                id,
                req: normalizeRequestObject(req),
            },
        });
    }

    return {
        updateInvoice, loading,
    };
}

export function useCreateInvoice() {
    const [mutateCreateInvoice, {loading}] = useMutation(MUTATION_CREATE_INVOICE, {
        refetchQueries: [
            'GetRoomsByInvoiceStatus',
            'RoomInvoices',
        ],
    });

    async function createInvoice(req) {
        await mutateCreateInvoice({
            variables: {
                req: normalizeRequestObject(req),
            },
        });
    }

    return {
        createInvoice, loading,
    };
}

const MUTATION_DELETE_INVOICE = gql`
    mutation DeleteInvoice($id: Int!) {
        deleteRoomInvoice(id: $id){
            ok
        }
    }
`;

export function useDeleteInvoice() {
    const [mutateDeleteInvoice] = useMutation(MUTATION_DELETE_INVOICE, {
        refetchQueries: [
            'GetRoomsByInvoiceStatus',
            'RoomInvoices',
        ],
    });

    async function deleteInvoice(id) {
        await mutateDeleteInvoice({
            variables: {
                id,
            },
        });
    }

    return {
        deleteInvoice,
    };
}

export const MUTATION_PAY_INVOICE = gql`
    mutation PayInvoice($id: Int!, $req: PaidDataRequest) {
        payRoomInvoice(roomInvoiceId: $id, paidData: $req) {
            ok
        }
    }
`;

export function usePayInvoice() {
    const [mutatePayInvoice, {loading}] = useMutation(MUTATION_PAY_INVOICE);

    async function payInvoice(id, req) {
        await mutatePayInvoice({
            variables: {
                id, req,
            },
            refetchQueries: [
                'RoomInvoices',
            ],
        });
    }

    return {
        payInvoice,
        loading,
    };
}