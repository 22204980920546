import React, { memo } from "react";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { useAutoCompleteInput } from "./useAutoCompleteInput";
import styles from "./AutoCompleteInput.module.css";

export default memo(function AutoCompleteInput({
    label,
    star,
    data,
    placeholder,
    changed,
    defaultValue,
    error,
    field,
    noOptionsText,
    isDisabled,
}) {
    const classes = useAutoCompleteInput();

    return (
        <FormControl fullWidth className={classes.formControl}>
            <Typography
                variant="p"
                component="div"
                className={`${classes.label} ${
                    label ? "" : styles.labelActive
                }`}
            >
                {label}{" "}
                <span className={`${styles.star} ${star ? styles.active : ""}`}>
                    *
                </span>
            </Typography>

            <Autocomplete
                disablePortal
                className={classes.select}
                disabled={isDisabled}
                options={data}
                noOptionsText="Không có dữ liệu"
                onChange={changed}
                value={defaultValue}
                renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.id}>
                        {option[field]}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField {...params} placeholder={placeholder} />
                )}
                getOptionLabel={(option) => option[field] || ""}
                isOptionEqualToValue={(option, value) =>
                    option[field] === value[field]
                }
            />
            <Typography
                className={`${classes.hiddenIt} ${
                    error ? classes.labelError : ""
                }`}
            >
                <i
                    className={`fa fa-exclamation-circle ${classes.iconError}`}
                ></i>
                {error}
            </Typography>
        </FormControl>
    );
});
