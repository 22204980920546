import { useEffect, useState, memo } from "react";
import { Box, Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useQuery } from "@apollo/client";
import {
    MY_ROOMS,
    GET_ROOM,
    SYSTEM_SERVICES,
} from "../../graphql-client/Room/query";
import { useDeleteRoom } from "../../graphql-client/Room/mutation";

import { MY_MOTELS, GET_MOTELS } from "../../graphql-client/Motel/query";

import Loading from "../../components/Loading/Loading";
import AutoCompleteInput from "../../components/AutoCompleteInput/AutoCompleteInput";
import ButtonHome from "../../components/ButtonHome/ButtonHome";
import CardsRoomDetail from "./CardsRoomDetail/CardsRoomDetail";
import ContainerServices from "./ContainerServices/ContainerServices";
import Notification from "../../components/Notification/Notification";
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import EmptyIcon from "../../assets/Icons/EmptyIcon";

import { useRoom } from "./useRoom";
import ListRoom from "./ListRoom/ListRoom";
import ModalAdd from "./ModalAdd/ModalAdd";
import InfoRoom from "./InfoRoom/InfoRoom";
import {
    findFirstRoomByFloorNumber,
    sortRoomByFloorNumber,
} from "../../useCase/Room/useCaseRoom";
import { roomText } from "../../constants/Constants";

import { convertServicesData } from "../../util/ConvertServices";

const Room = () => {
    const classes = useRoom();
    const [open, setOpen] = useState(false);
    const [myRooms, setMyRooms] = useState([]);
    const [myMotels, setMyMotels] = useState([]);
    const [selectMotel, setSelectMotel] = useState("");
    const [motelId, setMotelId] = useState(null);
    const [roomId, setRoomId] = useState(null);
    const [services, setServices] = useState([]);
    const [systemServices, setSystemServices] = useState([]);
    const [room, setRoom] = useState();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
    });
    const [notify, setNotify] = useState({
        isOpen: false,
        title: "",
        message: "",
        type: "",
    });
    const navigate = useNavigate();

    const {
        loading: loadingMyRooms,
        data: dataMyRooms,
        error: errorMyRooms,
    } = useQuery(MY_ROOMS, {
        variables: {
            motelIds: [motelId],
        },
        skip: motelId === null,
    });

    const {
        loading: loadingMyMotels,
        data: dataMyMotels,
        error: errorMyMotels,
    } = useQuery(MY_MOTELS);

    const {
        loading: loadingGetMotels,
        data: dataGetMotels,
        error: errorGetMotels,
    } = useQuery(GET_MOTELS, {
        variables: {
            motelId: +motelId,
        },
        skip: motelId === null,
    });

    const {
        loading: loadingGetRoom,
        data: dataGetRoom,
        error: errorGetRoom,
    } = useQuery(GET_ROOM, {
        variables: {
            roomId: roomId,
        },
        skip: roomId === null,
    });

    const {
        loading: loadingSystemServices,
        data: dataSystemServices,
        error: errorSystemServices,
    } = useQuery(SYSTEM_SERVICES);

    const { mutateDeleteRoom, dataDeleteRoom } = useDeleteRoom();

    const handleOpen = () => {
        selectMotel === undefined
            ? setConfirmDialog({
                  ...roomText.dialog.notMotel,
                  onConfirm: () => {
                      setConfirmDialog({
                          ...confirmDialog,
                          isOpen: false,
                      });
                      navigate("/motel");
                  },
              })
            : setOpen(true);

        return true;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteMotel = (id) => {
        let index = myRooms.findIndex((x) => x.id === id);
        setConfirmDialog({
            ...roomText.dialog.delete,
            onConfirm: async () => {
                await mutateDeleteRoom({ id: +id }, selectMotel.id);
                setConfirmDialog({
                    ...confirmDialog,
                    isOpen: false,
                });
                if (myRooms.length === 1) {
                    setRoomId(null);
                    setRoom();
                } else {
                    setRoomId(
                        index === myRooms.length - 1
                            ? myRooms[index - 1].id
                            : myRooms[index + 1].id
                    );
                }
                setNotify({
                    ...roomText.notify.delete,
                });
            },
        });
    };

    useEffect(() => {
        setRoomId(null);
    }, [selectMotel]);

    useEffect(() => {
        if (loadingMyMotels === false) {
            let data = dataMyMotels.myMotels;
            if (data.length > 0) {
                setMyMotels(data);
                setMotelId(data[0].id);
                setSelectMotel(data[0]);
            }
        }
    }, [loadingMyMotels, dataMyMotels]);

    useEffect(() => {
        if (loadingMyRooms === false && dataMyRooms) {
            const data = sortRoomByFloorNumber(dataMyRooms.myRooms);
            if (!findFirstRoomByFloorNumber(data)) {
                setRoom(null);
                setRoomId(null);
            }
            if (!roomId && findFirstRoomByFloorNumber(data)) {
                setRoomId(findFirstRoomByFloorNumber(data).id);
            }
            setMyRooms(data);
        }
    }, [loadingMyRooms, dataMyRooms, loadingMyMotels, roomId, motelId]);

    useEffect(() => {
        if (loadingGetRoom === false && dataGetRoom) {
            setRoom(dataGetRoom.getRoom);
        }
    }, [loadingGetRoom, dataGetRoom]);

    useEffect(() => {
        if (loadingGetMotels === false && dataGetMotels) {
            setServices(convertServicesData(dataGetMotels.getMotel.services));
        }
    }, [loadingGetMotels, dataGetMotels]);

    useEffect(() => {
        if (loadingSystemServices === false) {
            setSystemServices(dataSystemServices.systemServices);
        }
    }, [loadingSystemServices, dataSystemServices]);

    return (
        <Box sx={{ flexGrow: 1 }} className={classes.container}>
            <Grid container spacing={2} style={{ height: "100%" }}>
                <Grid item lg={4} xl={3} style={{ height: "100%" }}>
                    <Box className={classes.containerRoom}>
                        <AutoCompleteInput
                            data={myMotels}
                            changed={(_event, selected) => {
                                if (selected) {
                                    setSelectMotel(selected);
                                    setMotelId(selected.id);
                                }
                            }}
                            defaultValue={selectMotel}
                            field="name"
                        />
                        <Box className={classes.containerList}>
                            {loadingMyRooms ? (
                                <Loading />
                            ) : (
                                <ListRoom
                                    data={myRooms}
                                    clicked={(id) => setRoomId(id)}
                                    roomId={roomId}
                                />
                            )}
                        </Box>
                        <Box className={classes.buttonContainer}>
                            <ButtonHome
                                text="Thêm phòng"
                                onClick={handleOpen}
                            />
                            <ModalAdd
                                closeAdd={handleClose}
                                openAdd={open}
                                services={services}
                                motelId={motelId}
                                systemServices={systemServices}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    lg={8}
                    xl={9}
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {loadingGetRoom ? (
                        <Loading />
                    ) : room ? (
                        <Box style={{ height: "100%", width: "100%" }}>
                            <InfoRoom
                                data={room}
                                clickedDelete={(id) => handleDeleteMotel(id)}
                                services={services}
                                systemServices={systemServices}
                                motelId={motelId}
                            />
                            <CardsRoomDetail data={room} />
                            <ContainerServices data={room} />
                        </Box>
                    ) : (
                        <EmptyIcon />
                    )}
                    <Notification notify={notify} setNotify={setNotify} />
                    <ConfirmDialog
                        confirmDialog={confirmDialog}
                        setConfirmDialog={setConfirmDialog}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default memo(Room);
