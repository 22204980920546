import { memo } from "react"
import FormControl from '@mui/material/FormControl';
import { Typography, FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useRadioGroupBasic } from './useRadioGroupBasic'

const RadioGroupBasic = ({ label, data, value, changed }) => {
    const classes = useRadioGroupBasic()

    return (
        <FormControl component="fieldset" >
            <Typography style={{ fontSize: "14px" }}>{label}<Typography component="span" style={{ fontSize: "14px", color: "red" }}>&nbsp;*</Typography></Typography>

            <RadioGroup row name="row-radio-buttons-group" value={value} onChange={changed} className={classes.radio}>
                {data.map((value, index,) => <FormControlLabel key={index} value={value.id} control={<Radio disableRipple={true} />} label={value.name} />)}
            </RadioGroup>
        </FormControl>
    )
}

export default memo(RadioGroupBasic)
