import { useState, useEffect, memo, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useQuery } from "@apollo/client";
import Table from "./Table/Table";
import { GET_SERVICES } from "../../graphql-client/Services/query";
import { headCellServices } from "../../constants/Constants";
import { convertServicesData } from "../../util/ConvertServices";
import Loading from "../../components/Loading/Loading";
import { useService } from "./useService";
import { HomeContext } from "../../layout/HomeLayout/HomeLayout";
import { SearchByKeyword } from "../../util/SearchByKeyword";

const Service = () => {
    const classes = useService();

    const [dataTable, setDataTable] = useState([]);
    const home = useContext(HomeContext).dbValue;

    const { loading: getServicesLoading, data: getServicesData } =
        useQuery(GET_SERVICES);

    useEffect(() => {
        if (getServicesLoading === false) {
            setDataTable(convertServicesData(getServicesData.services));
        }
    }, [getServicesLoading, getServicesData]);

    return (
        <>
            <Box sx={{ flexGrow: 1 }} className={classes.box}>
                {getServicesLoading ? (
                    <Loading />
                ) : (
                    <Grid style={{ height: "100%", width: "100%" }}>
                        <Grid item xs={12} style={{ height: "100%" }}>
                            <Table
                                headCells={headCellServices}
                                rowsData={SearchByKeyword(
                                    dataTable,
                                    "name",
                                    home
                                )}
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
};

export default memo(Service);
