import { Box, Typography, } from '@mui/material'

const EmptyIcon = () => {
    return (
        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <svg width="106" height="124" viewBox="0 0 53 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_6054_3793)">
                    <path d="M44.8646 9H8.13542C6.95606 9 6 9.95606 6 11.1354V55.9792C6 57.1585 6.95606 58.1146 8.13542 58.1146H44.8646C46.0439 58.1146 47 57.1585 47 55.9792V11.1354C47 9.95606 46.0439 9 44.8646 9Z" fill="white" />
                </g>
                <path d="M22.2292 15.4062H11.125C10.4174 15.4062 9.84375 15.9799 9.84375 16.6875C9.84375 17.3951 10.4174 17.9688 11.125 17.9688H22.2292C22.9368 17.9688 23.5104 17.3951 23.5104 16.6875C23.5104 15.9799 22.9368 15.4062 22.2292 15.4062Z" fill="#04C35C" />
                <path d="M29.9167 20.9584H11.125C10.4174 20.9584 9.84375 21.532 9.84375 22.2396C9.84375 22.9472 10.4174 23.5209 11.125 23.5209H29.9167C30.6243 23.5209 31.1979 22.9472 31.1979 22.2396C31.1979 21.532 30.6243 20.9584 29.9167 20.9584Z" fill="#04C45C" fillOpacity="0.2" />
                <path d="M22.2292 26.9375H11.125C10.4174 26.9375 9.84375 27.5111 9.84375 28.2188C9.84375 28.9264 10.4174 29.5 11.125 29.5H22.2292C22.9368 29.5 23.5104 28.9264 23.5104 28.2188C23.5104 27.5111 22.9368 26.9375 22.2292 26.9375Z" fill="#04C45C" fillOpacity="0.2" />
                <path d="M29.9167 32.4896H11.125C10.4174 32.4896 9.84375 33.0633 9.84375 33.7709C9.84375 34.4785 10.4174 35.0521 11.125 35.0521H29.9167C30.6243 35.0521 31.1979 34.4785 31.1979 33.7709C31.1979 33.0633 30.6243 32.4896 29.9167 32.4896Z" fill="#04C45C" fillOpacity="0.2" />
                <path d="M22.2292 38.4688H11.125C10.4174 38.4688 9.84375 39.0424 9.84375 39.75C9.84375 40.4576 10.4174 41.0312 11.125 41.0312H22.2292C22.9368 41.0312 23.5104 40.4576 23.5104 39.75C23.5104 39.0424 22.9368 38.4688 22.2292 38.4688Z" fill="#04C45C" fillOpacity="0.2" />
                <path d="M29.9167 44.0209H11.125C10.4174 44.0209 9.84375 44.5945 9.84375 45.3021C9.84375 46.0097 10.4174 46.5834 11.125 46.5834H29.9167C30.6243 46.5834 31.1979 46.0097 31.1979 45.3021C31.1979 44.5945 30.6243 44.0209 29.9167 44.0209Z" fill="#04C45C" fillOpacity="0.2" />
                <defs>
                    <filter id="filter0_d_6054_3793" x="0" y="0" width="53" height="61.1146" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                        <feOffset dy="-3" />
                        <feGaussianBlur stdDeviation="3" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6054_3793" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6054_3793" result="shape" />
                    </filter>
                </defs>
            </svg >
            <Typography style={{ color: "rgba(51, 77, 110, 1)", fontSize: "14px" }}>Không có dữ liệu</Typography>
        </Box >


    )
}

export default EmptyIcon
