/* eslint-disable eqeqeq */
import React from "react";

import IconButton from "@mui/material/IconButton";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import styles from "./UploadImage.module.css";
import { useUploadImage } from "./useUploadImage";

export default function App({ previews, raws, changed, deleted, maxImage }) {
    const classes = useUploadImage();
    return (
        <>
            <div className={styles.images}>
                {previews.length > 0 &&
                    previews.map((item, index) => {
                        return (
                            <div key={item} className={styles.imagePreview}>
                                <img src={item} alt="" />
                                <IconButton
                                    className={classes.closeButton}
                                    aria-label="close"
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    size="small"
                                    onClick={() => deleted(index)}
                                >
                                    <CancelRoundedIcon
                                        className={classes.iconClose}
                                        color="error"
                                    />
                                </IconButton>
                            </div>
                        );
                    })}
            </div>
            {previews.length == maxImage ? (
                <></>
            ) : (
                <label htmlFor="image-add" style={{ cursor: "pointer" }}>
                    <svg
                        width="130"
                        height="78"
                        viewBox="0 0 130 78"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="130" height="78" rx="5" fill="#E8E8E8" />
                        <path
                            d="M78.2682 41H67.1182V52.25H63.8682V41H52.7682V38H63.8682V26.75H67.1182V38H78.2682V41Z"
                            fill="#C2CFE0"
                        />
                    </svg>
                </label>
            )}
            <div>
                <input
                    id="image-add"
                    accept="image/png,image/jpeg,image/jpg"
                    type="file"
                    style={{ display: "none", cursor: "pointer" }}
                    disabled={raws.length == maxImage}
                    onChange={changed}
                    value=""
                />
            </div>
        </>
    );
}
