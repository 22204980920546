import styles from "./Header.module.css";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

const Header = () => {
    const location = useLocation();
    const redirect = location.pathname.split("/")[1];

    if (redirect === "signup") {
        const navigation = [
            {
                text: "Hướng dẫn sử dụng",
                link: "#",
            },
            {
                text: "Liên hệ",
                link: "#",
            },
            {
                text: "Đăng nhập",
                link: "/",
            },
            {
                text: "Trang chủ",
                link: "#",
            },
        ];
        return (
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img src={Logo} alt="Logo" />
                    <p>LalaHome</p>
                </div>
                <nav>
                    <ul className={styles.navigation}>
                        {navigation.map((value, index) => (
                            <li key={index}>
                                <Link to={value.link}>{value.text}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        );
    } else {
        const navigation = [
            {
                text: "Hướng dẫn sử dụng",
                link: "#",
            },
            {
                text: "Liên hệ",
                link: "#",
            },
            {
                text: "Đăng ký",
                link: "signup",
            },
            {
                text: "Trang chủ",
                link: "#",
            },
        ];
        return (
            <div className={styles.container}>
                <div className={styles.logo}>
                    <img src={Logo} alt="Logo" />
                    <p>LalaHome</p>
                </div>
                <nav>
                    <ul className={styles.navigation}>
                        {navigation.map((value, index) => (
                            <li key={index}>
                                <Link to={value.link}>{value.text}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        );
    }
};

export default Header;
