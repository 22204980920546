import React, { memo, useEffect, useState } from 'react';
import { Box, Typography } from "@mui/material";
import CardService from "../../../components/CardService/CardService";
import BasicChip from "../../../components/Chip/BasicChip";
import { useContainerServices } from "./useContainerServices";
import { categoryServicesMotel } from "../../../useCase/Motel/categoryServicesMotel"
import { motelText } from "../../../constants/Constants"
import ListRoom from '../ListRoom/ListRoom';

import { useQuery } from "@apollo/client";
import { MY_ROOMS } from "../../../graphql-client/Room/query"
import Loading from '../../../components/Loading/Loading';
import EmptyIcon from '../../../assets/Icons/EmptyIcon';

const ContainerServices = ({ data }) => {
    const classes = useContainerServices();
    const [listRoom, setListRoom] = useState([])

    const { loading: loadingMyRooms, data: dataMyRooms, error: errorMyRooms } = useQuery(MY_ROOMS, {
        variables: {
            motelIds: data.getMotel.id
        },
        skip: data.getMotel.id === null,
        fetchPolicy: "cache-and-network"
    })

    useEffect(() => {
        if (loadingMyRooms === false) {
            let data = dataMyRooms.myRooms
            setListRoom(data)

        }
    }, [loadingMyRooms, dataMyRooms])


    return (
        <Box className={classes.container}>
            <Box className={classes.containerMotel} >
                <Typography className={classes.title}>
                    {motelText.paidService}
                </Typography>
                <Box className={classes.containerCardServices}>
                    {
                        categoryServicesMotel(data.getMotel.services).paidServices.length > 0 ?
                            <>
                                {
                                    categoryServicesMotel(data.getMotel.services).paidServices.map((value, index) =>
                                        <CardService key={index} data={value} />
                                    )
                                }
                            </> :
                            <EmptyIcon />
                    }

                </Box>

                <Typography className={classes.title}>
                    {motelText.freeService}
                </Typography>
                <Box className={classes.containerCardServices}>
                    {
                        categoryServicesMotel(data.getMotel.services).paidServices.length > 0 ?
                            <>
                                {
                                    categoryServicesMotel(data.getMotel.services).freeServices.map((value, index) =>
                                        <CardService key={index} data={value} />
                                    )
                                }
                            </> :
                            <EmptyIcon />
                    }

                </Box>
                <Typography className={classes.title}>
                    {motelText.systemServices}
                </Typography>
                <Box className={classes.chips}>
                    {data.getMotel.allSystemServices.map((item, index) =>
                        <div key={index} style={{ height: "32px" }}>
                            <BasicChip title={item.service.name} isActive={item.isActive} />
                        </div>
                    )}

                </Box>
                <Typography className={classes.title}>
                    {motelText.description}
                </Typography>
                <Box>
                    {
                        data.getMotel.description ?
                            <Typography className={classes.subNote}>
                                {data.getMotel.description}
                            </Typography>
                            :
                            <EmptyIcon />
                    }
                </Box>

                <Typography className={classes.title}>
                    {motelText.note}
                </Typography>

                <Box>
                    {
                        data.getMotel.note ?
                            <Typography className={classes.subNote}>
                                {data.getMotel.note}
                            </Typography>
                            :
                            <EmptyIcon />
                    }
                </Box>


                <Typography className={classes.title}>
                    {motelText.invoiceNote}
                </Typography>
                <Box>
                    {
                        data.getMotel.invoiceNote ?
                            <Typography className={classes.subNote}>
                                {data.getMotel.invoiceNote}
                            </Typography>
                            :
                            <EmptyIcon />
                    }
                </Box>

            </Box>

            <Box className={classes.containerListRoom} >
                {loadingMyRooms ? <Loading /> :
                    <ListRoom data={listRoom} />
                }
            </Box>

        </Box >

    );
};

export default memo(ContainerServices);
